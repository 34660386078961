import React from 'react';
import { Box, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';

interface ExternalComponentContainerProps {
  children: React.ReactNode[];
}

const PaymentFrame: React.FC<ExternalComponentContainerProps> = ({ children }) => {
  const { sizingBig } = useTheme();
  return (
    <AnimatePresence exitBeforeEnter>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: 3, p: 3, pt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: sizingBig(15), minHeight: sizingBig(18) }}>
          {children[0]}
        </Box>
        <Box
          sx={{
            width: sizingBig(15),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {children[1]}
        </Box>
      </Box>
    </AnimatePresence>
  );
};

export default PaymentFrame;
