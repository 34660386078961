import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    dialogForm: {
      overflowY: 'auto',
      minHeight: '50vh',
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

export default buildClasses;
