/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { assignCreditToCustomer } from 'stores/AllOrders';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Box, TextField, useTheme } from '@mui/material';
import InputUnit from 'components/Shared/Inputs/InputUnit';
import { useTranslation } from 'react-i18next';
import { formatToEdit, numberFromMoney } from 'utils/payment/PaymentUtils';
import { useForm } from 'react-hook-form';
import usePaymentSettings from 'hooks/usePaymentSettings';
import Validators from 'utils/validation/ValidationConstants';

interface AssignCustomerCreditDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: () => void;
}

const AssignCustomerCreditDialog: React.FC<AssignCustomerCreditDialogProps> = ({
  showDialog,
  orderId,
  abort,
  onConfirm,
}) => {
  const formId = 'assign-customer-credit-form';

  const { maxCustomerCreditAssignment } = useAppSelector((state) => state.config);

  const [t] = useTranslation('orders');
  const dispatch = useAppDispatch();
  const { spacing, sizing } = useTheme();
  const { register, errors, handleSubmit, getValues, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { GetPaymentSettings } = usePaymentSettings();
  const paymentSettings = GetPaymentSettings();

  function submit(data: any) {
    trigger().then((result) => {
      if (result === true) {
        dispatch(
          assignCreditToCustomer({
            reason: data.reason,
            amount: numberFromMoney(data.inputValue, paymentSettings),
            orderId,
          }),
        );
        onConfirm();
      }
    });
  }

  function maxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('intake:max.')} ${max})`;
  }

  return (
    <ModalContainer
      onClose={abort}
      open={showDialog}
      title={t('Assign customer credit')}
      primaryButton={{
        type: 'button',
        action: () => {
          submit(getValues());
        },
        label: t('Credit value'),
        testId: 'assign-customer-credit-modal__button--assign',
      }}
      testId="assign-customer-credit-modal"
    >
      <Box component="form" name={formId} id={formId} onSubmit={handleSubmit((data) => submit(data))}>
        <TextField
          sx={{ padding: `${spacing(3)} ${spacing(3)}`, width: sizing.full }}
          multiline
          name="reason"
          variant="outlined"
          rows="4"
          error={!!errors.reason}
          inputRef={register({
            required: t('Reason is required'),
            maxLength: { value: 255, message: maxLengthMessage(255) },
          })}
          helperText={errors.reason && errors.reason.message}
          inputProps={{ 'data-testid': 'assign-customer-credit-modal__input--credit-reason' }}
          placeholder={t('Reason')}
        />
        <Box sx={{ padding: `${spacing(2)} ${spacing(3)}` }}>
          <InputUnit
            inputValidation={{
              errors,
              formRef: register,
              required: true,
              validators: {
                isMoney: (val: string): boolean | string =>
                  Validators.Money.Validator(paymentSettings).test(val) || t(Validators.Money.Message),
                isAboveValue: (val: string): boolean | string =>
                  Validators.IsAboveGivenValue.Validator(numberFromMoney(val, paymentSettings), 0) ||
                  t('Value must be greater than 0'),
                isBelowLimit: (val: string): boolean | string =>
                  Validators.IsBelowOrderTotal.Validator(
                    numberFromMoney(val, paymentSettings),
                    Number(maxCustomerCreditAssignment),
                  ) || `${t('Value must be below')} ${maxCustomerCreditAssignment}`,
              },
            }}
            defaultValue={formatToEdit(0, paymentSettings)}
            testId="assign-customer-credit-modal__input--credit-value"
            label={t('Credit value')}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default AssignCustomerCreditDialog;
