import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import BasketItem from 'components/Shared/Basket/BasketItem';
import { DiscountType, ReceiptDiscount } from 'typings/Basket';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { DiscountIcon } from 'assets/icons';

interface BasketDiscountProps {
  receiptDiscount: ReceiptDiscount;
  alwaysExpanded?: boolean;
}

const BasketDiscount: React.FC<BasketDiscountProps> = ({ receiptDiscount, alwaysExpanded }) => {
  const { description, isSet } = receiptDiscount;
  const [showDetails, setShowDetails] = useState<boolean>(alwaysExpanded ?? false);
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const { t } = useTranslation('intake');
  const canShowDetails: boolean =
    (isSet &&
      receiptDiscount &&
      receiptDiscount.discountProducts &&
      receiptDiscount.discountProducts.length > 0) ??
    false;
  const expandDetailsButtonVisible = canShowDetails && !alwaysExpanded;

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${palette.grey[200]}`,
      }}
      data-testid={`basket-discount__wrapper--${receiptDiscount.code}`}
    >
      <Box
        sx={{
          px: 1.5,
          py: 1,
          backgroundColor: palette.green[50],
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: spacing(0.5),
            ml: spacing(-1.5),
            backgroundColor: palette.green[600],
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: palette.green[200],
            borderRadius: spacing(2),
            width: spacing(4),
            height: spacing(4),
            mr: 1.5,
          }}
        >
          <DiscountIcon />
        </Box>
        <Box sx={{ flexGrow: 1 }} data-testid="basket-discount__field--description">
          <Typography variant="body2" color="black.main">
            {description}
          </Typography>
          <Typography variant="body2" color="green.700">
            {t('Discount applied')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {receiptDiscount.value && receiptDiscount.discountType === DiscountType.FixedPrice && (
            <Typography variant="body1" color="black.main" data-testid="basket-discount__field--discount-value">
              {formatToDisplay(receiptDiscount.value)}
            </Typography>
          )}
          {expandDetailsButtonVisible && (
            <>
              {showDetails ? (
                <ExpandLess
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setShowDetails(false);
                  }}
                  data-testid="basket-discount__button--show-less"
                />
              ) : (
                <ExpandMore
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setShowDetails(true);
                  }}
                  data-testid="basket-discount__button--show-more"
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {showDetails && (
        <Box
          sx={{
            backgroundColor: palette.grey[100],
          }}
        >
          {receiptDiscount.discountProducts?.map((rp, index) => (
            <BasketItem
              receiptProduct={rp}
              discountColor={palette.green[600]}
              alwaysExpanded={alwaysExpanded}
              index={index}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BasketDiscount;
