import { AxiosResponse, AxiosError } from 'axios';
import posApi, { posApiUrls } from 'API/PosApi';

export interface RequestCalculateChange {
  totalPrice: number;
  paymentAmount: number;
}

async function calculateChange(paymentAmount: number, totalPrice: number): Promise<number | AxiosError> {
  const req: RequestCalculateChange = {
    paymentAmount,
    totalPrice,
  };
  try {
    const response: AxiosResponse<number> = await posApi.get(posApiUrls.PAYMENT_CHANGE, {
      params: req,
    });
    return response.data;
  } catch (error) {
    return error as AxiosError;
  }
}

export default calculateChange;
