import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, typography }) => {
  return {
    icon: {
      color: `${palette.primary.main}`,
      fontSize: spacing(3),
    },
    numpadButton: {
      userSelect: 'none',
      color: palette.black.main,
      background: palette.grey[50],
      margin: spacing(1),
      fontSize: typography.h5.fontSize,
      borderRadius: spacing(1),
      minHeight: '100px',
      width: `calc(33.3% - ${spacing(2)})`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    numpadClearButton: {
      fontSize: typography.button.fontSize,
      background: 'transparent',
    },
    hiddenInput: {
      width: sizing.pinPad.width,
      height: spacing(4),
      position: 'absolute',
      opacity: 0,
    },
  };
});
export default buildClasses;
