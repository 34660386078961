import posApi, { posApiUrls } from 'API/PosApi';
import { IntakeStatuses, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { isValidAxiosResponse } from 'typings/type-guards';
import { validateBasket, recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { clearBasket, setBasketState, initialState as initialBasketState } from 'stores/Basket/basket.slice';
import { formatToDisplay } from 'utils/payment/PaymentUtils';
import {
  initialState as initialIntakeState,
  setActiveDeliveryType,
  setActivePaymentMethod,
  setIntakeState,
} from 'stores/Intake/intake.slice';
import { BasketRequestParams, BasketState } from 'typings/Basket';
import {
  mapOSMDeliveryAddress,
  mapOSMDeliveryAddressToSelectedAddress,
  mapOSMDiscountToActiveDiscount,
  mapOSMDiscountToCouponInformation,
  mapToOSMDeliveryAddress,
} from 'utils/orders/ordersUtils';
import { OSMOrderDetails, OSMOrderDetailsWithBasketData, getOrderDetails } from 'stores/AllOrders';
import { PaymentMethod, PaymentMethodCode, PaymentTarget } from 'typings/Payments';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { startEftPayment } from 'stores/Eft';
import uuidGenerator from 'utils/GuidGenerator';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';
import { restartOrder } from 'stores/combined.actions';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { generateBasketRequest } from 'utils/intake/basketUtils';
import { isAddressValid } from 'utils/intake/IntakeUtils';
import { checkCurrentCustomerCanPayOnAccount, setCustomerAddresses } from 'stores/Customer';
import { StoreConfiguration } from 'typings/Store';
import { getPaymentMethods } from 'stores/Payments/payments.thunk-actions';
import { FiscalSummary } from 'utils/applicationContainer/fiscalUtils';
import { OrderStatus } from 'containers/AllOrders/AllOrderConsts';
import {
  RequestFinishTakeAwayOrder,
  RequestFinishDeliveryOrder,
  OrderHistory,
  RequestFinishPickupOrder,
  IntakeState,
  RequestAcceptTakeAwayPayment,
  OrderEditionMode,
  LocalizationDetails,
  OrderCustomer,
  OrderCustomerProfile,
  RequestFinishBaseOrder,
  OnSiteSettings,
  OrderDeliveryAddress,
  DeliveryAreaDetails,
} from './intakeTypes';

export const getOrderHistory = createAsyncThunk<
  { ticketNumber: string; isUnpaid: boolean; changeAmount?: string; tableIdentifier?: string },
  string,
  { state: RootState }
>('[INTAKE]/getOrderHistory', async (order, { getState }) => {
  const { lastOrderChangeAmount } = getState().intake;
  const { payment } = getState().config;

  const result = await posApi.get<OrderHistory>(posApiUrls.HISTORY_ORDER(order));
  const changeAmount =
    lastOrderChangeAmount && payment ? formatToDisplay(lastOrderChangeAmount, payment) : undefined;
  return {
    ticketNumber: result.data.order?.ticketNumber.toString(),
    isUnpaid: result.data.order.status === OrderStatus.AwaitingExternalPayment,
    tableIdentifier: result.data.order.tableIdentifier,
    changeAmount,
  };
});

export const getLocalizationDetails = createAsyncThunk<
  LocalizationDetails | undefined,
  { zipCode: string; streetNumber: string },
  { state: RootState }
>('[INTAKE]/getLocalizationDetails', async ({ zipCode, streetNumber }) => {
  const result = await posApi.get<LocalizationDetails>(
    posApiUrls.LOCALIZATION_PLACE_DETAILS(zipCode, streetNumber),
  );
  if (isValidAxiosResponse(result)) {
    return result.data;
  }

  return undefined;
});

export const getDeliveryAreaDetails = createAsyncThunk<
  DeliveryAreaDetails | undefined,
  OrderDeliveryAddress,
  { state: RootState }
>('[INTAKE]/getDeliveryAreaDetails', async (deliveryAddress, { getState }) => {
  const deliveryAddressDto = mapToOSMDeliveryAddress(deliveryAddress);
  const result = await posApi.get<StoreConfiguration[]>(posApiUrls.LOCALIZATION_DELIVERY_AREA_DETAILS, {
    params: deliveryAddressDto,
  });

  const { selectedStore } = getState().stores;
  if (isValidAxiosResponse(result)) {
    return {
      isAddressInCurrentStoreArea: result.data.some((el) => el.id === selectedStore?.id),
      storesDeliveringForRequiredAddress: result.data.map((el) => el.name),
    } as DeliveryAreaDetails;
  }
  throw new Error('Cannot load delivery areas information!');
});

export const finalizeOrder = createAsyncThunk<
  { ticketNumber?: string; changeAmount?: string },
  {
    finalizationOrderId: string;
    paymentMethodCode: PaymentMethodCode;
    eftPaymentId?: string;
    eftFinishedManually?: boolean;
    companyId?: number;
    successCallback: () => void;
  },
  { state: RootState }
>(
  '[INTAKE]/finalizeOrder',
  async (
    { finalizationOrderId, paymentMethodCode, eftPaymentId, eftFinishedManually, companyId, successCallback },
    { dispatch, getState },
  ) => {
    const { lastOrderChangeAmount } = getState().intake;
    const { payment } = getState().config;

    const result = await posApi.put(posApiUrls.ORDER_FINALIZE(finalizationOrderId), null, {
      params: {
        paymentMethodCode,
        eftPaymentId,
        eftFinishedManually,
        companyId,
      },
    });

    if (!result || result.status !== 200) {
      throw Error('Error finalizing order');
    }

    if (isValidAxiosResponse(result)) {
      successCallback();
      dispatch(restartOrder());
    }

    const orderResult = await posApi.get<OrderHistory>(posApiUrls.HISTORY_ORDER(finalizationOrderId));
    const changeAmount =
      lastOrderChangeAmount && payment ? formatToDisplay(lastOrderChangeAmount, payment) : undefined;

    return {
      ticketNumber: orderResult?.data?.order?.ticketNumber?.toString(),
      changeAmount,
    };
  },
);

export const fiscalizeOsmOrder = createAsyncThunk<
  {},
  {
    orderId: string;
    callback: () => void;
  },
  { state: RootState }
>('[INTAKE]/FiscalizeOsmOrder', async ({ orderId, callback }, { dispatch, getState }) => {
  const result = await posApi.put(posApiUrls.OSM_ORDER_FISCALIZE(orderId), null, {});

  callback();

  if (!result || result.status !== 200) {
    throw Error('Error fiscalization order');
  }

  return {};
});

export const postFinishTakeAwayOrder = createAsyncThunk<string, RequestFinishTakeAwayOrder, { state: RootState }>(
  '[INTAKE]/postFinishTakeAwayOrder',
  async (params, { dispatch }) => {
    const result = await posApi.post<string>(posApiUrls.ORDER_TAKEAWAY, JSON.stringify(params));

    if (!result || result.status !== 200) {
      throw Error('Error placing order!');
    }

    await dispatch(getOrderHistory(result.data));

    dispatch(restartOrder());
    sendIntakeFinalizedEvent();

    return result.data;
  },
);

export const postPlaceUnpaidTakeAwayOrder = createAsyncThunk<
  string,
  RequestFinishTakeAwayOrder,
  { state: RootState }
>('[INTAKE]/postPlaceUnpaidTakeAwayOrder', async (params, { dispatch, rejectWithValue, getState }) => {
  const {
    config: { payment },
    stores: { selectedStore },
  } = getState(); // to remove when V1 is deleted

  const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id); // to remove when V1 is deleted
  const apiEndpoint = useV2Payment // to remove when V1 is deleted
    ? posApiUrls.ORDER_TAKEAWAY_V3 // to remove when V1 is deleted
    : posApiUrls.ORDER_TAKEAWAY; // to remove when V1 is deleted

  const placeUnpaidOrderRequest = {
    // to remove when V1 is deleted
    ...params, // to remove when V1 is deleted
    paymentMethodCode: undefined, // to remove when V1 is deleted
  }; // to remove when V1 is deleted

  try {
    const result = await posApi.post<string>(apiEndpoint, JSON.stringify(placeUnpaidOrderRequest));

    if (!result || result.status !== 200) {
      throw Error('Error placing order!');
    }

    await dispatch(getOrderHistory(result.data));
    dispatch(payForOrderWithEft());

    return result.data;
  } catch (err: any) {
    return err instanceof Error ? rejectWithValue(err.message) : rejectWithValue(err.toString());
  }
});

export const payForOrderWithEft = createAsyncThunk<void, void, { state: RootState }>(
  '[INTAKE]/payForOrderWithEft',
  async (_, { dispatch, getState }) => {
    const appInsights = getAppInsights();
    const paymentIdentifier = uuidGenerator();
    const { settlePayment } = getState().payments;

    if (settlePayment?.totalToPay && settlePayment?.orderId) {
      appInsights.trackEvent({
        name: 'Eft payment started',
        properties: { Identifier: paymentIdentifier, Status: undefined },
      });
      dispatch(
        startEftPayment({
          amount: settlePayment?.totalToPay ?? 0,
          identifier: paymentIdentifier,
          orderId: settlePayment?.openTabId ?? settlePayment?.orderId,
          paymentTarget: settlePayment?.openTabId ? PaymentTarget.OpenTab : PaymentTarget.Order,
        }),
      );
    }
  },
);

export const putAcceptTakeAwayPayment = createAsyncThunk<
  { ticketNumber?: string; changeAmount?: string },
  RequestAcceptTakeAwayPayment,
  { state: RootState }
>('[INTAKE]/acceptTakeAwayPayment', async (params, { dispatch, getState }) => {
  const { lastOrderChangeAmount } = getState().intake;
  const { payment } = getState().config;

  const result = await posApi.put<void>(
    posApiUrls.ORDER_TAKEAWAY_PAYMENT_ACCEPT(params.orderId),
    JSON.stringify(params),
  );

  if (!result || result.status !== 200) {
    throw Error('Error paying for order');
  }

  dispatch(restartOrder());
  sendIntakeFinalizedEvent();

  const orderResult = await posApi.get<OrderHistory>(posApiUrls.HISTORY_ORDER(params.orderId));
  const changeAmount =
    lastOrderChangeAmount && payment ? formatToDisplay(lastOrderChangeAmount, payment) : undefined;

  return {
    ticketNumber: orderResult?.data?.order?.ticketNumber?.toString(),
    changeAmount,
  };
});

export const postFinishDeliveryOrder = createAsyncThunk<string, RequestFinishDeliveryOrder, { state: RootState }>(
  '[INTAKE]/postFinishDeliveryOrder',
  async (params, { dispatch }) => {
    const result = await posApi.post<string>(posApiUrls.ORDER_DELIVERY, JSON.stringify(params));

    if (!result || result.status !== 200) {
      throw Error('Error placing order!');
    }
    await dispatch(getOrderHistory(result.data));
    dispatch(restartOrder());
    sendIntakeFinalizedEvent();

    return result.data;
  },
);

export const postFinishPickupOrder = createAsyncThunk<string, RequestFinishPickupOrder, { state: RootState }>(
  '[INTAKE]/postFinishPickupOrder',
  async (params, { dispatch }) => {
    const result = await posApi.post(posApiUrls.ORDER_PICKUP, JSON.stringify(params));

    if (!result || result.status !== 200) {
      throw Error('Error placing order!');
    }
    await dispatch(getOrderHistory(result.data));
    dispatch(restartOrder());
    sendIntakeFinalizedEvent();

    return result.data;
  },
);

export const saveDeliveryOrderEdition = createAsyncThunk<string, RequestFinishDeliveryOrder, { state: RootState }>(
  '[INTAKE]/saveDeliveryOrderEdition',
  async (params, { dispatch, rejectWithValue }) => {
    const result = await posApi.put<string>(posApiUrls.OSM_ORDER_DETAILS(params.orderId), JSON.stringify(params));
    if (isValidAxiosResponse(result)) {
      dispatch(getOrderDetails({ doFetch: true, publicId: result.data }));
      dispatch(restartOrder());
      return result.data;
    }
    return rejectWithValue({});
  },
);

export const savePickupOrderEdition = createAsyncThunk<string, RequestFinishPickupOrder, { state: RootState }>(
  '[INTAKE]/savePickupOrderEdition',
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(clearBasket());
    const result = await posApi.put<string>(posApiUrls.OSM_ORDER_DETAILS(params.orderId), JSON.stringify(params));
    if (isValidAxiosResponse(result)) {
      dispatch(getOrderDetails({ doFetch: true, publicId: result.data }));
      dispatch(restartOrder());
      return result.data;
    }
    return rejectWithValue({});
  },
);

export const finalizeOsmOrder = createAsyncThunk<
  { ticketNumber?: string; changeAmount?: string },
  {
    finalizationOrderId: string;
    paymentMethodCode: PaymentMethodCode;
    eftPaymentId?: string;
    eftFinishedManually?: boolean;
    companyId?: number;
    successCallback: () => void;
  },
  { state: RootState }
>(
  '[INTAKE]/finalizeOsmOrder',
  async (
    { finalizationOrderId, paymentMethodCode, eftPaymentId, eftFinishedManually, companyId, successCallback },
    { getState },
  ) => {
    const {
      config: { payment },
      intake: { lastOrderChangeAmount },
    } = getState();

    const result = await posApi.put(posApiUrls.OSM_ORDER_FINALIZE_PICKUP(finalizationOrderId), null, {
      params: {
        paymentMethodCode,
        eftPaymentId,
        eftFinishedManually,
        companyId,
      },
    });

    if (!result || result.status !== 200) {
      throw Error('Error finalizing order');
    }

    if (isValidAxiosResponse(result)) {
      successCallback();
    }

    const orderResult = await posApi.get<OSMOrderDetails>(posApiUrls.OSM_ORDER_DETAILS(finalizationOrderId, true));
    const changeAmount =
      lastOrderChangeAmount && payment ? formatToDisplay(lastOrderChangeAmount, payment) : undefined;

    return {
      ticketNumber: orderResult?.data?.ticketNumber?.toString(),
      changeAmount,
    };
  },
);

export const finalizeOsmPickupV2 = createAsyncThunk<
  { ticketNumber?: string; changeAmount?: string },
  {
    finalizationOrderId: string;
    companyId?: number;
  },
  { state: RootState }
>('[INTAKE]/finalizeOsmPickupV2', async ({ finalizationOrderId, companyId }, { getState }) => {
  const {
    config: { payment },
    intake: { lastOrderChangeAmount },
  } = getState();

  const result = await posApi.put(posApiUrls.OSM_ORDER_FINALIZE_PICKUP_V2(finalizationOrderId), null, {
    params: {
      companyId,
    },
  });

  if (!result || result.status !== 200) {
    throw Error('Error finalizing order');
  }

  const orderResult = await posApi.get<OSMOrderDetails>(posApiUrls.OSM_ORDER_DETAILS(finalizationOrderId, true));
  const changeAmount =
    lastOrderChangeAmount && payment ? formatToDisplay(lastOrderChangeAmount, payment) : undefined;

  return {
    ticketNumber: orderResult?.data?.ticketNumber?.toString(),
    changeAmount,
  };
});

export const getOnSiteSettings = createAsyncThunk<OnSiteSettings, void, { state: RootState }>(
  '[CONFIGURATION]/getOnSiteSettings',
  async () => {
    const result = await posApi.get<OnSiteSettings>(posApiUrls.ONSITE_SETTINGS);

    return result.data;
  },
);

export const initOrderEdition = createAsyncThunk<
  boolean,
  { order: OSMOrderDetailsWithBasketData; orderEditMode: OrderEditionMode },
  { state: RootState }
>('[INTAKE]/initOrderEdition', async ({ order, orderEditMode }, { dispatch }) => {
  const isPaidOrderEdition = orderEditMode === 'editPaidOrder';
  const intakeState: IntakeState = {
    ...initialIntakeState,
    remarks: order.remarks,
    isEatIn: order.isEatIn,
    selectedOrderCustomer: mapToselectedOrderCustomer(order.customerDetails, order.companyId),
    intakeStatus: isPaidOrderEdition ? IntakeStatuses.finalizeOrder : IntakeStatuses.productsSection,
    activeDeliveryType: order.pickupType,
    manuallyFilledAddress: order.deliveryAddress ? mapOSMDeliveryAddress(order.deliveryAddress) : undefined,
    activePaymentMethod: order.paymentMethod?.code,
    selectedOrderDeliveryTime: orderEditMode === 'repeat' ? undefined : order.requestedCollectionTime,
    editMode:
      orderEditMode === 'repeat'
        ? undefined
        : {
            mode: isPaidOrderEdition ? 'paid' : 'full',
            orderId: order.id,
            canEditBasket: !isPaidOrderEdition,
            canChangePayment: !isPaidOrderEdition,
            basketEdited: false,
          },
  };
  const basketState: BasketState = {
    ...initialBasketState,
    basketItems: order.basketItems,
    basketCoupons: order.basketCoupons,
    virtualReceipt: isPaidOrderEdition ? order.virtualReceipt : { receiptDiscounts: [], receiptProducts: [] },
    basketData: {
      autoAddedItems: [], // to do - extend OSM with auto added items data.
      activeDiscounts:
        order.discounts.filter((d) => d.couponId !== undefined).map(mapOSMDiscountToActiveDiscount) ?? [],
      couponsInformation:
        order.discounts.filter((d) => d.couponId !== undefined).map(mapOSMDiscountToCouponInformation) ?? [],
      automaticDiscountsInformation: [],
      deliveryCharge: {
        grossPrice: order.deliveryCharge,
        netPrice: order.deliveryCharge,
        vatRatePercentage: order.deliveryCharge,
        vatValue: order.deliveryChargeVatRate,
      },
      deliveryChargeDetails: {
        wasFallback: false,
      },
      itemsDetails: [],
      minimumOrderValue: {
        deliveryChargeChange: 0,
        isReached: false,
        targetValue: 0,
      },
      summary: {
        deliveryCharge: order.deliveryCharge,
        discount: order.priceSummary.discount,
        originalTotal: order.priceSummary.originalTotal,
        subtotal: order.priceSummary.subtotal,
        total: order.priceSummary.total,
        totalVat: order.priceSummary.totalVat,
        vatSummary: order.priceSummary.vatBreakdown?.at(0) ?? { vatRatePercentage: 0, vatValue: 0 },
      },
    },
  };

  dispatch(setIntakeState(intakeState));

  dispatch(setBasketState(basketState));
  if (order.deliveryAddress?.id && order.deliveryAddressFormatted) {
    dispatch(
      setCustomerAddresses(
        order.deliveryAddress
          ? [mapOSMDeliveryAddressToSelectedAddress(order.deliveryAddress, order.deliveryAddressFormatted)]
          : [],
      ),
    );
  }

  if (!isPaidOrderEdition) {
    dispatch(
      validateBasket({
        requestedProducts: order.basketItems,
        requestedCoupons: order.basketCoupons,
      }),
    );
  }

  return true;
});

// order submit
export const submitOrder = createAsyncThunk<
  void,
  {
    orderId: string;
    data: CheckoutDetailsForm;
    placeUnpaid?: boolean;
    isAsap?: boolean;
    fiscalSummary?: FiscalSummary;
  },
  { state: RootState }
>(
  '[INTAKE]/submitOrder',
  async ({ orderId, data, placeUnpaid, isAsap, fiscalSummary }, { getState, dispatch }) => {
    const appInsights = getAppInsights();
    const { remarks, manualPriceOverride, activeDeliveryType, activePaymentMethod, isEatIn } = getState().intake;
    const { basketItems, basketCoupons, manualDeliveryCharge, autoAddedItems } = getState().basket;
    const { customerCanPayOnAccount, chargedCompany, customerCreditToUse, customerAddresses } =
      getState().customer;
    const { manuallyFilledAddress, editMode, tableTag } = getState().intake;
    const { paymentMethodsConfiguration, addressInput } = getState().config;

    let manualInputAddress = manuallyFilledAddress; // I think we should switch to always use "data" and not use redux value.
    if (!isAddressValid(manuallyFilledAddress, addressInput) && isAddressValid(data, addressInput)) {
      manualInputAddress = data;
    }

    const payOnAccountMethodCode = paymentMethodsConfiguration?.payOnAccountMethodCode;
    function generateBasketRequestBase(): BasketRequestParams {
      const request = generateBasketRequest({
        activeDeliveryType,
        coupons: basketCoupons,
        isEatIn,
        manualDeliveryCharge,
        requestedBasket: basketItems,
        customerCreditToUse,
        autoAddedItems,
        manuallyFilledAddress: manualInputAddress,
        manualPriceOverride,
        selectedDeliveryAddress: customerAddresses.find((el) => el.isSelected),
      });
      return request;
    }

    function generateBaseRequest(orderId: string, data: CheckoutDetailsForm): RequestFinishBaseOrder {
      let remarksWithTableTag = null;
      if (tableTag && tableTag.length > 0) {
        remarksWithTableTag = remarks
          ? `${remarks} \n TABLE_TAG_NUMBER:${tableTag}`
          : `TABLE_TAG_NUMBER:${tableTag}`;
      }

      return {
        orderId,
        basket: generateBasketRequestBase(),
        paymentMethodCode:
          (activeDeliveryType === PickUpTypesValues.pickUp && !canSendPayOnAccount()) ||
          activePaymentMethod === PaymentMethodCode.PayInStoreArtificial
            ? undefined
            : activePaymentMethod,
        customerDetails: {
          profile: data.profile,
          name: data.name,
          surname: data.surname,
          telephoneNumber: data.telephoneNumber,
          emailAddress: data.emailAddress,
        },
        remarks: remarksWithTableTag ?? remarks,
        fiscalSummary,
      };
    }

    function canSendPayOnAccount(): boolean {
      return activePaymentMethod === PaymentMethodCode.PayOnAccount && customerCanPayOnAccount;
    }

    async function submitTakeAway(
      orderId: string,
      data: CheckoutDetailsForm,
      placeUnpaid?: boolean,
    ): Promise<void> {
      const shouldChargeCompany =
        activePaymentMethod?.toString() === payOnAccountMethodCode && customerCanPayOnAccount && chargedCompany;
      const request = {
        ...generateBaseRequest(orderId, data),
        companyId: shouldChargeCompany ? chargedCompany.id : undefined,
        isEatIn: isEatIn ?? false,
        eftPaymentOperation: data.EftPaymentOperation,
      };

      appInsights.trackEvent({
        name: 'Submit take-away order',
        properties: {
          orderId,
          request,
          placeUnpaid,
        },
      });

      if (placeUnpaid) {
        const result = await dispatch(postPlaceUnpaidTakeAwayOrder(request));
        if (result.meta.requestStatus === 'rejected') {
          throw new Error(`Error placing unpaid order with id:${request.orderId}`);
        }
        return;
      }
      await dispatch(postFinishTakeAwayOrder(request));
    }

    async function submitPickUp(orderId: string, data: CheckoutDetailsForm): Promise<void> {
      const shouldChargeCompany =
        activePaymentMethod?.toString() === payOnAccountMethodCode && customerCanPayOnAccount && chargedCompany;
      const request = {
        ...generateBaseRequest(orderId, data),
        companyId: shouldChargeCompany ? chargedCompany.id : undefined,
        collectionTime: {
          date: data.date,
          time: isAsap ? '' : `${data.time}:00`,
        },
      };

      appInsights.trackEvent({
        name: 'Submit pickup order',
        properties: {
          orderId,
          request,
        },
      });

      if (editMode && editMode.mode === 'full') {
        await dispatch(savePickupOrderEdition({ ...request, orderId: editMode?.orderId }));
      } else {
        await dispatch(postFinishPickupOrder(request));
      }
    }

    async function submitDelivery(orderId: string, data: CheckoutDetailsForm): Promise<void> {
      const shouldChargeCompany =
        activePaymentMethod?.toString() === payOnAccountMethodCode && customerCanPayOnAccount && chargedCompany;
      const request = {
        ...generateBaseRequest(orderId, data),
        companyId: shouldChargeCompany ? chargedCompany.id : undefined,
        deliveryTime: {
          date: data.date,
          time: isAsap ? '' : `${data.time}:00`,
        },
      };
      appInsights.trackEvent({
        name: 'Submit delivery order',
        properties: {
          orderId,
          request,
        },
      });

      if (editMode && editMode.mode === 'full') {
        await dispatch(saveDeliveryOrderEdition({ ...request, orderId: editMode?.orderId }));
      } else {
        await dispatch(postFinishDeliveryOrder(request));
      }
    }

    appInsights.trackEvent({
      name: 'Started submit of order',
      properties: {
        orderId,
        checkoutData: data,
        activeDeliveryType,
        activePaymentMethod,
        basketItems,
        basketCoupons,
        editMode,
      },
    });

    const deliveryType = activeDeliveryType;
    try {
      switch (deliveryType) {
        case PickUpTypesValues.delivery:
          await submitDelivery(orderId, data);
          break;
        case PickUpTypesValues.pickUp:
          await submitPickUp(orderId, data);
          break;
        case PickUpTypesValues.takeAway:
          await submitTakeAway(orderId, data, placeUnpaid);
          break;
        default:
          throw Error('Error placing order without delivery type selected');
      }
    } catch (err) {
      throw Error(err as string);
    }
  },
);

export const changeDeliveryType = createAsyncThunk<void, PickUpTypesValues | undefined, { state: RootState }>(
  '[INTAKE]/changeDeliveryType',
  async (newDeliveryType, { dispatch, getState }) => {
    const { activeDeliveryType, editMode } = getState().intake;
    const { selectedStore } = getState().stores;

    let targetDeliveryType = newDeliveryType;
    if (!targetDeliveryType) {
      if (!editMode) {
        const onSiteSettings = await posApi.get<OnSiteSettings>(posApiUrls.ONSITE_SETTINGS);
        targetDeliveryType = onSiteSettings.data.defaultDeliveryType ?? activeDeliveryType;
      } else targetDeliveryType = activeDeliveryType;
    }

    dispatch(setActiveDeliveryType(targetDeliveryType));

    await dispatch(
      getPaymentMethods({ pickupType: targetDeliveryType, storeId: selectedStore ? selectedStore.id : undefined }),
    );

    await dispatch(checkCurrentCustomerCanPayOnAccount());

    const payOnAccountMethodCode = getState().config.paymentMethodsConfiguration?.payOnAccountMethodCode;
    const { customerCanPayOnAccount } = getState().customer;
    const { availablePaymentMethods } = getState().payments;
    const { activePaymentMethod } = getState().intake;
    const payOnAccountPaymentMethod = availablePaymentMethods.find(
      (method) => method.code === payOnAccountMethodCode,
    );

    let paymentMethods = availablePaymentMethods ?? [];
    if (customerCanPayOnAccount && payOnAccountPaymentMethod && newDeliveryType !== PickUpTypesValues.dineIn) {
      if (newDeliveryType === PickUpTypesValues.pickUp) {
        paymentMethods = paymentMethods?.filter((method) => method.code === payOnAccountMethodCode) ?? [];
        const artificialPaymentMethod = {
          code: PaymentMethodCode.PayInStoreArtificial,
          description: 'Pay in store',
        } as PaymentMethod;
        paymentMethods.push(artificialPaymentMethod);
      }
    } else {
      paymentMethods = paymentMethods?.filter((method) => method.code !== payOnAccountMethodCode) ?? [];
    }

    const activePaymentMethodIsNotIncollection = !paymentMethods.find(
      (method) => method.code === activePaymentMethod,
    );
    if (
      activePaymentMethodIsNotIncollection &&
      paymentMethods.filter((pm) => pm.code !== PaymentMethodCode.PayInStoreArtificial).length > 0
    ) {
      dispatch(setActivePaymentMethod(paymentMethods[0].code));
    }

    dispatch(recalculateBasket({}));
  },
);

export const sendIntakeFinalizedEvent = () => {
  const hybridModeCommunicator = getHybridModeCommunicator();

  hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.IntakeFinalized);
};

function mapToselectedOrderCustomer(
  customerDetails: OrderCustomer,
  companyId: number | undefined,
): OrderCustomerProfile | undefined {
  return {
    ...customerDetails,
    companyId,
  };
}
