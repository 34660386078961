import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DineInOrderStatus } from 'containers/Intake/IntakeConsts';
import buildClasses from './Table.css';

interface HeadCell {
  id: string;
  translationId: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'TableIdentifier', translationId: 'TableNameColumn', numeric: false },
  { id: 'ModificationTimestamp', translationId: 'LastEditColumn', numeric: false },
  { id: 'Status', translationId: 'StatusColumn', numeric: false },
  { id: 'Total', translationId: 'AmountColumn', numeric: true },
];

interface DineInOrdersTableHeadersProps {
  orderBy: string;
  sortingOrder: 'asc' | 'desc';
  orderStatus: DineInOrderStatus;
  onSortingChange: (id: string) => void;
}

const DineInOrdersTableHeaders = ({
  orderBy,
  sortingOrder,
  orderStatus,
  onSortingChange,
}: DineInOrdersTableHeadersProps) => {
  const [t] = useTranslation('intake');
  const { classes } = buildClasses();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? sortingOrder : false}
            className={classes.tableHeadCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sortingOrder : 'asc'}
              onClick={() => onSortingChange(headCell.id)}
            >
              {t(headCell.translationId)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortingOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {orderStatus === DineInOrderStatus.Opened && <TableCell align="right" className={classes.tableHeadCell} />}
      </TableRow>
    </TableHead>
  );
};

export default DineInOrdersTableHeaders;
