import React from 'react';
import CardActionArea from '@mui/material/CardActionArea';
import { Card, Typography } from '@mui/material';

import { Coupon, NotApplicableCouponReason } from 'typings/Coupons';
import buildClasses from './CouponCard.css';

type CardProps = {
  coupon: Coupon;
  onCouponSelected: (selectedCoupon: Coupon) => void;
  classOverwrite?: string;
};

const CouponCard: React.FC<CardProps> = ({ coupon, onCouponSelected, classOverwrite }) => {
  const { classes, cx } = buildClasses();
  const disableCoupon =
    !coupon.isApplicable && coupon.notApplicableReason !== NotApplicableCouponReason.CouponCombination;

  return (
    <Card
      className={cx(classOverwrite || classes.root, {
        [classes.disabledCard]: disableCoupon,
      })}
    >
      <CardActionArea
        sx={{ height: '100%', textAlign: 'center', padding: '0px 10px 0px 10px' }}
        onClick={(): void => onCouponSelected(coupon)}
        data-testid={`product-card__button--${coupon.description}`}
      >
        <Typography
          variant="body2"
          classes={{
            root: classes.couponDescription,
          }}
        >
          {coupon.description}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default CouponCard;
