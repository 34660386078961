import { Button, Dialog, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

export interface CashierBreakDialogProps {
  isOpened: boolean;
  isOnBreak: boolean | undefined;
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

const CashierBreakDialog: React.FC<CashierBreakDialogProps> = ({
  isOpened,
  isOnBreak,
  onCancelClick,
  onConfirmClick,
}) => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');

  return (
    <Dialog
      open={isOpened}
      onClose={onCancelClick}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ display: 'flex', paddingTop: 2, px: 3, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ color: palette.black.main }}>
          {isOnBreak ? t('Unpause shift') : t('Pause shift')}
        </Typography>
        <IconButton
          onClick={onCancelClick}
          disableRipple
          sx={{
            color: 'black',
            fontSize: spacing(2.5),
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box sx={{ py: 2, px: 3 }}>
        <Typography variant="body1" sx={{ color: palette.black.main }}>
          {isOnBreak ? t('Do you want to unpause the shift?') : t('Are you sure you want to pause the shift?')}
        </Typography>
      </Box>
      <Box sx={{ py: 1, px: 1, borderTop: `1px solid ${palette.grey[300]}` }}>
        <Button
          sx={{ py: 1.5, px: 2, marginLeft: spacing(20) }}
          variant="contained"
          color="secondary"
          onClick={onCancelClick}
        >
          {isOnBreak ? t('No, stay paused') : t('No, stay unpaused')}
        </Button>
        <Button sx={{ py: 1.5, px: 2, marginLeft: spacing(1) }} variant="contained" onClick={onConfirmClick}>
          {isOnBreak ? t('Yes, unpause') : t('Yes, pause')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CashierBreakDialog;
