import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    container: {
      marginBottom: spacing(1),
      '&:hover': {
        boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.6)',
        cursor: 'pointer',
      },
    },
    selectedIcon: {
      float: 'right',
      marginTop: spacing(-5),
      marginRight: spacing(-1),
      color: palette.primary.dark,
    },
    addressContainer: {
      marginLeft: spacing(1),
      display: 'inline-block',
      marginTop: spacing(0.5),
    },
    editAddressIcon: {
      marginRight: spacing(2),
      float: 'right',
    },
    addressCardContainer: {
      height: spacing(4),
    },
    payOnAccountAvailableLabel: {
      marginLeft: spacing(2),
      color: palette.text.disabled,
      fontSize: spacing(1.5),
    },
  };
});
export default buildClasses;
