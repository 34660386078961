import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ToppingDisplayModel } from 'typings/Products';

interface AddedRemovedToppingsProps {
  addedToppings: ToppingDisplayModel[] | undefined;
  removedToppings: ToppingDisplayModel[] | undefined;
}

const AddedRemovedToppings: React.FC<AddedRemovedToppingsProps> = ({ addedToppings, removedToppings }) => {
  const { palette } = useTheme();
  return (
    <>
      {addedToppings && addedToppings.length > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid="basket-item__customization-added-topping">
          <AddIcon sx={{ fill: palette.green[600], mr: 2 }} />
          <Typography variant="body2" color="grey.900">
            {addedToppings
              .map(
                (addedTopping) =>
                  `${addedTopping.toppingName}${addedTopping.quantity > 1 ? ` x ${addedTopping.quantity}` : ''}`,
              )
              .join(', ')}
          </Typography>
        </Box>
      )}
      {removedToppings && removedToppings.length > 0 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          data-testid="basket-item__customization-removed-topping"
        >
          <RemoveIcon sx={{ fill: palette.black.main, mr: 2 }} />
          <Typography variant="body2" color="grey.900">
            {removedToppings.map((removedTopping) => `${removedTopping.toppingName}`).join(', ')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AddedRemovedToppings;
