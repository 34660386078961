import React from 'react';
import {
  Chip,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
} from '@mui/material';
import { Done, Add } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductSimplifiedCard from 'components/Intake/Product/ProductSimplifiedCard/ProductSimplifiedCard';
import ReceiptProductDetails from 'components/Intake/Receipt/Item/ReceiptProduct/ReceiptProductDetails';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { CustomizationProduct, Product, SliceCustomization } from 'typings/Products';
import { Meal } from 'typings/Coupons';

type MealPartProps = {
  meal: Meal;
  isMealCustomizable: boolean;
  onMealProductSelected: (product: CustomizationProduct) => void;
  customizeMealProduct: (product: CustomizationProduct) => void;
  isMealValid: boolean;
};

const MealPart: React.FC<MealPartProps> = ({
  meal,
  isMealCustomizable,
  onMealProductSelected,
  customizeMealProduct,
  isMealValid,
}) => {
  const [t] = useTranslation('intake');
  // it's local instance of product customization, so no redux here needed.

  const { options } = useAppSelector((store) => store.products);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean>(false);
  const [productCustomizationEnabled, setProductCustomizationEnabled] =
    React.useState<boolean>(isMealCustomizable);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function handleProductClicked(product: Product): void {
    let optionId = product.options[0].productOptionId;
    let optionName: string | undefined = '';

    if (meal.selectedProduct) {
      optionId = meal.selectedProduct.originalOptionId;
      optionName = meal.selectedProduct.optionName;
    }
    const initialSliceCustomization: SliceCustomization[] = [
      {
        sliceProductId: product?.id,
        sliceProductName: product?.name,
        toppingsConfiguration: product?.defaultToppings,
        addedToppings: [],
        removedToppings: [],
      },
    ];

    const customizationFromProduct = {
      baseProduct: product,
      originalOptionId: optionId,
      optionName,
      quantity: 1,
      sliceCustomizations: product.isXTasty ? undefined : initialSliceCustomization,
    } as CustomizationProduct;

    if (product) {
      setProductCustomizationEnabled(product.isCustomizable);
    }

    onMealProductSelected(customizationFromProduct);
  }

  function getOptionName(optionId: number): string {
    return options.find((el) => el.id === optionId)?.title ?? '';
  }
  function getProductName(prd: CustomizationProduct): string {
    let baseName = '';
    if (prd.sliceCustomizations && prd.sliceCustomizations.length > 1) {
      baseName += `${prd.baseProduct.title} (`;
      baseName += prd.sliceCustomizations.map((slice) => `${slice.sliceProductName}`).join(' / ');
      baseName += ')';
    } else {
      baseName += prd.baseProduct.title;
    }

    return baseName;
  }
  function customizeProductButtonClicked(product: CustomizationProduct): void {
    customizeMealProduct(product);
  }

  const itemHasToppingCustomization: boolean = meal.selectedProduct?.sliceCustomizations
    ? meal.selectedProduct?.sliceCustomizations.length > 0 &&
      meal.selectedProduct?.sliceCustomizations.some(
        (el) =>
          (el.addedToppings && el.addedToppings.length > 0) ||
          (el.removedToppings && el.removedToppings.length > 0),
      )
    : false;

  const itemIsXTasty: boolean =
    (meal.selectedProduct &&
      meal.selectedProduct.sliceCustomizations &&
      meal.selectedProduct.sliceCustomizations.length > 1) ??
    false;
  const canShowDetails: boolean =
    itemHasToppingCustomization || itemIsXTasty || meal.selectedProduct?.remark !== undefined;

  return (
    <Accordion
      defaultExpanded={false}
      expanded={accordionExpanded}
      onClick={() => {
        setAccordionExpanded(!accordionExpanded);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {meal.selectedProduct ? (
            <>
              <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                {`${getProductName(meal.selectedProduct)} - ${getOptionName(
                  meal.selectedProduct.originalOptionId,
                )} `}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'end' }}>
                {!isMealValid && <Alert severity="error">{t('Customization required')}</Alert>}
                {canShowDetails && (
                  <>
                    <Alert
                      severity="success"
                      icon={<Add />}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {t('Customizations')}
                    </Alert>
                    <Popover
                      id="mouse-over-popover"
                      sx={{ pointerEvents: 'none' }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <ReceiptProductDetails
                        remark={meal.selectedProduct.remark}
                        isXTasty={itemIsXTasty}
                        slices={meal.selectedProduct.sliceCustomizations ?? []}
                      />
                    </Popover>
                  </>
                )}
                {productCustomizationEnabled && (
                  <>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        customizeProductButtonClicked(meal.selectedProduct as CustomizationProduct);
                      }}
                    >
                      <EditIcon fontSize="large" />
                    </IconButton>
                  </>
                )}

                <Chip sx={{ margin: '0px 5px' }} color="primary" icon={<Done />} label={t('Selected')} />
              </Box>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">{t(`Select your ${meal.categoryName}`)}</Typography>
              <Alert severity="error">{t('Selection required')}</Alert>
            </>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ flexWrap: 'wrap', display: 'flex' }}>
        <>
          {meal.availableProducts.map((product) => {
            return (
              <ProductSimplifiedCard
                selected={meal.selectedProduct?.baseProduct.id === product.id}
                key={product.id}
                product={product}
                onProductSelected={(prd): void => {
                  if (meal.selectedProduct && meal.selectedProduct.baseProduct === product) {
                    onMealProductSelected(meal.selectedProduct);
                  } else {
                    handleProductClicked(prd);
                  }
                  setAccordionExpanded(false);
                }}
              />
            );
          })}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default MealPart;
