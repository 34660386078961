import React, { useEffect, useState } from 'react';
import { IconButton, Paper, InputBase, Backdrop, CircularProgress, Chip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { CustomerSearchResultItem } from 'stores/Intake';
import CustomerResultItem from 'components/Intake/Customer/CustomerResultItem';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { mapToSelectedCustomer } from 'utils/intake/IntakeUtils';
import { useFormContext } from 'react-hook-form';
import { clearSearchResults, getCustomerSearchResults, selectCustomerOnOrder } from 'stores/Customer';
import buildClasses from './CustomerSearchResults.css';

interface CustomerSearchResultsProps {
  query: string;
  onIntakeWithoutProfileClick?: () => void;
  onCancelCustomerSelection?: () => void;
  onCustomerSelected: () => void;
}

const CustomerSearchResults: React.FC<CustomerSearchResultsProps> = ({
  query,
  onIntakeWithoutProfileClick,
  onCancelCustomerSelection,
  onCustomerSelected,
}) => {
  const { classes } = buildClasses();
  const searchQueryRef = React.createRef<HTMLInputElement>();
  const [customerSearchQuery, setCustomerSearchQuery] = useState<string | undefined>(query);
  const [resultsStoredForQuery, setResultsStoredForQuery] = useState<string | undefined>(query);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  const [t] = useTranslation('intake');

  const { customerSearchResults, loadingCustomerSearchResults, noMoreResults } = useAppSelector(
    (state) => state.customer,
  );
  const { reset } = useFormContext<CheckoutDetailsForm>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSearchResults());
    };
  }, []);

  useEffect(() => {
    if (customerSearchQuery !== undefined) {
      performSearch();
    }
  }, [customerSearchQuery, currentPageIndex]);

  useEffect(() => {
    setCustomerSearchQuery(query);
    if (searchQueryRef.current) {
      searchQueryRef.current.value = query;
    }
  }, [query]);

  function performSearch(): void {
    if (customerSearchQuery !== undefined) {
      dispatch(
        getCustomerSearchResults({
          customerSearchQuery,
          currentPageIndex,
          loadAdditional: resultsStoredForQuery === customerSearchQuery,
        }),
      );
      setResultsStoredForQuery(customerSearchQuery);
    }
  }

  function updateSearchQueryFromInput(): void {
    const newQuery = searchQueryRef.current?.value;

    if (newQuery !== customerSearchQuery) {
      setCurrentPageIndex(0);
      setCustomerSearchQuery(searchQueryRef.current?.value);
    }
  }

  function onSearchButtonClicked(): void {
    updateSearchQueryFromInput();
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.keyCode === 13) {
      updateSearchQueryFromInput();
    }
  }

  function onCustomerSelectedHandler(customer: CustomerSearchResultItem) {
    dispatch(selectCustomerOnOrder(customer));
    const dataForForm = mapToSelectedCustomer(customer);
    reset({ ...dataForForm });
    onCustomerSelected();
  }

  function onLoadMoreResultsClicked(): void {
    setCurrentPageIndex(currentPageIndex + 1);
  }

  return (
    <div className={classes.customerSearchContainer}>
      <IconButton
        onClick={onCancelCustomerSelection}
        size="small"
        data-testid="intake-customer-search_button--cancel-search"
      >
        <ArrowBackIcon />
        {t('Cancel customer selection')}
      </IconButton>
      <div className={classes.searchPanelContainer}>
        <div className={classes.numberOfResultsContainer}>
          <span>
            {t('Number of results')}
            <Chip
              size="small"
              label={customerSearchResults.length.toString()}
              className={classes.numberOfResultsValueContainer}
            />
          </span>
        </div>
        <Paper className={classes.searchPanel}>
          <InputBase
            className={classes.input}
            inputRef={searchQueryRef}
            placeholder={t('Search customer placeholder')}
            onKeyDown={onKeyDown}
            data-testid="intake-customer-search__input--search-query"
          />
          <IconButton
            className={classes.searchButton}
            onClick={onSearchButtonClicked}
            data-testid="intake-customer-search_button--search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.resultsPane}>
        <Backdrop className={classes.customerSearchOverlay} open={loadingCustomerSearchResults}>
          <CircularProgress className={classes.cusomerSearchSpinner} />
        </Backdrop>
        <div>
          {customerSearchResults.map((cust) => {
            return (
              <CustomerResultItem
                key={`customer-search-result-item-${cust.id}`}
                customer={cust}
                onCustomerSelected={onCustomerSelectedHandler}
              />
            );
          })}
        </div>
        <div className={classes.buttonContainer}>
          {!noMoreResults && (
            <div className={classes.button}>
              <RoundedButton
                onClick={onLoadMoreResultsClicked}
                margin="20px"
                testId="intake-customer-search__button--load-more-results"
              >
                {t('Load more results')}
              </RoundedButton>
            </div>
          )}
          <div className={classes.button}>
            <RoundedButton
              onClick={onIntakeWithoutProfileClick}
              margin="20px"
              testId="intake-customer-search__button--no-profile"
            >
              {t('Order without customer')}
            </RoundedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSearchResults;
