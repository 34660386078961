import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    chevronIcon: {
      transition: 'transform 0.2s',
    },
    rotate: {
      transform: 'rotate(90deg)',
    },
    buttonsContainer: {
      display: 'flex',
      paddingTop: spacing(2),
    },
  };
});

export default buildClasses;
