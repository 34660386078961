import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    remarkTooltip: {
      fontSize: '14px',
    },
  };
});

export default buildClasses;
