import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    listItem: {
      height: '70px',
      width: '100%',
      padding: `${spacing(0.5)} ${spacing(1)}`,
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row',
      backgroundColor: '#E2E2E2',
      borderBottom: '1px solid #cbcbcb',
      borderRight: '1px solid #cbcbcb',
      justifyContent: 'space-between',
      fontSite: '16px',
      '&:hover': {
        backgroundColor: palette.white,
        borderRight: 'none',
      },
    },
    active: {
      fontWeight: 'bold',
      backgroundColor: palette.white,
      borderRight: 'none',
    },
  };
});
export default buildClasses;
