import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    activeToggleButton: {
      borderRadius: 0,
      color: `${palette.black.main} !important`,
      width: '100%',
      '& $icon': {
        fill: palette.black.main,
      },
    },
    icon: {
      marginRight: spacing(1),
    },
  };
});

export default buildClasses;
