import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';
import { useWindowSize } from 'hooks/useWindow';
import SwiperPagination from 'components/Shared/Pagination/Pagination';
import 'swiper/swiper.min.css';
import { Coupon } from 'typings/Coupons';
import CouponCard from 'components/Intake/Coupon/CouponCard/CouponCard';
import buildClasses from './Coupons.css';

type CouponCatalogProps = {
  coupons: Coupon[];
  onCouponClick: (item: Coupon) => void;
};

export interface CouponPagination {
  activePage: number;
  pageCoupons: Coupon[][];
}

const PagedCatalog: React.FC<CouponCatalogProps> = ({ coupons, onCouponClick }) => {
  const { classes } = buildClasses();
  const couponsGridRef = useRef<HTMLElement>(null);
  const { width, height } = useWindowSize();
  const { sizing, breakpoints } = useTheme();

  const [pagination, setCouponPagination] = useState<CouponPagination>({
    activePage: 0,
    pageCoupons: [[]],
  });

  const calculatePageCount = (): { couponsPerPage: number; pagesCount: number } => {
    const gridHeight = couponsGridRef.current?.clientHeight ?? 0;
    const couponCardHeight = sizing.couponCard.height;
    const couponsPerColumn = Math.floor(
      (gridHeight - sizing.spacingBase * 5) / (couponCardHeight + sizing.spacingBase * 1),
    );
    const couponsPerRow = getCouponRowAmount(width);
    if (couponsPerColumn === 0) {
      return { pagesCount: Math.ceil(coupons.length / couponsPerRow), couponsPerPage: couponsPerRow };
    }

    const couponsPerPage = couponsPerColumn * couponsPerRow;
    const pagesCount = Math.ceil(coupons.length / couponsPerPage);
    return { pagesCount, couponsPerPage };
  };

  const getCouponRowAmount = (windowWidth: number | undefined): number => {
    if (windowWidth && windowWidth < breakpoints.values.md) return sizing.couponCard.lineCount.xs;
    if (windowWidth && windowWidth > breakpoints.values.lg) return sizing.couponCard.lineCount.xl;
    return sizing.couponCard.lineCount.md;
  };

  const recalculatePagination = () => {
    const { pagesCount, couponsPerPage } = calculatePageCount();
    const couponsPages = [];
    for (let i = 0; i < pagesCount; i += 1) {
      couponsPages.push(coupons.slice(i * couponsPerPage, (i + 1) * couponsPerPage));
    }
    setCouponPagination({
      activePage: 0,
      pageCoupons: couponsPages,
    });
  };

  useEffect(() => {
    recalculatePagination();
  }, [width, height]);

  useEffect(() => {
    recalculatePagination();
  }, [coupons]);

  return (
    <Box sx={{ mt: 3, flex: 1, overflow: 'hidden' }} ref={couponsGridRef}>
      <Swiper
        modules={[Pagination, Mousewheel]}
        className={classes.swiper}
        wrapperClass={classes.swiperWrapper}
        mousewheel
        onSlideChange={(swiper) => {
          setCouponPagination({
            ...pagination,
            activePage: swiper.activeIndex,
          });
        }}
      >
        {pagination.pageCoupons.map((page) => {
          return (
            <SwiperSlide className={classes.swiperSlide}>
              <Grid container spacing={1}>
                {page.map((coupon) => (
                  <Grid item xs={6} md={4} lg={3} key={coupon.couponCode}>
                    <CouponCard coupon={coupon} onCouponSelected={onCouponClick} />
                  </Grid>
                ))}
              </Grid>
            </SwiperSlide>
          );
        })}
        <SwiperPagination activePage={pagination.activePage} pageItems={pagination.pageCoupons} />
      </Swiper>
    </Box>
  );
};

export default PagedCatalog;
