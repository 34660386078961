import React, { FC } from 'react';
import { Box } from '@mui/system';
import DataSyncComponent from 'components/DataSync/DataSyncComponent';

const DataSync: FC = () => {
  return (
    <Box sx={{ width: '700px', my: 5, mx: 'auto', background: 'white', p: 5 }}>
      <DataSyncComponent />
    </Box>
  );
};

export default DataSync;
