/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  cancelEftPayment,
  clearEftData,
  getEftPaymentStatus,
  getEftPaymentTerminal,
  startEftPayment,
} from 'stores/Eft';
import PaymentByCard from 'components/Intake/Finalize/PaymentByCard/PaymentByCard';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import EftSuccess from 'components/Shared/EftPayment/EftSuccess';
import { getEFTStatus } from 'stores/Eft/eft.selector';
import EftPending from 'components/Shared/EftPayment/EftPending';
import uuidGenerator from 'utils/GuidGenerator';
import { PaymentTarget } from 'typings/Payments';

export interface EftPaymentProps {
  onClose: () => void;
  onSuccess: (eftId: string) => void;
  onEftManualFinalize: () => void;
  onPaymentStarted?: (eftId: string) => void;
  totalToPay: number;
  orderId: string;
  canFinalizeManually: boolean;
  openTabId?: string;
  eftEnabled?: boolean;
}

const EftPayment: React.FC<EftPaymentProps> = ({
  totalToPay,
  orderId,
  canFinalizeManually,
  onSuccess,
  onEftManualFinalize,
  onClose,
  onPaymentStarted,
  openTabId,
  eftEnabled,
}) => {
  const [t] = useTranslation('intake');
  const [tSettings] = useTranslation('settings');
  const { featureFlags, eftPaymentSettings } = useAppSelector(({ config }) => config);
  const { eftPaymentId, eftPaymentTerminal, success, error, cancelInProgress } = useAppSelector(
    (state) => state.eft,
  );
  const { settlePayment, unpaidOrderPlacementInProgress } = useAppSelector((state) => state.payments);
  const eftStatus = useAppSelector(getEFTStatus);

  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const pollingInterval = useRef<any>(null);

  useEffect(() => {
    if (featureFlags.OfflineModule_EFT === true) {
      dispatch(getEftPaymentTerminal({ checkCtmp: false }));
    }
    return () => {
      if (eftPaymentId) {
        dispatch(
          cancelEftPayment({
            orderId: openTabId ?? orderId,
            paymentTarget: openTabId ? PaymentTarget.OpenTab : PaymentTarget.Order,
            eftPaymentId,
          }),
        );
      }
      stopPaymentStatusPolling();
      dispatch(clearEftData());
    };
  }, []);

  useEffect(() => {
    if (eftPaymentId === undefined) {
      stopPaymentStatusPolling();
    }
    if (eftPaymentId) {
      startPaymentStatusPolling(eftPaymentId);
      appInsights.trackEvent({
        name: 'Eft payment started',
        properties: { Identifier: eftPaymentId },
      });
    }
  }, [eftPaymentId]);

  useEffect(() => {
    if (success) {
      stopPaymentStatusPolling();
      setTimeout(() => {
        onSuccess(eftPaymentId as string);
      }, 2000);
    }
  }, [success]);

  function startPaymentStatusPolling(identifier: string): void {
    const pollingIntervalInSeconds = eftPaymentSettings?.statusPollingIntervalInSeconds ?? 1;
    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(() => {
        dispatch(
          getEftPaymentStatus({
            identifier,
            orderId: openTabId ?? orderId,
            paymentTarget: openTabId ? PaymentTarget.OpenTab : PaymentTarget.Order,
          }),
        );
      }, pollingIntervalInSeconds * 1000);
    }
  }

  function stopPaymentStatusPolling(): void {
    clearInterval(pollingInterval?.current);
    if (pollingInterval) {
      pollingInterval.current = null;
    }
  }

  function onSendEftPayment(): void {
    const paymentIdentifier = uuidGenerator();
    if (onPaymentStarted && settlePayment?.useTwoStepOrderPlacing) {
      onPaymentStarted(paymentIdentifier);
    } else {
      appInsights.trackEvent({
        name: 'Eft payment started',
        properties: { Identifier: paymentIdentifier, Status: undefined },
      });
      dispatch(
        startEftPayment({
          amount: totalToPay,
          identifier: paymentIdentifier,
          orderId: openTabId ?? orderId,
          paymentTarget: openTabId ? PaymentTarget.OpenTab : PaymentTarget.Order,
        }),
      );
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', py: 1, px: 3 }}>
        {error && (
          <Alert severity="error" sx={{ my: 2 }}>
            {t(error)}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          {eftStatus === 'SUCCESS' && <EftSuccess toPay={totalToPay} />}
          {eftStatus === 'PENDING' && <EftPending toPay={totalToPay} />}
          {eftStatus === 'IDLE' && !unpaidOrderPlacementInProgress && <PaymentByCard totalAmount={totalToPay} />}
        </Box>

        {eftStatus !== 'SUCCESS' && eftEnabled && !unpaidOrderPlacementInProgress && (
          <>
            <Divider sx={{ mb: 1, mx: -3 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {eftStatus === 'PENDING' ? (
                <Button
                  color="black"
                  variant="outlined"
                  disabled={cancelInProgress}
                  onClick={() => {
                    dispatch(
                      cancelEftPayment({
                        orderId: openTabId ?? orderId,
                        paymentTarget: openTabId ? PaymentTarget.OpenTab : PaymentTarget.Order,
                        eftPaymentId,
                      }),
                    );
                  }}
                  data-testid="settle-payment__button--cancel-eft-payment"
                >
                  {t('Cancel payment')}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="black"
                  onClick={onClose}
                  data-testid="settle-payment__button--cancel"
                >
                  {t('Cancel')}
                </Button>
              )}

              {canFinalizeManually && eftStatus !== 'PENDING' && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onEftManualFinalize}
                  data-testid="settle-payment__button--manually-finish-eft-payment"
                >
                  {t('Finish manually')}
                </Button>
              )}
              {eftPaymentTerminal !== 'not-configured' ? (
                <>
                  {eftStatus !== 'PENDING' && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onSendEftPayment}
                      data-testid="settle-payment__button--send-eft-payment"
                      disabled={!eftPaymentTerminal || unpaidOrderPlacementInProgress}
                    >
                      {t('Send payment')}
                    </Button>
                  )}
                </>
              ) : (
                <Alert severity="warning" sx={{ ml: 1 }}>
                  {tSettings('Eft device not configured')}
                </Alert>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default EftPayment;
