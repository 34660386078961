import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    filter: {
      marginRight: spacing(1),
      marginBottom: spacing(2),
      width: sizing.filterInputWidth,
    },
    selectMenu: {
      maxHeight: sizing.orderTableFilterSelectMaxHeight,
    },
    menuItem: {
      padding: `${spacing(1)} 0px`,
      borderBottom: `1px solid ${palette.grey[100]}`,
      ':last-child': {
        borderBottom: 'none',
      },
      '&.Mui-selected': {
        backgroundColor: '#FFFFFF',
      },
    },
    searchInput: {
      paddingLeft: spacing(0),
      width: 400,
    },
  };
});
export default buildClasses;
