import React from 'react';
import { ReceiptProduct, ReceiptDiscount, BasketItem } from 'typings/Basket';
import { Box } from '@mui/system';
import BasketProductItem from 'components/Shared/Basket/BasketItem';
import BasketDiscount from 'components/Shared/Basket/BasketDiscount';

interface CustomerDisplayItemsCollectionProps {
  receiptProducts: ReceiptProduct[];
  receiptDiscounts: ReceiptDiscount[];
}

const CustomerDisplayItemsCollection: React.FC<CustomerDisplayItemsCollectionProps> = ({
  receiptProducts,
  receiptDiscounts,
}) => {
  return (
    <Box
      sx={{
        display: 'block',
        overflow: 'auto',
      }}
    >
      {receiptProducts.map((receiptProduct: ReceiptProduct, index) => (
        <BasketProductItem key={receiptProduct.id} receiptProduct={receiptProduct} alwaysExpanded index={index} />
      ))}
      {receiptDiscounts &&
        receiptDiscounts.map((discount: ReceiptDiscount) => (
          <BasketDiscount receiptDiscount={discount} alwaysExpanded />
        ))}
    </Box>
  );
};

export default CustomerDisplayItemsCollection;
