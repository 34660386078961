import React, { useState } from 'react';
import { Typography, TextField, Button, Box, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';

interface CustomerSearchProps {
  onSearchClicked: (query: string) => void;
  onIntakeWithoutProfileClick?: () => void;
}

const CustomerSearch: React.FC<CustomerSearchProps> = ({ onSearchClicked, onIntakeWithoutProfileClick }) => {
  const { spacing, sizing, palette } = useTheme();
  const searchQueryRef = React.createRef<HTMLInputElement>();
  const [t] = useTranslation('intake');
  const [isInvalidCharacterError, setInvalidCharacterError] = useState<boolean>();

  function checkInvalidCharacters(query: string) {
    const pattern = /[^a-zA-Z0-9 @.-]/;
    if (pattern.test(query)) {
      setInvalidCharacterError(true);
      return true;
    }
    setInvalidCharacterError(false);
    return false;
  }

  function performSearch(): void {
    const queryValue = searchQueryRef.current?.value ?? '';

    if (checkInvalidCharacters(queryValue)) {
      return;
    }
    onSearchClicked(queryValue);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.keyCode === 13) {
      performSearch();
    }
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        height: `calc(100vh - ${sizing.receiptFooterHeight + sizing.appBarHeight}px)`,
        padding: spacing(2),
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: palette.black.main, textAlign: 'start', marginBottom: spacing(2) }}
      >
        {t('Search customer')}
      </Typography>

      <TextField
        name="searchQuery"
        inputRef={searchQueryRef}
        fullWidth
        sx={{
          width: sizing.full,
          marginBottom: spacing(3),
          '&::placeholder': {
            color: palette.grey[500],
          },
        }}
        placeholder={t('Search customer placeholder')}
        variant="outlined"
        inputProps={{
          'data-testid': 'receipt-customer_searchQuery',
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: <Search sx={{ marginLeft: spacing(2), color: palette.grey[600] }} />,
        }}
        error={isInvalidCharacterError}
        helperText={isInvalidCharacterError && t('Invalid characters')}
      />
      <Button
        onClick={performSearch}
        variant="contained"
        data-testid="receipt-customer-search__button--search"
        sx={{ width: sizing.full, marginBottom: spacing(3), height: sizing.buttonHeight.md }}
      >
        {t('Search')}
      </Button>
      <Box
        component="span"
        sx={{
          zIndex: 100,
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
          backgroundColor: palette.white,
          textTransform: 'uppercase',
        }}
      >
        {t('or')}
      </Box>
      <Divider
        sx={{
          marginTop: '-10px',
          marginBottom: `calc(${spacing(3)} + 10px)`,
          borderColor: palette.customGray.dark,
        }}
      />
      <Button
        onClick={onIntakeWithoutProfileClick}
        variant="outlined"
        color="black"
        data-testid="receipt-customer-search__button--no-profile"
        sx={{ width: sizing.full, marginBottom: spacing(3), height: sizing.buttonHeight.md }}
      >
        {t('Order without customer')}
      </Button>
    </Box>
  );
};

export default CustomerSearch;
