import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePaymentSettings from 'hooks/usePaymentSettings';
import ReceiptToggleGroup from 'components/Intake/Receipt/ToggleGroup/ReceiptToggleGroup';
import ReceiptTotal from 'components/Intake/Receipt/Total/ReceiptTotal';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import DeliveryChargeModal from 'containers/Intake/Receipt/DeliveryCharge/DeliveryCharge';
import { Box, Button, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { TwoWheelerOutlined } from '@mui/icons-material';
import TextFieldDialog from 'components/Shared/TextFieldDialog/TextFieldDialog';
import PaymentMethodGroup from 'components/Intake/Receipt/ToggleGroup/PaymentMethodsGroup';
import { numberFromMoney } from 'utils/payment/PaymentUtils';
import { CashierPermission } from 'typings/Cashier';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setManualPriceOverride, setOrderRemarks } from 'stores/Intake';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { BasketSummary, DeliveryChargeDetails } from 'typings/Basket';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { sendOpenCashDrawerCommand } from 'stores/Payments';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { setAlert } from 'stores/Alerts';
import { useFormContext } from 'react-hook-form';
import { setManualDeliveryCharge } from 'stores/Basket/basket.slice';
import { setPendingIteration } from 'stores/DineIn/dineIn.slice';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { OrderDiscount, OpenDrawer } from 'assets/icons';
import ClockInConfirmationModal from 'components/Shared/Modal/ClockInConfirmationModal';
import { validateUser } from 'stores/AllOrders';
import ManualPriceOverride from '../ManualPriceOverride/ManualPriceOverride';
import buildClasses from './ReceiptFooter.css';

interface ReceiptItemProps {
  summary: BasketSummary | undefined;
  onFinishOrderButtonClick: () => void;
  deliveryType: PickUpTypesValues;
  deliveryChargeDetails: DeliveryChargeDetails | undefined;
  canChangeDeliveryType: boolean;
  canChangePaymentMethod: boolean;
  isFinishButtonVisible: boolean;
  isDineInSendButtonVisible: boolean;
  onDineInItemsSend: () => void;
  canEditBasket?: boolean;
}

const ReceiptFooter: React.FC<ReceiptItemProps> = ({
  summary,
  onFinishOrderButtonClick,
  deliveryType,
  deliveryChargeDetails,
  canChangeDeliveryType,
  canChangePaymentMethod,
  isFinishButtonVisible,
  isDineInSendButtonVisible,
  onDineInItemsSend,
  canEditBasket = true,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const { spacing, palette } = useTheme();

  const [isDeliveryPriceModal, setDeliveryPriceOpen] = useState(false);
  const [isRemarkOpen, setRemarkOpen] = useState(false);
  const [isManualPriceOverrideModal, setManualPriceOverrideOpen] = useState(false);
  const { getValues } = useFormContext<CheckoutDetailsForm>();

  const checkoutFormHaveValues = Object.values(getValues()).length > 0;

  const { GetPaymentSettings } = usePaymentSettings();
  const paymentSettings = GetPaymentSettings();

  const dispatch = useAppDispatch();

  const { remarks, editMode, editRequestStatus, activeDeliveryType } = useAppSelector(({ intake }) => intake);
  const { featureFlags } = useAppSelector((state) => state.config);
  const { selectedDineInOrder, isIterationPending } = useAppSelector((state) => state.dineIn);
  const { customerCreditToUse } = useAppSelector((store) => store.customer);
  const { virtualReceipt, manualDeliveryCharge, recalculateInProgress } = useAppSelector((state) => state.basket);

  const canUserAccess = useAppSelector(getPermissionChecker());
  const priceOverrideDisabled = !canUserAccess(CashierPermission.ChangeTotalPrice) || !!customerCreditToUse;
  const [showClockInDialog, setShowClockInDialog] = useState(false);

  function sendIteration(): void {
    if (isIterationPending) {
      onDineInItemsSend();
    }
  }

  function saveRemarks(value: string): void {
    setRemarkOpen(false);
    dispatch(
      setAlert({
        header: 'Remarks saved',
        variant: 'success',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      }),
    );
    dispatch(setOrderRemarks(value));
  }

  function finishButtonEventHandler(): void {
    dispatch(
      validateUser({
        clockInCallback: () => {
          setShowClockInDialog(true);
        },
        successCallback: () => {
          dispatch(onFinishOrderButtonClick());
        },
      }),
    );
  }

  function saveManualDiscount(value?: string): void {
    dispatch(setManualPriceOverride(value ? numberFromMoney(value, paymentSettings) : undefined));
    dispatch(recalculateBasket({}));
  }
  const saveManualDeliveryCharge = (value: string): void => {
    value && dispatch(setManualDeliveryCharge(numberFromMoney(value, paymentSettings)));
    dispatch(recalculateBasket({}));
  };
  const anySetOnReceipt = virtualReceipt.receiptDiscounts.some((rd) => rd.isSet === true);

  useEffect(() => {
    if (isDineInSendButtonVisible) {
      dispatch(setPendingIteration());
    }
  }, [isDineInSendButtonVisible]);

  return (
    <div className={classes.container}>
      {canChangePaymentMethod && canEditBasket && <PaymentMethodGroup placeRemarkOnTop />}
      {canChangeDeliveryType && canEditBasket && (
        <ReceiptToggleGroup isInDineInMode={selectedDineInOrder !== undefined} />
      )}
      <ReceiptTotal
        summary={summary}
        pickUpType={deliveryType}
        deliveryCharge={deliveryChargeDetails}
        showDeliveryCharge={checkoutFormHaveValues || manualDeliveryCharge !== null}
      />
      {canEditBasket && (
        <div className={classes.receiptFooterActionsContainer}>
          <Tooltip enterDelay={500} title={t('Delivery price override').toString()} arrow>
            <span>
              <Button
                variant="iconButton"
                disabled={activeDeliveryType !== PickUpTypesValues.delivery}
                onClick={(): void => setDeliveryPriceOpen(true)}
                aria-label="deliveryCharge"
                data-testid="receipt-footer__button--change-delivery-charge"
                sx={{ backgroundColor: palette.grey[200] }}
              >
                <TwoWheelerOutlined sx={{ fontSize: spacing(4) }} />
              </Button>
            </span>
          </Tooltip>
          <Tooltip enterDelay={500} title={t('Open cash drawer').toString()} arrow>
            <Button
              variant="iconButton"
              aria-label="cashDrawerOpen"
              data-testid="receipt-footer__button--cash-drawer-open"
              onClick={() => featureFlags.OfflineModule_UseCashDrawer && dispatch(sendOpenCashDrawerCommand())}
              sx={{ backgroundColor: palette.grey[200] }}
            >
              <OpenDrawer />
            </Button>
          </Tooltip>
          <Tooltip enterDelay={500} title={t('Order total override').toString()} arrow>
            <Button
              variant="iconButton"
              disabled={priceOverrideDisabled}
              onClick={(): void => setManualPriceOverrideOpen(true)}
              data-testid="receipt-footer__button--manual-discount"
              sx={{
                backgroundColor: palette.grey[200],
              }}
            >
              <OrderDiscount style={{ opacity: priceOverrideDisabled ? 0.5 : 1 }} />
            </Button>
          </Tooltip>
          <Tooltip enterDelay={500} title={t('Order remark').toString()} arrow>
            <Button
              variant="iconButton"
              onClick={(): void => setRemarkOpen(true)}
              data-testid="receipt-footer__button--add-remark"
              sx={{ backgroundColor: palette.grey[200] }}
            >
              <AddCommentOutlinedIcon sx={{ fontSize: spacing(4) }} />
            </Button>
          </Tooltip>
          {isFinishButtonVisible && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              onClick={finishButtonEventHandler}
              disabled={(virtualReceipt.receiptProducts.length < 1 && !anySetOnReceipt) || recalculateInProgress}
              data-testid="receipt-footer__button--go-to-finalize"
            >
              {editRequestStatus === 'pending' ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />
                  {t('Saving...')}
                </Box>
              ) : (
                <>{t(editMode?.mode === 'full' ? 'FINISH EDIT' : 'FINISH')}</>
              )}
            </Button>
          )}
          {isDineInSendButtonVisible && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              onClick={sendIteration}
              disabled={virtualReceipt.receiptProducts.length < 1 && !anySetOnReceipt}
              data-testid="receipt-footer__button--dine-in-send"
            >
              {t('SEND')}
            </Button>
          )}
        </div>
      )}
      <DeliveryChargeModal
        initialValue={deliveryChargeDetails?.grossPrice}
        open={isDeliveryPriceModal}
        onClose={(): void => setDeliveryPriceOpen(false)}
        onSuccess={saveManualDeliveryCharge}
      />
      <ManualPriceOverride
        open={isManualPriceOverrideModal}
        onClose={(): void => setManualPriceOverrideOpen(false)}
        onSuccess={(manualDiscount?: string): void => saveManualDiscount(manualDiscount)}
      />
      <TextFieldDialog
        showDialog={isRemarkOpen}
        headerKey="Remarks"
        formId="remarks"
        i18NameSpace="intake"
        onClose={(): void => setRemarkOpen(false)}
        onSubmit={(value): void => saveRemarks(value)}
        defaultValue={remarks}
        maxLength={500}
        placeholder="Remarks"
      />
      <ClockInConfirmationModal showDialog={showClockInDialog} abort={(): void => setShowClockInDialog(false)} />
    </div>
  );
};

export default ReceiptFooter;
