import { Theme } from '@mui/material/styles';

export const sizing: Theme['sizing'] = {
  full: '100%',
  spacingBase: 8,
  bigSpacingBase: 25,
  appBarHeight: 64,
  receiptWidth: 400,
  sideMenuWidth: 250,
  subNavBarsHeight: 50,
  receiptHeaderHeight: 100,
  receiptFooterHeight: 180,
  productCard: {
    height: 85,
    lineCount: {
      xs: 4,
      md: 6,
      xl: 8,
    },
  },
  couponCard: {
    height: 56,
    lineCount: {
      xs: 2,
      md: 3,
      xl: 4,
    },
  },
  bestSellerBoard: {
    numberOfLines: 5,
  },
  buttonBox: {
    xs: 36,
    sm: 40,
    md: 50,
  },
  buttonRound: {
    sm: 28,
    md: 32,
  },
  toppingCard: {
    height: 75,
  },
  badge: {
    sm: 16,
    md: 24,
  },
  buttonHeight: {
    md: 48,
  },
  filterDatePickerWidth: 220,
  filterInputWidth: 260,
  preFinalizeButtonHeight: 120,
  selectWidth: 240,
  receiptItemHeight: 64,
  receiptItemTextWidth: 184,
  propertyIcon: 16,
  alertWidth: 344,
  productSetToppingCardHeight: 92,
  pizzaButton: 100,
  pizzaButtonLabel: 100,
  singleSelectCardHeight: 62,
  pinPad: {
    width: 452,
    height: 720,
  },
  orderDetailsDrawerWidth: 480,
  orderTableFilterSelectMaxHeight: 450,
  customerDisplayHeaderIconSize: 40,
};
