import { Box } from '@mui/system';
import React from 'react';
import background from 'assets/images/welcome-screen-pizza.png';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CarouselImage from 'components/Shared/Banner/Banner';
import { useAppSelector } from 'hooks/useRedux';
import { getCustomerDisplaySplashScreen } from 'stores/Cms/cms.selector';

interface CustomerDisplaySplashScreenProps {
  showThankYouMessage: boolean;
}

const CustomerDisplaySplashScreen: React.FC<CustomerDisplaySplashScreenProps> = ({ showThankYouMessage }) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('customerDisplay');
  const cmsSplashScreen = useAppSelector(getCustomerDisplaySplashScreen);

  function getText() {
    if (showThankYouMessage) {
      return t('Thank you');
    }

    return t('Good morning');
  }

  const splashScreenText = getText();

  return (
    <Box
      sx={{
        display: 'flex',
        height: `100vh`,
        width: '100vw',
      }}
    >
      {cmsSplashScreen && cmsSplashScreen?.images?.length > 0 ? (
        <CarouselImage
          images={cmsSplashScreen?.images}
          carouselTimeSeconds={cmsSplashScreen?.carouselTimeSeconds}
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            flex: 1,
          }}
        />
      )}
      <Box
        sx={{
          padding: `${spacing(6)} ${spacing(8)} ${spacing(6)} ${spacing(8)}`,
          backgroundColor: palette.primary.main,
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <Typography
          align="center"
          color="white"
          sx={{
            fontSize: `${spacing(12)}`,
          }}
        >
          {splashScreenText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomerDisplaySplashScreen;
