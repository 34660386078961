import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { SvgIcon } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import buildClasses from './RoundedButton.css';

export interface RoundedButtonProps {
  secondary?: boolean;
  noShadow?: boolean;
  disabled?: boolean;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  icon?: SvgIconComponent;
  onClick?: (event: React.MouseEvent, value?: string | number) => void;
  type?: 'button' | 'reset' | 'submit';
  form?: string;
  value?: string | number;
  children?: JSX.Element | string;
  testId: string;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({
  secondary,
  noShadow,
  disabled,
  margin,
  padding,
  width,
  height,
  icon,
  onClick,
  type,
  form,
  value,
  children,
  testId,
}) => {
  const { classes } = buildClasses({ secondary, noShadow, margin, padding, width, height })();

  return (
    <ButtonBase
      type={type}
      form={form}
      disabled={disabled}
      onClick={(event: React.MouseEvent): void => {
        onClick && onClick(event, value);
      }}
      className={classes.button}
      data-testid={testId}
    >
      {icon && <SvgIcon className={classes.icon} component={icon} />}
      <span>{children}</span>
    </ButtonBase>
  );
};

export default RoundedButton;
