import React from 'react';
import { TableRow, TableCell, Button, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailableColumns, OrderColumnNames, OrderStatus } from 'containers/AllOrders/AllOrderConsts';
import OrderStatusIndicator from 'components/Orders/Table/OrderStatusIndicator';
import { OrderColumn } from 'stores/AllOrders';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { AllOrdersRowData } from 'containers/AllOrders/AllOrdersTypes';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import buildClasses from './GeneralRow.css';

type SelectRowType = (value: string) => void;

interface GeneralRowProps {
  row: AllOrdersRowData;
  orderId: string;
  isSelected: boolean;
  selectRow: SelectRowType;
  canBeFinalized: boolean;
  canBeContinued: boolean;
  onFinalize: () => void;
  onContinuePayment: () => void;
}

const GeneralRow: React.FC<GeneralRowProps> = ({
  row,
  orderId,
  isSelected,
  selectRow,
  canBeFinalized,
  canBeContinued,
  onFinalize,
  onContinuePayment,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('orders');
  const { palette } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  function onFinalizeClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();
    onFinalize();
  }
  function onContinueClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();
    onContinuePayment();
  }
  function getListOfIndicators(row: any): JSX.Element {
    const indicators = [
      { flag: row.giftCardsIndicator, text: t('GIFTCARD') },
      { flag: row.status === OrderStatus.AwaitingExternalPayment, text: t('UNPAID') },
    ].filter((x) => x.flag);
    let result = '';
    for (let i = 0; i < indicators.length; i += 1) {
      if (i > 0) result += ', ';
      result += indicators[i].flag === true ? indicators[i].text : '';
    }
    if (result.length > 0)
      return (
        <Typography variant="overline" color={palette.grey[500]}>
          {result}
        </Typography>
      );
    return <></>;
  }
  function getTableCellContent(column: OrderColumn): JSX.Element {
    switch (column.key) {
      case OrderColumnNames.storeName:
        return <Typography variant="body2">{row.store}</Typography>;
      case OrderColumnNames.ticketNumber:
        return (
          <Box>
            {getListOfIndicators(row)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {row.giftCardsIndicator && (
                <WarningAmberRoundedIcon color="warning" fontSize="small" sx={{ mr: 1 }} />
              )}
              {row.status === OrderStatus.AwaitingExternalPayment && (
                <WarningAmberRoundedIcon color="error" fontSize="small" sx={{ mr: 1 }} />
              )}
              <Typography variant="body2">{row.ticket}</Typography>
            </Box>
          </Box>
        );
      case OrderColumnNames.name:
        return <Typography variant="body2">{row.name}</Typography>;
      case OrderColumnNames.address:
        return <Typography variant="body2">{row.address}</Typography>;
      case OrderColumnNames.waitTime:
        return <Typography variant="body2">{row.waitingTime ?? t('Future')}</Typography>;
      case OrderColumnNames.price:
        return <Typography variant="body2">{formatToDisplay(row.price)}</Typography>;
      case OrderColumnNames.status:
        return <OrderStatusIndicator status={row.status} />;
      case OrderColumnNames.deliveryType:
        return <Typography variant="body2">{row.deliveryType}</Typography>;

      default:
        return <></>;
    }
  }

  return (
    <TableRow
      className={`${isSelected && classes.selected}`}
      key={orderId}
      hover
      onClick={(): void => selectRow(orderId)}
      selected={isSelected}
      data-testid={`all-orders-table__row--order-${orderId}`}
    >
      {AvailableColumns.sort((a, b) => a.columnSortNumber - b.columnSortNumber).map((columnData) => {
        return (
          <TableCell
            size="small"
            sx={{
              padding: 2,
            }}
            align={columnData.align}
            key={columnData.key}
            data-testid={`all-orders-table__cell--${columnData.key}`}
          >
            {getTableCellContent(columnData)}
          </TableCell>
        );
      })}
      <TableCell size="small" align="center" data-testid="all-orders-table__cell--finalize">
        {canBeFinalized ? (
          <Button
            color="black"
            onClick={onFinalizeClick}
            variant="outlined"
            data-testid="all-orders-table__button--finalize"
          >
            {t('Finalize')}
          </Button>
        ) : canBeContinued ? (
          <Button
            color="black"
            onClick={onContinueClick}
            variant="outlined"
            data-testid="all-orders-table__button--continuePayment"
          >
            {t('Continue payment')}
          </Button>
        ) : (
          <Typography variant="body2">-</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default GeneralRow;
