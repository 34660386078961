import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    banknote: {
      margin: '3px',
      color: 'white',
      cursor: 'pointer',
      position: 'relative',
      backgroundColor: 'rgb(0,180,50)',
      display: 'inline-block',
      width: '45px',
      minWidth: '45px',
      height: '1.25rem',
      textAlign: 'center',
      fontSize: '0.75rem',
      border: '1px solid #006400',
      borderRadius: '5px',
      boxShadow: 'inset 0px 0px 6px -2px rgba(0,0,0,0.75)',
      '&:hover': {
        '& $deleteCircle': {
          opacity: '1',
          visibility: 'visible',
        },
      },
    },
  };
});

export default buildClasses;
