import React from 'react';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddressBookEntry } from 'stores/Intake/intakeTypes';
import { useTranslation } from 'react-i18next';
import buildClasses from './AddressBookItem.css';

interface AddressBookItemProps {
  address: AddressBookEntry;
  onAddressSelected: (address: AddressBookEntry) => void;
}

const AddressBookItem: React.FC<AddressBookItemProps> = ({ address, onAddressSelected }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');

  function onAddressClicked(): void {
    onAddressSelected(address);
  }

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.addressCardContainer} onClick={onAddressClicked}>
        <span className={classes.addressContainer} data-testid="customer-address__book">
          {address.fullAddress}
        </span>
        {address.hasPayOnAccountAvailable && (
          <span
            className={classes.payOnAccountAvailableLabel}
            data-testid="customer-address__book--pay--on--account"
          >
            {t('PayOnAccount available')}
          </span>
        )}
      </Paper>
      {address.isSelected && <CheckCircleIcon className={classes.selectedIcon} />}
    </div>
  );
};

export default AddressBookItem;
