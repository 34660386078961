import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getActivePayment, getIsActivePaymentCashType } from 'stores/OrderPayment/orderPayment.selector';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { motion } from 'framer-motion';
import TipSelectionButtons from 'components/Shared/OrderPayment/Shared/TipSelectionButtons';
import { PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import { getShowTippingModule } from 'stores/Config/config.selector';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';

export interface ConfirmStepButtonsProps {
  onGoBack: () => void;
  backDisabled?: boolean;
  onNextStep: () => void;
  nextDisabled?: boolean;
}

const ConfirmStepButtons: React.FC<ConfirmStepButtonsProps> = ({
  onGoBack,
  onNextStep,
  backDisabled,
  nextDisabled,
}) => {
  const [t] = useTranslation('common');
  const activePayment = useAppSelector(getActivePayment);
  const isActiveCashPayment = useAppSelector(getIsActivePaymentCashType());
  const { totalLeftAmount, changeAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const [initialChange] = useState(changeAmount ?? 0);
  const tippingModuleAvailable = useAppSelector(getShowTippingModule);
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const { reset } = useFormContext<OrderPaymentForm>();

  if (!activePayment) {
    return null;
  }

  function handleGoBack(): void {
    reset({ cashInput: formatToDisplay(totalLeftAmount, true, false) });
    onGoBack();
  }
  const customerPaysWith = activePayment.customerPaidWith;
  const changeExists = !isActiveCashPayment ? customerPaysWith - totalLeftAmount > 0 : initialChange > 0;
  const showTipping = tippingModuleAvailable && changeExists;

  return (
    <motion.div
      style={{ position: 'absolute', bottom: '0', width: '100%' }}
      key="confirm"
      variants={PaymentModalAnimations.variants}
      initial="slideUp"
      animate="onPlace"
      exit="slideUp"
      transition={PaymentModalAnimations.transition}
    >
      {showTipping && <TipSelectionButtons />}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: 2,
        }}
      >
        <Button
          onClick={handleGoBack}
          disabled={backDisabled}
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          sx={{ py: 2, px: 3 }}
          data-testid="finalize-payment--back-button"
        >
          {t('Go back')}
        </Button>

        <Button
          onClick={onNextStep}
          disabled={nextDisabled}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ py: 2, px: 3 }}
          data-testid="finalize-payment--confirmation-button"
        >
          {t('Confirm')}
        </Button>
      </Box>
    </motion.div>
  );
};
export default ConfirmStepButtons;
