import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import {
  PrintingConfiguration,
  GeneralPrintingConfiguration,
  LocalPrintingConfiguration,
} from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import FirstTimeLaunchPage from 'components/Settings/SettingGroup/FirstTimeLaunchPage';
import { StoreConfiguration } from 'typings/Store';
import buildClasses from './FirstLaunchSummary.css';

export interface FirstLaunchSummaryProps {
  onSuccess: () => void;
  onFailure: () => void;
  store?: StoreConfiguration;
  printingConfig: PrintingConfiguration;
}

const FirstLaunchSummary: React.FC<FirstLaunchSummaryProps> = ({
  onSuccess,
  onFailure,
  store,
  printingConfig,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('settings');

  function renderStoreSummary(configuration: StoreConfiguration): JSX.Element {
    return (
      <>
        <Typography variant="h5" className={classes.subHeader}>
          {t('Store settings')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Selected store')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-selected-store"
            >
              {configuration.name}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderGeneralPrintingSettings(configuration: GeneralPrintingConfiguration): JSX.Element {
    return (
      <>
        <Typography variant="h5" className={classes.subHeader}>
          {t('General printing Settings')}
        </Typography>
        <Grid container rowSpacing={3}>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Print employee name on receipt?')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-print-employee-name"
            >
              {configuration.printEmployeeNameOnReceipt ? t('common:Yes') : t('common:No')}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Using roll printer?')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-is-roll-printer"
            >
              {configuration.isRollPrinter ? t('common:Yes') : t('common:No')}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderLocalPrinterSummary(configuration: LocalPrintingConfiguration): JSX.Element {
    return (
      <>
        <Typography variant="h5" className={classes.subHeader}>
          {t('Local printing Settings')}
        </Typography>
        <Grid container rowSpacing={3}>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Automatic printing after each order')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-print-after-order"
            >
              {configuration.printReceiptsAfterOrderFinalization ? t('common:Yes') : t('common:No')}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Selected printer')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-selected-printer"
            >
              {configuration.targetPrinterName}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Path to PDF program')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-print-tool-path"
            >
              {configuration.printingToolPath}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">{t('Path to HTML to PDF program')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              align="right"
              variant="subtitle1"
              data-testid="first-config__label--summary-htmltopdf-tool-path"
            >
              {configuration.htmlToPdfConverterPath}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <FirstTimeLaunchPage label={t('Summary')}>
      {store && renderStoreSummary(store)}
      {renderGeneralPrintingSettings(printingConfig.general)}
      {printingConfig.local.targetPrinterName && renderLocalPrinterSummary(printingConfig.local)}
      <div className={classes.stepButtons}>
        <RoundedButton
          secondary
          testId="prev_config_button"
          height="4rem"
          width="20%"
          type="button"
          onClick={onFailure}
          icon={ArrowBack}
        />
        <RoundedButton testId="finish_config_button" height="4rem" width="70%" type="button" onClick={onSuccess}>
          {t('Finish')}
        </RoundedButton>
      </div>
    </FirstTimeLaunchPage>
  );
};

export default FirstLaunchSummary;
