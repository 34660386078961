import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AppRoute } from 'App/Routes/routes-list';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { switchStore } from 'stores/Store/store.thunk-actions';

const SwitchedStoreGuard: React.FC<{ route: AppRoute }> = ({ children, route }) => {
  const { forbiddenOnSwitchedStore } = route;
  const dispatch = useAppDispatch();
  const { availableStores } = useAppSelector((store) => store.stores);
  const switchedStore = useAppSelector(getSwitchedStore);
  const defaultStore = useMemo(() => {
    return availableStores?.find((el) => el.isDefaultStore);
  }, [availableStores]);

  useEffect(() => {
    if (forbiddenOnSwitchedStore && defaultStore && defaultStore !== switchedStore) {
      dispatch(switchStore({ storeId: defaultStore.id?.toString() }));
    }
  }, [switchedStore]);

  return <>{children}</>;
};
export default SwitchedStoreGuard;
