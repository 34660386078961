import React, { useEffect, useMemo, useState } from 'react';
import ProductSubOptions from 'components/Intake/Product/SubOptions/ProductSubOptions';
import {
  filterOptionsByActiveSubOption,
  filterProductsSubOptions,
  filterProductsByActiveOption,
} from 'stores/Intake/IntakeStoreUtils';
import ProductOptions from 'components/Intake/Product/Options/ProductOptions';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import VisibilityContainer from 'components/Shared/Containers/VisibilityContainer';
import ToggleButtonGroup, { ToggleButtonTab } from 'components/Shared/Buttons/ToggleButtonGroup';
import XTastyPizzaOverview, { XTastyPizzaPartOverview } from 'components/Shared/XTasty/XTastyPizzaOverview';
import ToppingsWrapper from 'components/Intake/XTasty/Toppings/ToppingsWrapper';
import XTastyPizzaWrapper from 'components/Intake/XTasty/Pizzas/XTastyPizzaWrapper';
import { OutlinedButton } from 'components/Shared/Buttons';

import * as xTastyUtils from 'utils/intake/xTastyUtils';
import { prepareToppingsCounter } from 'utils/intake/productCustomizationUtils';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveProductOption, setActiveProductSubOption } from 'stores/Products';
import {
  Product,
  SubOption,
  CustomizationProduct,
  Customization,
  ToppingsCounter,
  ToppingSelection,
  Option,
  ToppingSelectionSimplified,
  ToppingGroup,
} from 'typings/Products';
import { getAllToppings } from 'stores/Toppings/toppings.selector';
import { singleSelectCategories } from 'containers/Intake/Products/ProductCustomization/CustomizeProduct/CustomizeProduct';
import SingleSelectToppingWrapper from 'components/Intake/XTasty/Toppings/SingleSelectToppingWrapper';
import { getPizzaCategoryCode } from 'stores/Config/config.selector';
import { sortBy } from 'lodash';
import buildClasses from './XTasty.css';

interface XTastyContainerProps {
  pizzas: Product[];
  options: Option[];
  subOptions: SubOption[];
  customization: CustomizationProduct;
  triggerAddItem?: boolean;
  quantity: number;
  onItemRecalculate: (pizzaSlices: Customization[], activeOptionId: number) => void;
  onAddItem: (pizzaSlices: Customization[], activeOptionId: number) => void;
}

const XTastyContainer: React.FC<XTastyContainerProps> = ({
  pizzas,
  options,
  subOptions,
  quantity,
  customization,
  onItemRecalculate,
  triggerAddItem,
  onAddItem,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');

  const [showCustomizePart, setShowCustomizePart] = useState(false);
  const [activeSliceIndex, setActiveSliceIndex] = useState(0);
  const [toppingsCounter, setToppingsCounter] = useState<ToppingsCounter>();

  const { activeDeliveryType } = useAppSelector(({ intake }) => intake);
  const toppings = useAppSelector(getAllToppings);
  const { activeOptionId, activeSubOptionId, activeCategoryCode } = useAppSelector((state) => state.products);
  const { toppingCategories } = useAppSelector((state) => state.toppings);
  const { featureFlags, toppingsCustomizationConfiguration } = useAppSelector((state) => state.config);
  const { baseProduct, basketItemIndex, sliceCustomizations, originalOptionId } = customization;
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);

  const [pizzaSlices, setPizzaSlices] = useState<Customization[]>(
    xTastyUtils.createEmptyModelForXSlices(baseProduct.xTastySettings?.numberOfSlices ?? 2),
  );

  const activeSlice = pizzaSlices[activeSliceIndex];

  const availableGroupedToppings = useMemo(() => {
    if (!showCustomizePart) return [];
    const baseProduct = pizzaSlices[activeSliceIndex]?.selectedPizza;
    const availableToppings = toppings.filter(
      (tp) => !baseProduct?.forbiddenToppings.some((ft) => ft.toppingId === tp.id),
    );
    const groupedToppings = availableToppings.reduce<ToppingGroup[]>((acc, current) => {
      const categoryIndex = acc.findIndex((el) => el.code === current.toppingCategoryCode);
      if (categoryIndex > -1) acc[categoryIndex].toppings.push(current);
      else {
        const baseToppingCategory = toppingCategories.find((el) => el.code === current.toppingCategoryCode);
        acc.push({
          name: baseToppingCategory?.name ?? current.toppingCategoryCode,
          code: current.toppingCategoryCode,
          sortOrder: baseToppingCategory?.sortOrder ?? -1,
          toppings: [current],
          isSingleSelectGroup: singleSelectCategories.some(
            (singleSelectGroupCode) => singleSelectGroupCode === current.toppingCategoryCode.toUpperCase(),
          ),
        });
      }
      return acc;
    }, []);
    const sorted = sortBy(groupedToppings, 'sortOrder');
    return sorted;
  }, [activeSliceIndex, activeSlice.selectedPizza, showCustomizePart]);

  const dispatch = useAppDispatch();

  function setActiveOption(optionId: number): void {
    dispatch(setActiveProductOption(optionId));
  }
  function setActiveSubOption(subOptionId: number): void {
    dispatch(setActiveProductSubOption(subOptionId));
  }

  useEffect(() => {
    if (triggerAddItem && activeOptionId) {
      onAddItem(pizzaSlices, activeOptionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAddItem]);

  useEffect(() => {
    // to improve -> maybe all of those (category,subOption,option  shall be dispatched in one action?)
    // find available subOption
    const originalOption = options.find((el) => el.id === originalOptionId);
    if (originalOption) {
      dispatch(setActiveProductSubOption(originalOption.optionSubTypeId));
    }
    dispatch(setActiveProductOption(originalOptionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const predefinedModel =
      sliceCustomizations &&
      xTastyUtils.mapSliceCustomizationsToXTastyModel(sliceCustomizations, pizzas, toppings);
    predefinedModel && setPizzaSlices(predefinedModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceCustomizations, basketItemIndex]);

  useEffect(() => {
    setUpToppingsCounter();
  }, [pizzaSlices, activeSliceIndex]);

  const filteredPizzas = filterProductsByActiveOption(xTastyUtils.removeXTastyPizzas(pizzas), activeOptionId);
  const filteredOptions: Option[] = xTastyUtils.filterOptionsByBaseProduct(baseProduct, options);
  const filteredSubOptions: SubOption[] = xTastyUtils.removeSubOptionWithoutOptions(
    filteredOptions,
    filterProductsSubOptions(filteredPizzas, options, subOptions, pizzaCategoryCode),
  );
  const activeOptions = filterOptionsByActiveSubOption(filteredOptions, activeSubOptionId);

  function setUpToppingsCounter(): void {
    if (
      featureFlags.OfflineModule_RespectMaxToppingsSettings &&
      activeSlice?.selectedPizza &&
      toppingsCustomizationConfiguration
    ) {
      setToppingsCounter(
        prepareToppingsCounter(
          activeSlice.selectedPizza,
          activeSlice.selectedToppings ?? [],
          toppings,
          toppingsCustomizationConfiguration,
        ),
      );
    }
  }

  function createTabsFromPizzaSlices(): ToggleButtonTab[] {
    return pizzaSlices.map((pizzaSlice) => ({
      value: pizzaSlice.sliceNumber,
      text: `${t('part')} ${pizzaSlice.sliceNumber + 1}/${pizzaSlices.length}`,
      icon: pizzaSlice.selectedPizza ? <CheckCircle /> : '',
    }));
  }
  const tabs = createTabsFromPizzaSlices();

  function findNotSelectedFlavours(): number[] {
    return pizzaSlices
      .filter((pizzaSlice) => pizzaSlice.selectedPizza === undefined)
      .map((pizzaSlice) => pizzaSlice.sliceNumber);
  }

  function allPizzaSlicesValid(): boolean {
    return findNotSelectedFlavours().length === 0;
  }

  function goToNotSelectedFlavour(): void {
    const notSelected = findNotSelectedFlavours();
    if (notSelected.length) {
      setActiveSliceIndex(notSelected[0]);
    } else {
      setUpToppingsCounter();
    }
  }

  function selectPizzaOnSlice(pizza: Product, slice: Customization): void {
    const temp = [...pizzaSlices];
    const sliceIndex = temp.indexOf(slice);
    if (sliceIndex !== -1) {
      const defaultProductToppings = xTastyUtils.mapToSimplifiedToppingSelection(toppings, pizza.defaultToppings);

      temp[sliceIndex].selectedPizza = pizza;
      temp[sliceIndex].selectedToppings = defaultProductToppings;
    }
    setPizzaSlices(temp);
    if (pizza.isCYO) {
      setShowCustomizePart(true);
    } else {
      goToNotSelectedFlavour(); // automatically switch to pizza half 2 if 1 is selected, or go back to 1 if 2 is selected and 1 is not.
    }
  }

  function setToppingsOnSlice(selectedToppings: ToppingSelectionSimplified[], slice: Customization): void {
    const temp = [...pizzaSlices];
    const sliceIndex = temp.indexOf(slice);
    if (sliceIndex !== -1) {
      temp[sliceIndex].selectedToppings = selectedToppings;
    }
    setPizzaSlices(temp);
  }

  function getPizzaOverviewModel(): XTastyPizzaPartOverview[] {
    return pizzaSlices.map((ps) => ({
      PizzaName: ps.selectedPizza?.name,
      sliceIndex: ps.sliceNumber,
      completed: !!ps.selectedPizza,
    }));
  }

  function toggleCustomizePart(): void {
    setShowCustomizePart(!showCustomizePart);
  }

  function handleToppingClicked(selToppings: ToppingSelectionSimplified[]): void {
    setToppingsOnSlice(selToppings, activeSlice);
  }

  useEffect(() => {
    allPizzaSlicesValid() && activeOptionId && onItemRecalculate(pizzaSlices, activeOptionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pizzaSlices, quantity, activeOptionId, activeDeliveryType]);

  return (
    <>
      <div className={classes.headingContainer}>
        <div className={classes.pizzaOptions}>
          {subOptions && options && (
            <>
              <ProductSubOptions
                subOptions={filteredSubOptions}
                activeSubOptionId={activeSubOptionId}
                onSubOptionChange={setActiveSubOption}
              />
              <ProductOptions
                options={activeOptions}
                activeOptionId={activeOptionId}
                onOptionChange={setActiveOption}
                activeCategoryCode={activeCategoryCode}
              />
            </>
          )}
        </div>
        <XTastyPizzaOverview
          onPizzaSliceClick={(sliceNumber: number): void => {
            setActiveSliceIndex(sliceNumber);
          }}
          pizzaParts={getPizzaOverviewModel()}
          selectedPart={activeSliceIndex}
        />
      </div>

      <ToggleButtonGroup
        val={activeSliceIndex}
        tabs={tabs}
        onClick={(selectedSliceIndex: number): void => {
          setActiveSliceIndex(selectedSliceIndex);
        }}
        testIdPrefix="xTasty-customization"
        secondary
      >
        <OutlinedButton
          disabled={!pizzaSlices[activeSliceIndex].selectedPizza}
          onClickFn={toggleCustomizePart}
          testId="xTasty-customization__button--edit-toppings"
        >
          {t('Edit toppings')}
        </OutlinedButton>
      </ToggleButtonGroup>
      {!!activeSlice && (
        <>
          <XTastyPizzaWrapper
            selectedPizzaId={activeSlice.selectedPizza?.id}
            pizzas={filteredPizzas}
            onPizzaClick={(product: Product): void => selectPizzaOnSlice(product, activeSlice)}
          />
          <VisibilityContainer isVisible={showCustomizePart}>
            <SingleSelectToppingWrapper
              selectedToppings={activeSlice.selectedToppings ?? []}
              singleSelectionToppingGroups={availableGroupedToppings.filter((el) => el.isSingleSelectGroup)}
              onToppingSelected={handleToppingClicked}
            />
            {activeSlice?.selectedPizza?.canChangeToppings && (
              <div className={classes.toppingsWrapper}>
                <ToppingsWrapper
                  onToppingsChange={handleToppingClicked}
                  toppingsCounter={toppingsCounter}
                  availableGroupedToppings={availableGroupedToppings.filter((el) => !el.isSingleSelectGroup)}
                  selectedToppings={activeSlice.selectedToppings ?? []}
                />
              </div>
            )}
            {!activeSlice?.selectedPizza?.canChangeToppings && <div className={classes.noToppingsWrapper} />}
          </VisibilityContainer>
        </>
      )}
    </>
  );
};

export default XTastyContainer;
