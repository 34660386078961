import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    customerIcon: {
      float: 'left',
      marginRight: spacing(1),
    },
    customerSelected: {
      color: palette.primary.dark,
    },
  };
});

export default buildClasses;
