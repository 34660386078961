import React from 'react';
import ReceiptProductItem from 'components/Intake/Receipt/Item/ReceiptProduct';
import ReceiptDiscountItem from 'components/Intake/Receipt/Item/ReceiptDiscount';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCustomizationProductSettings } from 'stores/Products';
import { MealCoupon } from 'typings/Coupons';
import { setCouponToCustomize } from 'stores/Coupons';
import {
  recalculateBasket,
  removeCouponFromTheBasket,
  removeFromBasket,
} from 'stores/Basket/basket.thunk-actions';
import { changeAutoItemQuantity } from 'stores/Basket/basket.slice';
import { ReceiptProduct, ReceiptDiscount, BasketItem } from 'typings/Basket';
import { clearCustomerCreditUsage } from 'stores/Customer';
import { canEditBasket } from 'stores/Intake/intake.selector';
import buildClasses from './ReceiptItems.css';

interface ReceiptItemsProps {
  receiptProducts: ReceiptProduct[];
  receiptDiscounts: ReceiptDiscount[];
  canRemoveItems: boolean;
}

const ReceiptItems: React.FC<ReceiptItemsProps> = ({ receiptProducts, receiptDiscounts, canRemoveItems }) => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const { basketCoupons } = useAppSelector((store) => store.basket);
  const basketEdition = useAppSelector(canEditBasket);

  const removeCouponFromBasketHandler = (coupon: ReceiptDiscount): void => {
    if (coupon.discountType === 'CustomerCredit') {
      dispatch(clearCustomerCreditUsage());
      dispatch(recalculateBasket({}));
      return;
    }
    coupon.couponId && dispatch(removeCouponFromTheBasket(coupon.couponId));
  };

  function customizeProductHandler(basketItem: BasketItem): void {
    dispatch(getCustomizationProductSettings({ basketItem, searchType: 'itemId' }));
  }

  function customizeDiscountHandler(discountItem: ReceiptDiscount): void {
    const couponToCustomize = basketCoupons.find((bc) => bc.couponId === discountItem.couponId);

    if (couponToCustomize?.useMealConfigurator) {
      const mealCoupon = couponToCustomize as MealCoupon;
      dispatch(setCouponToCustomize(mealCoupon));
    }
  }
  function changeAutoAddedItemQuantity(itemId: number, quantity: number): void {
    dispatch(changeAutoItemQuantity({ itemId, quantity }));
    dispatch(recalculateBasket({}));
  }

  return (
    <div
      className={`${classes.itemsContainer} withripple ${
        canRemoveItems ? classes.itemsContainerWithToggleGroup : classes.itemsContainerWithoutToggleGroup
      }`}
    >
      {receiptProducts.map((receiptProduct: ReceiptProduct) => (
        <ReceiptProductItem
          isBasketEditable={basketEdition}
          key={receiptProduct.id}
          receiptProduct={receiptProduct}
          removeItem={(): void => {
            dispatch(removeFromBasket(receiptProduct.id));
          }}
          customizeProduct={customizeProductHandler}
          canRemoveItem={canRemoveItems}
          changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
        />
      ))}
      {receiptDiscounts &&
        receiptDiscounts.map((discount: ReceiptDiscount) => (
          <ReceiptDiscountItem
            discount={discount}
            key={discount.couponId ?? discount.description}
            canRemoveItem={canRemoveItems}
            canRemoveDiscount={(canRemoveItems && discount.canDelete) ?? false}
            customizeProduct={customizeProductHandler}
            customizeDiscount={customizeDiscountHandler}
            removeItem={(_) => {
              removeCouponFromBasketHandler(discount);
            }}
            changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
          />
        ))}
    </div>
  );
};

export default ReceiptItems;
