import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethodToggleGroup from 'components/Intake/Finalize/PaymentMethodToggleGroup/PaymentMethodToggleGroup';
import TakeAwayTypeSelector from 'components/Intake/Finalize/TakeAwayTypeSelector/TakeAwayTypeSelector';
import PickUpTypeBar from 'components/Intake/Finalize/PickUpTypeBar/PickUpTypeBar';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import OrderRequiredInfoForm from 'components/Intake/Finalize/OrderRequiredInfoForm/OrderRequiredInfoForm';
import uuidGenerator from 'utils/GuidGenerator';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  EftPaymentOperation,
  PaymentMethodCode,
  PaymentSuccessfulResult,
  StartPaymentResult,
} from 'typings/Payments';
import { canChangePayment } from 'stores/Intake/intake.selector';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { clearOrderEdition, restartOrder } from 'stores/combined.actions';
import { FiscalContext } from 'App/AppEventsTracker/AppEventsTracker';
import { editOrder } from 'stores/AllOrders';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import { useFormContext } from 'react-hook-form';
import {
  payForOrderWithEft,
  setFinalizeTrigger,
  setTableTag,
  submitOrder,
  putAcceptTakeAwayPayment,
  changeDeliveryType,
} from 'stores/Intake';
import buildClasses from './Finalize.css';
import { PickUpTypesValues } from '../IntakeConsts';

const Finalize = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const orderRequiredInfoFormRef = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();
  const paymentEdition = useAppSelector(canChangePayment);
  const [orderId, setOrderId] = useState(uuidGenerator());
  const { currentFiscalSummary } = useContext(FiscalContext);

  const {
    activeDeliveryType,
    selectedOrderCustomer,
    finalizeTrigger,
    editMode,
    editRequestStatus,
    activePaymentMethod,
  } = useAppSelector(({ intake }) => intake);
  const { selectedStore } = useAppSelector(({ stores }) => stores);
  const { basketData } = useAppSelector((store) => store.basket);
  const { customerCanPayOnAccount, chargedCompany } = useAppSelector((store) => store.customer);
  const { availablePaymentMethods } = useAppSelector((store) => store.payments);
  const { successfulTrigger, cancelTrigger, abandonTrigger } = useAppSelector((store) => store.orderPayment);

  const { reset: resetCheckoutForm, setValue, getValues, trigger } = useFormContext<CheckoutDetailsForm>();

  const openSettlePaymentForm = async (): Promise<void> => {
    const isCheckoutFormValid = await trigger();

    if (!isCheckoutFormValid) {
      return;
    }
    if (basketData?.summary) {
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId,
          closeCallback: closeSettlePaymentForm,
          successCallback: finalizePayment,
          startPaymentCallback: startPayment,
          preselectedPaymentMethod: activePaymentMethod,
          totalToPay: basketData.summary.total,
          useTwoStepOrderPlacing: true,
          orderFinalizationData: getFormData(),
          deliveryType: activeDeliveryType,
          isLocalOrder: true,
          cancelOnClose: true,
          customerData: {
            payOnAccountAccount: {
              customerCanPayOnAccount,
              chargedCompany,
            },
          },
        }),
      );
    }
  };

  const closeSettlePaymentForm = (): void => {
    setValue('EftPaymentOperation', undefined); // to remove with payment V2
    dispatch(clearSettlePayment()); // to remove with payment V2
    dispatch(clearEftPaymentData());
    setOrderId(uuidGenerator());
  };

  function finalizePayment(result: PaymentSuccessfulResult): void {
    // to remove with payment V2
    const usePaymentAcceptance =
      result.orderAlreadyPlaced ||
      (activeDeliveryType === PickUpTypesValues.takeAway && !!result.eftPaymentOperation?.eftPaymentId);

    if (usePaymentAcceptance) {
      acceptPayment(orderId, result.paymentMethodCode, result.eftPaymentOperation);
    } else {
      placePaidOrder(orderId, result.paymentMethodCode, result.eftPaymentOperation);
    }
  }

  function startPayment(result: StartPaymentResult): void {
    // to remove with payment V2
    const usePaymentAcceptance =
      activeDeliveryType === PickUpTypesValues.takeAway && result.eftPaymentId !== undefined;

    if (usePaymentAcceptance) {
      if (result.orderAlreadyPlaced) {
        dispatch(payForOrderWithEft());
      } else {
        setValue('EftPaymentOperation', {
          eftPaymentId: result.eftPaymentId,
          finishedManually: false,
        });
        submitForm();
      }
    }
  }

  function submitForm(): void {
    // to remove with payment V2
    if (!orderRequiredInfoFormRef.current) {
      return;
    }

    orderRequiredInfoFormRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
  }

  function getFormData(): CheckoutDetailsForm {
    let finalizationData = { ...getValues() };

    if (selectedOrderCustomer?.profile?.id) {
      finalizationData = { ...finalizationData, profile: { ...selectedOrderCustomer.profile } };
    }

    return finalizationData;
  }

  function onFormSubmit(_: CheckoutDetailsForm): void {
    const finalizationData = getFormData();

    if (editMode && editMode.mode === 'paid') {
      saveOrder(finalizationData);
      return;
    }

    dispatch(
      submitOrder({
        orderId,
        data: finalizationData,
        placeUnpaid:
          activeDeliveryType === PickUpTypesValues.takeAway &&
          finalizationData.EftPaymentOperation?.eftPaymentId !== undefined &&
          finalizationData.EftPaymentOperation?.finishedManually === false,
        isAsap: finalizationData.time === t('asap'),

        fiscalSummary: currentFiscalSummary,
      }),
    );
  }

  function placePaidOrder(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    setValue('EftPaymentOperation', eftPaymentOperation);
    submitForm();
    closeSettlePaymentForm();
  }

  function acceptPayment(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    dispatch(
      putAcceptTakeAwayPayment({
        orderId,
        paymentMethodCode: paymentMethod,
        eftPaymentOperation,
      }),
    );
    closeSettlePaymentForm();
  }

  function deliveryTypeChange(value: PickUpTypesValues): void {
    if (value) {
      dispatch(changeDeliveryType(value));
    }
  }

  function saveOrder(data: CheckoutDetailsForm): void {
    const asapTanslation = t('asap');

    dispatch(
      editOrder({
        ...data,
        translations: {
          asap: asapTanslation,
        },
      }),
    );
  }

  function closeEdition(): void {
    resetCheckoutForm({});
    dispatch(clearOrderEdition());
  }

  function paymentMethodAvailable(paymentMethodCode?: PaymentMethodCode): boolean {
    return (
      paymentMethodCode !== undefined &&
      availablePaymentMethods !== undefined &&
      availablePaymentMethods.length > 0 &&
      availablePaymentMethods.find((pm) => pm.code === paymentMethodCode) !== undefined
    );
  }

  function showPaymentSelectionForm(): boolean {
    if (!paymentEdition) return false;

    if (
      activeDeliveryType === PickUpTypesValues.pickUp &&
      (!customerCanPayOnAccount || !paymentMethodAvailable(PaymentMethodCode.PayOnAccount))
    )
      return false;

    return true;
  }

  useEffect(() => {
    if (finalizeTrigger) {
      dispatch(setFinalizeTrigger(false));
      if (activeDeliveryType === PickUpTypesValues.takeAway) {
        openSettlePaymentForm();
      } else {
        submitForm();
      }
    }
  }, [finalizeTrigger]);

  useEffect(() => {
    if (editRequestStatus === 'failure' || editRequestStatus === 'success') {
      closeEdition();
    }
  }, [editRequestStatus]);

  useEffect(() => {
    if (successfulTrigger === orderId || abandonTrigger === orderId) {
      closeSettlePaymentForm(); // all those will be moved to one nice action when payment V1 is removed
      dispatch(restartOrder());
      resetCheckoutForm();
    }
    if (cancelTrigger === orderId) {
      closeSettlePaymentForm();
    }
  }, [successfulTrigger, cancelTrigger, abandonTrigger]);

  return (
    <div className={classes.wrapper}>
      <PickUpTypeBar activeDeliveryType={activeDeliveryType} onChange={deliveryTypeChange} />
      <div className={classes.content}>
        <OrderRequiredInfoForm reference={orderRequiredInfoFormRef} onFormSubmit={onFormSubmit} />
        {showPaymentSelectionForm() && <PaymentMethodToggleGroup />}
        {!editMode &&
          selectedStore?.enableEatInOrders === true &&
          activeDeliveryType === PickUpTypesValues.takeAway && (
            <TakeAwayTypeSelector onTableTagChange={(value?: string) => dispatch(setTableTag(value))} />
          )}
      </div>
      {editMode?.mode === 'paid' && (
        <Grid container className={classes.editButtons}>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={closeEdition}
              variant="outlined"
              fullWidth
              color="black"
              data-testid="finalize-intake__button--cancel"
              className={classes.button}
            >
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={8} paddingLeft={4}>
            <Button
              onClick={() => submitForm()}
              disabled={editRequestStatus === 'pending'}
              fullWidth
              variant="contained"
              data-testid="finalize-intake__button--save"
              className={classes.button}
            >
              {editRequestStatus === 'pending' ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />
                  {t('Saving...')}
                </Box>
              ) : (
                t('Save')
              )}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Finalize;
