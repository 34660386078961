import React, { useState } from 'react';
import { Button, Box, useTheme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SvgIconComponent } from '@mui/icons-material';

export interface HeaderOptionButton {
  value: string;
  icon: SvgIconComponent;
}
interface PageHeaderProps {
  headerOptions: HeaderOptionButton[];
  translationNS: string;
  onSelect?: (value: string) => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({ headerOptions, translationNS, onSelect }) => {
  const { palette, spacing } = useTheme();
  const [activeOption, setActiveOption] = useState(headerOptions[0].value);
  const [t] = useTranslation(translationNS);

  function onOptionSelectHandler(value: string): void {
    setActiveOption(value);
    onSelect && onSelect(value);
  }

  const headerToggleButtons = headerOptions.map((button) => {
    return (
      <Button
        sx={{
          color: palette.primary.contrastText,
          backgroundColor:
            activeOption === button.value ? palette.appBar?.main ?? palette.primary.main : 'transparent',
          padding: `${spacing(0.5)} ${spacing(0.5)}`,
          margin: `0 ${spacing(1)} ${spacing(4)} 0`,
          borderRadius: '0',
          textTransform: 'none',
        }}
        key={button.value}
        value={button.value}
        onClick={(): void => onOptionSelectHandler(button.value)}
        data-testid={`history-header__button--${button.value}`}
      >
        <button.icon />
        {t(button.value)}
      </Button>
    );
  });

  return (
    <Box
      sx={{
        backgroundColor: palette.appBar?.subMain ?? palette.primary.light,
        padding: spacing(4),
        marginBottom: -2,
      }}
    >
      {headerToggleButtons}
      <Typography
        variant="h5"
        sx={{
          color: palette.primary.contrastText,
          paddingLeft: spacing(1),
        }}
      >
        {t(activeOption)}
      </Typography>
    </Box>
  );
};

export default PageHeader;
