import React from 'react';
import FilterButton from 'components/Orders/FilterButton/FilterButton';
import { Box, Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FilterInput from 'components/Orders/FilterInput/FilterInput';
import FilterDatePicker from 'components/Orders/FilterDatePicker/FilterDatePicker';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useAppSelector } from 'hooks/useRedux';
import { deliveryFilterOptions, HeaderOptions } from '../../LocalOrdersConsts';

interface FiltersProps {
  loadDataRows: () => void;
  setSearchInputValue: (value: string) => void;
  setDeliveryType: (value: PickUpTypesValues) => void;
  setPaymentMethod: (value: string) => void;
  setTicketNr: (value: string) => void;
  startDate: Date;
  setStartDate: (value: Date) => void;
  endDate: Date;
  setEndDate: (value: Date) => void;
  selectedOption: string;
}

const Filters: React.FC<FiltersProps> = ({
  loadDataRows,
  setSearchInputValue,
  setDeliveryType,
  setPaymentMethod,
  setTicketNr,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedOption,
}) => {
  const { palette } = useTheme();
  const { availablePaymentMethods } = useAppSelector(({ payments }) => payments);
  const [t] = useTranslation('orders');
  const paymentFilterOptions = availablePaymentMethods?.map((pm) => pm.code);

  let searchTimer: ReturnType<typeof setTimeout>;

  function searchValueChanged(value: string): void {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(function updateRowsOnSearchChange() {
      setSearchInputValue(value);
    }, 1000);
  }

  return (
    <Box
      sx={{
        backgroundColor: palette.appBar?.main ?? palette.primary.main,
        py: 1.5,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexFlow: 'row', flex: 1 }}>
          <FilterInput
            placeholder={t('searchPlaceholder')}
            fullWidth
            onValueChange={(value): void => searchValueChanged(value)}
            testId="history-filter__input--search"
          />
          {selectedOption !== HeaderOptions.open && (
            <>
              <FilterDatePicker
                onDateChange={(value): void => setStartDate(value)}
                maxFilterDate={endDate}
                testId="history-filter__input--start-date"
              />
              <FilterDatePicker
                onDateChange={(value): void => setEndDate(value)}
                minFilterDate={startDate}
                testId="history-filter__input--end-date"
              />
              <FilterInput
                placeholder={t('Ticket nr')}
                onValueChange={(value): void => setTicketNr(value)}
                testId="history-filter__input--order-nr"
                type="number"
              />
            </>
          )}
        </Box>
        <Button
          color="secondary"
          size="large"
          variant="outlined"
          onClick={loadDataRows}
          data-testid="history-filter__button--search"
        >
          {t('Search')}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'row' }}>
        {selectedOption !== HeaderOptions.open && (
          <>
            <FilterButton
              label={t('Payment method')}
              options={paymentFilterOptions}
              onItemSelected={(methodValue): void => setPaymentMethod(methodValue)}
              testId="history-filter__dropdown--payment-methods"
            />
            <FilterButton
              label={t('Order type')}
              options={deliveryFilterOptions}
              onItemSelected={(deliveryTypeValue): void => setDeliveryType(deliveryTypeValue as PickUpTypesValues)}
              testId="history-filter__dropdown--order-types"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Filters;
