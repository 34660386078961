import { HeaderOptionButton } from 'components/Shared/PageHeader/PageHeader';
import { Settings } from '@mui/icons-material';

export enum MenuItems {
  language = 'language',
  intakeSettings = 'Intake Settings',
  printingSettings = 'Printing Settings',
  paymentSettings = 'Payment settings',
  systemSettings = 'System settings',
}

export const settingsOptions: HeaderOptionButton[] = [{ value: 'Settings', icon: Settings }];
