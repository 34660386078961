import React from 'react';
import { FormControl, TextField, Box, useTheme } from '@mui/material';
import { useForm, DeepMap, Validate, FieldError } from 'react-hook-form';
import { CurrencySymbolPosition } from 'stores/Config/configTypes';
import usePaymentSettings from 'hooks/usePaymentSettings';

export interface ValidationInput {
  formRef: ReturnType<typeof useForm>['register'];
  errors: DeepMap<InputValidation, FieldError>;
  required: boolean;
  validators: Validate | Record<string, Validate>;
}

export interface InputValidation {
  inputValue: string;
}

export interface InputUnitProps {
  placeholder?: string;
  fullWidth?: boolean;
  value?: number | string;
  defaultValue?: number | string;
  onChange?: (val: string) => void;
  name?: string;
  id?: string;
  inputValidation?: ValidationInput;
  testId: string;
  autofocus?: boolean;
  label?: string;
  type?: string;
}

const InputUnit: React.FC<InputUnitProps> = ({
  fullWidth,
  value,
  placeholder,
  id,
  defaultValue,
  onChange,
  inputValidation,
  testId,
  autofocus,
  label,
  type,
}) => {
  const { palette } = useTheme();
  const { GetPaymentSettings } = usePaymentSettings();
  const paymentSettings = GetPaymentSettings();

  function changeHandler(val: string) {
    if (onChange) onChange(val);
  }

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'row', marginBottom: '0.75rem' }} fullWidth={fullWidth}>
      {paymentSettings.currencySymbolPosition === CurrencySymbolPosition.before && (
        <Box
          sx={{
            display: 'flex',
            width: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            color: `${palette.secondary.contrastText}`,
            backgroundColor: `${palette.secondary.light}`,
          }}
        >
          {paymentSettings.currencySymbol}
        </Box>
      )}
      <TextField
        inputRef={inputValidation?.formRef({
          validate: inputValidation?.validators,
        })}
        type={type ?? 'text'}
        autoFocus={autofocus}
        error={inputValidation?.errors.inputValue && true}
        helperText={inputValidation?.errors.inputValue && inputValidation?.errors.inputValue.message}
        defaultValue={defaultValue}
        value={value}
        name="inputValue"
        onChange={(e) => changeHandler(e.target.value)}
        sx={{
          backgroundColor: `${palette.secondary.light}`,
          color: '#737373',
          fontSize: '1rem',
          width: '100%',
          height: '100%',
          '& .MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            bottom: '-1.25rem',
          },
        }}
        placeholder={placeholder}
        variant="outlined"
        id={id}
        inputProps={{ 'data-testid': testId }}
        label={label}
      />
      {paymentSettings.currencySymbolPosition === CurrencySymbolPosition.after && (
        <Box
          sx={{
            display: 'flex',
            width: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            color: `${palette.secondary.contrastText}`,
            backgroundColor: `${palette.secondary.light}`,
          }}
        >
          {paymentSettings.currencySymbol}
        </Box>
      )}
    </FormControl>
  );
};

export default InputUnit;
