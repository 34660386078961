import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const PartialLoader: React.FC = () => {
  return (
    <Box sx={{ width: ' 100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
};

export default PartialLoader;
