import React from 'react';
import { TableRow, TableCell, Collapse, Button, Tooltip, Box, useTheme } from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useTranslation } from 'react-i18next';
import { ProductDescription } from 'stores/AllOrders';
import buildClasses from './ExpandableOrdersRow.css';

interface ExpandableOrdersRowProps {
  isVisible: boolean;
  products: ProductDescription[];
  printClicked: () => void;
  cancelOrderClicked: () => void;
  isCancelable: boolean;
}

const ExpandableOrdersRow: React.FC<ExpandableOrdersRowProps> = ({
  products,
  isVisible,
  printClicked,
  cancelOrderClicked,
  isCancelable,
}) => {
  const { classes } = buildClasses();
  const { palette } = useTheme();
  const [t] = useTranslation('orders');

  const productsList = products.map((product) => {
    const quantity = `${product.quantity} x`;
    const option = ` (${product.type})`;
    return (
      <Box
        sx={{ marginRight: 'auto', padding: '5px', display: 'inline-flex', alignItems: 'center' }}
        key={product.description}
      >
        <Box
          sx={{
            height: '28px',
            width: '28px',
            textAlign: 'center',
            lineHeight: '28px',
            color: palette.white,
            backgroundColor: palette.grey[200],
            borderRadius: '28px',
            fontSize: '12px',
            marginRight: '10px',
          }}
          data-testid="history-orders-list-expanded__label--quantity"
        >
          {quantity}
        </Box>
        <Box data-testid="history-orders-list-expanded__label--description">{product.description}</Box>
        <small className={classes.productType} data-testid="history-orders-list-expanded__label--option">
          {option}
        </small>
        {product.sliceDescriptions.length > 0 && (
          <small className={classes.productType} data-testid="history-orders-list-expanded__label--flavours">
            ({product.sliceDescriptions.map((sd) => sd.title).join(' - ')})
          </small>
        )}
        {product.remark && (
          <Tooltip title={<span className={classes.remarkTooltip}>{product.remark}</span>} placement="top">
            <Box
              sx={{
                backgroundColor: 'rgb(232, 244, 253)',
                textAlign: 'center',
                padding: '5px',
                marginLeft: '5px',
                display: 'inline-flex',
                alignItems: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '50px',
                wordBreak: 'break-word',
                maxWidth: '150px',
              }}
            >
              <AnnouncementIcon sx={{ marginRight: '5px', color: '#2196f3' }} />
              <small>{product.remark}</small>
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  });

  return (
    <TableRow sx={{ padding: 0, margin: 0 }}>
      <TableCell
        align="center"
        colSpan={10}
        sx={{ padding: '0 !important', margin: 0, borderBottom: 0, backgroundColor: '#F5F5F5' }}
      >
        <Collapse in={isVisible}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>{productsList}</Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              height: '50px',
              padding: '7px 10px',
              backgroundColor: palette.primary.light,
            }}
          >
            {isCancelable && (
              <Button
                variant="contained"
                sx={{ marginLeft: '10px' }}
                onClick={cancelOrderClicked}
                data-testid="history-orders-list-expanded__button--cancel-order"
              >
                {t('Cancel Order')}
              </Button>
            )}
            <Button
              variant="contained"
              sx={{ marginLeft: '10px' }}
              onClick={printClicked}
              data-testid="history-orders-list-expanded__button--print-receipt"
            >
              {t('Print Receipt')}
            </Button>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default ExpandableOrdersRow;
