import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    tab: {
      background: palette.customGray.main,
      padding: spacing(2),
      width: 'fit-content',
      borderTopLeftRadius: spacing(0.5),
      borderTopRightRadius: spacing(0.5),
      position: 'relative',
      zIndex: 1200,
      marginRight: spacing(1),
      minWidth: '240px',
      cursor: 'pointer',
    },
    tabActive: {
      background: palette.white,
      boxShadow: '0 10px 0 rgb(255 255 255), 0 1px 5px 0 rgb(0 0 0 / 16%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
    },

    activeFloorText: {
      color: palette.black.main,
    },
  };
});

export default buildClasses;
