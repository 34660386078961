import { enGB, nl, pl, es } from 'date-fns/locale';
import { getI18n } from 'react-i18next';

function dateFnsLang(): Locale {
  const currentLang = getI18n().language;

  if (currentLang === 'en') return enGB;
  if (currentLang === 'nl') return nl;
  if (currentLang === 'pl') return pl;
  if (currentLang === 'es') return es;
  return enGB;
}

export default dateFnsLang;
