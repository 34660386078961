import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    ordersOverviewContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
      maxHeight: '100%',
      overflow: 'hidden',
    },
    orderStatusTab: {
      color: palette.grey[800],
      '&.Mui-selected': {
        fontWeight: 'bold',
        color: palette.black.main,
      },
    },
    tableWrapper: {
      maxHeight: `calc(100% - ${sizing.appBarHeight}px - ${spacing(4)} - ${spacing(3)})`,
    },
  };
});
export default buildClasses;
