import React from 'react';
import { Chip, Box, useTheme } from '@mui/material';

interface CustomerResultItemFooterInfoProps {
  title: string;
  value: string | number;
  testId?: string;
}

const CustomerResultItemFooterInfo: React.FC<CustomerResultItemFooterInfoProps> = ({ title, value, testId }) => {
  const { spacing } = useTheme();
  function renderValueContainer(): JSX.Element {
    if (testId) {
      return <Chip data-testid={testId} size="small" label={value} sx={{ marginLeft: spacing(0.5) }} />;
    }

    return <Chip size="small" label={value} sx={{ marginLeft: spacing(0.5) }} />;
  }

  const valueContainer = renderValueContainer();

  return (
    <Box sx={{ marginLeft: spacing(1), marginRight: spacing(1), display: 'inline-flex' }}>
      {title}
      {valueContainer}
    </Box>
  );
};

export default CustomerResultItemFooterInfo;
