import React from 'react';
import { AppBar, Tabs, Tab, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setActivePaymentMethod } from 'stores/Intake';
import { AccountBalanceWalletOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { PaymentMethod, PaymentMethodCode } from 'typings/Payments';
import { clearEftResult } from 'stores/Eft';
import { paymentMethodTypes } from 'containers/Intake/IntakeConsts';

interface PaymentMethodTypeBarProps {
  excludedPaymentMethodsCodes?: string[];
  forcedPaymentMethodCode?: string;
  additionalPaymentMethodsWhenForced?: string[];
}

const PaymentMethodTypeBar: React.FC<PaymentMethodTypeBarProps> = ({
  excludedPaymentMethodsCodes,
  forcedPaymentMethodCode,
  additionalPaymentMethodsWhenForced,
}) => {
  const [t] = useTranslation('intake');
  const { availablePaymentMethods } = useAppSelector(({ payments }) => payments);
  const { activePaymentMethod } = useAppSelector(({ intake }) => intake);
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  function paymentMethodChange(ev: React.ChangeEvent<{}>, value: PaymentMethodCode): void {
    dispatch(setActivePaymentMethod(value));
    dispatch(clearEftResult());
  }

  function notExcluded(code: string): boolean {
    if (!excludedPaymentMethodsCodes) {
      return true;
    }

    return !excludedPaymentMethodsCodes.includes(code);
  }

  function forcedOrAdditional(code: string): boolean {
    if (!forcedPaymentMethodCode) {
      return true;
    }

    return (
      (forcedPaymentMethodCode === code ||
        (additionalPaymentMethodsWhenForced && additionalPaymentMethodsWhenForced.includes(code))) === true
    );
  }

  function getPaymentOption(pm: PaymentMethod) {
    const paymentMethodType = paymentMethodTypes.find((pt) => pm.code === pt.value);
    if (paymentMethodType !== undefined) {
      return { value: paymentMethodType.value, icon: paymentMethodType.icon };
    }
    return { value: pm.code, icon: AccountBalanceWalletOutlined };
  }

  const paymentOptions = availablePaymentMethods
    .map((pm) => getPaymentOption(pm))
    .filter((pm) => notExcluded(pm.value) && forcedOrAdditional(pm.value));

  return (
    <>
      {paymentOptions && (
        <AppBar position="static" sx={{ background: `${palette.primary.dark}`, boxShadow: 'none' }}>
          <Tabs
            value={activePaymentMethod}
            onChange={paymentMethodChange}
            sx={{
              color: 'black',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.white,
              },
            }}
            variant="scrollable"
            textColor="secondary"
            aria-label="scrollable force tabs example"
          >
            {paymentOptions.map((paymentOption) => (
              <Tab
                sx={{ color: `${palette.white} !important` }}
                key={paymentOption.value}
                label={t(paymentOption.value)}
                icon={<paymentOption.icon />}
                value={paymentOption.value}
                data-testid={`settle-payment__button--payment-option-${paymentOption.value}`}
              />
            ))}
          </Tabs>
        </AppBar>
      )}
    </>
  );
};

export default PaymentMethodTypeBar;
