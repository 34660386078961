import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    remarkTooltip: {
      fontSize: '14px',
    },
    productType: {
      paddingLeft: '5px',
      fontSize: '80%',
    },
  };
});

export default buildClasses;
