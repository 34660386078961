import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing }) => {
  return {
    root: {
      width: sizing.full,
    },
    paper: {
      width: sizing.full,
      marginBottom: spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: spacing(2.5),
      width: 1,
    },
    finalizeButton: {
      padding: `${spacing(1)} ${spacing(2)}`,
      boxShadow: 'none',
      border: `1px solid ${palette.primary.main}`,
    },
    cancelButton: {
      padding: `${spacing(1)} ${spacing(2)}`,
      marginRight: spacing(2),
      backgroundColor: palette.white,
    },
    rowSelected: {
      backgroundColor: `${palette.green[100]} !important`,
    },
    tableActionCell: {
      padding: `${spacing(1)} ${spacing(2)}`,
      borderBottom: `0.5px solid ${palette.secondary.dark}`,
      borderTop: `0.5px solid ${palette.secondary.dark}`,
    },
    tableHeadCell: {
      borderBottom: `0.5px solid ${palette.secondary.dark}`,
      color: palette.black.main,
    },
    tableCell: {
      borderBottom: `0.5px solid ${palette.secondary.dark}`,
      borderTop: `0.5px solid ${palette.secondary.dark}`,
      color: palette.black.main,
    },
  };
});
export default buildClasses;
