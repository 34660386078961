/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { acceptPaymentForOrder, checkIfOrderIsFullyPaid } from 'stores/OrderPayment/orderPayment.actions';
import { getActivePayment, getFinalizationTimeDataSelector } from 'stores/OrderPayment/orderPayment.selector';
import { clearActivePayment, setActivePaymentStep } from 'stores/OrderPayment/orderPayment.slice';
import { AnimatePresence, motion } from 'framer-motion';
import ProvideAmountStepButtons from 'components/Shared/OrderPayment/Shared/ProvideAmountStepButtons';
import CashAmountEdition from 'components/Shared/OrderPayment/Shared/CashAmountEdition';
import ChangeValue from 'components/Shared/OrderPayment/Shared/ChangeValue';
import TipEdition from 'components/Shared/OrderPayment/Shared/TipEdition';
import PaymentSummary from 'components/Shared/OrderPayment/Shared/PaymentSummary';
import ConfirmStepButtons from 'components/Shared/OrderPayment/Shared/ConfirmStepButtons';
import AdjustTipButtons from 'components/Shared/OrderPayment/Shared/AdjustTipButtons';
import { PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';

const CashPayment: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter());
  const activePayment = useAppSelector(getActivePayment);
  const getFinalizationTimeData = useAppSelector(getFinalizationTimeDataSelector());
  const [timeToFinalization, setTimeToFinalization] = useState<number>();
  const interval = useRef<any>(null);
  const { totalLeftAmount, acceptanceInProgress } = useAppSelector(({ orderPayment }) => orderPayment);
  const { reset, watch } = useFormContext<OrderPaymentForm>();
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);
  const activeCashStep = activePayment?.paymentStep ?? 'provideAmount';

  useEffect(() => {
    if (activeCashStep !== 'finalizePayment') return undefined;

    const finalizationTime = getFinalizationTimeData();
    if (!finalizationTime.finalizationTime) return undefined;

    setTimeToFinalization(finalizationTime.finalizationTime);
    interval.current = setInterval(() => setTimeToFinalization((current) => current && current - 1), 1000);

    return () => {
      clearInterval(interval?.current);
    };
  }, [activeCashStep]);

  useEffect(() => {
    if (timeToFinalization === 0) {
      tryFinalizePayment();
      clearInterval(interval?.current);
    }
  }, [timeToFinalization]);

  if (!activePayment) return null;

  function skipFinalizePaymentStep(): boolean {
    return isNextPaymentNeeded() || getFinalizationTimeData().skipFinalizationScreen;
  }

  function tryFinalizePayment(): void {
    reset(undefined, { isDirty: false });
    clearInterval(interval?.current);
    dispatch(checkIfOrderIsFullyPaid());
  }

  function isNextPaymentNeeded(): boolean {
    return cashInputNumberValue < totalLeftAmount;
  }

  return (
    <PaymentFrame>
      <>
        {activeCashStep === 'provideAmount' && <CashAmountEdition />}
        {activeCashStep === 'provideTip' && <TipEdition />}
        {(activeCashStep === 'confirm' || activeCashStep === 'finalizePayment') && <ChangeValue />}
      </>
      <>
        <PaymentSummary />
        <AnimatePresence initial={false}>
          {activeCashStep === 'confirm' && (
            <ConfirmStepButtons
              nextDisabled={acceptanceInProgress}
              onNextStep={() => {
                dispatch(
                  acceptPaymentForOrder({
                    successCallback: () => {
                      skipFinalizePaymentStep()
                        ? tryFinalizePayment()
                        : dispatch(setActivePaymentStep('finalizePayment'));
                    },
                  }),
                );
              }}
              onGoBack={() => {
                dispatch(clearActivePayment());
              }}
            />
          )}
          {activeCashStep === 'provideTip' && <AdjustTipButtons />}
          {activeCashStep === 'provideAmount' && (
            <motion.div
              style={{ position: 'absolute', bottom: '0', width: '100%' }}
              key="provideData"
              variants={PaymentModalAnimations.variants}
              initial="slideUp"
              animate="onPlace"
              exit="slideDown"
              transition={PaymentModalAnimations.transition}
            >
              <ProvideAmountStepButtons />
            </motion.div>
          )}
          {activeCashStep === 'finalizePayment' && (
            <motion.div
              style={{ position: 'absolute', bottom: '0', width: '100%' }}
              key="finalizePayment"
              variants={PaymentModalAnimations.variants}
              initial="slideDown"
              animate="onPlace"
              exit="slideUp"
              transition={PaymentModalAnimations.transition}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  gap: 2,
                }}
              >
                {/* <Button
                    onClick={() => {
                      //
                    }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    sx={{ py: 2, px: 3 }}
                  >
                    {t('Refund payment')}
                  </Button> */}

                <Button
                  onClick={() => {
                    tryFinalizePayment();
                  }}
                  disabled={acceptanceInProgress}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  sx={{ py: 2, px: 3 }}
                  data-testid="finalize-payment_close-order-submit"
                >
                  {timeToFinalization
                    ? `${t('Order closing in')} ${timeToFinalization} ${t('sec')}`
                    : t('Close order')}
                </Button>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    </PaymentFrame>
  );
};
export default CashPayment;
