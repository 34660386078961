import React from 'react';
import { IconButton, OutlinedInput, Typography, Box, useTheme } from '@mui/material';
import { DuplicateObjectIcon } from 'assets/icons';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

interface MenuFloorSectionProps {
  floorName: string;
  setFloorName(val: string): void;
  duplicateFloor(): void;
  deleteFloor(): void;
  canDelete: boolean;
  canDuplicate: boolean;
}

export default function MenuFloorSection({
  floorName,
  setFloorName,
  duplicateFloor,
  deleteFloor,
  canDelete,
  canDuplicate,
}: MenuFloorSectionProps) {
  const { t } = useTranslation('intake');
  const { palette, spacing } = useTheme();

  return (
    <Box sx={{ padding: spacing(3) }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" color="black" sx={{ color: palette.black.main }}>
          {t('Floor')}
        </Typography>
        <div>
          <IconButton
            onClick={duplicateFloor}
            sx={{
              borderRadius: spacing(0.5),
              padding: spacing(1),
              backgroundColor: palette.customGray.light,
              marginRight: spacing(1),
              color: palette.black.main,
            }}
            disabled={!canDuplicate}
          >
            <DuplicateObjectIcon />
          </IconButton>
          <IconButton
            onClick={deleteFloor}
            sx={{
              borderRadius: spacing(0.5),
              padding: spacing(1),
              backgroundColor: '#f4f4f4',
              color: palette.warning.main,
            }}
            disabled={!canDelete}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      </Box>
      <Box sx={{ paddingTop: spacing(2) }}>
        <Typography variant="body2" sx={{ color: palette.black.main }}>
          {t('Floor name')}
        </Typography>
        <OutlinedInput value={floorName} onChange={(e) => setFloorName(e.target.value)} fullWidth />
      </Box>
    </Box>
  );
}
