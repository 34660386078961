import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    tableContainer: {
      flex: 1,
      boxShadow: 'none',
      overflowY: 'auto',
    },
    success: {
      marginRight: spacing(1),
      color: palette.success.main,
    },
    pending: {
      marginRight: spacing(1),
      color: palette.warning.main,
    },
    direct: {
      marginRight: spacing(1),
      color: palette.info.main,
    },
    tableBodyContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(255,255,255,0.6)',
    },
  };
});
export default buildClasses;
