import React from 'react';
import { TextField } from '@mui/material';

interface FilterButtonProps {
  placeholder: string;
  fullWidth?: boolean;
  onValueChange?: (value: string) => void;
  testId?: string;
  type?: string;
}

const FilterInput: React.FC<FilterButtonProps> = ({ placeholder, fullWidth, onValueChange, testId, type }) => {
  return (
    <TextField
      fullWidth={fullWidth}
      sx={{ marginRight: 1 }}
      variant="outlined"
      placeholder={placeholder}
      type={type}
      onChange={(event): void => onValueChange && onValueChange(event.target.value)}
      inputProps={{ 'data-testid': testId }}
    />
  );
};

export default FilterInput;
