import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CreditCard } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const EftIdle: React.FC = () => {
  const [t] = useTranslation('common');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '374px',
        minHeight: '456px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Typography color="black.main" variant="h5" sx={{ m: 1 }} data-testid="settle-payment__label--eft-idle">
        {t('Eft idle')}
      </Typography>
      <CreditCard />
    </Box>
  );
};
export default EftIdle;
