import React from 'react';
import { sortProductsAlphabetically } from 'stores/Intake/IntakeStoreUtils';
import { Product } from 'typings/Products';
import SingleSelectCard from 'components/Shared/Cards/SingleSelectCard/SingleSelectCard';
import { Grid, useTheme } from '@mui/material';

interface XTastyPizzaWrapperProps {
  pizzas: Product[];
  selectedPizzaId?: number;
  onPizzaClick: (pizza: Product) => void;
}
const XTastyPizzaWrapper: React.FC<XTastyPizzaWrapperProps> = ({ pizzas, onPizzaClick, selectedPizzaId }) => {
  const { spacing } = useTheme();
  const sortedPizzas = sortProductsAlphabetically(pizzas);
  return (
    <Grid
      container
      data-testid="xtasty-customization__container--show-pizza"
      sx={{ paddingBottom: spacing(3) }}
      spacing={1}
    >
      {sortedPizzas.map((pizza) => {
        return (
          <Grid item key={pizza.id} xs={2}>
            <SingleSelectCard
              active={selectedPizzaId === pizza.id}
              selected={selectedPizzaId === pizza.id}
              testId={`product-card__button--${pizza.title}`}
              label={pizza.name}
              onSelect={() => onPizzaClick(pizza)}
              center
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default XTastyPizzaWrapper;
