import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    selected: {
      backgroundColor: `${palette.green[100]} !important`,
    },
    dataRowCell: {
      '&:last-child': {
        padding: `${spacing(1)} ${spacing(2)}`,
      },
    },
  };
});

export default buildClasses;
