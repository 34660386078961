import React, { useState } from 'react';
import { ArrowBack, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OutlinedButton } from 'components/Shared/Buttons';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { restartOrder } from 'stores/combined.actions';
import { getIsBasketEdited, getIsEditMode } from 'stores/Intake/intake.selector';
import { Box, Typography, useTheme } from '@mui/material';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { BasketRecalculated, HybridModeMessages } from 'typings/HybridMode';
import { useFormContext } from 'react-hook-form';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';

interface ReceiptControlsProps {
  editOrder: () => void;
  canEditOrder: boolean;
}

const ReceiptControls: React.FC<ReceiptControlsProps> = ({ editOrder, canEditOrder }) => {
  const { spacing } = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [t] = useTranslation('intake');
  const isEditMode = useAppSelector(getIsEditMode);
  const isBasketEdited = useAppSelector(getIsBasketEdited);
  const { reset } = useFormContext<CheckoutDetailsForm>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  function restartOrderHandler(): void {
    reset({});
    dispatch(restartOrder());

    const hybridModeCommunicator = getHybridModeCommunicator();
    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.BasketRecalculated, {} as BasketRecalculated);

    isEditMode && history.push('/all-orders');
  }

  function handleRestartClicked(): void {
    if (isEditMode) {
      if (isBasketEdited) {
        setShowConfirmModal(true);
        return;
      }
    }
    restartOrderHandler();
  }

  return (
    <>
      <Box sx={{ width: '100%', padding: spacing(2) }}>
        {canEditOrder && (
          <OutlinedButton
            icon={<ArrowBack />}
            onClickFn={(): void => handleRestartClicked()}
            testId="receipt__button--restart-order"
            fullWidth
          >
            {t(isEditMode ? 'Cancel Order Edition' : 'Restart Order')}
          </OutlinedButton>
        )}
        {!canEditOrder && (
          <OutlinedButton
            icon={<EditOutlined />}
            onClickFn={(): void => editOrder()}
            testId="receipt__button--edit-order"
            fullWidth
          >
            {t('Edit order')}
          </OutlinedButton>
        )}
      </Box>
      <ModalContainer
        maxWidth="sm"
        onClose={() => {
          setShowConfirmModal(false);
        }}
        open={showConfirmModal}
        title={t('Edit order')}
        primaryButton={{
          label: t('Cancel'),
          testId: 'restart-order-modal-confirm',
          action: restartOrderHandler,
        }}
        secondaryButton={{
          label: t('Continue editing'),
          testId: 'restart-order-modal-cancel',
          action: () => {
            setShowConfirmModal(false);
          },
        }}
        testId="restart-order-modal"
      >
        <Typography variant="body1" textAlign="center" sx={{ p: 5 }}>
          {t('The edit of the order has not been saved.')}
        </Typography>
      </ModalContainer>
    </>
  );
};

export default ReceiptControls;
