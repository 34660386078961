import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';
import { Alert, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface EtaAdjustmentChoicesProps {
  adjustments: number[];
  disabled: boolean;
  label?: string | null;
  selectedAdjustmentValue: number | undefined;
  title: string;
  onAdjustmentClick: (val: number) => void;
}

const EtaAdjustmentChoices: React.FC<EtaAdjustmentChoicesProps> = ({
  adjustments,
  disabled,
  label,
  selectedAdjustmentValue,
  title,
  onAdjustmentClick,
}) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('common');

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="subtitle1" sx={{ color: palette.black.main }}>
          {title}
        </Typography>
        {label && (
          <Typography variant="caption" sx={{ color: palette.black.main }}>
            <Alert
              icon={false}
              severity="warning"
              sx={{
                color: 'rgba(125, 60, 0, 1)',
                backgroundColor: 'rgba(255, 237, 216, 1)',
                padding: `0px ${spacing(1)}`,
                borderRadius: '4px',
                border: '0px',
                '&> div': {
                  padding: `${spacing(0.5)} 0px`,
                },
                ml: 1,
              }}
            >
              {label}
            </Alert>
          </Typography>
        )}
      </Box>
      <ToggleButtonGroup exclusive disabled={disabled} sx={{ width: '100%', mt: 2 }}>
        {adjustments.map((val) => {
          return (
            <ToggleButton
              key={val}
              value={val}
              selected={val === selectedAdjustmentValue}
              onClick={() => onAdjustmentClick(val)}
              sx={{
                width: '100%',
                px: 3,
                textTransform: 'none',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography variant={val === selectedAdjustmentValue ? 'subtitle1' : 'body1'}>
                {val} {t('Minute')}
              </Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

export default EtaAdjustmentChoices;
