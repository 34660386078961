import React from 'react';
import { Box, useTheme } from '@mui/material';
import AddedRemovedToppings from 'components/Shared/Basket/AddedRemovedToppings';
import { ProductSetStepSelection, SliceCustomization } from 'typings/Products';
import XTastyDetails from 'components/Shared/Basket/XTastyDetails';
import ProductSetDetails from './ProductSetDetails';

interface BasketItemDetailsProps {
  customizations: SliceCustomization[] | undefined;
  stepSelections: ProductSetStepSelection[] | undefined;
}

const BasketItemDetails: React.FC<BasketItemDetailsProps> = ({ customizations, stepSelections }) => {
  if (!customizations || customizations?.length === 0) return null;

  const slicesCount = customizations.length;
  const isXTasty = slicesCount > 1;
  const isProductSet = stepSelections && stepSelections.length > 0;
  const isRegularProduct = !isXTasty && !isProductSet;

  const someAddedToppings = customizations.some((cust) => cust.addedToppings?.length !== 0);
  const someRemovedToppings = customizations.some((cust) => cust.removedToppings?.length !== 0);

  if (!someAddedToppings && !someRemovedToppings && !isProductSet && !isXTasty) return null;

  return (
    <Box sx={{ px: 2, py: 1 }} data-testid="basket-item__customization-wrapper">
      {isRegularProduct && (
        <AddedRemovedToppings
          addedToppings={customizations[0].addedToppings}
          removedToppings={customizations[0].removedToppings}
        />
      )}
      {isXTasty && <XTastyDetails customizations={customizations} />}
      {isProductSet && <ProductSetDetails stepSelections={stepSelections} />}
    </Box>
  );
};

export default BasketItemDetails;
