import React, { useEffect } from 'react';
import { Box, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmployeeInfo } from 'typings/Cashier';
import { getClockedInUsers } from 'stores/Cashier';
import ClockedInEmployee from 'components/Auth/ClockedInEmployee';
import { useAppDispatch } from 'hooks/useRedux';
import buildClasses from './ClockedInEmployeesList.css';

export interface ClockedInEmployeesListProps {
  clockedInEmployees: EmployeeInfo[];
}

const ClockedInEmployeesList: React.FC<ClockedInEmployeesListProps> = ({ clockedInEmployees }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('authentication');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClockedInUsers());
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h3" color="black.main" sx={{ mb: 4 }}>
        {t('Clocked in employees')}
      </Typography>
      {clockedInEmployees.length < 1 ? (
        <Box className={classes.emptyListAlert}>
          <Typography variant="body1">{t('Oops, it looks like no one has checked in for work yet!')}</Typography>
        </Box>
      ) : (
        <List className={classes.employeeListContainer}>
          {clockedInEmployees.map((employeeInfo) => {
            if (!employeeInfo) return null;
            return <ClockedInEmployee employeeInfo={employeeInfo} key={employeeInfo.fullname} />;
          })}
        </List>
      )}
    </Box>
  );
};

export default ClockedInEmployeesList;
