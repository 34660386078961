import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { lowerCaseFirstLetter } from 'utils/orders/ordersUtils';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks/useRedux';
import { OpenOrderStatuses } from 'containers/AllOrders/AllOrderConsts';
import { OrderFilter } from 'stores/AllOrders';
import buildClasses from './ExpandedTableFilters.css';

interface ExpandedFilterProps {
  filters: OrderFilter[];
  show: boolean;
}

const ExpandedTableFilters: React.FC<ExpandedFilterProps> = ({ filters, show }) => {
  const { classes } = buildClasses();
  const { t } = useTranslation('orders');
  const { control, errors } = useFormContext();
  const { currentStoreTab, currentStatusTab } = useAppSelector(({ allOrders }) => allOrders);

  return (
    <Box px={2} pt={2} sx={{ display: show ? 'flex' : 'none', flexWrap: 'wrap' }}>
      {filters.map((filter) => {
        switch (filter.type.component) {
          case 'number' || 'string':
            return (
              <Controller
                rules={{
                  validate: {
                    genericValidate: (v) => {
                      if (v === '') return true;
                      if (filter.type.validate) {
                        return filter.type.validate(v);
                      }
                      return true;
                    },
                  },
                }}
                key={filter.name}
                control={control}
                name={lowerCaseFirstLetter(filter.name)}
                defaultValue=""
                render={(field) => (
                  <TextField
                    error={errors[filter.name]}
                    helperText={errors[filter.name] && t(filter.type.message ?? 'Value is incorrect')}
                    type="text"
                    label={t(filter.name)}
                    placeholder={t(filter.name)}
                    className={classes.filter}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        className: classes.searchInput,
                        'data-testid': `all-orders-table__filters-input-${field.name}`,
                      },
                    }}
                    {...field}
                  />
                )}
              />
            );
          case 'select': {
            return (
              <Controller
                key={filter.name}
                control={control}
                className={classes.filter}
                name={lowerCaseFirstLetter(filter.name)}
                defaultValue={[]}
                render={(field) => (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: showFilter(filter.name, currentStoreTab, currentStatusTab) ? 'inherit' : 'none',
                    }}
                  >
                    <InputLabel>{t(filter.name)}</InputLabel>
                    <Select
                      label={t(filter.name)}
                      placeholder={t(filter.name)}
                      data-testid={`all-orders-table__filters-select-${field.name}`}
                      required
                      className={classes.filter}
                      MenuProps={{
                        PaperProps: { className: classes.selectMenu },
                      }}
                      variant="outlined"
                      renderValue={(selected: (string | number)[]) =>
                        filter.type.options
                          ?.filter((el) => selected.includes(el.key))
                          ?.map((x) => x.displayName)
                          .join(', ')
                      }
                      defaultValue={[]}
                      multiple
                      {...field}
                    >
                      {filter.type.options?.map((option) => {
                        if (
                          filter.name === 'orderStatus' &&
                          currentStatusTab === 'open-orders' &&
                          !OpenOrderStatuses.some((el) => el.toString() === option.key)
                        ) {
                          return null;
                        }
                        return (
                          <MenuItem
                            key={option.key}
                            value={option.key}
                            className={classes.menuItem}
                            data-testid={`all-orders-table__filters-select-${field.name}__${option.key}`}
                          >
                            <Checkbox checked={field.value?.includes(option.key)} />
                            <ListItemText primary={t(option.displayName)} color="black.main" />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            );
          }

          default: {
            return null;
          }
        }
      })}
    </Box>
  );
};

const showFilter = (filterName: string, currentStoreTab: string, currentStatusTab: string): boolean => {
  if (filterName === 'storeName' && currentStoreTab === 'my-store') {
    return false;
  }
  if (filterName === 'orderStatus' && currentStatusTab === 'unpaid-orders') {
    return false;
  }
  return true;
};

export default ExpandedTableFilters;
