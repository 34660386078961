import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrderDetailsSection from 'components/Orders/OrderDetails/OrderDetailsSection';
import OrderStatusLabel from 'components/Orders/OrderDetails/OrderStatusLabel';
import OrderDetailsDataField from 'components/Orders/OrderDetails/OrderDetailsDataField';
import { getDateTimeFormatter, getPaymentFormatter } from 'stores/Payments/payment.selector';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import BasketItem from 'components/Shared/Basket/BasketItem';
import BasketDiscount from 'components/Shared/Basket/BasketDiscount';
import moment from 'moment';
import { addInternalComment, getOrderDetails } from 'stores/AllOrders';

interface OrderDetailsOverviewProps {
  showCommentInput: boolean;
  onAddCommentClick: () => void;
  onCloseCommentEditClick: () => void;
}

const OrderDetailsOverview: React.FC<OrderDetailsOverviewProps> = ({
  showCommentInput,
  onAddCommentClick,
  onCloseCommentEditClick,
}) => {
  const { selectedOrderDetails } = useAppSelector((state) => state.allOrders);
  const [t] = useTranslation('orders');
  const formatDateTime = useAppSelector(getDateTimeFormatter());
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [commentContent, setCommentContent] = useState('');
  const commentInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    commentInputRef?.current?.focus();
    commentInputRef?.current?.scrollIntoView();
  }, [showCommentInput]);

  if (!selectedOrderDetails) return null;
  const waitTime = moment(selectedOrderDetails.waitTime ?? '00:00:00.000000', 'HH:mm:ss.SSSSSS').format('HH:mm');

  function handleAddComment() {
    if (onAddCommentClick) onAddCommentClick();
  }

  function handleCancelComment() {
    if (onCloseCommentEditClick) onCloseCommentEditClick();
  }

  function handleSaveComment() {
    if (selectedOrderDetails)
      dispatch(
        addInternalComment({
          comment: commentContent,
          orderId: selectedOrderDetails?.id,
          successCallback: () => {
            dispatch(getOrderDetails({ doFetch: true, publicId: selectedOrderDetails?.id as string }));
            onCloseCommentEditClick();
          },
        }),
      );
  }

  return (
    <>
      <OrderDetailsSection name="Customer details">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <OrderDetailsDataField
              name="Name"
              value={`${selectedOrderDetails.customerDetails.name} ${selectedOrderDetails.customerDetails.surname}`}
            />
            <OrderDetailsDataField name="Address" value={selectedOrderDetails.deliveryAddressFormatted} />
            <OrderDetailsDataField name="Attached company" value={selectedOrderDetails.companyName} />
          </Grid>
          <Grid item xs={6}>
            <OrderDetailsDataField name="Phone" value={selectedOrderDetails.customerDetails.telephoneNumber} />
            <OrderDetailsDataField name="Email" value={selectedOrderDetails.customerDetails.emailAddress} />
          </Grid>
        </Grid>
      </OrderDetailsSection>
      <OrderDetailsSection name="Order details">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <OrderStatusLabel status={selectedOrderDetails.status} />
            <OrderDetailsDataField name="Delivery type" value={selectedOrderDetails.pickupType.toString()} />
            <OrderDetailsDataField name="Payment method" value={selectedOrderDetails.paymentMethod?.description} />
            <OrderDetailsDataField
              name="Ordered on"
              value={formatDateTime(new Date(selectedOrderDetails.createdAt))}
            />
            {selectedOrderDetails.externalReference && (
              <OrderDetailsDataField name="External reference" value={selectedOrderDetails.externalReference} />
            )}
          </Grid>
          <Grid item xs={6}>
            <OrderDetailsDataField
              name="Ticket number"
              value={selectedOrderDetails?.ticketNumber?.toString() ?? 'Unknown'}
            />
            <OrderDetailsDataField
              name="Waiting time"
              value={waitTime !== null && waitTime !== '00:00' ? waitTime : t('Future')}
            />
            <OrderDetailsDataField
              name="Pickup date time"
              value={formatDateTime(new Date(selectedOrderDetails.collectionTimestamp))}
            />
            <OrderDetailsDataField name="Channel" value={selectedOrderDetails.systemName} />
          </Grid>
        </Grid>
      </OrderDetailsSection>
      <OrderDetailsSection name="Order products">
        {selectedOrderDetails.remarks && (
          <Box
            sx={{ px: 2, py: 1, mb: 1, backgroundColor: palette.grey[50] }}
            data-testid="all-orders__order_details_field-order-remark"
          >
            <Typography variant="caption" sx={{ mb: 1 }} color="grey.800">
              {t('Order remark')}
            </Typography>
            <Typography variant="body2" color="grey.800">
              {selectedOrderDetails.remarks}
            </Typography>
          </Box>
        )}
        <Box sx={{ backgroundColor: palette.grey[50], mb: 2 }}>
          {selectedOrderDetails.virtualReceipt.receiptProducts.map((rp, index) => (
            <BasketItem receiptProduct={rp} key={rp.basketItemGuid} index={index} />
          ))}
          {selectedOrderDetails.virtualReceipt.receiptDiscounts.map((rd) => (
            <BasketDiscount receiptDiscount={rd} key={rd.couponId} />
          ))}
          {selectedOrderDetails.priceSummary.additionalTransactionCost > 0 && (
            <Box
              sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-additional-transaction-cost"
            >
              <Typography variant="body2" color="grey.800">
                {t('Additional transaction cost')}
              </Typography>
              <Typography variant="subtitle1" color="grey.800">
                {formatToDisplay(selectedOrderDetails.priceSummary.additionalTransactionCost)}
              </Typography>
            </Box>
          )}
          {selectedOrderDetails.deliveryCharge > 0 && (
            <Box
              sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-delivery-charge"
            >
              <Typography variant="body2" color="grey.800">
                {t('Delivery charge')}
              </Typography>
              <Typography variant="subtitle1" color="grey.800">
                {formatToDisplay(selectedOrderDetails.deliveryCharge)}
              </Typography>
            </Box>
          )}

          <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="grey.800">
              {t('Order amount')}
            </Typography>
            <Typography
              variant="subtitle1"
              color="grey.800"
              data-testid="all-orders__order_details_field-order-amount"
            >
              {formatToDisplay(selectedOrderDetails.priceSummary.originalTotal)}
            </Typography>
          </Box>
          <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="black.main">
              {t('Total amount')}
            </Typography>
            <Typography variant="h6" color="black.main" data-testid="all-orders__order_details_field-total-amount">
              {formatToDisplay(selectedOrderDetails.priceSummary.total)}
            </Typography>
          </Box>
        </Box>
      </OrderDetailsSection>
      <OrderDetailsSection name="Comment">
        {selectedOrderDetails.internalComments.map((ic) => (
          <Box sx={{ marginBottom: 1 }} key={new Date(ic.createdAt).valueOf()}>
            <Box
              sx={{ py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-comment"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <AccountCircleIcon sx={{ marginRight: 1, color: palette.grey[600] }} />
                <Typography variant="body1" color="grey.600">
                  {ic.userName}
                </Typography>
              </Box>
              <Typography variant="body2" color="grey.600">
                {formatDateTime(new Date(ic.createdAt))}
              </Typography>
            </Box>
            <Box sx={{ px: 2, py: 1, backgroundColor: palette.grey[50], display: 'flex', alignItems: 'center' }}>
              <CommentOutlinedIcon sx={{ marginRight: 1, color: palette.grey[400] }} />
              <Typography variant="body2" color="grey.800" sx={{ whiteSpace: 'pre-line' }}>
                {ic.comment}
              </Typography>
            </Box>
          </Box>
        ))}
        {!showCommentInput && (
          <Button
            fullWidth
            variant="outlined"
            color="black"
            startIcon={<AddCommentOutlinedIcon />}
            onClick={handleAddComment}
            data-testid="all-orders__order_details_button-comment-add"
          >
            {t('COMMENT')}
          </Button>
        )}
        {showCommentInput && (
          <Box>
            <TextField
              fullWidth
              type="text"
              multiline
              rows={3}
              inputRef={commentInputRef}
              placeholder={t('Add comment to the order')}
              data-testid="all-orders__order_details_text_input-comment"
              variant="outlined"
              onChange={(event): void => {
                setCommentContent(event.target.value);
              }}
              sx={{ p: 0 }}
              InputProps={{
                sx: { p: 0 },
              }}
            />
            <Box
              sx={{
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <Button
                variant="text"
                color="black"
                onClick={handleCancelComment}
                data-testid="all-orders__order_details_button-comment-cancel"
              >
                {t('CANCEL')}
              </Button>
              <Button
                variant="outlined"
                color="black"
                onClick={handleSaveComment}
                data-testid="all-orders__order_details_button-save"
              >
                {t('ADD')}
              </Button>
            </Box>
          </Box>
        )}
      </OrderDetailsSection>
    </>
  );
};

export default OrderDetailsOverview;
