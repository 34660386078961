import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    container: {
      paddingBottom: spacing(3),
      backgroundColor: palette.externalComponentBackground,
      minHeight: `calc(100vh - ${sizing.appBarHeight}px)`,
    },
    settingsWrapper: {
      display: 'flex',
      margin: 'auto',
      backgroundColor: palette.white,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
      maxWidth: '1100px',
      minHeight: '600px',
    },
    sideMenu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: palette.externalComponentBackground,
      width: '275px',
    },
    header: {
      textAlign: 'center',
    },
    settingsOptions: {
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'baseline',
      width: '100%',
      padding: spacing(2),
    },
    label: {
      paddingRight: spacing(1),
      color: palette.text.primary,
    },
  };
});
export default buildClasses;
