import React, { useState, useEffect } from 'react';
import IntakeContentSwitch from 'components/Intake/Finalize/IntakeContentSwitch/IntakeContentSwitch';
import CouponCatalog from 'containers/Intake/Coupons/Coupons';
import { Drawer, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  clearPhoneNumberSearchQuery,
  setActiveIntakeTab,
  setCustomerSearchQuery,
  setIntakeStatus,
} from 'stores/Intake';
import { fetchAllCouponsData } from 'stores/Coupons';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { getAvailableIntakeContents } from 'stores/Intake/intake.selector';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import uuidGenerator from 'utils/GuidGenerator';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { postFinalizeDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import { fetchAllProductData } from 'stores/combined.actions';
import { useFormContext } from 'react-hook-form';
import { restoreTransferredIntakeState } from 'stores/HybridMode/hybridMode.thunk-actions';
import ProductsContainer from './Products/Products';
import ReceiptBar from './Receipt/Receipt';
import buildClasses from './Intake.css';
import PreFinalize from './PreFinalize/PreFinalize';
import Finalize from './Finalize/Finalize';
import { AvailableIntakeContents, IntakeStatuses, PickUpTypesValues } from './IntakeConsts';
import CustomerSearchResults from './Customer/CustomerSearchResults';
import DineInOrdersOverview from './DineInOrders/Overview';
import FloorsSection from './Floors/FloorsSection';
import { isBigScreenMediaQuery } from './Receipt/Receipt.css';
import MealConfigurator from './Products/MealConfigurator/MealConfigurator';

const Intake: React.FC = () => {
  const { classes, cx } = buildClasses();
  const [receiptVisible, setReceiptVisible] = useState<boolean>(true);
  const {
    activeDeliveryType,
    activeIntakeTab,
    manualPriceOverride,
    intakeStatus,
    editMode,
    phoneNumberSearchQuery,
    activePaymentMethod,
    customerSearchQuery,
  } = useAppSelector((state) => state.intake);
  const { productToCustomize } = useAppSelector((state) => state.products);
  const { couponToCustomize } = useAppSelector((state) => state.coupons);
  const { selectedDineInOrder, isTableChangeInProgress } = useAppSelector((state) => state.dineIn);
  const { basketData } = useAppSelector((state) => state.basket);
  const { selectedStore } = useAppSelector((state) => state.stores);

  const availableIntakeContents = useAppSelector(getAvailableIntakeContents());
  const { transferredIntake } = useAppSelector((store) => store.hybridMode);
  const dispatch = useAppDispatch();
  const { trigger } = useFormContext<CheckoutDetailsForm>();

  const isBigScreen = useMediaQuery(isBigScreenMediaQuery);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const phoneNumber = searchParams.get('phone-number');
    if (phoneNumber) dispatch(setCustomerSearchQuery(phoneNumber));
  }, []);

  useEffect(() => {
    if (phoneNumberSearchQuery) {
      dispatch(setCustomerSearchQuery(phoneNumberSearchQuery));
      dispatch(clearPhoneNumberSearchQuery());
    }
  }, [phoneNumberSearchQuery]);

  useEffect(() => {
    if (transferredIntake && selectedStore) {
      dispatch(restoreTransferredIntakeState(transferredIntake));
    }
  }, [transferredIntake, selectedStore]);

  useEffect(() => {
    if (selectedStore) {
      dispatch(fetchAllProductData(selectedStore));
      dispatch(fetchAllCouponsData());
    }
  }, [selectedStore, activeIntakeTab]);

  useEffect(() => {
    if (productToCustomize) {
      dispatch(setIntakeStatus(IntakeStatuses.productsSection));
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }, [productToCustomize]);

  function paymentSuccessfulHandler(result: PaymentSuccessfulResult): void {
    if (selectedDineInOrder) {
      dispatch(
        postFinalizeDineInOrder({
          tabId: selectedDineInOrder.id,
          newOrderId: result.orderId,
          config: {
            paymentMethodCode: result.paymentMethodCode,
            eftPaymentOperation: result.eftPaymentOperation,
            manualPriceOverride,
          },
          refreshConfig:
            activeIntakeTab === AvailableIntakeContents.DineInOpenOrders ||
            activeIntakeTab === AvailableIntakeContents.Tables,
        }),
      );
    }

    closeSettlePaymentForm();
  }

  function closeSettlePaymentForm(): void {
    dispatch(clearSettlePayment());
  }

  function onHideReceipt(): void {
    if (!isBigScreen) setReceiptVisible(false);
  }

  async function finishButtonClickedHandler(): Promise<void> {
    const isCheckoutFormValid = await trigger();

    if (!isCheckoutFormValid) {
      return;
    }

    if (
      activeDeliveryType === PickUpTypesValues.dineIn &&
      basketData?.summary &&
      selectedDineInOrder !== undefined
    ) {
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId: uuidGenerator(),
          openTabId: selectedDineInOrder.id,
          deliveryType: activeDeliveryType,
          closeCallback: closeSettlePaymentForm,
          successCallback: paymentSuccessfulHandler,
          totalToPay: basketData.summary.total,
          preselectedPaymentMethod: activePaymentMethod,
          isLocalOrder: true,
          cancelOnClose: false,
        }),
      );
    } else {
      dispatch(
        setIntakeStatus(
          editMode || selectedStore?.upsellScreenActive === false
            ? IntakeStatuses.finalizeOrder
            : IntakeStatuses.preFinalize,
        ),
      );
    }
  }

  function switchIntakeContent(_event: React.ChangeEvent<{}>, value: AvailableIntakeContents): void {
    dispatch(setActiveIntakeTab(value));
  }

  function onCustomerSelected() {
    dispatch(setCustomerSearchQuery(undefined));
  }

  const showMealConfigurator = couponToCustomize?.meals.every((x) => x.availableProducts.length > 0) === true;

  function showIntakeContentSwitch(): boolean {
    if (productToCustomize !== undefined) {
      return false;
    }
    if (intakeStatus !== IntakeStatuses.productsSection) {
      return false;
    }
    if (isTableChangeInProgress) {
      return false;
    }
    return true;
  }

  function onSearchCustomer(query: string): void {
    dispatch(setCustomerSearchQuery(query));
  }

  function onCancelCustomerSelection(): void {
    dispatch(setCustomerSearchQuery(undefined));
  }

  function onOrderWithoutCustomerProfile(): void {
    dispatch(setCustomerSearchQuery(undefined));
  }

  return (
    <div id="intake-window" className={classes.intakeWindow}>
      <div className={cx(classes.intakeContent, { [classes.intakeContentFullWidth]: !receiptVisible })}>
        {customerSearchQuery !== undefined ? (
          <CustomerSearchResults
            query={customerSearchQuery}
            onCancelCustomerSelection={onCancelCustomerSelection}
            onIntakeWithoutProfileClick={onOrderWithoutCustomerProfile}
            onCustomerSelected={onCustomerSelected}
          />
        ) : (
          <>
            <IntakeContentSwitch
              handleChange={switchIntakeContent}
              intakeContent={activeIntakeTab}
              availableContents={availableIntakeContents}
              isVisible={showIntakeContentSwitch()}
            />
            {showMealConfigurator ? (
              <MealConfigurator />
            ) : (
              <>
                <>
                  {intakeStatus === IntakeStatuses.productsSection && (
                    <>
                      {activeIntakeTab === AvailableIntakeContents.Coupons && <CouponCatalog />}
                      {activeIntakeTab === AvailableIntakeContents.DineInOpenOrders && <DineInOrdersOverview />}
                      {activeIntakeTab === AvailableIntakeContents.Tables && (
                        <FloorsSection receiptVisible={receiptVisible} setReceiptVisibility={setReceiptVisible} />
                      )}
                      {activeIntakeTab === AvailableIntakeContents.Products && <ProductsContainer />}
                    </>
                  )}
                </>
                {intakeStatus === IntakeStatuses.preFinalize && <PreFinalize />}
                {intakeStatus === IntakeStatuses.finalizeOrder && <Finalize />}
              </>
            )}
          </>
        )}
      </div>
      <Drawer className={classes.receiptDrawer} anchor="right" open={receiptVisible} variant="persistent">
        <ReceiptBar
          canChangePaymentMethod={false}
          intakeStatus={intakeStatus}
          onFinishOrderButtonClick={finishButtonClickedHandler}
          canEdit
          onSearchCustomer={onSearchCustomer}
          onIntakeWithoutProfile={onOrderWithoutCustomerProfile}
          onHideReceipt={onHideReceipt}
        />
      </Drawer>
    </div>
  );
};

export default Intake;
