import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emailPattern } from 'utils/validation/ValidationConstants';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useRedux';
import { emailReceiptToCustomer, emailReceiptToStore } from 'stores/AllOrders';

enum EmailMode {
  customerEmail = 'customerEmail',
  storeEmail = 'storeEmail',
}

interface EmailOrderDialogProps {
  isOpened: boolean;
  orderId: string;
  onClose: () => void;
  customerEmail: string | undefined;
}

const EmailOrderDialog: React.FC<EmailOrderDialogProps> = ({ orderId, onClose, isOpened, customerEmail }) => {
  const {
    register,
    errors,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [selectedMode, setSelectedMode] = useState(EmailMode.customerEmail);
  const [t] = useTranslation('orders');
  const { spacing, palette } = useTheme();
  const dispatch = useAppDispatch();

  function toEmailMode(value: string): EmailMode | undefined {
    const emailModeValue = EmailMode[value as keyof typeof EmailMode];
    return emailModeValue;
  }

  const onModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mode = toEmailMode((event.target as HTMLInputElement).value);
    if (mode) setSelectedMode(mode);
  };

  function maxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('max.')} ${max})`;
  }

  function sendEmail() {
    if (orderId === undefined || (selectedMode === EmailMode.customerEmail && !isValid)) return;

    if (selectedMode === EmailMode.customerEmail)
      dispatch(emailReceiptToCustomer({ orderId, customerEmail: getValues('customerEmail') }));
    else dispatch(emailReceiptToStore({ orderId }));

    onClose();
  }

  useEffect(() => {
    if (isOpened) setSelectedMode(EmailMode.customerEmail);
  }, [isOpened]);

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingTop: 2,
          px: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          minWidth: '560px',
        }}
      >
        <Typography variant="h5" sx={{ color: palette.black.main }}>
          {t('Send receipt via email')}
        </Typography>
        <IconButton
          onClick={onClose}
          disableRipple
          sx={{
            color: 'black',
            fontSize: spacing(2.5),
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          px: 3,
          '& .MuiButtonBase-root': {
            color: '#737373',
          },
        }}
      >
        <RadioGroup row name="row-radio-buttons-group" value={selectedMode} onChange={onModeChange}>
          <FormControlLabel value={EmailMode.customerEmail} control={<Radio />} label={t('To the customer')} />
          <FormControlLabel value={EmailMode.storeEmail} control={<Radio />} label={t('To the store')} />
        </RadioGroup>
      </Box>
      {selectedMode === EmailMode.customerEmail && (
        <Box sx={{ paddingTop: 2, paddingBottom: 1, px: 3, minHeight: '90px' }}>
          <TextField
            id="customer-email"
            label={t('Customer email')}
            name="customerEmail"
            data-testid="email-order-dialog--customer-email"
            variant="outlined"
            fullWidth
            inputRef={register({
              required: t('Email is required'),
              pattern: { value: emailPattern, message: t('Incorrect email') },
              maxLength: { value: 250, message: maxLengthMessage(250) },
            })}
            defaultValue={customerEmail}
            error={errors.customerEmail && true}
            helperText={errors.customerEmail && errors.customerEmail.message}
          />
        </Box>
      )}
      {selectedMode === EmailMode.storeEmail && (
        <Box sx={{ py: 2, px: 3, maxWidth: '532px', minHeight: '90px' }}>
          <Typography variant="body1" sx={{ color: palette.black.main }}>
            {t('The receipt will be sent to the email address configured for your store.')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          py: 1,
          px: 1,
          borderTop: `1px solid ${palette.grey[300]}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <Button
          sx={{ py: 1.5, px: 2, marginLeft: spacing(20) }}
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          sx={{ py: 1.5, px: 2, marginLeft: spacing(1) }}
          variant="contained"
          onClick={sendEmail}
          disabled={!isValid}
        >
          {t('Send')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default EmailOrderDialog;
