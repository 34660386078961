import { OrderDeliveryTime, OrderCustomer, OrderDeliveryAddress } from 'stores/Intake/intakeTypes';
import { EftPaymentOperation } from 'typings/Payments';

export interface CheckoutDetailsForm extends OrderDeliveryAddress, OrderDeliveryTime, OrderCustomer {
  orderId: string;
  EftPaymentOperation?: EftPaymentOperation;
  translations?: Translations;
}

export interface Translations {
  asap?: string;
}

export const emptyAddress: OrderDeliveryAddress = {
  addressRemark: undefined,
  addressType: undefined,
  apartmentNumber: undefined,
  buildingName: undefined,
  city: undefined,
  district: undefined,
  entranceNumber: undefined,
  region: undefined,
  street: undefined,
  streetNumber: undefined,
  streetNumberAddition: undefined,
  streetType: undefined,
  zipcode: undefined,
};
