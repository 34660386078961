/* eslint-disable jsx-a11y/alt-text */
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { LogoIcon } from 'assets/icons';
import PluginComponent from 'components/Plugins/PluginComponent';
import ScreenComponent from 'components/Plugins/ScreenComponent';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { resetCashierState } from 'stores/Cashier';
import { clearOrderEdition } from 'stores/combined.actions';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { getLogo } from 'stores/Cms/cms.selector';
import ClockInConfirmationModal from 'components/Shared/Modal/ClockInConfirmationModal';
import { validateUser } from 'stores/AllOrders';
import routes, { AppRoute } from '../Routes/routes-list';
import AppMenuItem from './AppMenuItem/AppMenuItem';
import { getIsDesktop } from '../../stores/Config/config.selector';

interface AppMenuProps {
  open: boolean;
  menuItemClicked: () => void;
}

const AppMenu: React.FC<AppMenuProps> = ({ open, menuItemClicked }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const switchedStore = useAppSelector(getSwitchedStore);
  const cmsImage = useAppSelector(getLogo);
  const { brandLogo, sizing } = useTheme();
  const storeChanged = (switchedStore && !switchedStore.isDefaultStore) ?? false;
  const { featureFlags, hiddenRoutes } = useAppSelector(({ config }) => config);
  const isDesktop = useAppSelector(getIsDesktop);
  const [showClockInDialog, setShowClockInDialog] = useState(false);

  function getCurrentRouteId(): number {
    const foundRoutes = routes.filter((route) => route.path === location.pathname);
    return foundRoutes.length > 0 ? foundRoutes[0].routeId : 0;
  }

  const [activeRouteId, setActiveRouteId] = useState(getCurrentRouteId());

  const activateRoute = (routeId: number, moduleIdentifier: string): void => {
    document.cookie = `ActiveModule=${moduleIdentifier};path=/`;
    setActiveRouteId(routeId);
    dispatch(resetCashierState());
    dispatch(clearOrderEdition());
  };

  const showLocalOrders = featureFlags.OfflineModule_DisableLocalOrdersInCloud === false || isDesktop;

  const showCoreModules = featureFlags.OfflineModule_SingleNavigation && !isDesktop;

  const visibleRoutes = routes
    .filter((route) => !route.isExperimental || showCoreModules)
    .filter((route) => (storeChanged ? !route.forbiddenOnSwitchedStore : true))
    .filter((route) => !isDesktop || route.path !== '/all-orders')
    .filter((route) => showLocalOrders || route.path !== '/local-orders')
    .filter((route) => (hiddenRoutes ? !hiddenRoutes.some((hr) => hr === route.path) : true));

  function onMenuItemClicked(route: AppRoute) {
    menuItemClicked();
    if (route?.requireClockIn) {
      dispatch(
        validateUser({
          clockInCallback: () => {
            setShowClockInDialog(true);
          },
        }),
      );
    }
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{ alignItems: 'left', width: sizing.sideMenuWidth, flexShrink: 0 }}
    >
      <Box
        component="img"
        sx={{ mx: 'auto', my: 1, width: sizing.sideMenuWidth - 40, height: 'auto' }}
        src={cmsImage ? cmsImage.url : (brandLogo as string)}
      />
      <List sx={{ alignItems: 'center', flex: 1 }}>
        {visibleRoutes.map((route) => {
          return (
            <React.Fragment key={route.routeId}>
              <AppMenuItem
                route={route}
                isActive={route.routeId === activeRouteId}
                menuItemClicked={() => onMenuItemClicked(route)}
                routeActivationHandler={activateRoute}
                testId={`navigation-drawer__anchor--go-to-${route.navbarName}`}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
      <ScreenComponent />
      <PluginComponent />
      <ClockInConfirmationModal showDialog={showClockInDialog} abort={(): void => setShowClockInDialog(false)} />
    </Drawer>
  );
};

export default AppMenu;
