import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    container: {
      marginTop: 'auto',
    },
    receiptFooterActionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(1),
      padding: `${spacing(1)} ${spacing(1)}`,
      minHeight: '50px',
      overflowY: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});
export default buildClasses;
