import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, typography }) => {
  return {
    dateSelector: {
      width: '100%',
    },
    container: {
      width: '370px',
      maxHeight: '490px',
    },
    timeSelectInput: {
      cursor: 'pointer',
    },
    timePicker: {
      color: palette.text.primary,
    },
    timePickerButtons: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-around',
      paddingBottom: spacing(1),
    },
    gridList: {
      width: '370px',
      maxWidth: '370px',
      maxHeight: '500px',
    },
    gridTile: {
      textAlign: 'center',
    },
    gridButton: {
      padding: 0,
      lineHeight: 1,
      width: '100%',
      height: '100%',
    },
  };
});
export default buildClasses;
