import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';

const CheckingIdentity: React.FC<{ checkingPin: boolean }> = ({ checkingPin = true }) => {
  const [t] = useTranslation('authentication');
  const { sizing } = useTheme();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: sizing.pinPad.height }}>
      <Box sx={{ display: 'flex' }}>
        <JumpingDotsLoader />
        <Typography variant="h5" sx={{ ml: 2 }}>
          {t(checkingPin ? 'Unlocking the POS...' : 'We are checking your identity...')}
        </Typography>
      </Box>
    </Box>
  );
};
export default CheckingIdentity;
