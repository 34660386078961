import React, { useEffect } from 'react';
import { Typography, Popover, ButtonBase, Box, useTheme } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getAppVersion } from 'stores/Config';

const AppVersion: React.FC = () => {
  const { palette, spacing } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const { appVersion, fiscalizationConfiguration } = useAppSelector((state) => state.config);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const { onsiteMachine } = useAppSelector((state) => state.authorization);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAppVersion());
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {appVersion && (
        <>
          <ButtonBase sx={{ alignItems: 'center', display: 'flex', padding: spacing(1) }} onClick={handleClick}>
            <CodeIcon />
            <Typography color="textPrimary" variant="subtitle2">
              {appVersion.baseVersion}
            </Typography>
          </ButtonBase>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ padding: spacing(3), background: palette.primary.main }}>
              <Typography color="textSecondary" variant="subtitle2">
                App version: <b>{appVersion.baseVersion}</b>
              </Typography>
              {appVersion.gitSha && (
                <Typography color="textSecondary" variant="subtitle2">
                  sha: <b>{appVersion.gitSha}</b>
                </Typography>
              )}
            </Box>
          </Popover>
        </>
      )}
      {fiscalizationConfiguration?.fiscalProvider === 'FDM' && fiscalizationConfiguration?.isActive && (
        <>
          <Typography color="textPrimary" variant="subtitle2">
            POS: {selectedStore?.posSerialNumber}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            Terminal: {onsiteMachine?.touchpointId.slice(-12)}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AppVersion;
