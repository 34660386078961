import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, transitions, spacing }) => {
  return {
    menuHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0, 1),
      justifyContent: 'flex-end',
      height: sizing.appBarHeight,
    },
    content: {
      flexGrow: 1,
      padding: spacing(0),
      transition: transitions.create('margin', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      marginLeft: -sizing.sideMenuWidth,
      width: '100%',
    },
  };
});

export default buildClasses;
