import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';

const getProductsStateReducer = ({ products }: RootState) => products;

export const getActiveCategory = ({ products }: RootState) =>
  products.categories.find((el) => el.code === products.activeCategoryCode);

export const getProductsByCategoryCode = (code: string) =>
  createSelector(getProductsStateReducer, (items) => items.products.filter((item) => item.categoryCode === code));
