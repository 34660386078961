/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProcessingComponent from 'components/Shared/Loaders/ProcessingComponent';
import { getTokenFromAuthenticationCode } from 'stores/Auth/auth.thunk-actions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box, useTheme } from '@mui/material';

const PostLogin: React.FC = () => {
  const location = useLocation();
  const [t] = useTranslation('common');
  const { palette } = useTheme();
  const history = useHistory();
  const [text, setText] = useState<string>(t('Redirecting back to application...'));
  const dispatch = useAppDispatch();
  const { identityServer } = useAppSelector((state) => state.config);
  const { postPageRedirectStatus } = useAppSelector((state) => state.authorization);

  useEffect(() => {
    const code = (location.search.match(/code=([^&]+)/) || [])[1];
    if (identityServer) {
      dispatch(getTokenFromAuthenticationCode(code));
    }
  }, [identityServer]);

  useEffect(() => {
    if (postPageRedirectStatus === 'COMPLETE') {
      history.replace('/'); // this will redirect to default page
    }
    if (postPageRedirectStatus === 'ERROR') {
      setText(t('Could not log in, please try again...'));
      setTimeout(() => {
        history.replace('/');
      }, 1000); // simple timeout to improve UX and avoid 'blinking' text
    }
  }, [postPageRedirectStatus]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        zIndex: 1101,
        backgroundColor: palette.primary.main,
        paddingTop: '15%',
      }}
    >
      <ProcessingComponent text={text} />
    </Box>
  );
};
export default PostLogin;
