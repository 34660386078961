import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';
import { Button, Popover, Typography } from '@mui/material';
import StoreOutlineIcon from '@mui/icons-material/StoreOutlined';
import DeliveryDiningOutlineIcon from '@mui/icons-material/DeliveryDiningOutlined';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { getEtaConfiguration } from 'stores/Notifications/notifications.thunk-actions';
import { updateStoreEtaConfiguration } from 'stores/Store/store.thunk-actions';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { CashierPermission } from 'typings/Cashier';
import StrikeThroughContainer from 'components/Shared/Containers/StrikeThroughContainer';
import EtaAdjustmentChoices from './EtaAdjustmentChoices';

export default function EtaInfo(): JSX.Element {
  const { etaConfiguration, orderingOption } = useAppSelector(({ notifications }) => notifications);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const { etaAdjustmentConfiguration } = useAppSelector(({ config }) => config);
  const [etaInfoAnchorEl, setEtaInfoAnchorEl] = useState<null | HTMLElement>(null);
  const [pickupEta, setPickupEta] = useState<number | undefined>();
  const [deliveryEta, setDeliveryEta] = useState<number | undefined>();
  const [pickupAdjustments, setPickupAdjustments] = useState<number[]>([]);
  const [deliveryAdjustments, setDeliveryAdjustments] = useState<number[]>([]);
  const open = Boolean(etaInfoAnchorEl);
  const dispatch = useDispatch();
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');
  const pollingInterval = useRef<any>(null);
  const pollingIntervalInSec = 60;

  const canUserAccess = useAppSelector(getPermissionChecker());
  const cashierCanChangeEta = canUserAccess(CashierPermission.EditEta);

  useEffect(() => {
    dispatch(getEtaConfiguration());
    startEtaConfigurationPooling(false);
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [selectedStore]);

  useEffect(() => {
    startEtaConfigurationPooling(true);
    setPickupEta(orderingOption?.pickupOrdersAllowed ? etaConfiguration?.pickUpEtaMinutes : undefined);
    setDeliveryEta(orderingOption?.deliveryOrdersAllowed ? etaConfiguration?.deliveryEtaMinutes : undefined);
    setPickupAdjustments(
      getSortedEtaAdjustments(
        etaAdjustmentConfiguration?.pickupEtaAdjustments ?? [],
        etaConfiguration?.pickUpEtaMinutes,
      ),
    );
    setDeliveryAdjustments(
      getSortedEtaAdjustments(
        etaAdjustmentConfiguration?.deliveryEtaAdjustments ?? [],
        etaConfiguration?.deliveryEtaMinutes,
      ),
    );
    setEtaInfoAnchorEl(null);
  }, [etaConfiguration, orderingOption]);

  function startEtaConfigurationPooling(withReset: boolean) {
    if (withReset) {
      clearInterval(pollingInterval.current);
    }

    pollingInterval.current = setInterval(() => {
      dispatch(getEtaConfiguration());
    }, pollingIntervalInSec * 1000);
  }

  function getSortedEtaAdjustments(values: number[], currentValue: number | undefined) {
    const result = [...values];
    if (currentValue && !result.some((x) => x === currentValue)) {
      result.push(currentValue);
    }
    return result.sort((a, b) => a - b);
  }

  const onEtaInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    if (cashierCanChangeEta) {
      setEtaInfoAnchorEl(event.currentTarget);
    }
  };

  const onSave = () => {
    if (selectedStore) {
      dispatch(
        updateStoreEtaConfiguration({
          storeId: selectedStore.id,
          config: {
            pickUpEtaMinutes: orderingOption?.pickupOrdersAllowed ? pickupEta : etaConfiguration?.pickUpEtaMinutes,
            deliveryEtaMinutes: orderingOption?.deliveryOrdersAllowed
              ? deliveryEta
              : etaConfiguration?.deliveryEtaMinutes,
          },
        }),
      );
    }
  };

  const onClose = () => {
    setPickupEta(orderingOption?.pickupOrdersAllowed ? etaConfiguration?.pickUpEtaMinutes : undefined);
    setDeliveryEta(orderingOption?.deliveryOrdersAllowed ? etaConfiguration?.deliveryEtaMinutes : undefined);
    setEtaInfoAnchorEl(null);
  };

  return (
    <Box sx={{ borderRight: `1px solid ${palette.white}`, mr: 2, pr: 1 }}>
      <Box
        data-testid="eta-configuration-container"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: palette.white,
          py: cashierCanChangeEta ? 0.5 : 0,
          px: cashierCanChangeEta ? 1 : 0,
          background: cashierCanChangeEta ? 'rgba(255, 255, 255, 0.15)' : 'initial',
          borderRadius: spacing(0.5),
        }}
        onClick={onEtaInfoClick}
      >
        <Box
          sx={{
            pr: 1,
            borderRight: `1px solid ${
              cashierCanChangeEta ? (palette.appBar ? palette.appBar.main : palette.primary.main) : palette.white
            }`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {orderingOption?.pickupOrdersAllowed ? (
            <>
              <StoreOutlineIcon />
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
              >
                {etaConfiguration?.pickUpEtaMinutes ?? 0} {t('Minutes')}
              </Typography>
            </>
          ) : (
            <>
              <StrikeThroughContainer>
                <StoreOutlineIcon />
              </StrikeThroughContainer>
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
                color={palette.warning.main}
              >
                {t('Off')}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
          {orderingOption?.deliveryOrdersAllowed ? (
            <>
              <DeliveryDiningOutlineIcon />
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
              >
                {etaConfiguration?.deliveryEtaMinutes ?? 0} {t('Minutes')}
              </Typography>
            </>
          ) : (
            <>
              <StrikeThroughContainer>
                <DeliveryDiningOutlineIcon />
              </StrikeThroughContainer>
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
                color={palette.warning.main}
              >
                {t('Off')}
              </Typography>
            </>
          )}
        </Box>
        {cashierCanChangeEta && !open && <ArrowDropDown sx={{ cursor: 'pointer' }} />}
        {cashierCanChangeEta && open && <ArrowDropUp sx={{ cursor: 'pointer' }} />}
      </Box>
      {cashierCanChangeEta && etaConfiguration && (
        <Popover
          open={open}
          onClose={onClose}
          anchorEl={etaInfoAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ mt: 1 }}
        >
          <Box
            sx={{
              px: 3,
              py: 3,
            }}
          >
            <EtaAdjustmentChoices
              title={t('Pick up ETA')}
              label={orderingOption?.pickupOrdersAllowed ? null : t('Closed for pickup')}
              adjustments={pickupAdjustments}
              disabled={orderingOption?.pickupOrdersAllowed !== true}
              selectedAdjustmentValue={pickupEta}
              onAdjustmentClick={setPickupEta}
            />
            <Box sx={{ mt: 3 }} />
            <EtaAdjustmentChoices
              title={t('Delivery ETA')}
              label={orderingOption?.deliveryOrdersAllowed ? null : t('Closed for delivery')}
              adjustments={deliveryAdjustments}
              disabled={orderingOption?.deliveryOrdersAllowed !== true}
              selectedAdjustmentValue={deliveryEta}
              onAdjustmentClick={setDeliveryEta}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-end',
                mt: 3,
              }}
            >
              <Button
                onClick={onClose}
                variant="contained"
                color="secondary"
                sx={{ py: 2, px: 3, mr: 1, color: palette.customGray.dark }}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={onSave}
                disabled={!orderingOption?.deliveryOrdersAllowed && !orderingOption?.pickupOrdersAllowed}
                color={
                  !orderingOption?.deliveryOrdersAllowed && !orderingOption?.pickupOrdersAllowed
                    ? 'secondary'
                    : undefined
                }
              >
                {t('Save')}
              </Button>
            </Box>
          </Box>
        </Popover>
      )}
    </Box>
  );
}
