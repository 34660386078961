import { Paper, Typography, Box, useTheme } from '@mui/material';
import { BasketItem, ReceiptDiscount } from 'typings/Basket';
import React from 'react';
import ItemQuantity from '../ItemQuantity/ItemQuantity';

interface ReceiptProductDetailsProps {
  discount: ReceiptDiscount;
  discountProducts: BasketItem[];
}

const ReceiptProductDetails: React.FC<ReceiptProductDetailsProps> = ({ discount, discountProducts }) => {
  const { palette } = useTheme();
  return (
    <Paper>
      {discountProducts.map((product, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${discount.description}-${product.itemId}`}
          data-testid="receipt-item--discount-details"
          sx={{
            padding: '10px 15px',
            borderBottom: `1px solid ${palette.secondary.dark}`,
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ItemQuantity quantity={product.quantity} />
            <Typography
              sx={{ marginLeft: '15px', color: palette.black.main }}
              variant="body2"
            >{`${product.optionName} - ${product.itemName}`}</Typography>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

export default ReceiptProductDetails;
