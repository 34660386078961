import React from 'react';
import { useAppSelector } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';

interface FinalizePaidOrderDialogProps {
  showDialog: boolean;
  totalPaid: number;
  canFinalize: boolean;
  abort: () => void;
  onConfirm: () => void;
}

const FinalizePaidOrderDialog: React.FC<FinalizePaidOrderDialogProps> = ({
  showDialog,
  totalPaid,
  abort,
  canFinalize,
  onConfirm,
}) => {
  const [t] = useTranslation('orders');
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  if (!showDialog) return null;

  return (
    <ModalContainer
      onClose={abort}
      title={t('Complete order confirmation')}
      open={showDialog}
      testId="order-complete-modal"
      primaryButton={{
        label: t(canFinalize ? 'Complete order' : 'Ok'),
        testId: 'order-complete-modal__button--ok',
        type: 'submit',
        action: canFinalize ? onConfirm : abort,
      }}
      secondaryButton={
        canFinalize
          ? {
              action: abort,
              label: t('Cancel'),
              testId: 'order-complete-modal__button--cancel',
            }
          : undefined
      }
    >
      <Box sx={{ padding: 5, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {canFinalize ? (
          <>
            <Typography variant="h6">
              {t('This order has already been paid online, you can complete the order. The amount paid:')}
            </Typography>
            <Typography variant="h4" color="primary" textAlign="center" sx={{ marginTop: 3 }}>
              {formatToDisplay(totalPaid)}
            </Typography>
          </>
        ) : (
          <Typography variant="h6">{t('This order has already been finalized')}</Typography>
        )}
      </Box>
    </ModalContainer>
  );
};

export default FinalizePaidOrderDialog;
