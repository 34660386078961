import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomizableCanvas, FloorsEditTabs, MenuFloorSection, MenuObjectSection } from 'components/Intake/Floors';
import useFloorEditor from 'hooks/useFloorEditor';
import MenuObjectsListSection from 'components/Intake/Floors/MenuObjectsListSection';
import GeneralPopup from 'components/Shared/Modal/GeneralPopup';
import { useTranslation } from 'react-i18next';
import { FloorEntity } from 'typings/Tables';
import { uploadFloorBackground } from 'stores/Floors/floors.thunk-actions';
import { useAppDispatch } from 'hooks/useRedux';
import buildClasses from './FloorsEdit.css';

interface FloorsEditProps {
  onGoBack(): void;
  receiptVisible: boolean;
}

const FloorsEdit = ({ onGoBack, receiptVisible }: FloorsEditProps) => {
  const [canvasContainerHeight, setCanvasContainerHeight] = useState<number>();
  const [canvasContainerWidth, setCanvasContainerWidth] = useState<number>();
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState<boolean>(false);

  const {
    addNewFloor,
    addNewTable,
    selectFloor,
    setFloorName,
    duplicateFloor,
    deleteFloor,
    saveFloors,
    setDefaultCanvasSize,
    rotateObject,
    copyObject,
    deleteObject,
    setObjectNumber,
    setObjectRotation,
    updateObject,
    updateObjects,
    selectedObject,
    setSelectedObject,
    tableNumberError,
    setTableNumberError,
    floors,
    updateBackground,
    MAX_FLOORS_COUNT,
    MIN_FLOORS_COUNT,
    selectedFloor,
    clearDeletedFloorsIds,
  } = useFloorEditor();

  const container = document.getElementById('intake-window') as HTMLElement;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('intake');
  const { classes, cx } = buildClasses();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    if (
      canvasContainerWidth !== wrapperRef.current?.clientWidth ||
      canvasContainerHeight !== wrapperRef.current?.clientHeight
    ) {
      setCanvasContainerWidth(wrapperRef.current?.clientWidth);
      setCanvasContainerHeight(wrapperRef.current?.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  });

  useEffect(() => {
    if (canvasContainerWidth && canvasContainerHeight) {
      updateObjects(canvasContainerWidth, canvasContainerHeight);
    }
  }, [canvasContainerWidth, canvasContainerHeight]);

  if (!container) {
    return <></>;
  }

  function onSaveClick() {
    saveFloors(onGoBack);
  }

  function onCancelEditionClicked() {
    clearDeletedFloorsIds();
    onGoBack();
  }

  function onFloorDelete() {
    setShowCancelConfirmationDialog(true);
  }

  function onCancelFloorConfirmed() {
    setShowCancelConfirmationDialog(false);
    setTimeout(deleteFloor, 200);
  }

  function onCancelFloorAborted() {
    setShowCancelConfirmationDialog(false);
  }

  function uploadBackground(floor: FloorEntity, bloblUrl: string): void {
    dispatch(uploadFloorBackground({ ...floor, background: bloblUrl }));
  }

  return ReactDOM.createPortal(
    <div className={cx(classes.wrapper, { [classes.wrapperFullWidth]: !receiptVisible })}>
      <div className={classes.leftMenu}>
        <div className={classes.floorEditorContainer}>
          <div className={classes.goBackContainer}>
            <Button onClick={onCancelEditionClicked} color="inherit">
              <ArrowBackIcon className={classes.arrowIcon} />
              {t('GO BACK')}
            </Button>
          </div>
          <FloorsEditTabs
            onSelect={selectFloor}
            onAddFloor={() =>
              addNewFloor({ width: canvasContainerWidth ?? 0, height: canvasContainerHeight ?? 0 })
            }
            floorEntities={floors}
            selectedFloorId={selectedFloor.id}
            selectedFloorName={selectedFloor.name}
            showAddButton={floors.length < MAX_FLOORS_COUNT}
          />
          <div className={classes.canvasContainer}>
            <div id="canvas-wrapper" className={classes.canvasWrapper} ref={wrapperRef}>
              <CustomizableCanvas
                floor={selectedFloor}
                onChangeSize={updateObject}
                onChangePosition={updateObject}
                setDefaultCanvasSize={setDefaultCanvasSize}
                selectedObjectId={selectedObject ? selectedObject.id : undefined}
                setSelectedObject={(object) => {
                  setSelectedObject(object);
                  setTableNumberError(undefined);
                }}
                wrapperHeight={canvasContainerHeight}
                wrapperWidth={canvasContainerWidth}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.rightMenu}>
        <MenuFloorSection
          floorName={selectedFloor.name}
          setFloorName={setFloorName}
          duplicateFloor={duplicateFloor}
          deleteFloor={onFloorDelete}
          canDelete={floors.length > MIN_FLOORS_COUNT}
          canDuplicate={floors.length <= MAX_FLOORS_COUNT}
        />
        <div>
          <MenuObjectSection
            show={!!selectedObject}
            onRotate={rotateObject}
            copyObject={copyObject}
            deleteObject={deleteObject}
            tableNumber={selectedObject?.name ?? ''}
            setTableNumber={setObjectNumber}
            rotation={selectedObject?.placement.rotate ?? 0}
            setRotation={setObjectRotation}
            error={tableNumberError}
          />
        </div>
        <MenuObjectsListSection
          addNewTable={addNewTable}
          background={selectedFloor.background}
          setBackground={updateBackground}
          uploadBackground={(backgroundBlobUrl: string) => {
            uploadBackground(selectedFloor, backgroundBlobUrl);
          }}
        />
        <div className={classes.saveButtonContainer}>
          <Button variant="contained" fullWidth onClick={onSaveClick}>
            {t('SAVE')}
          </Button>
        </div>
      </div>
      <GeneralPopup
        showDialog={showCancelConfirmationDialog}
        headerKey={t('Delete confirmation')}
        text={`${t('Floorplan delete confirmation message')} "${selectedFloor.name}"?`}
        onSubmit={onCancelFloorConfirmed}
        onClose={onCancelFloorAborted}
      />
    </div>,
    container,
  );
};

export default FloorsEdit;
