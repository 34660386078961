import React from 'react';
import { Button, Tooltip, Typography, Box, useTheme } from '@mui/material';
import ItemQuantity from 'components/Intake/Receipt/Item/ItemQuantity/ItemQuantity';
import { Add, Remove } from '@mui/icons-material';
import { ToppingSelection } from 'typings/Products';
import DisabledOverlay from 'components/Shared/DisabledOverlay/DisabledOverlay';
import buildClasses from './ProductSetToppingCard.css';

type ProductSetToppingCardProps = {
  topping: ToppingSelection;
  preventToppingAdd?: boolean;
  onQuantityIncreased: (topping: ToppingSelection) => void;
  onQuantityDecreased: (topping: ToppingSelection) => void;
};

const ProductSetToppingCard: React.FC<ProductSetToppingCardProps> = ({
  topping,
  preventToppingAdd,
  onQuantityIncreased,
  onQuantityDecreased,
}) => {
  const { classes, cx } = buildClasses();
  const { palette, sizing, spacing } = useTheme();

  function onQuantityIncreaseButtonClicked(): void {
    onQuantityIncreased(topping);
  }

  function quantityDecreasedButtonClicked(): void {
    onQuantityDecreased(topping);
  }

  const toppingQuantity = topping.quantity ?? 0;
  const toppingActive = toppingQuantity > 0;

  return (
    <div className={cx(classes.toppingCard, { [classes.activeCard]: toppingActive })}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        data-testid={`topping-card__button--${topping.id}`}
      >
        <Button
          disabled={toppingQuantity === 0}
          data-testid={`product_set_topping_${topping.id}__button--subtract-quantity`}
          sx={{
            backgroundColor: palette.customGray.main,
            width: sizing.buttonBox.xs,
            height: sizing.buttonBox.xs,
            padding: 0,
            minWidth: 'unset',
            color: palette.black.main,
            '&:disabled': {
              backgroundColor: palette.customGray.light,
            },
          }}
          onClick={quantityDecreasedButtonClicked}
        >
          <Remove />
        </Button>
        <Box sx={{ padding: `0px ${spacing(2)}` }}>
          <ItemQuantity
            quantity={toppingQuantity}
            colorVariant={toppingQuantity === 0 ? 'transparent' : 'black'}
            size="sm"
          />
        </Box>
        <Button
          disabled={preventToppingAdd}
          data-testid={`product_set_topping_${topping.id}__button--increase-quantity`}
          sx={{
            backgroundColor: palette.customGray.main,
            width: sizing.buttonBox.xs,
            height: sizing.buttonBox.xs,
            padding: 0,
            minWidth: 'unset',
            color: palette.black.main,
            '&:disabled': {
              backgroundColor: palette.customGray.light,
            },
          }}
          onClick={onQuantityIncreaseButtonClicked}
        >
          <Add />
        </Button>
      </Box>
      <Box sx={{ padding: spacing(1), marginTop: 'auto', textAlign: 'center' }}>
        <Typography variant="body2" sx={{ color: palette.black.main, wordBreak: 'break-word' }}>
          {topping.name}
        </Typography>
      </Box>
      <DisabledOverlay show={preventToppingAdd && !toppingActive} />
    </div>
  );
};

export default ProductSetToppingCard;
