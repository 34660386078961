import { ButtonBase, Grid, Typography, Box, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import buildClasses from './XTastyPizzaOverview.css';

interface XTastyPizzaOverviewPros {
  onPizzaSliceClick: (sliceClicked: number) => void;
  pizzaParts: XTastyPizzaPartOverview[];
  selectedPart: number;
}
export interface XTastyPizzaPartOverview {
  PizzaName?: string;
  completed?: boolean;
  sliceIndex: number;
}

const XTastyPizzaOverview: React.FC<XTastyPizzaOverviewPros> = ({
  pizzaParts,
  onPizzaSliceClick,
  selectedPart,
}) => {
  const [t] = useTranslation('intake');
  const { classes, cx } = buildClasses();
  const { sizing, spacing } = useTheme();

  const checkIfIsValid = () => {
    return pizzaParts.length === 2 || pizzaParts.length === 4;
  };

  if (!checkIfIsValid()) return <></>;
  const isDoubleSided = pizzaParts.length === 2;

  const getPizzaPartClass = (slice: number) => {
    switch (slice) {
      case 0:
        return isDoubleSided ? classes.halfPizzaLeft : classes.quarterPizzaLeftTop;
      case 1:
        return isDoubleSided ? classes.halfPizzaRight : classes.quarterPizzaRightTop;
      case 2:
        return classes.quarterPizzaLeftBottom;
      case 3:
        return classes.quarterPizzaRightBottom;
      default:
        return '' as never;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: sizing.full, margin: 'auto' }}>
      <Grid
        container
        spacing={0}
        sx={{ width: `${sizing.pizzaButton + sizing.pizzaButtonLabel * 2 + 32}px`, marginLeft: spacing(4) }}
      >
        {pizzaParts.map((part) => (
          <Grid item xs={6}>
            <ButtonBase
              onClick={(): void => {
                onPizzaSliceClick(part.sliceIndex);
              }}
              className={cx({
                [classes.reverse]: part.sliceIndex === 1 || part.sliceIndex === 3,
              })}
            >
              <Typography
                variant="subtitle2"
                className={cx(classes.sliceNameLabel, {
                  [classes.active]: selectedPart === part.sliceIndex,
                })}
              >
                {part.PizzaName?.toUpperCase() ?? t(`part ${part.sliceIndex + 1}`).toUpperCase()}
              </Typography>
              <div
                className={cx(classes.pizzaButton, getPizzaPartClass(part.sliceIndex), {
                  [classes.active]: selectedPart === part.sliceIndex,
                  [classes.complete]: part.completed,
                })}
              />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default XTastyPizzaOverview;
