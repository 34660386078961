import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/Shared/Inputs/SearchInput';
import ProductCategories from 'components/Intake/Product/Categories/ProductCategories';
import ProductSubOptions from 'components/Intake/Product/SubOptions/ProductSubOptions';
import ProductOptions from 'components/Intake/Product/Options/ProductOptions';
import {
  removeOptionsWithoutProducts,
  filterProductsByActiveOption,
  filterProductsBySearchString,
  filterProductsSubOptions,
  filterOptionsByActiveSubOption,
} from 'stores/Intake/IntakeStoreUtils';
import { clearActiveProductOption, setActiveProductOption, setActiveProductSubOption } from 'stores/Products';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { SubOption, Product, Option } from 'typings/Products';
import { Box, useTheme } from '@mui/material';
import { getActiveCategory } from 'stores/Products/products.selector';
import BestSellersCatalog from 'containers/Intake/Products/ProductCatalog/BestSellersCatalog';
import ProductCatalog from 'containers/Intake/Products/ProductCatalog/ProductCatalog';
import { getAllToppings } from 'stores/Toppings/toppings.selector';

const ProductsView: React.FC = () => {
  const [t] = useTranslation('intake');
  const { palette, spacing } = useTheme();
  const [searchString, setSearchString] = useState('');
  const { activeCategoryCode, activeOptionId, activeSubOptionId, products, options, subOptions, groups } =
    useAppSelector((state) => state.products);
  const toppings = useAppSelector(getAllToppings);
  const activeCategory = useAppSelector(getActiveCategory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchString.length > 0 && activeOptionId !== null) {
      dispatch(clearActiveProductOption());
    }
  }, [searchString]);

  function setActiveOption(optionId: number): void {
    dispatch(setActiveProductOption(optionId));
  }

  function setActiveSubOption(subOptionId: number): void {
    dispatch(setActiveProductSubOption(subOptionId));
  }

  let filteredOptions: Option[] = [];
  let filteredProducts: Product[] = [];
  let filteredSubOptions: SubOption[] = [];

  if (searchString.length === 0) {
    if (activeCategory?.useSubOptionsSelector) {
      filteredOptions = filterOptionsByActiveSubOption(options, activeSubOptionId);
      if (activeCategory) {
        filteredSubOptions = filterProductsSubOptions(products, options, subOptions, activeCategoryCode);
        filteredProducts = filterProductsByActiveOption(products, activeOptionId);
        filteredOptions = removeOptionsWithoutProducts(filteredOptions, activeCategory.products);
      }
    } else {
      filteredProducts = activeCategory ? activeCategory.products : [];
    }
  } else {
    filteredProducts = filterProductsBySearchString(products, toppings, options, subOptions, searchString);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        padding: spacing(4),
        paddingBottom: spacing(2),
        backgroundColor: palette.background.default,
      }}
    >
      <ProductCategories />
      <SearchInput
        value={searchString}
        fullWidth
        placeholder={t('searchPlaceholder')}
        changeFunc={(val: string): void => setSearchString(val)}
      />
      {activeCategory?.useSubOptionsSelector && searchString.length === 0 && (
        <ProductSubOptions
          subOptions={filteredSubOptions}
          activeSubOptionId={activeSubOptionId}
          onSubOptionChange={setActiveSubOption}
        />
      )}
      {activeCategory?.useSubOptionsSelector && filteredOptions != null && (
        <ProductOptions
          options={filteredOptions}
          activeOptionId={activeOptionId}
          onOptionChange={setActiveOption}
          activeCategoryCode={activeCategoryCode}
        />
      )}
      {activeCategoryCode === 'BSL' && !searchString ? (
        <BestSellersCatalog />
      ) : (
        <ProductCatalog products={filteredProducts} groups={groups} />
      )}
    </Box>
  );
};

export default ProductsView;
