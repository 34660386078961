import { makeStyles } from 'tss-react/mui';

const buildClasses = (secondary?: boolean) =>
  makeStyles()(({ palette, spacing }) => {
    return {
      button: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '1rem',
        fontWeight: 'bold',
        backgroundColor: secondary ? palette.secondary.light : palette.primary.main,
        color: secondary ? palette.secondary.contrastText : palette.primary.contrastText,
        transition: '0.2s',
        '&:hover': {
          backgroundColor: secondary ? palette.secondary.main : palette.primary.dark,
        },
        '&:disabled': {
          opacity: '0.7',
        },
      },
      icon: {
        fontSize: '2rem',
      },
      subNavbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      secondaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '52px',
        marginBottom: spacing(2),
      },
      indicator: {
        height: spacing(0.5),
      },
      secondaryTab: {
        color: palette.text.primary,
        width: '160px',
        minHeight: 'unset',
        // ...typography.button,
      },
      secondaryTabIcon: {
        fontSize: '16px',
        minHeight: 'unset',
        // ...typography.button,
      },
    };
  });
export default buildClasses;
