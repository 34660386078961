import {
  SvgIconComponent,
  CreditCard,
  Money,
  AccountBalanceWalletOutlined,
  CardGiftcard,
} from '@mui/icons-material';

import { DeliveryIcon, DineInIcon, PickUpIcon, TakeAwayIcon } from 'assets/icons';
import { PaymentMethodCode } from 'typings/Payments';

export enum IntakeStatuses {
  productsSection,
  preFinalize,
  finalizeOrder,
}

export enum PickUpTypesValues {
  dineIn = 'DineIn',
  pickUp = 'Pickup',
  takeAway = 'TakeAway',
  delivery = 'Delivery',
  eatIn = 'EatIn',
}

export enum AvailableIntakeContents {
  Products = 'Products',
  Customer = 'Customer',
  Coupons = 'Coupons',
  DineInOpenOrders = 'Dine In Orders',
  Tables = 'Tables',
}
export enum CouponValidity {
  Active = 'Active',
  MissingItems = 'MissingItems',
  WrongDeliveryType = 'WrongDeliveryType',
  ForbiddenCouponCombination = 'ForbiddenCouponCombination',
  InvalidPaymentMethod = 'InvalidPaymentMethod',
}

export enum DineInOrderStatus {
  Opened = 'Opened',
  Finished = 'Finished',
  Cancelled = 'Cancelled',
}

export interface DeliveryOption {
  name: string;
  value: string;
  icon: SvgIconComponent | null;
  disabled?: boolean;
}

export interface PaymentOption {
  value: string;
  icon: SvgIconComponent | null;
  disabled?: boolean;
}

export const deliveryTypes = [
  {
    value: PickUpTypesValues.pickUp,
    icon: PickUpIcon,
  },
  {
    value: PickUpTypesValues.takeAway,
    icon: TakeAwayIcon,
  },
  {
    value: PickUpTypesValues.delivery,
    icon: DeliveryIcon,
  },
];

export interface ExtendedDeliveryType {
  key: PickUpTypesValues;
  deliveryType: PickUpTypesValues;
  isEatIn: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const extendedDeliveryTypes: ExtendedDeliveryType[] = [
  {
    key: PickUpTypesValues.pickUp,
    deliveryType: PickUpTypesValues.pickUp,
    isEatIn: false,
    icon: PickUpIcon,
  },
  {
    key: PickUpTypesValues.eatIn,
    deliveryType: PickUpTypesValues.takeAway,
    isEatIn: true,
    icon: DineInIcon,
  },
  {
    key: PickUpTypesValues.takeAway,
    deliveryType: PickUpTypesValues.takeAway,
    isEatIn: false,
    icon: TakeAwayIcon,
  },
  {
    key: PickUpTypesValues.delivery,
    deliveryType: PickUpTypesValues.delivery,
    isEatIn: false,
    icon: DeliveryIcon,
  },
];

export const paymentMethodTypes = [
  {
    value: PaymentMethodCode.Cash,
    icon: Money,
  },
  {
    value: PaymentMethodCode.Card,
    icon: CreditCard,
  },
  {
    value: PaymentMethodCode.ManualIdeal,
    icon: AccountBalanceWalletOutlined,
  },
  {
    value: PaymentMethodCode.GiftCard,
    icon: CardGiftcard,
  },
];

export function getAvailableIntakeContents(
  displayDineInOrders: boolean,
  displayFloorPlan: boolean,
): AvailableIntakeContents[] {
  const availableIntakeContents: AvailableIntakeContents[] = [
    AvailableIntakeContents.Products,
    AvailableIntakeContents.Coupons,
  ];

  if (displayDineInOrders) {
    availableIntakeContents.push(AvailableIntakeContents.DineInOpenOrders);
  }

  if (displayFloorPlan) {
    availableIntakeContents.push(AvailableIntakeContents.Tables);
  }

  return availableIntakeContents;
}
export function getAvailableToGoIntakeContents(
  displayDineInOrders: boolean,
  displayFloorPlan: boolean,
): AvailableIntakeContents[] {
  const availableToGoIntakeContents = getAvailableIntakeContents(false, false);

  if (displayDineInOrders) {
    availableToGoIntakeContents.push(AvailableIntakeContents.DineInOpenOrders);
  }

  if (displayFloorPlan) {
    availableToGoIntakeContents.push(AvailableIntakeContents.Tables);
  }

  availableToGoIntakeContents.push(AvailableIntakeContents.Customer);
  return availableToGoIntakeContents;
}

export const PAGE_SIZE_FOR_DINE_IN_FLOORS = 1000;
