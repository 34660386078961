import { makeStyles } from 'tss-react/mui';

interface RoundedButtonCssProps {
  secondary?: boolean;
  noShadow?: boolean;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
}

const buildClasses = ({ secondary, noShadow, margin, padding, width, height }: RoundedButtonCssProps) =>
  makeStyles()(({ palette, spacing }) => {
    return {
      button: {
        display: 'flex',
        flexDirection: 'row',
        padding: padding ?? `${spacing(1)} ${spacing(2)}`,
        borderRadius: '1000px', // just workaround for full 50% border-radius
        fontSize: '1rem',
        fontWeight: 'bold',
        width: width ?? 'auto',
        height: height ?? 'auto',
        margin: margin ?? 'auto auto',
        backgroundColor: secondary ? palette.secondary.light : palette.primary.main,
        color: secondary ? palette.secondary.contrastText : palette.primary.contrastText,
        boxShadow: noShadow ? 'none' : '0 2px 5px 0 rgba(0, 0, 0, 0.16)',
        transition: '0.2s',
        '&:hover': {
          backgroundColor: secondary ? palette.secondary.main : palette.primary.dark,
        },
        '&:disabled': {
          backgroundColor: '#CCC',
        },
      },
      icon: {
        fontSize: '2rem',
      },
    };
  });
export default buildClasses;
