import { RootState } from 'stores';
import { GiftCardActivationStatus } from 'typings/Payments/giftCardTypes';

export const getShowGiftCardModal = ({ giftCards }: RootState) => giftCards.giftCards?.length > 0;

export const getCurrentGiftCardActivation = ({ giftCards }: RootState) =>
  giftCards.giftCards.find((el) => el.isActiveStep);

export const getAllCardsAmount = ({ giftCards }: RootState) => giftCards.giftCards.length;

export const getCardsToActivateAmount = ({ giftCards }: RootState) =>
  giftCards.giftCards.filter((el) => el.status !== GiftCardActivationStatus.Succeeded).length;
