import { Box } from '@mui/system';
import CustomerDisplayBasket from 'components/CustomerDisplay/CustomerDisplayBasket';
import CustomerDisplayHeader from 'components/CustomerDisplay/CustomerDisplayHeader';
import CustomerDisplaySplashScreen from 'components/CustomerDisplay/CustomerDisplaySplashScreen';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { useEffect } from 'react';
import { clearCustomerDisplayBasket, hideIntakeFinalizeSuccessMessage } from 'stores/CustomerDisplay';

const CustomerDisplay: React.FC = () => {
  const { receiptProducts, receiptDiscounts, basketSummary, showIntakeFinalizedMessage, cashierIsAway } =
    useAppSelector((state) => state.customerDisplay);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showIntakeFinalizedMessage) {
      dispatch(clearCustomerDisplayBasket());

      setTimeout(() => {
        dispatch(hideIntakeFinalizeSuccessMessage());
      }, 10000);
    }
  }, [showIntakeFinalizedMessage]);

  const shouldDisplaySplashScreen = !basketSummary || showIntakeFinalizedMessage || cashierIsAway;

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CustomerDisplayHeader />
      {!shouldDisplaySplashScreen && (
        <CustomerDisplayBasket products={receiptProducts} discounts={receiptDiscounts} summary={basketSummary} />
      )}
      {shouldDisplaySplashScreen && (
        <CustomerDisplaySplashScreen showThankYouMessage={showIntakeFinalizedMessage ?? false} />
      )}
    </Box>
  );
};

export default CustomerDisplay;
