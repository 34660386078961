import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getOrdersFiscalStatus } from 'stores/Notifications/notifications.thunk-actions';

const FiscalStatusHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const { fiscalizationConfiguration } = useAppSelector((store) => store.config);
  const { loggedCashier } = useAppSelector((appState) => appState.cashier);

  const pollingInterval = useRef<any>(null);

  const initializeFiscalStatusTimer = (interval: number): void => {
    pollingInterval.current = setInterval(() => {
      dispatch(getOrdersFiscalStatus());
    }, interval * 1000);
    dispatch(getOrdersFiscalStatus());
  };

  useEffect(() => {
    if (
      loggedCashier &&
      fiscalizationConfiguration?.isActive &&
      fiscalizationConfiguration?.awaitingFiscalizationNotificationIntervalInSeconds
    ) {
      initializeFiscalStatusTimer(fiscalizationConfiguration.awaitingFiscalizationNotificationIntervalInSeconds);
    }

    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [loggedCashier, fiscalizationConfiguration]);

  return <></>;
};

export default FiscalStatusHandler;
