import React from 'react';
import AllOrdersTable from 'containers/AllOrders/Overview/Table/AllIOrdersTable';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { StatusTabType, setCurrentStatusTab } from 'stores/AllOrders';
import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import { useFormContext } from 'react-hook-form';
import { submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import buildClasses from './AllOrdersWrapper.css';
import TableFilters from './Filters/TableFilters';

const AllOrdersWrapper: React.FC = () => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('orders');
  const { setValue } = useFormContext<OrderFilters>();
  const { currentStatusTab } = useAppSelector(({ allOrders }) => allOrders);

  function handleChange(_: React.SyntheticEvent<Element, Event>, value: StatusTabType): void {
    dispatch(setCurrentStatusTab(value));
    setValue('orderStatus', []);
    submitAllOrdersForm();
  }

  return (
    <Box className={classes.tableWrapper}>
      <Tabs value={currentStatusTab} onChange={handleChange}>
        <Tab
          label={t('All orders')}
          value="all-orders"
          className={classes.orderStatusTab}
          data-testid="all-orders__order_status_tab-all-orders"
        />
        <Tab
          label={t('Open orders')}
          value="open-orders"
          className={classes.orderStatusTab}
          data-testid="all-orders__order_status_tab-open-orders"
        />
        <Tab
          label={t('Unpaid orders')}
          value="unpaid-orders"
          className={classes.orderStatusTab}
          data-testid="all-orders__order_status_tab-unpaid-orders"
        />
      </Tabs>
      <Box className={classes.ordersOverviewContainer}>
        <TableFilters />
        <AllOrdersTable />
      </Box>
    </Box>
  );
};

export default AllOrdersWrapper;
