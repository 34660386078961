import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import keyscanner from 'keyscanner';
import { handleScanEvent } from 'stores/combined.actions';

const inputs = ['input', 'textarea'];
const KeyboardScannerHandler: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // eslint-disable-next-line new-cap
    const keyScanHandle = new keyscanner(onScan);
    return () => {
      keyScanHandle.stop();
    };
  }, []);

  function onScan(scannedValue: string): void {
    const { activeElement } = document;
    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
      // do not trigger any action if some input is in focus
      return;
    }

    dispatch(handleScanEvent(scannedValue));
  }

  return <></>;
};

export default KeyboardScannerHandler;
