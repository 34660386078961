import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CmsCarouselBanner } from 'API/CmsApi';

const CarouselImage: React.FC<CmsCarouselBanner> = ({ carouselTimeSeconds, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const imageInterval = useRef<any>(null);

  useEffect(() => {
    if (images?.length > 1 && carouselTimeSeconds && carouselTimeSeconds > 0) {
      imageInterval.current = setInterval(() => {
        setCurrentImageIndex((ct) => (ct === images.length - 1 ? 0 : ct + 1));
      }, carouselTimeSeconds * 1000);
    }
    return () => {
      clearInterval(imageInterval.current);
    };
  }, [images, carouselTimeSeconds]);

  const renderImageOrVideo = () => {
    const currentMedia = images[currentImageIndex];

    if (currentMedia.ext === '.mp4') {
      return (
        <motion.video
          key={currentMedia.url}
          src={currentMedia.url}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'tween', ease: 'easeInOut', duration: 0.5 }}
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          autoPlay
          loop
          muted
        />
      );
    }
    return (
      <motion.img
        key={currentMedia.url}
        src={currentMedia.url}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.5 }}
        style={{ height: '100%', width: '100%' }}
      />
    );
  };

  return <AnimatePresence>{renderImageOrVideo()}</AnimatePresence>;
};

export default CarouselImage;

const variants = {
  enter: {
    opacity: 0.5,
    x: '-100vw',
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0.5,
    x: '100vw',
  },
};
