import React from 'react';
import { useAppDispatch } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Typography, Box } from '@mui/material';
import { restartOrder } from 'stores/combined.actions';
import { logOutCashier } from 'stores/Cashier/cashier.thunk-actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ClockInConfirmationModalProps {
  showDialog: boolean;
  abort: () => void;
}

const ClockInConfirmationModal: React.FC<ClockInConfirmationModalProps> = ({ showDialog, abort }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [t] = useTranslation('common');

  function onSubmit() {
    dispatch(logOutCashier());
  }

  function onClose() {
    if (abort) abort();
    dispatch(restartOrder());
    history.push('/intake');
  }

  return (
    <ModalContainer
      onClose={onClose}
      title="Clock in confirmation"
      open={showDialog}
      testId="clockin-modeal-text-field"
      secondaryButton={{
        label: t('Cancel'),
        testId: 'clockin-confirmation-modal__button--cancel',
        action: onClose,
      }}
      primaryButton={{
        label: t('OK'),
        testId: 'clockin-confirmation-modal__button--ok',
        type: 'submit',
        action: onSubmit,
      }}
    >
      <Box
        sx={{ width: '100%', minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="subtitle2">
          To perform this action, the current user needs to be clocked-in
        </Typography>
      </Box>
    </ModalContainer>
  );
};

export default ClockInConfirmationModal;
