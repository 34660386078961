import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { getConfigState } from 'stores/Config/config.selector';
import { getShowSettlePayment } from 'stores/Payments/payment.selector';
import { FinalizationTimeData, PaymentMethodCode } from 'typings/Payments';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';

export const getShowOrderPaymentModal = ({ orderPayment }: RootState) => !!orderPayment.showOrderPayment;

export const getPaymentModalToShow = () =>
  createSelector([getShowOrderPaymentModal, getShowSettlePayment], (showNewPaymentModal, showOldPaymentModal) => {
    if (showNewPaymentModal) return 'newModal';
    if (showOldPaymentModal) return 'oldModal';
    return undefined;
  });
export const getActivePayment = ({ orderPayment }: RootState) => orderPayment.payments.find((el) => el.isActive);
export const getOrderPayment = ({ orderPayment }: RootState) => orderPayment;
export const getIsAnyOrderActionPending = ({ orderPayment }: RootState) => {
  if (orderPayment.acceptanceInProgress) return true;
  if (orderPayment.cancelInProgress) return true;
  if (orderPayment.orderPaymentStatusInProgress) return true;
  if (orderPayment.orderFinalizationInProgress) return true;
  return false;
};

export const getIsActivePaymentCashType = () =>
  createSelector([getActivePayment], (activePayment) => {
    if (!activePayment) return false;
    // PaymentMethodCode.Cash should be replaced with some value from backend
    return activePayment.paymentMethod?.code === PaymentMethodCode.Cash;
  });

export const getIsSplitPaymentAvailableFor = () =>
  createSelector([getConfigState, getOrderPayment], (configState, orderPayment) => {
    return (
      configState.payment?.v2.splitPaymentConfiguration.enableSplitPayment &&
      orderPayment.deliveryType != null &&
      !configState.payment?.v2.splitPaymentConfiguration.excludedPickupTypes.includes(orderPayment.deliveryType)
    );
  });

export const getFinalizationTimeDataSelector = () =>
  createSelector([getActivePayment, getConfigState], (activePayment, configState) => (): FinalizationTimeData => {
    const { payment } = configState;
    if (!payment?.v2.finalizeAutomaticallyForPaymentMethods.some((e) => e === activePayment?.paymentMethod?.id))
      return { skipFinalizationScreen: false, finalizationTime: undefined };

    const skipFinalizePaymentStep =
      getLocalStorageItem<boolean>(LocalStorageItems.skipFinalizePaymentStep) ?? false;

    if (skipFinalizePaymentStep) return { skipFinalizationScreen: true, finalizationTime: undefined };

    const finalizationTime =
      getLocalStorageItem<number>(LocalStorageItems.finalizePaymentTime) ??
      payment?.v2.automaticFinalizationDefaultTime ??
      3;

    return { skipFinalizationScreen: false, finalizationTime };
  });
