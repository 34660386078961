import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EftError as EftErrorIcon } from 'assets/icons';
import { useAppSelector } from 'hooks/useRedux';

const EftError: React.FC = () => {
  const [t] = useTranslation('errorCodes');
  const { eftStatusResponse, startError } = useAppSelector(({ eftPayment }) => eftPayment);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '374px',
        minHeight: '456px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Typography color="black.main" variant="h5" sx={{ m: 1 }} data-testid="settle-payment__label--eft-error">
        {t(startError ?? eftStatusResponse?.status ?? 'Eft transaction error')}
      </Typography>
      <EftErrorIcon />
    </Box>
  );
};
export default EftError;
