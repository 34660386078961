import React from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getSupportedLanguages } from 'stores/Cms/cms.selector';
import { SupportedUILanguage } from 'stores/Cms/cms.interface';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';

const LanguageSwitch = (): JSX.Element => {
  const [t, i18n] = useTranslation('settings');

  function handleLanguageChange(languageCode: string): void {
    i18n.changeLanguage(languageCode);
    const hybridModeCommunicator = getHybridModeCommunicator();
    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.LanguageChanged, {
      languageCode,
    });
  }
  const languages = useAppSelector(getSupportedLanguages);

  return (
    <ButtonGroup size="small" aria-label="outlined primary button group">
      {languages.map((lang: SupportedUILanguage): JSX.Element => {
        return (
          <Tooltip title={`Switch language to ${lang.name}`} key={lang.code}>
            <Button
              color="secondary"
              onClick={(): void => {
                handleLanguageChange(lang.code);
              }}
            >
              {lang.code}
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default LanguageSwitch;
