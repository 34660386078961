import React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { LocalOrderRowData } from 'containers/LocalOrders/LocalOrdersTypes';
import { useAppSelector } from 'hooks/useRedux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PlacementMode, SynchronizationStatuses } from 'stores/LocalOrders/localOrdersTypes';
import buildClasses from './OrdersTableRow.css';

type SelectRowType = (value: string) => void;

interface OrdersTableRowProps {
  row: LocalOrderRowData;
  orderId: string;
  isSelected: boolean;
  selectRow: SelectRowType;
  canBeFinalized: boolean;
  onFinalize: () => void;
}

const OrdersTableRow: React.FC<OrdersTableRowProps> = ({
  row,
  orderId,
  isSelected,
  selectRow,
  canBeFinalized,
  onFinalize,
}) => {
  const { classes, cx } = buildClasses();
  const [t] = useTranslation('orders');

  const formatToDisplay = useAppSelector(getPaymentFormatter());

  function onFinalizeClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();
    onFinalize();
  }

  function isFutureOrder(waitingTime: string): string {
    return waitingTime ?? t('Future');
  }

  const cellValue = [
    { value: row.name, key: 'name' },
    { value: row.address, key: 'address' },
    { value: row.phone, key: 'phone' },
    { value: formatToDisplay(row.price), key: 'price' },
    { value: row.date, key: 'date' },
    { value: row.collection, key: 'collection' },
    { value: isFutureOrder(row.waitingTime), key: 'waitingTime' },
    { value: row.deliveryType, key: 'deliveryType' },
  ];

  return (
    <TableRow
      className={`${isSelected && classes.selected}`}
      key={orderId}
      hover
      onClick={(): void => selectRow(orderId)}
      selected={isSelected}
      data-testid={`history-orders-list__row--order-${orderId}`}
      data-testattr-status={row.syncStatus}
    >
      <TableCell
        sx={{ borderBottom: '1px solid #F5F5F5' }}
        align="left"
        data-testid="history-orders-list__cell--ticker-nr"
      >
        <div className={classes.statusCell}>
          <CloudUploadIcon
            className={cx({
              [classes.direct]: row.placementMode === PlacementMode.Direct,
              [classes.success]: row.syncStatus === SynchronizationStatuses.Success,
              [classes.pending]: row.syncStatus !== SynchronizationStatuses.Success,
            })}
          />
          {row.ticket}
        </div>
      </TableCell>
      {cellValue.map((rowCell) => (
        <TableCell
          sx={{ borderBottom: '1px solid #F5F5F5' }}
          align="right"
          key={rowCell.key}
          data-testid={`history-orders-list__cell--${rowCell.key}`}
        >
          {rowCell.value}
        </TableCell>
      ))}
      <TableCell
        sx={{ borderBottom: '1px solid #F5F5F5' }}
        align="right"
        data-testid="history-orders-list__cell--status"
      >
        {canBeFinalized ? (
          <Button
            color="primary"
            onClick={onFinalizeClick}
            variant="contained"
            sx={{ padding: '0 5px', height: '20px', display: 'flex', flexFlow: 'column', float: 'right' }}
            data-testid="history-orders-list__button--finalize"
          >
            {t('Finalize')}
          </Button>
        ) : (
          row.status
        )}
      </TableCell>
    </TableRow>
  );
};

export default OrdersTableRow;
