import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { resetCashierState } from 'stores/Cashier';
import { LoginStates } from 'typings/Cashier';

const ClockInResult: React.FC = () => {
  const [t] = useTranslation('common');
  const [t_auth] = useTranslation('authentication');
  const dispatch = useAppDispatch();
  const { sizing } = useTheme();
  const { loginState, errorCode } = useAppSelector(({ cashier }) => cashier);
  const isError =
    loginState === LoginStates.timekeepingError || loginState === LoginStates.cashflowConfirmationError;
  const isClockIn = loginState === LoginStates.clockInSuccess;

  function getTimekeepingErrorMessage(): string {
    if (!errorCode) return t('Error on clocking operation');
    switch (errorCode) {
      case 'UserAlreadyClockedIn':
        return t_auth('Already clocked in');
      case 'UserAlreadyClockedInAnotherStore':
        return t_auth('Already clocked in another store');
      case 'UserNotClockedIn':
        return t_auth('Not clocked in');
      case 'InvalidPin':
        return t_auth('Invalid PIN');
      case 'UserHasUnfinishedRides':
        return t_auth('Unfinished rides');
      case 'UnableToFiscalizeClockInCommand':
        return t_auth('Unable to register clock in command');
      case 'UnableToFiscalizeClockOutCommand':
        return t_auth('Unable to register clock out command');
      case 'ClockInFiscalizationRejected':
        return t_auth('Rejected registering clock in command');
      case 'ClockOutFiscalizationRejected':
        return t_auth('Rejected registering clock out command');
      default: {
        return errorCode;
      }
    }
  }

  const message = isError
    ? getTimekeepingErrorMessage()
    : isClockIn
    ? 'You have been successfully clocked-in'
    : 'You have been successfully clocked-out';

  return (
    <Box
      sx={{ height: sizing.pinPad.height, display: 'flex', justifyContent: 'flexStart', flexDirection: 'column' }}
    >
      <Typography color="black.main" variant="h3" sx={{ mb: 3 }}>
        {t(isError ? 'Sorry...' : 'All set!')}
      </Typography>
      <Typography variant="body1" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%', mb: 3 }}>
        <Alert
          severity={isError ? 'error' : 'success'}
          icon={
            isError ? <ErrorOutlineOutlined fontSize="inherit" /> : <ErrorOutlineOutlined fontSize="inherit" />
          }
        >
          {`${t(message)}`}
        </Alert>
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          dispatch(resetCashierState());
        }}
      >
        {t('Ok, thanks')}
      </Button>
    </Box>
  );
};
export default ClockInResult;
