import React, { useMemo } from 'react';
import { Card, CardActionArea, Typography, Tooltip, Box, useTheme } from '@mui/material';
import { Block, MoneyOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Product, Option, ImageLabel } from 'typings/Products';
import buildClasses from './ProductCard.css';

type CardProps = {
  product: Product;
  selectedOption?: Option;
  hideOptionName?: boolean;
  onProductSelected: (selectedProduct: Product) => void;
  displayImage?: boolean | null;
};

const ProductCard: React.FC<CardProps> = ({
  product,
  selectedOption,
  hideOptionName,
  onProductSelected,
  displayImage,
}) => {
  const { classes } = buildClasses();
  const { spacing, sizing, palette, breakpoints } = useTheme();
  const [t] = useTranslation('intake');
  const imageUrl = displayImage
    ? product.images.find((x) => x.labelId === ImageLabel.Homepage)?.imageUrl ?? product.categoryImageUrl
    : null;
  const productCategoryCode = product.categoryCode.toUpperCase();
  const option = useMemo(
    () => product.options.find((opt) => opt.productOptionId === selectedOption?.id),
    [product, selectedOption],
  );
  const shouldGrayOut = useMemo(
    () => (option ? !option.isPriceAvailable : !product.options.some((opt) => opt.isPriceAvailable)),
    [option, product],
  );

  const allOptionsOutOfStock = useMemo(() => !product.options.some((opt) => opt.isOptionAvailable), [product]);
  const productIsOutOfStock = !product.isAvailable;
  const optionOutOfStock = option ? !option.isOptionAvailable : allOptionsOutOfStock;

  const showOutOfStockIcon = (productIsOutOfStock || optionOutOfStock) && !shouldGrayOut;

  return (
    <Card
      sx={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: sizing.productCard.height,
        position: 'relative',
        width: `calc(${100 / sizing.productCard.lineCount.md}% - ${spacing(1)})`,
        [breakpoints.down('md')]: {
          width: `calc(${100 / sizing.productCard.lineCount.xs}% - ${spacing(1)})`,
        },
        [breakpoints.up('xl')]: {
          width: `calc(${100 / sizing.productCard.lineCount.xl}% - ${spacing(1)})`,
        },
      }}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {!imageUrl && displayImage && (
        <Box sx={{ position: 'absolute', width: sizing.full, textAlign: 'center', marginTop: spacing(1) }}>
          {productCategoryCode}
        </Box>
      )}

      <Box sx={{ position: 'absolute', top: spacing(0.5), right: spacing(0.5) }}>
        {product.properties?.map((prop) => (
          <img key={prop.propertyName} src={prop.imageUrl} alt={prop.propertyName} className={classes.property} />
        ))}
      </Box>
      {showOutOfStockIcon && (
        <Block sx={{ position: 'absolute', top: spacing(0.5), left: spacing(0.5), color: palette.red[600] }} />
      )}
      <CardActionArea
        sx={{ height: sizing.full }}
        onClick={(): void => onProductSelected(product)}
        data-testid={`product-card__button--${product.title}`}
        disabled={shouldGrayOut}
      >
        {!displayImage && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: '0px 10px 0px 10px',
            }}
          >
            <Typography variant="body2" sx={{ color: palette.black.main }}>
              {product.name} {selectedOption && !hideOptionName && `(${selectedOption.name})`}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            width: sizing.full,
            backgroundColor: palette.customGray.main,
            position: 'absolute',
            justifyContent: 'center',
            bottom: '0px',
            textAlign: 'center',
          }}
        >
          {displayImage && (
            <Typography
              variant="body2"
              sx={{ padding: `${spacing(0.25)} ${spacing(1)}`, color: palette.grey[800] }}
            >
              {product.name} {selectedOption && !hideOptionName && `(${selectedOption.name})`}
            </Typography>
          )}
        </Box>
      </CardActionArea>
      {shouldGrayOut && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(105, 105, 108, 0.3)',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Tooltip title={<span>{t('Price not configured')}</span>} placement="bottom">
            <MoneyOff />
          </Tooltip>
        </Box>
      )}
    </Card>
  );
};

export default ProductCard;
