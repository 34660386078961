import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    container: {
      margin: spacing(2),
    },
    addressesContent: {
      marginLeft: spacing(5),
    },
    manualInputButton: {
      color: palette.primary.dark,
    },
    heading: {
      color: palette.black.main,
      marginBottom: spacing(3),
    },
  };
});
export default buildClasses;
