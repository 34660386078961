import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { useAppDispatch } from 'hooks/useRedux';
import { logIn } from 'stores/Auth';
import ProcessingComponent from 'components/Shared/Loaders/ProcessingComponent';
import { Box, useTheme } from '@mui/system';

const LogIn: React.FC = () => {
  const [t] = useTranslation('common');
  const [loginInProgress, setLoginInProgress] = useState(false);
  const dispatch = useAppDispatch();
  const { spacing } = useTheme();

  const handleLogin = useCallback(async () => {
    dispatch(logIn());
    setLoginInProgress(true);
  }, []);

  return (
    <Box sx={{ maxWidth: '50%', padding: spacing(3), margin: '0px auto' }}>
      {loginInProgress === false ? (
        <RoundedButton
          secondary
          type="button"
          testId="login_button"
          width="50%"
          height="4rem"
          margin="25px auto"
          onClick={handleLogin}
        >
          {t('Log in')}
        </RoundedButton>
      ) : (
        <ProcessingComponent text={t('Login in progress...')} />
      )}
    </Box>
  );
};
export default LogIn;
