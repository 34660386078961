import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm, FieldError } from 'react-hook-form';
import { format, addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import dateFnsLng from 'utils/i18n/dateFnsLang';

export interface DeliveryTimeErrors {
  date: FieldError;
}

interface MenuItemData {
  value: string;
  label: string;
}

interface DataSelectProps {
  formRef: ReturnType<typeof useForm>['register'];
  isPickup: boolean;
  isToday: (value: boolean) => void;
  setValue: (name: string, newValue: unknown) => void;
  preselectedDate?: string;
}

const DateSelect: React.FC<DataSelectProps> = ({ formRef, isPickup, isToday, setValue, preselectedDate }) => {
  const [selectedDate, setSelectedDate] = useState(preselectedDate || format(new Date(), 'yyyy-MM-dd'));
  const [t] = useTranslation('intake');

  formRef({ name: 'date', value: selectedDate });

  const dayOptions = (function dayOptions(): MenuItemData[] {
    const currentDate = new Date();

    function checkDay(dayIndex: number): string {
      switch (dayIndex) {
        case 0:
          return t('today');
        case 1:
          return t('tomorrow');
        default:
          return '';
      }
    }

    const items: MenuItemData[] = [];
    const locale = dateFnsLng();
    for (let i = 0; i <= 8; i += 1) {
      const optionDate = addDays(currentDate, i);
      const formattedDate = format(optionDate, 'yyyy-MM-dd');
      const displayValue = format(optionDate, 'EEEE, do MMMM', { locale }) + checkDay(i);
      items.push({ value: formattedDate, label: displayValue });
    }

    return items;
  })();

  function handleChange(event: SelectChangeEvent<{ value: string }>): void {
    setValue('date', event.target.value);
    setSelectedDate(event.target.value as string);
    isToday(event.target.value === dayOptions[0].value);
  }

  return (
    <FormControl
      variant="outlined"
      sx={{
        dateSelector: {
          width: '100%',
        },
      }}
    >
      <InputLabel>{isPickup ? t('Pickup date') : t('Delivery date')}</InputLabel>
      <Select
        // @ts-ignore
        value={selectedDate}
        onChange={handleChange}
        name="date"
        label={isPickup ? t('Pickup date') : t('Delivery date')}
        inputProps={{ 'data-testid': 'finalize-time-form__input--date-selector' }}
      >
        {dayOptions.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-testid={`finalize-time-form__input--date-selector-option-${index}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DateSelect;
