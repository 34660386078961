import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizingBig }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100vw',
      position: 'fixed',
      top: 0,
      zIndex: 1101,
      backgroundColor: palette.primary.main,
    },
    langSwitch: {
      alignSelf: 'flex-end',
      display: 'inline-flex',
      paddingTop: spacing(1),
      paddingRight: spacing(2),
    },
    content: {
      overflowY: 'auto',
    },
    stepperContainer: {
      backgroundColor: 'transparent !important',
      color: 'white',
      '&-active': {
        color: 'white',
      },
    },
    step: {
      backgroundColor: '#ccc',
      color: '#fff',
      zIndex: 1,
      width: sizingBig(1),
      height: sizingBig(1),
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeStep: {
      backgroundImage: `linear-gradient( 136deg, ${palette.primary.light} 0%, ${palette.primary.main} 50%, ${palette.primary.dark} 100%)`,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      border: `1px solid ${palette.primary.contrastText}`,
    },
    completedStep: {
      backgroundImage: `linear-gradient( 136deg, ${palette.primary.light} 0%, ${palette.primary.main} 50%, ${palette.primary.dark} 100%)`,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      border: `1px solid ${palette.primary.contrastText}`,
    },
    stepperLine: {
      backgroundColor: `${palette.primary.contrastText}`,
      top: spacing(3),
    },
    activeLine: {
      backgroundImage: `linear-gradient( 90deg, ${palette.primary.dark} 40%, ${palette.primary.contrastText} 100%)`,
    },
    completedLine: {
      backgroundColor: `${palette.primary.dark}`,
    },
    stepLabel: { color: 'white !important' },
    line: {
      height: spacing(1),
      border: 0,
      boxShadow: '0 9px 10px 0 rgba(0,0,0,.1)',
      borderRadius: 1,
    },
  };
});
export default buildClasses;
