import React, { useState } from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SettingMenuItem from 'components/Settings/SettingMenuItem/SettingMenuItem';
import PrintingSettings from 'containers/Settings/PrintingSettings/PrintingSettings';
import AppVersion from 'components/Settings/AppVersion/AppVersion';
import { useAppSelector } from 'hooks/useRedux';
import { settingsOptions, MenuItems } from './SettingsConsts';
import buildClasses from './Settings.css';
import LanguageSettings from './LanguageSettings/LanguageSettings';
import IntakeSettings from './IntakeSettings/IntakeSettings';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import SystemSettings from './SystemSettings/SystemSettings';

export default function Settings(): JSX.Element {
  const { classes } = buildClasses();
  const [active, setActive] = useState(MenuItems.intakeSettings);
  // Right now in payment settings section there is only stuff for EFT
  // - that's why feature flag is used here and not inside PaymentSettings component
  const { featureFlags, instanceType } = useAppSelector((store) => store.config);

  // added because hard logout is only possible in central instance
  const showSystemSetting = instanceType === 'Central';

  return (
    <div className={classes.container}>
      <PageHeader headerOptions={settingsOptions} translationNS="settings" />
      <div className={classes.settingsWrapper}>
        <div className={classes.sideMenu}>
          <div>
            <SettingMenuItem
              label={MenuItems.intakeSettings}
              setActive={setActive}
              isActive={active === MenuItems.intakeSettings}
            />
            <SettingMenuItem
              label={MenuItems.printingSettings}
              setActive={setActive}
              isActive={active === MenuItems.printingSettings}
            />
            <SettingMenuItem
              label={MenuItems.language}
              setActive={setActive}
              isActive={active === MenuItems.language}
            />
            {featureFlags.OfflineModule_EFT === true && (
              <SettingMenuItem
                label={MenuItems.paymentSettings}
                setActive={setActive}
                isActive={active === MenuItems.paymentSettings}
              />
            )}
            {showSystemSetting && (
              <SettingMenuItem
                label={MenuItems.systemSettings}
                setActive={setActive}
                isActive={active === MenuItems.systemSettings}
              />
            )}
          </div>
          <AppVersion />
        </div>
        <div className={classes.settingsOptions}>
          {active === MenuItems.intakeSettings && <IntakeSettings />}
          {active === MenuItems.printingSettings && <PrintingSettings />}
          {active === MenuItems.language && <LanguageSettings />}
          {active === MenuItems.paymentSettings && <PaymentSettings />}
          {showSystemSetting && active === MenuItems.systemSettings && <SystemSettings />}
        </div>
      </div>
    </div>
  );
}
