import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableFooter,
  TablePagination,
  Typography,
  CircularProgress,
} from '@mui/material';
import { LocalOrder } from 'stores/LocalOrders/localOrdersTypes';
import OrdersTableRow from 'components/Orders/Table/Row/OrdersTableRow';
import ExpandableOrdersRow from 'components/Orders/Table/ExpandableRow/ExpandableOrdersRow';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { setActivePaymentMethod, finalizeOrder as finalizeOrderAction } from 'stores/Intake';
import { checkSpecifiedCustomerCanPayOnAccount, clearCustomerData } from 'stores/Customer';
import { postReprintOrder } from 'stores/LocalOrders';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { PaymentMethodCode, PaymentSuccessfulResult } from 'typings/Payments';
import { setAlert } from 'stores/Alerts';
import CancelOrderDialog from '../../../../components/Orders/CancelDialog/CancelOrderDialog';
import buildClasses from './LocalOrdersTable.css';
import { LocalOrderRowData } from '../../LocalOrdersTypes';

const OrdersTable: React.FC = () => {
  const { classes } = buildClasses();
  const [selectedRow, setSelectedRow] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [orderId, setOrderId] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const { ordersList, ordersFetchInProgress } = useAppSelector((store) => store.localOrders);
  const { availablePaymentMethods } = useAppSelector((store) => store.payments);
  const { featureFlags } = useAppSelector((store) => store.config);
  const [t] = useTranslation('orders');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availablePaymentMethods && availablePaymentMethods.length > 0) {
      dispatch(setActivePaymentMethod(availablePaymentMethods[0].code));
    }
  }, [availablePaymentMethods]);

  function checkWhetherOrderCustomerCanPayOnAccount(customerId: number, orderTotal: number): void {
    if (featureFlags.OfflineModule_PayOnAccountEnabled) {
      const customerPayOnAccountCheckRequest = {
        customerId,
        orderValue: orderTotal,
        pickupType: PickUpTypesValues.pickUp,
      };

      dispatch(checkSpecifiedCustomerCanPayOnAccount(customerPayOnAccountCheckRequest));
    }
  }

  function mapOrderToTableRow(order: LocalOrder): LocalOrderRowData {
    const placingDateTime = new Date(order.orderPlacedTimestamp);
    const collectionDateTime = new Date(order.collectionTimestamp);
    return {
      ticket: order.ticketNumber,
      name: order.customerName,
      address: order.address,
      phone: order.phoneNumber,
      price: order.price,
      date: format(placingDateTime, 'dd/MM/yyyy HH:mm'),
      collection: format(collectionDateTime, 'dd/MM/yyyy HH:mm'),
      waitingTime: order.waitTime?.slice(0, 8),
      deliveryType: t(order.pickupType),
      status: t(order.orderStatus),
      syncStatus: order.orderSynchronizationStatus,
      placementMode: order.placementMode,
    };
  }

  function printClickedHandler(id: string): void {
    dispatch(postReprintOrder(id));
  }

  function cancelOrderDialogHandler(id: string): void {
    setShowDialog(true);
    setOrderId(id);
  }

  function confirmCancelOrderHandler(): void {
    setShowDialog(false);
    dispatch(
      setAlert({
        header: 'Order canceled',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      }),
    );
  }

  function finalizeClickHandler(
    id: string,
    price: number,
    ticketNumber: number,
    customerId?: number,
    deliveryType?: PickUpTypesValues,
    paymentMethodCode?: PaymentMethodCode,
  ): void {
    if (customerId) {
      checkWhetherOrderCustomerCanPayOnAccount(customerId, price);
    } else {
      dispatch(clearCustomerData());
    }
    dispatch(
      openOrderPaymentFromSettlePayment({
        successCallback: finalizeOrder,
        closeCallback: closeSettlePayment,
        totalToPay: price,
        orderId: id,
        deliveryType,
        forcedPaymentMethodCode: paymentMethodCode,
        orderPaymentDetails: {
          ticketNumber,
        },
        isLocalOrder: true,
      }),
    );
  }
  function closeSettlePayment(): void {
    dispatch(setActivePaymentMethod(undefined));
    dispatch(clearCustomerData());
    dispatch(clearSettlePayment());
  }

  async function finalizeOrder(result: PaymentSuccessfulResult) {
    const paymentMethodCode = availablePaymentMethods.find((pm) => pm.code === result.paymentMethodCode)
      ? result.paymentMethodCode
      : availablePaymentMethods[0]?.code;
    dispatch(
      finalizeOrderAction({
        finalizationOrderId: result.orderId,
        paymentMethodCode,
        eftPaymentId: result.eftPaymentOperation?.eftPaymentId,
        eftFinishedManually: result.eftPaymentOperation?.finishedManually,
        companyId: result.chargedCompany?.id,
        successCallback: () => ({}),
      }),
    );
    closeSettlePayment();
  }

  const selectRowHandler = function selectRowHandler(rowId: string): void {
    if (rowId) {
      setSelectedRow(rowId);
      return;
    }
    setSelectedRow('');
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableRows =
    ordersList?.map((order) => {
      const row = mapOrderToTableRow(order);
      return (
        <React.Fragment key={order.orderId}>
          <OrdersTableRow
            row={row}
            orderId={order.orderId}
            selectRow={(rowId): void => selectRowHandler(rowId)}
            isSelected={selectedRow === order.orderId}
            canBeFinalized={
              order.pickupType === PickUpTypesValues.pickUp &&
              order.orderStatus !== 'Completed' &&
              order.orderStatus !== 'Canceled'
            }
            onFinalize={(): void =>
              finalizeClickHandler(
                order.orderId,
                order.price,
                order.ticketNumber,
                order.customerId,
                order.pickupType,
                order.paymentMethodCode,
              )
            }
          />
          <ExpandableOrdersRow
            products={order.shortProductDescription}
            isVisible={selectedRow === order.orderId}
            printClicked={(): void => printClickedHandler(order.orderId)}
            cancelOrderClicked={(): void => cancelOrderDialogHandler(order.orderId)}
            isCancelable={order.canCancel}
          />
        </React.Fragment>
      );
    }) ?? [];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableRows.length - page * rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        {ordersFetchInProgress && (
          <div className={classes.tableBodyContent}>
            <CircularProgress size={40} />
          </div>
        )}
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">{t('Ticket nr')}</TableCell>
              <TableCell align="right">{t('Name')}</TableCell>
              <TableCell align="right">{t('Address')}</TableCell>
              <TableCell align="right">{t('Phone')}</TableCell>
              <TableCell align="right">{t('Price')}</TableCell>
              <TableCell align="right">{t('Placed at')}</TableCell>
              <TableCell align="right">{t('Collection time')}</TableCell>
              <TableCell align="right">{t('Waiting time')}</TableCell>
              <TableCell align="right">{t('Delivery type')}</TableCell>
              <TableCell align="right">{t('Status')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsPerPage > 0 ? tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableRows}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell style={{ height: 52 * emptyRows }} colSpan={10} />
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 60]}
                colSpan={10}
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={(event, newPage): void => setPage(newPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <div className={classes.legend}>
          <div className={classes.legendItem}>
            <CloudUploadIcon className={classes.direct} />
            <Typography variant="subtitle2">{t('Direct Order')}</Typography>
          </div>
          <div className={classes.legendItem}>
            <CloudUploadIcon className={classes.success} />
            <Typography variant="subtitle2">{t('Order uploaded')}</Typography>
          </div>
          <div className={classes.legendItem}>
            <CloudUploadIcon className={classes.pending} />
            <Typography variant="subtitle2">{t('Order pending')}</Typography>
          </div>
        </div>
      </TableContainer>
      <CancelOrderDialog
        isLocalOrder
        showDialog={showDialog}
        abort={(): void => setShowDialog(false)}
        orderId={orderId}
        onConfirm={confirmCancelOrderHandler}
      />
    </>
  );
};

export default OrdersTable;
