import React, { useState } from 'react';
import {
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  Popper,
  Box,
  useTheme,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import buildClasses from './FilterButton.css';

interface FilterButtonProps {
  label: string;
  options: string[];
  onItemSelected: (values: string) => void;
  testId?: string;
}

const FilterButton: React.FC<FilterButtonProps> = ({ label, options, onItemSelected, testId }) => {
  const { classes } = buildClasses();
  const { palette, spacing } = useTheme();
  // THIS WAS LEFT COMMENTED FOR MULTI OPTION SELECTION
  // const [checked, setChecked] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState<string>('');
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t] = useTranslation('orders');

  function handleDropdownCollapse(event: React.MouseEvent<HTMLButtonElement>): void {
    setIsExpanded((val) => !val);
    setAnchorEl(event.currentTarget);
  }

  const handleToggle = function handleToggle(value: string): void {
    let checkedValue = '';
    if (checked !== value) {
      checkedValue = value;
    }

    setChecked(checkedValue);
    onItemSelected(checkedValue);
    // THIS WAS LEFT COMMENTED FOR MULTI OPTION SELECTION
    // const currentIndex = checked && checked.indexOf(value);
    // const newChecked = [...checked];
    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }
    // setChecked(newChecked);
    // onItemSelected(newChecked);
  };

  const listItems = options.map((option) => {
    return (
      <ListItem
        sx={{ minWidth: 0, height: spacing(3), color: palette.primary.contrastText }}
        key={option}
        role={undefined}
        dense
        button
        onClick={(): void => handleToggle(option)}
        data-testid={`${testId}-option-${option}`}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked === option}
            tabIndex={-1}
            disableRipple
            // eslint-disable-next-line
            // @ts-ignore
            inputProps={{ 'data-testid': `${testId}-option-${option}-checkbox` }}
          />
        </ListItemIcon>
        <ListItemText id={option} primary={t(option)} sx={{ color: palette.primary.contrastText }} />
      </ListItem>
    );
  });

  return (
    <Box sx={{ display: 'block' }}>
      <Button
        onClick={handleDropdownCollapse}
        className={`${classes.collapseButton} ${isExpanded && classes.activeButton}`}
        data-testid={testId}
        ref={setRef}
      >
        {label}
        {isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Popper
        open={isExpanded}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        keepMounted
        style={{
          position: 'fixed',
          transform: ref ? `translate3d(${ref?.offsetLeft}px, ${ref?.offsetTop + ref?.clientHeight}px, 0px)` : '',
          top: '0px',
          left: '0px',
          willChange: 'transform',
        }}
      >
        {(TransitionProps): JSX.Element => (
          <Collapse in={TransitionProps.TransitionProps?.in}>
            <List className={`${classes.filterOptionsList} ${isExpanded}`}>{listItems}</List>
          </Collapse>
        )}
      </Popper>
    </Box>
  );
};

export default FilterButton;
