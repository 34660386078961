import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormControl, MenuItem, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { ArrowBack } from '@mui/icons-material';
import FirstTimeLaunchPage from 'components/Settings/SettingGroup/FirstTimeLaunchPage';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getInitialStores } from 'stores/Auth';
import buildClasses from './SelectStoreScreen.css';

export interface SelectStoreScreenProps {
  onSuccess: (storeId: number, storeName?: string) => void;
  onFailure: () => void;
  preselectedStoreId?: number;
}

const SelectStoreScreen: React.FC<SelectStoreScreenProps> = ({ onSuccess, onFailure, preselectedStoreId }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('settings');
  const [SelectedStoreId, SetSelectedStore] = useState<number | string>(preselectedStoreId ?? '');
  const { initialStores, storesLoading } = useAppSelector((state) => state.authorization);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialStores.length === 0) {
      dispatch(getInitialStores());
    }
  }, []);

  return (
    <FirstTimeLaunchPage label={t('Store settings')}>
      <Typography>{t('Selected store')}</Typography>
      <FormControl className={classes.formControl}>
        {storesLoading && <CircularProgress size={30} className={classes.selectProgress} />}
        <Select
          variant="outlined"
          value={SelectedStoreId.toString()}
          disabled={storesLoading}
          onChange={(event: SelectChangeEvent<string | number>): void => {
            SetSelectedStore(event.target.value);
          }}
          color="primary"
          data-testid="first-config__input--store"
        >
          {initialStores?.map((store) => {
            return (
              <MenuItem key={store.id} value={store.id}>
                {`${store.name} (${store.id})`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className={classes.stepButtons}>
        <RoundedButton
          secondary
          testId="back_button"
          height="4rem"
          width="20%"
          type="button"
          onClick={onFailure}
          icon={ArrowBack}
        />
        <RoundedButton
          disabled={!SelectedStoreId}
          type="button"
          testId="next_button"
          width="70%"
          height="4rem"
          onClick={(): void => {
            onSuccess(
              SelectedStoreId as number,
              initialStores?.find((store) => store.id === SelectedStoreId)?.name,
            );
          }}
        >
          {t('Next')}
        </RoundedButton>
      </div>
    </FirstTimeLaunchPage>
  );
};
export default SelectStoreScreen;
