import React, { useState } from 'react';
import { IconButton, Collapse, CardActionArea, Typography, Box } from '@mui/material';
import { DeleteOutlined, ExpandMore } from '@mui/icons-material';
import { BasketItem, ReceiptProduct } from 'typings/Basket';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import ReceiptBoundedProduct from 'components/Intake/Receipt/Item/ReceiptBoundedProduct';
import ReceiptProductDetails from 'components/Intake/Receipt/Item/ReceiptProduct/ReceiptProductDetails';
import { useAppSelector } from 'hooks/useRedux';
import { useTheme } from '@mui/system';
import ItemQuantity from './ItemQuantity/ItemQuantity';
import buildClass from './ReceiptProduct.css';
import ReceiptProductSetDetails from './ReceiptProductSet/ReceiptProductSetDetails';

interface ReceiptProductProps {
  receiptProduct: ReceiptProduct;
  removeItem: (productGuid: string) => void;
  customizeProduct: (item: BasketItem) => void;
  canRemoveItem: boolean;
  isBasketEditable?: boolean;
  changeAutoAddedItemQuantity?: (itemId: number, quantity: number) => void;
}

const ReceiptProductItem: React.FC<ReceiptProductProps> = ({
  removeItem,
  customizeProduct,
  canRemoveItem,
  receiptProduct,
  isBasketEditable = true,
  changeAutoAddedItemQuantity,
}) => {
  const { classes, cx } = buildClass();
  const { palette } = useTheme();
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const canEditItem = isBasketEditable && !receiptProduct.isAutoItem;

  const itemIsXTasty: boolean = receiptProduct.sliceCustomizations
    ? receiptProduct.sliceCustomizations.length > 1
    : false;

  const itemHasToppingCustomization: boolean = receiptProduct.sliceCustomizations
    ? receiptProduct.sliceCustomizations.length > 0 &&
      receiptProduct.sliceCustomizations.some(
        (el) =>
          (el.addedToppings && el.addedToppings.length > 0) ||
          (el.removedToppings && el.removedToppings.length > 0),
      )
    : false;

  const itemHasBoundedProducts =
    (receiptProduct.boundedProducts && receiptProduct.boundedProducts.length > 0) ?? false;

  const itemHasRemark = (!!receiptProduct.remark && receiptProduct.remark.length > 0) ?? false;

  const itemHasDetails: boolean = itemHasToppingCustomization || itemIsXTasty || itemHasRemark;

  const showExpandableDetails = itemHasDetails || itemHasBoundedProducts;

  const priceColumn = (function priceColumn(): JSX.Element | null {
    const price = receiptProduct.itemPrice.total.originalGrossValue;
    return (
      <Typography className={classes.itemTotalPrice} variant="subtitle2">
        {formatToDisplay(price)}
      </Typography>
    );
  })();

  const isProductSet = receiptProduct.selectedSetSteps && receiptProduct.selectedSetSteps.length > 0;

  return (
    <Collapse in={!!receiptProduct} data-testid="receipt-product__container" className={classes.itemWrapper}>
      <Box
        className={classes.listItem}
        data-testid={`receipt-item__container--${receiptProduct.itemId}-${receiptProduct.optionId}`}
        sx={{
          borderBottom: showDetails && showExpandableDetails ? 'none' : `1px solid ${palette.secondary.dark}`,
        }}
      >
        <CardActionArea
          data-testid="receipt-item-product__click-zone"
          className={classes.itemClickZone}
          onClick={(): void => {
            canEditItem && customizeProduct(receiptProduct);
          }}
        >
          <ItemQuantity quantity={receiptProduct.quantity} />
          <div className={classes.itemPriceDetails}>
            <div className={classes.itemDetails}>
              <Typography className={classes.itemName} variant="subtitle2" data-testid="receipt-item__label--name">
                {receiptProduct.itemName}
              </Typography>
              <Typography
                className={classes.itemDescription}
                variant="body2"
                data-testid="receipt-item__label--description"
              >
                {receiptProduct.optionName}
              </Typography>
            </div>
            <div className={classes.priceDetails} data-testid="receipt-item__label--price">
              {priceColumn}
            </div>
          </div>
        </CardActionArea>
        {showExpandableDetails && (
          <IconButton
            onClick={(): void => {
              setShowDetails(!showDetails);
            }}
            data-testid="receipt-item-product__button--show-details"
          >
            <ExpandMore className={cx(classes.rotate, { [classes.rotateActive]: showDetails })} />
          </IconButton>
        )}
        {isBasketEditable && canRemoveItem && (
          <IconButton
            onClick={(): void => {
              if (receiptProduct.isAutoItem && changeAutoAddedItemQuantity) {
                changeAutoAddedItemQuantity(receiptProduct.id, 0);
              } else {
                removeItem(receiptProduct.basketItemGuid);
              }
            }}
            className={classes.deleteIcon}
            data-testid="receipt-item-product__button--delete-product"
          >
            <DeleteOutlined />
          </IconButton>
        )}
      </Box>
      {showExpandableDetails && (
        <Collapse
          in={!!showDetails}
          data-testid="receipt-item-product__wrapper--details"
          sx={{ borderBottom: `1px solid ${palette.secondary.dark}` }}
        >
          {itemHasDetails && (
            <div className={classes.listItemDetails}>
              <div className={classes.listItemDetailsArrow} />
              {isProductSet ? (
                <ReceiptProductSetDetails receiptProductSet={receiptProduct} remark={receiptProduct.remark} />
              ) : (
                <ReceiptProductDetails
                  remark={receiptProduct.remark}
                  isXTasty={itemIsXTasty}
                  slices={receiptProduct.sliceCustomizations ?? []}
                />
              )}
            </div>
          )}

          {itemHasBoundedProducts &&
            receiptProduct.boundedProducts?.map((bp) => (
              <ReceiptBoundedProduct
                maxQuantity={receiptProduct.quantity}
                changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
                boundedProduct={bp}
              />
            ))}
        </Collapse>
      )}
    </Collapse>
  );
};

export default ReceiptProductItem;
