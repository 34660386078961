/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import isElectronApplication from 'utils/applicationContainer/ApplicationContainerUtils';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import { Box, IconButton, Typography } from '@mui/material';

const ScreenComponent: React.FC = () => {
  const [fullscreen, setFullscreen] = useState<boolean>(isFullScreen());
  const [zoomFactor, setZoomFactor] = useState<number>(getCurrentZoomFactor());
  const elem = document.documentElement;

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
    setFullscreen(true);
  }
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setFullscreen(false);
  }
  function toggleFullscreen() {
    fullscreen ? closeFullscreen() : openFullscreen();
  }
  function isFullScreen(): boolean {
    return screen.availHeight === window.innerHeight;
  }

  function zoomIn() {
    setZoom(zoomFactor + 0.1);
  }
  function zoomOut() {
    setZoom(zoomFactor - 0.1);
  }
  function setZoom(value: number) {
    if (!isElectronApplication()) return;
    const { webFrame } = window.require('electron');
    if (webFrame) {
      webFrame.setZoomFactor(value);
    }
    setZoomFactor(value);
  }

  function getCurrentZoomFactor(): number {
    if (!isElectronApplication()) return 1;
    const { webFrame } = window.require('electron');
    return webFrame.getZoomFactor();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        paddingBottom: 2,
      }}
    >
      <IconButton onClick={toggleFullscreen}>{fullscreen ? <FullscreenExit /> : <FullscreenIcon />}</IconButton>
      {isElectronApplication() && (
        <Box pl={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton onClick={zoomOut} disabled={zoomFactor <= 0.8}>
            <ZoomOut />
          </IconButton>
          {zoomFactor && <Typography variant="subtitle2">{`${(zoomFactor * 100).toFixed()}%`}</Typography>}
          <IconButton onClick={zoomIn} disabled={zoomFactor >= 1.2}>
            <ZoomIn />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ScreenComponent;
