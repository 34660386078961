import React from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { AppRoute } from 'App/Routes/routes-list';

interface AppMenuItemProps {
  route: AppRoute;
  isActive: boolean;
  menuItemClicked: () => void;
  routeActivationHandler: (value: number, moduleIdentifier: string) => void;
  testId: string;
}

export default function AppMenuItem({
  route,
  isActive,
  menuItemClicked,
  routeActivationHandler,
  testId,
}: AppMenuItemProps): JSX.Element {
  const { palette } = useTheme();
  const [t] = useTranslation('common');

  const menuItemClickedHandler = (): void => {
    routeActivationHandler(route.routeId, route.moduleIdentifier ?? 'offline-pos');
    menuItemClicked();
  };

  return (
    <MenuItem
      component={Link}
      to={route.path}
      onClick={menuItemClickedHandler}
      selected={isActive}
      sx={{
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
      }}
      data-testid={testId}
    >
      <ListItemIcon sx={{ mr: 5, ml: 2 }}>
        <route.icon />
      </ListItemIcon>
      <ListItemText primary={t(route.navbarName)} sx={{ '& span': { fontWeight: 700 } }} />
    </MenuItem>
  );
}
