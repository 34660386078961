import React from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useRedux';
import { submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { OrderFilter, resetTableOptions } from 'stores/AllOrders';

interface FiltersChipsOverviewProps {
  availableFilters: OrderFilter[];
}

const FiltersChipsOverview: React.FC<FiltersChipsOverviewProps> = ({ availableFilters }) => {
  const { spacing, palette } = useTheme();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const appliedFilters = watch();
  const dispatch = useAppDispatch();

  // skip first 3 as we do not want to watch those.
  const filterKeys = Object.keys(appliedFilters).slice(3);
  const filterValues = Object.values(appliedFilters).slice(3);

  function getChipValue(filterName: string, filterValue: string | string[]): JSX.Element {
    if (Array.isArray(filterValue)) {
      const remainingItems = filterValue.length - 1;
      return (
        <Typography variant="body1" sx={{ display: 'flex' }} color="black.main" component="div">
          {`${t(filterName)}: ${t(getFilterValueName(filterName, filterValue[0]))}`}
          {remainingItems > 0 && (
            <Box
              sx={{
                backgroundColor: palette.grey[200],
                padding: `${spacing(0)} ${spacing(1)}`,
                borderRadius: spacing(1.5),
                marginLeft: spacing(1),
              }}
            >{`+${remainingItems}`}</Box>
          )}
        </Typography>
      );
    }
    return (
      <Typography variant="body1" color="black.main">{`${t(filterName)}: ${t(
        getFilterValueName(filterName, filterValue),
      )}`}</Typography>
    );
  }

  function getFilterValueName(filterName: string, filterValue: string): string {
    const foundFilter = availableFilters
      .find((el) => el.name === filterName)
      ?.type.options?.find((el) => el.key === filterValue);
    return foundFilter?.displayName ?? filterValue;
  }

  return (
    <Box p={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {filterKeys.map((fk, index) => {
        if (!filterValues[index] || filterValues[index].length === 0) return null;
        return (
          <Chip
            key={fk}
            sx={{
              marginRight: 1,
            }}
            data-testid={`all-orders-table__filters-chip-${fk}`}
            label={<Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>{getChipValue(fk, filterValues[index])}</Box>}
            variant="outlined"
            onDelete={() => {
              dispatch(resetTableOptions());
              const isArray = Array.isArray(filterValues[index]);
              setValue(fk, isArray ? [] : '');
              submitAllOrdersForm();
            }}
          />
        );
      })}
    </Box>
  );
};

export default FiltersChipsOverview;
