import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

interface GroupProps {
  label: string;
}

const FirstTimeLaunchPage: React.FC<GroupProps> = ({ label, children }) => {
  const { palette, spacing } = useTheme();

  return (
    <div>
      <Typography sx={{ color: palette.primary.contrastText }} variant="h4" align="center">
        {label}
      </Typography>
      <Box
        sx={{
          background: 'white',
          borderRadius: spacing(3),
          boxShadow: `0 2px ${spacing(0.5)} 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: '70%',
          margin: `${spacing(0.5)} auto`,
          padding: spacing(3),
          textAlign: 'start',
          borderBottom: `1px solid ${palette.primary.contrastText}`,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default FirstTimeLaunchPage;
