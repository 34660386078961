import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    dialogForm: {
      overflowY: 'auto',
      padding: `${spacing(2.5)} ${spacing(3)}`,
    },
  };
});
export default buildClasses;
