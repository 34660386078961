import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, IconButton, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CashPayment from 'components/Shared/OrderPayment/PaymentMethods/CashPayment';
import { FormProvider, useForm } from 'react-hook-form';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { PaymentMethodCode } from 'typings/Payments';
import { getActivePayment, getIsAnyOrderActionPending } from 'stores/OrderPayment/orderPayment.selector';
import { canCloseEftModal, getEFTPaymentStatus } from 'stores/EftPayment/eftPayment.selector';
import { manualExitOrderPayment } from 'stores/OrderPayment/orderPayment.actions';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import FinalizeFullyPaid from 'components/Shared/OrderPayment/FinalizeFullyPaid';
import CardPayment from 'components/Shared/OrderPayment/PaymentMethods/CardPayment';
import GenericPaymentMethod from 'components/Shared/OrderPayment/PaymentMethods/GenericPaymentMethod';
import PaymentMethodsGrouped from './PaymentMethodsGrouped';
import GiftCardQrPayment from './PaymentMethods/GiftCardQrPayment';

export interface OrderPaymentForm {
  cashInput: string;
  tipInput: string;
}

const OrderPayment: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { spacing, palette } = useTheme();
  const { details, isPaid, selectedPaymentMethodGroupName } = useAppSelector(({ orderPayment }) => orderPayment);

  const eftStatus = useAppSelector(getEFTPaymentStatus);
  const activePayment = useAppSelector(getActivePayment);
  const isAnyPaymentActionPending = useAppSelector(getIsAnyOrderActionPending);
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  const canCloseEft = useAppSelector(canCloseEftModal());
  const hideCloseIcon = !canCloseEft;

  const orderPaymentForm = useForm<OrderPaymentForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      cashInput: formatToDisplay(activePayment?.toPayAmount ?? 0, true, false),
      tipInput: formatToDisplay(activePayment?.tipValue ?? 0, true, false),
    },
  });

  const closeClicked = (): void => {
    if (isAnyPaymentActionPending) return;
    if (eftStatus === 'PENDING' || !canCloseEft) return;
    dispatch(manualExitOrderPayment());
  };

  const isEftPaymentSelected =
    activePayment?.paymentMethod?.supportsEftPayment === true &&
    activePayment.paymentMethod.code !== PaymentMethodCode.GiftcardQr;
  const isCashPaymentSelected = activePayment?.paymentMethod?.code === PaymentMethodCode.Cash;
  const isGiftCardQrPaymentSelected =
    activePayment?.paymentMethod?.supportsEftPayment === true &&
    activePayment.paymentMethod.code === PaymentMethodCode.GiftcardQr;

  function getOrderHeader(): string {
    let header = t('Order payment');
    if (details) {
      header += ` ${t('for ticket nr')} ${details.ticketNumber}`;
      if (details.tableName) {
        header += ` (${t('Table')}: '${details.tableName}')`;
      }
      if (details.orderReference) {
        header += ` (${t('Order reference')}: '${details.orderReference}')`;
      }
    }

    return header;
  }

  useEffect(() => {
    if (activePayment && activePayment.toPayAmount > 0) {
      orderPaymentForm.setValue('cashInput', formatToDisplay(activePayment?.toPayAmount ?? 0, true, false));
    }
  }, [activePayment?.toPayAmount]);

  return (
    <Dialog
      onClose={closeClicked}
      open
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <FormProvider {...orderPaymentForm}>
        <form autoComplete="off" name="orderPaymentForm" id="orderPaymentForm">
          <Box sx={{ display: 'flex', py: 2, px: 3, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ color: palette.black.main }}>
              {getOrderHeader()}
            </Typography>
            {!hideCloseIcon && (
              <IconButton
                onClick={closeClicked}
                disableRipple
                sx={{
                  color: 'black',
                  fontSize: spacing(2.5),
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            {isAnyPaymentActionPending && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  zIndex: 2000,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <Typography variant="h5" sx={{ mr: 2 }} color="black.main">
                  {t('Payment in progress')}
                </Typography>
                <JumpingDotsLoader />
              </Box>
            )}
            <Box
              sx={{
                filter: isAnyPaymentActionPending ? 'blur(5px)' : 'none',
              }}
            >
              {selectedPaymentMethodGroupName ? (
                <PaymentMethodsGrouped />
              ) : (
                <>
                  {isEftPaymentSelected && <CardPayment />}
                  {isCashPaymentSelected && <CashPayment />}
                  {isGiftCardQrPaymentSelected && <GiftCardQrPayment />}
                  {!isEftPaymentSelected && !isCashPaymentSelected && !isGiftCardQrPaymentSelected && (
                    <GenericPaymentMethod />
                  )}
                </>
              )}
              {!activePayment && isPaid && <FinalizeFullyPaid />}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default OrderPayment;
