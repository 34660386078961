import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(() => {
  return {
    externalServicePanelHeader: {
      margin: '10px 0px 0px 0px',
    },
  };
});

export default buildClasses;
