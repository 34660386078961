import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { EftPaymentOperation, EftPaymentState, EftPaymentStatus } from 'typings/EftPayment/eftPayment.types';

export const ErrorResponseStatuses = [
  EftPaymentStatus.Failed,
  EftPaymentStatus.FailedToStart,
  EftPaymentStatus.TimedOut,
  EftPaymentStatus.Abandoned,
  EftPaymentStatus.Cancelled,
];
export const PendingResponseStatuses = [
  EftPaymentStatus.CancellationRequested,
  EftPaymentStatus.PendingToStart,
  EftPaymentStatus.Started,
];
const SuccessResponseStatuses = [EftPaymentStatus.Successful, EftPaymentStatus.Accepted];

export const getEftState = ({ eftPayment }: RootState): EftPaymentState => eftPayment;

export const getCurrentlyPossibleOperations = ({ eftPayment }: RootState): EftPaymentOperation[] => {
  if (eftPayment.startError) {
    return [EftPaymentOperation.RetryStart, EftPaymentOperation.Exit];
  }
  if (eftPayment.eftPaymentId) {
    if (eftPayment.eftStatusResponse) {
      return eftPayment.eftStatusResponse.possibleOperations;
    }
  }
  return [];
};

export const getEFTPaymentStatus = ({ eftPayment }: RootState): 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR' => {
  if (eftPayment.startError) return 'ERROR';
  if (ErrorResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'ERROR';
  if (SuccessResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'SUCCESS';
  if (PendingResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'PENDING';
  if (eftPayment.startInProgress || (eftPayment.eftPaymentId && !eftPayment.startError)) return 'PENDING';
  return 'IDLE';
};

export const canCloseEftModal = () =>
  createSelector([getCurrentlyPossibleOperations, getEftState], (possibleEftOperations, eftState) => {
    if (!eftState.eftPaymentId) return true;
    if (possibleEftOperations.some((el) => el === EftPaymentOperation.Exit)) return true;
    return false;
  });
