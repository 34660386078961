import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, breakpoints, spacing }) => {
  return {
    cardRoot: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: sizing.productCard.height,
      position: 'relative',
      width: `calc(${100 / sizing.productCard.lineCount.md}% - ${spacing(1)})`,
      [breakpoints.down('md')]: {
        width: `calc(${100 / sizing.productCard.lineCount.xs}% - ${spacing(1)})`,
      },
      [breakpoints.up('xl')]: {
        width: `calc(${100 / sizing.productCard.lineCount.xl}% - ${spacing(1)})`,
      },
    },
    noItemsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: `calc(100vh - ${sizing.appBarHeight}px - ${sizing.subNavBarsHeight}px - 206px)`,
    },
    noItemsIcon: {
      fontSize: '50px',
    },
    swiper: {
      overflowY: 'auto',
      display: 'flex !important',
      flexDirection: 'column',
      flex: '1',
      height: '100%',
      width: '100%',
    },
    swiperSlide: {
      height: 'fit-content !important',
      display: 'flex !important',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
    swiperWrapper: {
      flex: 1,
      position: 'relative',
      width: ' 100%',
      zIndex: 1,
      display: 'flex !important',
      transitionProperty: 'transform',
      transitionTimingFunction: 'var(--swiper-wrapper-transition-timing-function,initial)',
      boxSizing: 'content-box',
    },
  };
});
export default buildClasses;
