import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import { setCustomizeProduct } from 'stores/Products';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ProductsView from 'containers/Intake/Products/ProductsView/ProductsView';
import { Category, SubOption, Option, Group } from 'typings/Products';
import ProductCustomization from './ProductCustomization/ProductCustomization';

const areProductsLoaded = (
  categories: Category[],
  options: Option[],
  subOptions: SubOption[],
  groups: Group[],
): boolean => {
  return categories?.length > 0 && options?.length > 0 && subOptions?.length > 0 && groups?.length > 0;
};

interface ProductContainerProps {
  onHideReceipt?: () => void;
  onShowReceipt?: () => void;
}

const ProductsContainer: React.FC<ProductContainerProps> = ({ onHideReceipt, onShowReceipt }) => {
  const { categories, options, subOptions, groups, productToCustomize } = useAppSelector(
    (state) => state.products,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (productToCustomize) {
      if (onHideReceipt) onHideReceipt();
      if (onShowReceipt) onShowReceipt();
    }
  }, [productToCustomize]);

  if (!areProductsLoaded(categories, options, subOptions, groups)) return <PartialLoader />;

  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      {productToCustomize ? (
        <ProductCustomization
          productToCustomize={productToCustomize}
          onCancelClick={() => {
            dispatch(setCustomizeProduct());
          }}
        />
      ) : (
        <ProductsView />
      )}
    </Box>
  );
};

export default ProductsContainer;
