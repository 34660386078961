import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, TextField, Typography } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTranslation } from 'react-i18next';
import {
  updateOnsiteStoreId,
  registerStoreWithData,
  hardLogout,
  getMachineProfiles,
  clearCreatingMachine,
} from 'stores/Auth';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Fade from '@mui/material/Fade';
import uuidGenerator from 'utils/GuidGenerator';
import { MachineProfile } from 'typings/Auth';
import { getDateTimeFormatter } from 'stores/Payments/payment.selector';
import CreateMachineProfile from 'containers/Authorize/CreateMachineProfile';
import { StoreConfiguration } from 'typings/Store';
import { ErrorOutlineOutlined } from '@mui/icons-material';

const LoginStoreSelection = () => {
  const [t] = useTranslation('authentication');
  const [selectedStore, setSelectedStore] = useState<StoreConfiguration>();
  const [selectedMachine, setSelectedMachine] = useState<MachineProfile>();
  const [selectionPage, setSelectionPage] = useState<'select-store' | 'select-machine' | 'create-machine'>(
    'select-store',
  );

  const [showDevProfiles, setShowDevProfiles] = useState<boolean>(false);

  const { initialStores, storesLoading, machineProfiles, machinesLoaded } = useAppSelector(
    (state) => state.authorization,
  );

  const formatDateTime = useAppSelector(getDateTimeFormatter());
  const dispatch = useAppDispatch();

  function handleConfirmStoreAndTouchPoint(): void {
    if (selectedStore && selectedMachine) {
      dispatch(
        registerStoreWithData({
          storeId: selectedStore.id as number,
          touchpointId: selectedMachine?.touchpointId ?? uuidGenerator(),
        }),
      );
      dispatch(updateOnsiteStoreId(selectedStore.id as number));
    }
  }

  function handleLogout(): void {
    dispatch(hardLogout());
  }

  function handleBackButtonClicked(): void {
    selectionPage === 'select-machine' && setSelectionPage('select-store');
    if (selectionPage === 'create-machine') {
      dispatch(clearCreatingMachine());
      setSelectionPage('select-machine');
    }
  }

  function handleDevShortcut(e: KeyboardEvent) {
    if (e?.key === 'ArrowDown' && e?.ctrlKey) {
      setShowDevProfiles(!showDevProfiles);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleDevShortcut);

    return () => {
      document.removeEventListener('keydown', handleDevShortcut);
    };
  }, [showDevProfiles]);

  return (
    <div>
      {selectionPage === 'select-store' && (
        <Fade in unmountOnExit timeout={{ enter: 700 }}>
          <Box>
            <Typography variant="h2">{t('Hello.')}</Typography>
            <Typography variant="h5">{t('Select your store to start')}</Typography>
            {initialStores.length === 0 && storesLoading ? (
              <Box sx={{ mt: 5, display: 'flex' }}>
                <JumpingDotsLoader />
                <Typography sx={{ ml: 1 }} variant="subtitle1">
                  {t('Getting stores...')}
                </Typography>
              </Box>
            ) : (
              <Autocomplete
                sx={{ mt: 5, maxHeight: '30vh' }}
                fullWidth
                options={initialStores}
                key={selectedStore?.id}
                defaultValue={selectedStore}
                autoHighlight
                disableClearable
                data-testid="first-config__input--store"
                getOptionLabel={(store) => store.name}
                renderOption={(props, store) => (
                  <Box
                    component="li"
                    {...props}
                    onClick={(event) => {
                      setSelectedStore(store);
                      setSelectedMachine(undefined);
                      dispatch(getMachineProfiles(store.id as number));
                      props.onClick && props.onClick(event);
                    }}
                  >
                    {store.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('Your store')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              onClick={() => {
                setSelectionPage('select-machine');
              }}
              disabled={!selectedStore}
              fullWidth
            >
              {t('Continue')}
            </Button>
          </Box>
        </Fade>
      )}
      {selectionPage === 'select-machine' && (
        <Fade in unmountOnExit timeout={{ enter: 500 }}>
          <Box>
            <Button
              sx={{ mb: 3 }}
              variant="text"
              color="black"
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                handleBackButtonClicked();
              }}
            >
              {t('Back')}
            </Button>

            {machinesLoaded === false && (
              <Box sx={{ mt: 5, display: 'flex' }}>
                <JumpingDotsLoader />
                <Typography sx={{ ml: 1 }} variant="subtitle1">
                  {t('Getting machine profiles...')}
                </Typography>
              </Box>
            )}

            {machinesLoaded === true && machineProfiles.length === 0 && (
              <Typography variant="h5">{t('There are no machines for this store yet')}</Typography>
            )}

            {machineProfiles.length > 0 && (
              <>
                <Typography variant="h5">{t('Select your machine profile')}</Typography>
                {showDevProfiles && (
                  <Typography sx={{ mt: 2 }} variant="h5" gutterBottom data-testid="development-machine-alert">
                    <Alert severity="warning" icon={<ErrorOutlineOutlined fontSize="inherit" />}>
                      {t('Development usage machines are visible')}
                    </Alert>
                  </Typography>
                )}
                <Autocomplete
                  sx={{ mt: 5, maxHeight: '30vh' }}
                  fullWidth
                  options={
                    showDevProfiles ? machineProfiles : machineProfiles.filter((mp) => !mp.isDevelopmentMachine)
                  }
                  autoHighlight
                  key={selectedMachine?.touchpointId}
                  defaultValue={selectedMachine}
                  disableClearable
                  placeholder={t('Machines list')}
                  data-testid="first-config__input--machine"
                  getOptionLabel={(machine) => machine.machineName as string}
                  renderOption={(props, machine) => (
                    <Box
                      component="li"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      {...props}
                      onClick={(event) => {
                        setSelectedMachine(machine);
                        props.onClick && props.onClick(event);
                      }}
                    >
                      <Typography variant="body1" alignSelf="start" color="black.main" noWrap>
                        {machine.machineName ?? machine.touchpointId}
                      </Typography>
                      <Typography variant="body2" alignSelf="start">
                        {`${t('Last activity')}: ${formatDateTime(new Date(machine.lastActivity))}`}
                        {machine.isDevelopmentMachine && ` <${t('DEVELOPMENT')}>`}
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('Your machine')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </>
            )}
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 4 }}
              onClick={() => {
                setSelectionPage('create-machine');
              }}
              fullWidth
            >
              {t('Create new machine')}
            </Button>
            {machinesLoaded && machineProfiles.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
                onClick={() => handleConfirmStoreAndTouchPoint()}
                disabled={!selectedStore || !selectedMachine}
                fullWidth
              >
                {t('Confirm & Continue')}
              </Button>
            )}
          </Box>
        </Fade>
      )}
      {selectionPage === 'create-machine' && selectedStore?.id && (
        <Fade in unmountOnExit timeout={{ enter: 500 }}>
          <Box>
            <Button
              sx={{ mb: 3 }}
              variant="text"
              color="black"
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                handleBackButtonClicked();
              }}
            >
              {t('Back')}
            </Button>
            <CreateMachineProfile
              storeId={selectedStore?.id}
              onMachineCreated={(createdMachine) => {
                setSelectionPage('select-machine');
                const newMachine = machineProfiles.find((el) => el.touchpointId === createdMachine.touchpointId);
                newMachine && setSelectedMachine(newMachine);
                dispatch(clearCreatingMachine());
              }}
            />
          </Box>
        </Fade>
      )}

      {initialStores.length === 0 && !storesLoading ? (
        <Button
          data-testid="first-config__logout"
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={handleLogout}
          fullWidth
        >
          {t('Logout')}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LoginStoreSelection;
