import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getStringToNumberFormatter, getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { getActivePayment } from 'stores/OrderPayment/orderPayment.selector';
import { motion } from 'framer-motion';
import {
  setActivePaymentCustomerPaysWith,
  setActivePaymentStep,
  setActivePaymentTip,
} from 'stores/OrderPayment/orderPayment.slice';
import { PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import { getShowTippingModule } from 'stores/Config/config.selector';

const AdjustTipButtons: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { changeAmount, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const activePayment = useAppSelector(getActivePayment);
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter());
  const tippingModuleAvailable = useAppSelector(getShowTippingModule);
  const { watch, setValue } = useFormContext<OrderPaymentForm>();
  const tipInputValue = watch('tipInput');
  const tipInputNumberValue = formatStringToNumber(tipInputValue);
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);

  if (!activePayment) {
    return null;
  }

  const showTipping = tippingModuleAvailable && cashInputNumberValue > totalLeftAmount;

  return (
    <motion.div
      style={{ position: 'absolute', bottom: '0', width: '100%' }}
      key="confirm"
      variants={PaymentModalAnimations.variants}
      initial="slideUp"
      animate="onPlace"
      exit="slideUp"
      transition={PaymentModalAnimations.transition}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: 2,
        }}
      >
        <Button
          onClick={() => {
            dispatch(setActivePaymentStep('confirm'));
          }}
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          sx={{ py: 2, px: 3 }}
          data-testid="tip_back-button"
        >
          {t('Go back')}
        </Button>

        <Button
          onClick={() => {
            if (changeAmount && changeAmount < 0) {
              const newReceived = cashInputNumberValue + -changeAmount;
              setValue('cashInput', formatToDisplay(newReceived, true, false));
              dispatch(setActivePaymentCustomerPaysWith(newReceived));
            }
            dispatch(setActivePaymentTip(tipInputNumberValue));
            dispatch(setActivePaymentStep('confirm'));
          }}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ py: 2, px: 3 }}
          data-testid="tip_confirmation-button"
        >
          {t('Confirm')}
        </Button>
      </Box>
    </motion.div>
  );
};
export default AdjustTipButtons;
