import React, { ReactNode } from 'react';
import { Button, useTheme } from '@mui/material';

interface OutlinedButtonProps {
  children: string;
  icon?: ReactNode;
  onClickFn(): void;
  testId: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  children,
  icon,
  onClickFn,
  testId,
  fullWidth = false,
  disabled = false,
}) => {
  const { palette, spacing } = useTheme();

  return (
    <Button
      data-testid={testId}
      startIcon={icon}
      onClick={onClickFn}
      variant="outlined"
      sx={{
        height: 'fit-content',
        width: fullWidth ? '100%' : 'auto',
        padding: `${spacing(1)} ${spacing(1.5)}`,
        color: palette.black.main,
        borderColor: palette.customGray.dark,
        '&:hover': {
          color: palette.black.main,
          borderColor: palette.customGray.dark,
          backgroundColor: 'white',
        },
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default OutlinedButton;
