import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(() => {
  return {
    stage: {
      backgroundSize: 'contain',
      backgroundColor: '#f4f4f4',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  };
});

export default buildClasses;
