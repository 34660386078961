import React from 'react';
import TextFieldDialog from 'components/Shared/TextFieldDialog/TextFieldDialog';
import { useAppDispatch } from 'hooks/useRedux';
import { cancelOrder } from 'stores/AllOrders';
import { cancelLocalOrder } from 'stores/LocalOrders';

interface CancelOrderDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: () => void;
  isLocalOrder: boolean;
}

const CancelOrderDialog: React.FC<CancelOrderDialogProps> = ({
  showDialog,
  orderId,
  abort,
  onConfirm,
  isLocalOrder,
}) => {
  const formId = `cancellation-form-order-${orderId}`;
  const dispatch = useAppDispatch();
  function onFormSubmit(reason: string) {
    isLocalOrder
      ? dispatch(cancelLocalOrder({ reason, orderId, successCallback: () => onConfirm() }))
      : dispatch(cancelOrder({ reason, orderId, successCallback: () => onConfirm() }));
  }

  return (
    <TextFieldDialog
      showDialog={showDialog}
      onClose={abort}
      onSubmit={onFormSubmit}
      i18NameSpace="orders"
      errorMessageKey="Reason required"
      headerKey="Cancellation reason"
      formId={formId}
      maxLength={500}
      placeholder="Reason"
    />
  );
};

export default CancelOrderDialog;
