import { Chip, Paper, Tooltip, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SliceCustomization } from 'typings/Products';
import { CommentOutlined } from '@mui/icons-material';
import buildClass from './ReceiptProductDetails.css';
import ReceiptProductToppings from './ReceiptProductToppings';

interface ReceiptProductDetailsProps {
  slices: SliceCustomization[];
  remark?: string;
  isXTasty: boolean;
}

const ReceiptProductDetails: React.FC<ReceiptProductDetailsProps> = ({ slices, remark, isXTasty }) => {
  const { classes } = buildClass();
  const { palette, spacing, radius } = useTheme();
  const [t] = useTranslation('intake');
  const firstSlice = slices.length > 0 ? slices[0] : undefined;
  const hasCustomDetails =
    isXTasty ||
    (firstSlice &&
      ((firstSlice.addedToppings && firstSlice.addedToppings.length > 0) ||
        (firstSlice.removedToppings && firstSlice.removedToppings.length > 0)));

  return (
    <>
      <Paper>
        {hasCustomDetails &&
          slices.map((slice, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${slice.sliceProductId}`}
              data-testid="receipt-item--item-details"
              sx={{
                padding: `${spacing(1)}`,
                borderBottom: `1px solid ${palette.secondary.dark}`,
                '&:last-child': {
                  borderBottom: 'none',
                },
              }}
            >
              {isXTasty && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle2">{slice.sliceProductName}</Typography>
                  {isXTasty && <Chip label={`${t('part')} ${index + 1}`} size="small" />}
                </Box>
              )}
              <ReceiptProductToppings
                addedToppings={slice.addedToppings}
                removedToppings={slice.removedToppings}
              />
            </Box>
          ))}
      </Paper>
      {hasCustomDetails && remark && <Box sx={{ marginRight: spacing(1), color: palette.blue[300] }} />}
      {remark && (
        <Paper>
          <Tooltip title={<span className={classes.remarkTooltip}>{remark}</span>} placement="top">
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '100px',
                wordBreak: 'break-word',
                padding: spacing(1),
                display: 'flex',
                border: `1px solid ${palette.blue[300]}`,
                borderRadius: radius.sm,
              }}
            >
              <CommentOutlined sx={{ marginRight: spacing(1), color: palette.blue[300] }} />
              <Typography variant="body2">{remark}</Typography>
            </Box>
          </Tooltip>
        </Paper>
      )}
    </>
  );
};

export default ReceiptProductDetails;
