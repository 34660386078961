import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Chip, Paper, Box, useTheme } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { CustomerSearchResultItem } from 'stores/Intake/intakeTypes';
import { getDateFormatter, getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';
import buildClasses from './CustomerResultItem.css';
import CustomerResultItemFooterInfo from './CustomerResultItemFooterInfo';

interface CustomerResultItemProps {
  customer: CustomerSearchResultItem;
  onCustomerSelected: (customer: CustomerSearchResultItem) => void;
}

const CustomerResultItem: React.FC<CustomerResultItemProps> = ({ customer, onCustomerSelected }) => {
  const { classes } = buildClasses();
  const { spacing } = useTheme();

  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const formatToDate = useAppSelector(getDateFormatter());

  const [tIntake] = useTranslation('intake');
  const [tCommon] = useTranslation('common');
  const testId = `intake-customer-search_result-item--${customer.id}`;

  function prepareTimeElapsedDisplayValue(): string | undefined {
    if (!customer.timeElapsedSinceLastOrder) {
      return undefined;
    }

    return `${customer.timeElapsedSinceLastOrder.value} ${tCommon(
      customer.timeElapsedSinceLastOrder.unit.toString(),
    )} ${tCommon('Ago')}`;
  }

  function prepareLastOrderDateDisplayValue(): string | undefined {
    if (!customer.lastOrderDate) {
      return undefined;
    }

    return formatToDate(new Date(customer.lastOrderDate));
  }

  function valueOrDefault(value: string | undefined | null, defaultValue: string): string {
    return value ?? defaultValue;
  }

  const timeElapsedDisplayValue = prepareTimeElapsedDisplayValue();
  const lastOrderDateDisplayValue = prepareLastOrderDateDisplayValue();

  function onCustomerClicked(): void {
    onCustomerSelected(customer);
  }

  const customerName = `${valueOrDefault(customer.firstname, '-')} ${valueOrDefault(customer.lastname, '-')}`;

  return (
    <Box sx={{ marginRight: spacing(2.5) }}>
      <Paper
        data-testid={testId}
        elevation={3}
        sx={{
          width: '100%',
          overflowX: 'auto',
          margin: spacing(1),
          background: '#FFFFFF',
          borderRadius: '2px',
          transition: 'all .25s linear',
          boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.255)',
          '&:hover': {
            boxShadow: '-1px 5px 29px 0px rgba(0,0,0,0.255)',
            cursor: 'pointer',
          },
        }}
        onClick={onCustomerClicked}
      >
        <Box sx={{ display: 'inline-flex', marginTop: spacing(0.5) }}>
          <AccountCircleIcon style={{ fontSize: 60, marginRight: '10px' }} />
          <div>
            <span
              data-testid={`intake-customer-search-result_label--full-name-${customer.id}`}
              className={classes.customerName}
            >
              {customerName}
            </span>
            <Chip
              data-testid={`intake-customer-search-result_label--phone-number-${customer.id}`}
              sx={{ marginLeft: spacing(1), marginBottom: spacing(0.5) }}
              size="small"
              icon={<PhoneIcon fontSize="small" />}
              label={customer.phoneNumber}
            />
            {customer.profileAddress?.fullAddress && (
              <Chip
                data-testid={`intake-customer-search-result_label--address-${customer.id}`}
                sx={{ marginLeft: spacing(1), marginBottom: spacing(0.5) }}
                size="small"
                icon={<HomeIcon fontSize="small" />}
                label={customer.profileAddress?.fullAddress}
              />
            )}

            <br />
            <Chip
              data-testid={`intake-customer-search-result_label--email-${customer.id}`}
              sx={{ marginRight: spacing(1), marginBottom: spacing(0.5) }}
              size="small"
              icon={<EmailIcon fontSize="small" />}
              label={customer.username}
            />
            {lastOrderDateDisplayValue && (
              <CustomerResultItemFooterInfo
                title={tIntake('Last order')}
                value={lastOrderDateDisplayValue}
                testId={`intake-customer-search-result_label--last-order-date-${customer.id}`}
              />
            )}
          </div>
        </Box>
        <br />
        <Box
          sx={{
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            marginBottom: spacing(0.5),
            marginLeft: spacing(2),
            fontWeight: 'bold',
          }}
        >
          <CustomerResultItemFooterInfo
            title={tIntake('Average order value')}
            value={formatToDisplay(customer.averageOrderPrice)}
            testId={`intake-customer-search-result_label--average-order-value-${customer.id}`}
          />
          <CustomerResultItemFooterInfo title={tIntake('Number of orders')} value={customer.numberOfOrdersTotal} />
          <CustomerResultItemFooterInfo
            title={tIntake('This month')}
            value={customer.numberOfOrdersCurrentMonth}
            testId={`intake-customer-search-result_label--number-of-orders-this-month-${customer.id}`}
          />
          {timeElapsedDisplayValue && (
            <CustomerResultItemFooterInfo
              title={tIntake('Last order')}
              value={timeElapsedDisplayValue}
              testId={`intake-customer-search-result_label--time-since-last-order-${customer.id}`}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomerResultItem;
