import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';
import { canEditBasket } from 'stores/Intake/intake.selector';
import { BasketSummary } from 'typings/Basket';
import { Box } from '@mui/system';

interface CustomerDisplayBasketTotalProps {
  summary: BasketSummary | undefined;
}

const CustomerDisplayBasketTotal: React.FC<CustomerDisplayBasketTotalProps> = ({ summary }) => {
  const [t] = useTranslation('customerDisplay');
  const basketEditable = useAppSelector(canEditBasket);
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  const deliveryCharge = summary?.deliveryCharge ?? 0;
  const deliveryChargeVisible = deliveryCharge !== 0;
  const discountVisible = summary !== undefined && summary.discount !== 0;

  const shouldShowDeliveryCharge = deliveryChargeVisible || !basketEditable;

  const total = getTotal();
  const subtotal = getSubtotal();

  const { palette, spacing } = useTheme();

  function getTotal(): number {
    if (!summary) return 0;

    if (shouldShowDeliveryCharge || !deliveryCharge) return summary.total;

    return summary.total - summary.deliveryCharge;
  }

  function getSubtotal(): number {
    if (!summary) return 0;

    if (shouldShowDeliveryCharge || !deliveryCharge) return summary.subtotal;

    return summary.subtotal - deliveryCharge;
  }

  return (
    <Box
      sx={{
        backgroundColor: palette.white,
        position: 'sticky',
        width: '100%',
        bottom: 0,
        marginTop: 'auto',
      }}
    >
      <Box
        sx={{
          padding: `${spacing(1)} ${spacing(2)}`,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Box>
          {shouldShowDeliveryCharge && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body2">{t('Delivery charge')}</Typography>
              <Typography
                variant="body2"
                data-testid="customer-display-receipt-footer__label--delivery-charge-value"
              >
                {deliveryCharge && formatToDisplay(deliveryCharge)}
              </Typography>
            </Box>
          )}
          {discountVisible && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body2">{t('Discount')}</Typography>
              <Typography variant="body2" data-testid="customer-display-receipt-footer__label--discount-value">
                {formatToDisplay(summary?.discount ?? 0)}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2">{t('Subtotal')}</Typography>
            <Typography variant="body2" data-testid="customer-display-receipt-footer__label--subtotal-value">
              {formatToDisplay(summary ? subtotal : 0)}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2">{t('Tax')}</Typography>
            <Typography variant="body2" data-testid="customer-display-receipt-footer__label--tax-value">
              {formatToDisplay(summary?.totalVat ?? 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: `${spacing(2)} ${spacing(3)} ${spacing(2)} ${spacing(3)}`,
        }}
      >
        <Box>
          <Box
            sx={{
              width: '100%',
              color: palette.black.main,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5">{t('Total')}</Typography>
            <Typography variant="h4" data-testid="customer-display-receipt-footer__label--total-value">
              {formatToDisplay(summary ? total : 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDisplayBasketTotal;
