import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    label: {
      color: palette.text.primary,
      fontSize: '1.25rem',
    },
  };
});

export default buildClasses;
