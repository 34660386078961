import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dateFnsLng from 'utils/i18n/dateFnsLang';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTheme } from '@mui/material';

interface FilterDatePickerProps {
  onDateChange: (date: Date) => void;
  minFilterDate?: Date;
  maxFilterDate?: Date;
  testId?: string;
  label?: string;
}

const FilterDatePicker: React.FC<FilterDatePickerProps> = ({
  onDateChange,
  minFilterDate,
  maxFilterDate,
  testId,
  label,
}) => {
  const { spacing, sizing } = useTheme();

  const [selectedDate, setSelectedDate] = useState(new Date());

  function handleDateChange(newDate: Date): void {
    if (maxFilterDate && newDate > maxFilterDate) return;
    if (minFilterDate && newDate < minFilterDate) return;
    // eslint-disable-next-line no-restricted-globals
    if (newDate && !isNaN(newDate.getTime())) {
      setSelectedDate(newDate);
      onDateChange(newDate);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLng()}>
      <div data-testid={testId}>
        <DesktopDatePicker
          label={label}
          sx={{ width: sizing.filterDatePickerWidth, marginRight: spacing(1) }}
          value={selectedDate}
          onChange={(date, _): void => {
            handleDateChange(date as Date);
          }}
          minDate={minFilterDate}
          maxDate={maxFilterDate}
          format="dd/MM/yyyy"
        />
      </div>
    </LocalizationProvider>
  );
};

export default FilterDatePicker;
