import React, { useState } from 'react';
import Routes from 'App/Routes/routes-list';
import Typography from '@mui/material/Typography';
import { CircularProgress, MenuItem, OutlinedInput, Alert } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { switchStore } from 'stores/Store/store.thunk-actions';
import { SwitchStore } from 'typings/Store';
import { restartOrder } from 'stores/combined.actions';
import { useLocation } from 'react-router';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { CashierPermission } from 'typings/Cashier';
import buildClasses from './StorePanel.css';

export default function StorePanel(): JSX.Element {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [t] = useTranslation('common');
  const { selectedStore, availableStores } = useAppSelector((store) => store.stores);
  const { basketItems, basketCoupons } = useAppSelector((store) => store.basket);
  const canUserAccess = useAppSelector(getPermissionChecker());
  const isProcessing = !selectedStore || !availableStores;
  const [storeToSwitchId, setStoreToSwitchId] = useState<string | undefined>(undefined);
  const switchedStore = useAppSelector(getSwitchedStore);
  const activeRoute = Routes.find((el) => el.path === location.pathname);
  const cashierCanChangeStore = canUserAccess(CashierPermission.ChooseStore);

  if (isProcessing) {
    return <CircularProgress size={30} />;
  }

  const storeChangedWarning = switchedStore && !switchedStore.isDefaultStore;
  const basketIsNotEmpty = basketItems?.length > 0 || basketCoupons?.length > 0;

  function handleStoreChange(storeId: string): void {
    if (basketIsNotEmpty) {
      setStoreToSwitchId(storeId);
      return;
    }
    dispatch(switchStore({ storeId }));
  }

  const sortedAvailableStores = [
    switchedStore,
    ...availableStores.filter((el) => el.id !== switchedStore?.id),
  ] as SwitchStore[];

  function onClose(): void {
    setStoreToSwitchId(undefined);
  }

  function onSuccess(): void {
    dispatch(restartOrder());
    dispatch(switchStore({ storeId: storeToSwitchId as string }));
    onClose();
  }
  const switchingAllowedOnCurrentRoute = !activeRoute?.forbiddenOnSwitchedStore;

  const storeSwitchingAvailable =
    sortedAvailableStores?.length > 1 && selectedStore && switchingAllowedOnCurrentRoute && cashierCanChangeStore;

  return storeSwitchingAvailable ? (
    <>
      <Select
        className={classes.storeSelector}
        value={selectedStore.id.toString()}
        onChange={(event: SelectChangeEvent) => {
          handleStoreChange(event.target.value);
        }}
        data-testid="store-panel-selected-store"
        input={<OutlinedInput className={classes.storeSelectorInner} />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={() => {
          return switchedStore?.name;
        }}
      >
        {sortedAvailableStores.map((store) => {
          return (
            <MenuItem
              key={store.id}
              value={store.id}
              className={classes.selectItem}
              data-testid={`switch-store-list-item_${store.id}`}
            >
              <div className={classes.storeNameWrapper}>
                <Typography variant="body1" className={classes.storeName}>{`${store.name}`}</Typography>
                {store.isDefaultStore && (
                  <Typography variant="body2" className={classes.myStore}>
                    {`(${t('My store')})`}
                  </Typography>
                )}
              </div>
              {store.id === switchedStore?.id && <CheckRoundedIcon color="success" />}
            </MenuItem>
          );
        })}
      </Select>
      {storeChangedWarning && (
        <Alert
          className={classes.storeChangeWarningMessage}
          severity="warning"
          icon={false}
          data-testid="switch-store-external-store-warning"
        >
          {t('External store')}
        </Alert>
      )}
      <ModalContainer
        maxWidth="sm"
        onClose={onClose}
        open={!!storeToSwitchId}
        title={t('Change store')}
        primaryButton={{
          label: t('Change store'),
          testId: 'switch-store-modal-confirm',
          action: onSuccess,
        }}
        secondaryButton={{
          label: t('Cancel'),
          testId: 'switch-store-modal-cancel',
          action: onClose,
        }}
        testId="switch-store-modal"
      >
        <Typography variant="body1" textAlign="center" className={classes.changeStoreText}>
          {t(
            'All items in the basket will be removed when the store is changed. Do you want to change the store anyway?',
          )}
        </Typography>
      </ModalContainer>
    </>
  ) : (
    <Typography variant="h6" noWrap data-testid="store-panel-selected-store">
      {selectedStore?.name}
    </Typography>
  );
}
