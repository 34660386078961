import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, spacing }) => {
  return {
    itemsContainer: {
      display: 'block',
      overflow: 'auto',
    },
    itemsContainerWithToggleGroup: {
      height: `calc(100vh - ${sizing.receiptHeaderHeight + sizing.receiptFooterHeight + sizing.appBarHeight}px)`,
    },
    itemsContainerWithoutToggleGroup: {
      height: `calc(100vh - ${
        sizing.receiptHeaderHeight + sizing.receiptFooterHeight + sizing.appBarHeight - 39
      }px)`,
    },
    listItem: {
      padding: `${spacing(0.5)} ${spacing(1)}`,
      alignItems: 'center',
      backgroundColor: '#fff',
      borderBottom: '1px solid #cbcbcb',
      '&:first-of-type': {
        borderTop: '1px solid #cbcbcb',
      },
    },
    itemDetails: {
      padding: `0 ${spacing(1)} 0 ${spacing(1)}`,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    itemDescription: {
      fontSize: '0.75rem',
    },
    priceDetails: {
      padding: `0 ${spacing(1)} 0 ${spacing(1)}`,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    itemGroupPrice: {
      fontWeight: 'bold',
    },
    singleItemPrice: {
      fontSize: '0.75rem',
    },
  };
});
export default buildClasses;
