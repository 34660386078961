import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { ArrowBack } from '@mui/icons-material';
import PrintingSettingsGroup from 'components/Settings/SettingGroup/PrintingSettingsGroup';
import FirstTimeLaunchPage from 'components/Settings/SettingGroup/FirstTimeLaunchPage';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { validateSettings } from 'stores/Setup/setup.thunk-actions';
import { updateSettingsValidity } from 'stores/Setup';
import { Box, useTheme } from '@mui/material';
import { PrintingConfiguration } from '../FirstTimeLaunch';

export interface ConfigureScreenProps {
  onSuccess: (
    foxitLocation: string,
    wkhtmlLocation: string,
    usePrinter: boolean,
    selectedPrinter: string,
    isRollPrinter: boolean,
    printCopies: number,
    printEmployeeNameOnReceipt: boolean,
  ) => void;
  onFailure: () => void;
  printerConfig: PrintingConfiguration;
}

const ConfigureScreen: React.FC<ConfigureScreenProps> = ({ onSuccess, onFailure, printerConfig }) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('settings');
  const usePrinter = printerConfig.local?.targetPrinterName !== '';
  const [usingPrinter, setPrinterUsage] = useState<boolean>(usePrinter);
  const [printEmployeeName, setPrintEmployeeName] = useState<boolean>(
    printerConfig.general.printEmployeeNameOnReceipt,
  );
  const [isRollPrinter, setIsRollPrinter] = useState<boolean>(printerConfig.general.isRollPrinter);
  const { validatedPrintingConfig } = useAppSelector((store) => store.setup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (validatedPrintingConfig?.isValid && validatedPrintingConfig.config) {
      dispatch(updateSettingsValidity(undefined));

      onSuccess(
        validatedPrintingConfig.config.local.printingToolPath,
        validatedPrintingConfig.config.local.htmlToPdfConverterPath,
        usingPrinter,
        validatedPrintingConfig.config.local.targetPrinterName,
        isRollPrinter,
        validatedPrintingConfig.config.local.printCopies,
        printEmployeeName,
      );
    }
  }, [validatedPrintingConfig]);

  function savePrintingSettings(settings: PrintingConfiguration): void {
    const submittedSettings = {
      general: {
        printEmployeeNameOnReceipt: printEmployeeName,
      },
      local: {
        ...settings.local,
      },
    } as PrintingConfiguration;

    dispatch(validateSettings(submittedSettings));
  }

  function saveSettingsWithoutPrinterUsage(): void {
    if (!usingPrinter) onSuccess('', '', false, '', false, 1, printEmployeeName);
  }

  return (
    <FirstTimeLaunchPage label={t('Printing Settings')}>
      <Box sx={{ width: '100%' }}>
        <SettingItem
          label={t('Print employee name on receipt?')}
          type="custom"
          testId="printing-settings__input--print-employee-name"
        >
          <FilledSwitch
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
              setPrintEmployeeName(checked)
            }
            checked={printEmployeeName}
          />
        </SettingItem>
        <SettingItem
          label={t('Use roll printing?')}
          type="custom"
          testId="printing-settings__input--use-roll-printing"
        >
          <FilledSwitch
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
              setIsRollPrinter(checked)
            }
            checked={isRollPrinter}
            name="isRoll"
          />
        </SettingItem>
      </Box>
      <Box>
        <SettingItem
          label={t('Does this system uses local printing?')}
          type="custom"
          testId="printing-settings__input--using-printer"
        >
          <FilledSwitch
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
              setPrinterUsage(checked)
            }
            checked={usingPrinter}
          />
        </SettingItem>
      </Box>
      {usingPrinter && (
        <PrintingSettingsGroup predefinedSettings={printerConfig} onFormSubmit={savePrintingSettings} />
      )}
      <Box
        sx={{
          width: '100%',
          margin: `${spacing(3)} auto 0px auto`,
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <RoundedButton
          secondary
          testId="prev_button"
          height="4rem"
          width="20%"
          type="button"
          onClick={onFailure}
          icon={ArrowBack}
        />
        <RoundedButton
          onClick={saveSettingsWithoutPrinterUsage}
          form="printingSettingsForm"
          testId="next_button"
          height="4rem"
          width="70%"
          type="submit"
        >
          {t('Next')}
        </RoundedButton>
      </Box>
    </FirstTimeLaunchPage>
  );
};

export default ConfigureScreen;
