import React, { useState, useEffect } from 'react';
import { FormControl, Dialog, Button, TextField, Popover, ImageList, ImageListItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { format, addMinutes } from 'date-fns';
import { StaticTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import usePrevious from 'hooks/usePrevious';
import buildClasses from './TimeSelect.css';

interface DataSelectProps {
  formRef: ReturnType<typeof useForm>['register'];
  isPickup: boolean;
  isToday: boolean;
  preselectedTime?: string;
}

const TimeSelect: React.FC<DataSelectProps> = ({ formRef, isPickup, isToday, preselectedTime }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const [selectedTime, setSelectedTime] = useState<string>(
    preselectedTime === t('asap') || !preselectedTime ? t('asap') : preselectedTime?.slice(0, 5),
  );
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [time, setTime] = useState<Date | number>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const prevIsToday = usePrevious(isToday);
  function changeTimeHandler(date: unknown): void {
    setTime(date as Date | number);
  }

  function cancelTimePickerHandler(): void {
    setOpenTimePicker(false);
    setSelectedTime(t('asap'));
  }

  function handleOpen(event: React.MouseEvent<HTMLDivElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function setCustomTimeHandler(): void {
    time && setSelectedTime(format(time, 'HH:mm'));
    setOpenTimePicker(false);
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  function hoursInterval(from: string, to: string): string[] {
    const hours: string[] = [];

    let start = new Date(`2020-01-01T${from}:00`);
    const end = new Date(`2020-01-0${from >= to ? '2' : '1'}T${to}:00`);

    while (end > start) {
      start = addMinutes(start, 15);
      hours.push(format(start, 'HH:mm'));
    }

    return hours;
  }

  function roundQuarterHourDown(timeToRound: number): number {
    return timeToRound - (timeToRound % 15);
  }
  const now = new Date();
  const timeNow = format(now.setMinutes(roundQuarterHourDown(now.getMinutes())), 'HH:mm');
  const availableHours = hoursInterval(isToday ? timeNow : '08:00', '00:00'); // to do -> get store opening hour here

  function gridButtonHandler(value: string): void {
    setSelectedTime(value);
    setAnchorEl(null);
  }

  useEffect(() => {
    if (prevIsToday === undefined) return; // skip assigning on first render
    setSelectedTime(isToday ? t('asap') : availableHours[0]);
  }, [isToday]);

  return (
    <FormControl variant="outlined" className={classes.dateSelector}>
      <TextField
        inputRef={formRef({ required: { value: true, message: t('time required') } })}
        name="time"
        value={selectedTime}
        label={isPickup ? t('Pickup time') : t('Delivery time')}
        variant="outlined"
        onClick={handleOpen}
        placeholder="choose hour"
        InputProps={{
          endAdornment: <ArrowDropDownOutlined />,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ 'data-testid': 'finalize-time-form__input--time-selector' }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(): void => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ImageList className={classes.gridList} rowHeight={40} cols={3}>
          {isToday && (
            <ImageListItem cols={1} className={classes.gridTile}>
              <Button
                className={classes.gridButton}
                onClick={(): void => gridButtonHandler(t('asap'))}
                data-testid="finalize-time-form__button--time-selector-option-asap"
                color="inherit"
                variant="text"
              >
                {t('asap')}
              </Button>
            </ImageListItem>
          )}
          {availableHours.map((hour, index) => (
            <ImageListItem key={hour} cols={1} className={classes.gridTile}>
              <Button
                className={classes.gridButton}
                onClick={(): void => gridButtonHandler(hour)}
                data-testid={`finalize-time-form__button--time-selector-option-${index}`}
                color="inherit"
              >
                {hour}
              </Button>
            </ImageListItem>
          ))}
          <ImageListItem cols={1}>
            <Button
              className={classes.gridButton}
              color="primary"
              onClick={(): void => setOpenTimePicker(true)}
              data-testid="finalize-time-form__button--time-selector-option-other"
            >
              {t('OTHER')}
            </Button>
          </ImageListItem>
        </ImageList>
        <Dialog open={openTimePicker} onClose={(): void => setOpenTimePicker(false)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              ampm={false}
              openTo="hours"
              value={time}
              onChange={changeTimeHandler}
              minutesStep={5}
              className={classes.timePicker}
            />
          </LocalizationProvider>
          <div className={classes.timePickerButtons}>
            <Button
              color="primary"
              onClick={cancelTimePickerHandler}
              data-testid="finalize-time-form__button--cancel-time-selector"
            >
              {t('CANCEL')}
            </Button>
            <Button
              color="primary"
              onClick={(): void => setCustomTimeHandler()}
              data-testid="finalize-time-form__button--ok-time-selector"
            >
              OK
            </Button>
          </div>
        </Dialog>
      </Popover>
    </FormControl>
  );
};

export default TimeSelect;
