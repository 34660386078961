import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OrderDetailsDataFieldProps {
  name: string;
  value: string | undefined;
}

const OrderDetailsDataField: React.FC<OrderDetailsDataFieldProps> = ({ name, value }) => {
  const { t } = useTranslation('orders');
  if (!value || value.length === 0) return null;
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" color="grey.600">
        {t(name)}
      </Typography>
      <Typography variant="body1" color="black.main" data-testid={`all-orders__order_details_field-${name}`}>
        {value}
      </Typography>
    </Box>
  );
};

export default OrderDetailsDataField;
