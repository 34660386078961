import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing, radius }) => {
  return {
    filtersWrapper: {
      backgroundColor: palette.grey[100],
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: `${radius.sm}px ${radius.sm}px 0  0`,
    },
    searchInput: {
      paddingLeft: spacing(0),
      width: 400,
    },
    datePickerWrapper: {
      marginRight: spacing(2),
      width: sizing.filterDatePickerWidth,
    },
  };
});
export default buildClasses;
