import React from 'react';
import { Box } from '@mui/system';
import buildClasses from './StrikeThroughContainer.css';

interface StrikeThroughContainerProps {
  children: React.ReactNode;
}

const StrikeThroughContainer: React.FC<StrikeThroughContainerProps> = ({ children }) => {
  const { classes } = buildClasses();

  return <Box className={classes.strikeThrough}>{children}</Box>;
};

export default StrikeThroughContainer;
