import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    addNewCheckButtonContainer: {
      width: 'fit-content',
    },
    ordersContainer: {
      marginTop: spacing(0),
      boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
    },
    overviewTable: {
      backgroundColor: '#FFFFFF',
    },
    dineInWrapper: {
      overflowY: 'auto',
      padding: `${spacing(2)} ${spacing(4)}`,
    },
    filterWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: spacing(2),
    },
  };
});
export default buildClasses;
