import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, sizing, radius, spacing }) => {
  return {
    toppingCard: {
      height: sizing.productSetToppingCardHeight,
      position: 'relative',
      backgroundColor: palette.white,
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${palette.customGray.main}`,
      // hack necessary to maintain item placement after activating card
      margin: '1px',
      borderRadius: radius.sm,
    },

    toppingTitleTooltip: {
      fontSize: '14px',
    },

    activeCard: {
      borderColor: palette.black.main,
      borderWidth: '2px',
      margin: spacing(0),
    },
  };
});

export default buildClasses;
