import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, palette, sizing }) => {
  return {
    searchPanelContainer: {
      margin: spacing(1),
      zIndex: 2,
    },
    searchPanel: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: spacing(3),
    },
    input: {
      width: '100%',
      marginLeft: spacing(1),
    },
    searchButton: {
      color: palette.primary.dark,
      marginLeft: 'auto',
    },
    title: {
      marginTop: spacing(4),
    },
    customerSearchField: {
      margin: spacing(2),
      width: '90%',
    },
    customerSearchContainer: {
      marginTop: spacing(1),
      width: '100%',
    },
    buttonContainer: {
      textAlign: 'center',
      width: '100%',
    },
    button: {
      display: 'inline-flex',
    },
    resultsPane: {
      overflowX: 'hidden',
      height: 'calc(100vh - 210px)',
    },
    customerSearchOverlay: {
      zIndex: 1,
    },
    cusomerSearchSpinner: {
      color: palette.primary.dark,
      marginRight: `${sizing.receiptWidth}px`,
    },
    numberOfResultsContainer: {
      marginBottom: spacing(0.5),
    },
    numberOfResultsValueContainer: {
      marginLeft: spacing(0.5),
    },
  };
});
export default buildClasses;
