import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardTerminalSymbol from 'assets/images/payment-terminal.png';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';

interface CreditCardProps {
  totalAmount: number;
}

const PaymentByCard: React.FC<CreditCardProps> = ({ totalAmount }) => {
  const [t] = useTranslation('intake');
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const { palette } = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={CardTerminalSymbol} alt="Credit Card Terminal" />
      <Typography variant="h6" gutterBottom>
        {t('Fill amount')}
      </Typography>

      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: `${palette.primary.dark}` }}>
        {formatToDisplay(totalAmount)}
      </Typography>
    </Box>
  );
};

export default PaymentByCard;
