import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    selectContainer: {
      width: '70%',
      margin: `${spacing(1.5)} auto`,
      padding: spacing(3),
      textAlign: 'start',
    },
    selectProgress: {
      width: spacing(4),
      height: spacing(4),
      position: 'absolute',
      left: `calc(50% - ${spacing(2)})`,
      top: `calc(50% - ${spacing(2)})`,
    },
    formControl: {
      width: '100%',
    },
    stepButtons: {
      width: '100%',
      margin: `${spacing(3)} auto 0px auto`,
      display: 'flex',
      justifyContent: 'space-around',
    },
  };
});
export default buildClasses;
