import React, { useEffect, useState } from 'react';
import { Button, Collapse, IconButton, OutlinedInput, Typography, Box, useTheme } from '@mui/material';
import { TableRoundIcon, TableSquareIcon } from 'assets/icons';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import DoorLeft from '@mui/icons-material/DoorBack';
import DoorRight from '@mui/icons-material/DoorFront';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import YardIcon from '@mui/icons-material/Yard';
import WcIcon from '@mui/icons-material/Wc';
import StairsIcon from '@mui/icons-material/Stairs';
import { useTranslation } from 'react-i18next';
import { AddBoxSharp } from '@mui/icons-material';
import buildClasses from './MenuObjectsListSection.css';

interface MenuObjectsListSectionProps {
  background?: string;
  addNewTable(type: 'RECT' | 'ROUND'): void;
  setBackground(val: string): void;
  uploadBackground(val: string): void;
}

export default function MenuObjectsListSection({
  addNewTable,
  background,
  setBackground,
  uploadBackground,
}: MenuObjectsListSectionProps) {
  const [image, setImage] = useState<File | undefined>(undefined);
  const [openList, setOpenList] = useState<'TABLES' | 'WALLS' | 'RECOGNITION' | 'BACKGROUND'>('TABLES');
  const [backgroundValue, setBackgroundValue] = useState<string>('');
  const { t } = useTranslation('intake');
  const { classes, cx } = buildClasses();
  const { spacing, palette } = useTheme();
  // TODO: Remove this flag once support for walls and other objects is added
  const allObjectsSupported = false;

  useEffect(() => {
    if (background) {
      setBackgroundValue(background);
    }
  }, [background]);

  useEffect(() => {
    if (backgroundValue !== '') {
      setBackground(backgroundValue);
    }
  }, [backgroundValue]);

  useEffect(() => {
    if (!image) {
      return;
    }
    const imageUrl = URL.createObjectURL(image);
    setBackgroundValue(imageUrl);
  }, [image]);

  function onImageChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const target = e.target as HTMLInputElement;

    if (!target?.files) {
      return;
    }

    const file = target.files[0];
    setImage(file);
  }

  function onUploadClicked() {
    uploadBackground(backgroundValue);
    setImage(undefined);
  }

  const listNameStyle = { display: 'flex', color: palette.black.main };
  const listContainerStyle = { paddingBottom: spacing(4) };
  const buttonStyle = {
    borderRadius: spacing(0.5),
    padding: spacing(2),
    backgroundColor: '#f4f4f4',
    marginRight: spacing(2),
    color: palette.black.main,
  };

  return (
    <Box sx={{ padding: spacing(3), borderTop: '1px solid #ccc' }}>
      <Typography variant="h5" color="black" sx={{ paddingBottom: spacing(2), color: palette.black.main }}>
        {t('Add objects')}
      </Typography>
      <Box sx={listContainerStyle}>
        <Box sx={listNameStyle} onClick={() => setOpenList('TABLES')}>
          <ChevronRightIcon
            classes={{ root: cx(classes.chevronIcon, { [classes.rotate]: openList === 'TABLES' }) }}
          />
          <Typography variant="subtitle1" color="black">
            {t('Tables')}
          </Typography>
        </Box>
        <Collapse in={openList === 'TABLES'} classes={{ wrapperInner: classes.buttonsContainer }}>
          <IconButton onClick={() => addNewTable('ROUND')} sx={buttonStyle}>
            <TableRoundIcon />
          </IconButton>
          <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
            <TableSquareIcon />
          </IconButton>
        </Collapse>
      </Box>
      {allObjectsSupported && (
        <>
          <Box sx={listContainerStyle}>
            <Box sx={listNameStyle} onClick={() => setOpenList('WALLS')}>
              <ChevronRightIcon
                classes={{ root: cx(classes.chevronIcon, { [classes.rotate]: openList === 'WALLS' }) }}
              />
              <Typography variant="subtitle1" color="black">
                {t('Walls & doors')}
              </Typography>
            </Box>
            <Collapse in={openList === 'WALLS'} classes={{ wrapperInner: classes.buttonsContainer }}>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <BorderStyleIcon />
              </IconButton>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <DoorLeft />
              </IconButton>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <DoorRight />
              </IconButton>
            </Collapse>
          </Box>
          <Box sx={listContainerStyle}>
            <Box sx={listNameStyle} onClick={() => setOpenList('RECOGNITION')}>
              <ChevronRightIcon
                classes={{ root: cx(classes.chevronIcon, { [classes.rotate]: openList === 'WALLS' }) }}
              />
              <Typography variant="subtitle1" color="black">
                {t('Points of recognition')}
              </Typography>
            </Box>
            <Collapse in={openList === 'RECOGNITION'} classes={{ wrapperInner: classes.buttonsContainer }}>
              <IconButton onClick={() => addNewTable('ROUND')} sx={buttonStyle}>
                <PointOfSaleIcon />
              </IconButton>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <YardIcon />
              </IconButton>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <WcIcon />
              </IconButton>
              <IconButton onClick={() => addNewTable('RECT')} sx={buttonStyle}>
                <StairsIcon />
              </IconButton>
            </Collapse>
          </Box>
        </>
      )}
      <Box sx={listContainerStyle}>
        <AddBoxSharp sx={listNameStyle} onClick={() => setOpenList('BACKGROUND')}>
          <ChevronRightIcon
            classes={{ root: cx(classes.chevronIcon, { [classes.rotate]: openList === 'BACKGROUND' }) }}
          />
          <Typography variant="subtitle1" color="black">
            {t('Background')}
          </Typography>
        </AddBoxSharp>
        <Collapse in={openList === 'BACKGROUND'} classes={{ wrapperInner: classes.buttonsContainer }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{ color: palette.black.main }}>
              {t('Background')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <OutlinedInput type="file" inputProps={{ accept: 'image/*' }} onChange={onImageChange} fullWidth />
              <Button variant="contained" onClick={onUploadClicked} disabled={!image}>
                {t('UPLOAD')}
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}
