import React from 'react';
import { Box, useTheme } from '@mui/material';

interface ExternalComponentContainerProps {
  children: React.ReactNode;
}

const ExternalComponentContainer: React.FC<ExternalComponentContainerProps> = ({ children }) => {
  const { sizing, palette } = useTheme();
  return (
    <Box
      sx={{
        height: `calc(100vh - ${sizing.appBarHeight}px)`,
        backgroundColor: palette.externalComponentBackground,
      }}
    >
      {children}
    </Box>
  );
};

export default ExternalComponentContainer;
