import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import PinPad from 'components/Auth/PinPad';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

export interface RevalidatePinModalProps {
  onSubmit: (tagNumber: string) => void;
  onClose: () => void;
  defaultValue?: string;
  isMandatory: boolean;
}

const TableTagNumpadModal: React.FC<RevalidatePinModalProps> = ({
  defaultValue = '',
  isMandatory,
  onSubmit,
  onClose,
}) => {
  const { sizing, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const [tableTag, setTableTag] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTableTag(defaultValue);
  }, []);

  function updateTableTag(value: string): void {
    if (value.length > 3) {
      return;
    }
    const isTableTagMissing = !value;
    if (isMandatory) {
      setErrorMessage(isTableTagMissing ? t('Table tag number required') : undefined);
    }
    setTableTag(value);
  }

  function validateTableTag(): boolean {
    if (!isMandatory) {
      return true;
    }
    const isTableTagMissing = !tableTag;
    setErrorMessage(isTableTagMissing ? t('Table tag number required') : undefined);
    return !isTableTagMissing;
  }

  function handleSubmit(): void {
    if (!validateTableTag()) {
      return;
    }

    onSubmit(tableTag);
    handleClose();
  }

  function handleClose() {
    onClose();
  }

  return (
    <ModalContainer
      maxWidth="md"
      onClose={handleClose}
      open
      title={t('Enter the table tag number')}
      testId="table-tag-numpad-modal"
      primaryButton={{
        action: () => {
          handleSubmit();
        },
        type: 'submit',
        label: t('Confirm'),
        testId: 'table-tag-numpad-modal-submit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: sizing.pinPad.width,
          margin: `${spacing(3)}`,
        }}
      >
        <PinPad
          pinCode={tableTag}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          clearPin={() => {}}
          submit={handleSubmit}
          showExtraConfirm
          onChange={updateTableTag}
          errorMessage={errorMessage}
          inputType="text"
        />
      </Box>
    </ModalContainer>
  );
};

export default TableTagNumpadModal;
