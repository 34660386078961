import React, { useRef, useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { getActivePayment } from 'stores/OrderPayment/orderPayment.selector';
import { PaymentMethod } from 'typings/Payments';
import { setActivePaymentMethod, setSelectedPaymentMethodGroupName } from 'stores/OrderPayment/orderPayment.slice';
import { useFormContext } from 'react-hook-form';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { useTheme } from '@mui/system';
import { getPayOnAccountCode } from 'stores/Config/config.selector';

interface PaymentTypeButtonsProps {
  onPaymentMethodSelected: (method: PaymentMethod | undefined) => void;
}

const PaymentTypeButtons: React.FC<PaymentTypeButtonsProps> = ({ onPaymentMethodSelected }) => {
  const [t] = useTranslation('intake');
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { palette, spacing } = useTheme();
  const stringToNumber = useAppSelector(getStringToNumberFormatter());
  const payOnAccountMethodCode = useAppSelector(getPayOnAccountCode);
  const activePayment = useAppSelector(getActivePayment);
  const { availablePaymentMethods, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const { watch } = useFormContext<OrderPaymentForm>();
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = stringToNumber(cashInputValue);

  const kebabMenu = useRef<HTMLButtonElement>(null);

  const selectedPaymentMethod = availablePaymentMethods.find(
    (el) => el.code === activePayment?.paymentMethod?.code,
  );
  const methodsToShow = availablePaymentMethods
    .filter(
      (pm) =>
        pm.code !== activePayment?.paymentMethod?.code &&
        (!pm.groupName || pm.groupName !== activePayment?.paymentMethod?.groupName),
    )
    .reduce((acc: PaymentMethod[], pm) => {
      if (!pm.groupName || !acc.find((item) => item.groupName === pm.groupName)) {
        acc.push(pm);
      }
      return acc;
    }, []);

  selectedPaymentMethod && methodsToShow.unshift(selectedPaymentMethod);

  const amountOfPaymentsToShowOnMainScreen = selectedPaymentMethod ? 1 : 2;

  const isTotalModified = totalLeftAmount !== cashInputNumberValue;

  const handleKebabMenuItemClicked = (method: PaymentMethod) => {
    if (method.groupName) {
      dispatch(setSelectedPaymentMethodGroupName(method.groupName));
      return;
    }

    dispatch(setActivePaymentMethod(method));
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: 2,
        }}
      >
        {methodsToShow.slice(0, amountOfPaymentsToShowOnMainScreen).map((pm) => {
          return (
            <Button
              data-testid={`order-payment__paymentMethod--${pm.groupName || pm.code}`}
              key={`order-payment__paymentMethod--${pm.id}`}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={() => {
                onPaymentMethodSelected(pm);
              }}
            >
              {t(pm.groupName || pm.code)}
            </Button>
          );
        })}
        {methodsToShow.length > amountOfPaymentsToShowOnMainScreen && (
          <>
            <Button
              data-testid="order-payment__paymentMethod--ShowMorePayments"
              color="secondary"
              variant="iconButton"
              ref={kebabMenu}
              onClick={() => setOpen(true)}
            >
              <MoreVertIcon sx={{ fill: palette.grey[800] }} />
            </Button>
            {kebabMenu.current && (
              <Menu
                anchorEl={kebabMenu.current}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                sx={{
                  li: {
                    p: 0.5,
                    minWidth: spacing(5),
                  },
                  button: {
                    '&:hover': {
                      background: 'none',
                    },
                  },
                }}
              >
                {methodsToShow.slice(amountOfPaymentsToShowOnMainScreen).map((pm) => {
                  return (
                    <MenuItem
                      key={`order-payment__paymentMethod--${pm.groupName || pm.id}`}
                      disabled={pm.code === payOnAccountMethodCode && isTotalModified}
                      onClick={() => {
                        handleKebabMenuItemClicked(pm);
                        setOpen(false);
                      }}
                    >
                      <Button
                        color="black"
                        disableRipple
                        data-testid={`order-payment__paymentMethod--${pm.groupName || pm.code}`}
                      >
                        {`${t('Pay with')} ${t(pm.groupName || pm.code)}`}
                      </Button>
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default PaymentTypeButtons;
