import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    alertContainer: {
      width: sizing.full,
      padding: spacing(2),
      display: 'flex',
    },
    containerSuccess: {
      borderColor: palette.green[500],
      backgroundColor: palette.green[100],
      color: palette.green[800],
    },
    containerInfo: {
      borderColor: palette.blue[500],
      backgroundColor: palette.blue[50],
      color: palette.blue[600],
    },
    containerWarning: {
      borderColor: palette.yellow[600],
      backgroundColor: palette.yellow[100],
      color: palette.yellow[800],
    },
    containerError: {
      borderColor: palette.red[500],
      backgroundColor: palette.red[100],
      color: palette.red[900],
    },
    text: {
      color: palette.black.main,
      marginLeft: spacing(1),
    },
  };
});
export default buildClasses;
