import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Backdrop, Box } from '@mui/material';
import AppMenu from 'App/AppMenu/AppMenu';
import ApplicationBar from 'App/ApplicationBar/ApplicationBar';
import InactivityGuard from 'App/MainContainer/InactivityGuard';
import CashierRoute from 'components/Auth/ProtectedRoute';
import { getSelectedStore, getSwitchStores } from 'stores/Store/store.thunk-actions';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import SettlePayment from 'components/Shared/SettlePayment/SettlePayment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import timeSpanToSeconds from 'utils/localisation/dateTimeUtils';
import Messages from 'typings/Messaging';
import { updateConnectivityStatus } from 'stores/Config';
import { getPaymentModalToShow } from 'stores/OrderPayment/orderPayment.selector';
import OrderPayment from 'components/Shared/OrderPayment/OrderPayment';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { clearSettlePayment, getPaymentMethods } from 'stores/Payments';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';
import { useTranslation } from 'react-i18next';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { updateMachineLastActivity } from 'stores/Auth';
import { clearEditRequestStatus, initOrderEdition } from 'stores/Intake';
import { initEmbeddedModeOrderEdition } from 'stores/EmbeddedMode/embeddedMode.thunk-actions';
import { clearEmbeddedModeOrderEdit } from 'stores/EmbeddedMode';
import OperationInProgress from 'App/MainContainer/OperationInProgress';
import FiscalStatusHandler from 'App/FiscalStatusHandler/FiscalStatusHandler';
import CashierBreakDialog from 'components/Auth/CashierBreakDialog';
import { endBreak, getLoggedInCashier, startBreak } from 'stores/Cashier';
import { getShowGiftCardModal } from 'stores/GiftCardActivation/giftCardActivation.selector';
import GiftCardActivation from 'components/Shared/GiftCardActivation/GiftCardActivation';
import buildClasses from './MainContainer.css';
import routes, { DefaultRoute } from '../Routes/routes-list';

const MainContainer: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [heartbeatTimer, setHeartbeatTimer] = useState<NodeJS.Timer | undefined>(undefined);
  const [showConnectivityInfo, setShowConnectivityInfo] = useState<boolean>(false);
  const [callbackEvent, setCallbackEvent] = React.useState<MessageEvent | undefined>(undefined);
  const [isShiftBreakDialogOpened, setIsShiftBreakDialogOpened] = useState<boolean>(false);
  const [askedAboutShiftBreak, setAskedAboutShiftBreak] = useState<boolean>(false);
  const [isClockedIn, setIsClockedIn] = useState<boolean>(false);
  const [isOnBreak, setIsOnBreak] = useState<boolean>(false);
  const { editRequestStatus } = useAppSelector(({ intake }) => intake);
  const { instanceType, maxUserIdleTime, machineActivityUpdate, featureFlags } = useAppSelector(
    (store) => store.config,
  );
  const { embeddedModeOrderEdition } = useAppSelector((store) => store.embeddedMode);
  const { selectedOrderDetails } = useAppSelector(({ allOrders }) => allOrders);
  const { selectedStore } = useAppSelector((store) => store.stores);
  const showGiftCardActivation = useAppSelector(getShowGiftCardModal);

  const idleTimeoutSeconds = useMemo(
    () =>
      timeSpanToSeconds(
        getLocalStorageItem<string>(LocalStorageItems.idleTimeSpan) ?? maxUserIdleTime ?? '00:01:00',
      ),
    [maxUserIdleTime],
  );

  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };
  const { onsiteMachine, onsiteMachineLoading } = useAppSelector((appState) => appState.authorization);
  const { loggedCashier, clockedInEmployees } = useAppSelector((appState) => appState.cashier);
  const paymentModalToShow = useAppSelector(getPaymentModalToShow());

  const dispatch = useAppDispatch();
  const history = useHistory();

  function paymentSuccessfulHandler(result: PaymentSuccessfulResult): void {
    if (callbackEvent?.source) {
      const totalPriceFromMessage = Number.parseFloat(callbackEvent?.data?.payload?.totalPrice?.replace(',', '.'));
      callbackEvent.source.postMessage({
        type: Messages.Events.PaymentSucceeded,
        data: {
          orderId: callbackEvent?.data?.payload?.orderId,
          value: totalPriceFromMessage,
          method: result.paymentMethodCode,
          eftPaymentOperationId: result.eftPaymentOperation?.finishedManually
            ? undefined
            : result.eftPaymentOperation?.eftPaymentId,
          eftDeviceId: result.eftPaymentOperation?.deviceId,
        },
      });
    }
    closeSettlePaymentForm();
    setCallbackEvent(undefined);
  }

  function paymentModalClosedHandler(): void {
    if (callbackEvent?.source) {
      callbackEvent.source.postMessage({
        type: Messages.Events.PaymentModalClosed,
      });
    }
    closeSettlePaymentForm();
    setCallbackEvent(undefined);
  }

  function handleStartPaymentCommand(messageEvent: MessageEvent<any>): void {
    setCallbackEvent(messageEvent);
  }

  function handleChangeViewCommand(messageEvent: MessageEvent<any>): void {
    if (messageEvent.data.payload.viewType === 'intake') {
      window.location.hash = '#intake';
    }
  }

  function handleEditOrderCommand(messageEvent: MessageEvent<any>): void {
    dispatch(
      initEmbeddedModeOrderEdition({
        orderPublicId: messageEvent.data.payload.orderPublicId as string,
        isOrderPaid: messageEvent.data.payload.isOrderPaid as boolean,
      }),
    );
    history.push('/intake');
  }

  const closeSettlePaymentForm = (): void => {
    dispatch(clearSettlePayment());
  };

  const onStartBreakClick = () => {
    setIsShiftBreakDialogOpened(false);
    dispatch(startBreak());
  };

  const onEndBreakClick = () => {
    setIsShiftBreakDialogOpened(false);
    dispatch(endBreak());
  };

  const onConfirmClick = () => {
    isOnBreak ? onEndBreakClick() : onStartBreakClick();
  };

  useEffect(() => {
    dispatch(getLoggedInCashier());
  }, [selectedStore?.id]);

  useEffect(() => {
    const clockedInEmployee = clockedInEmployees.find((employee) => employee.userId === loggedCashier?.id);

    setIsClockedIn(clockedInEmployee !== undefined);

    const isEmployeeOnBreak = clockedInEmployee?.breakDurationInMinutes !== undefined;
    setIsOnBreak(isEmployeeOnBreak);

    if (!askedAboutShiftBreak) {
      setIsShiftBreakDialogOpened(isEmployeeOnBreak);
      setAskedAboutShiftBreak(true);
    }
  }, [loggedCashier]);

  useEffect(() => {
    if (embeddedModeOrderEdition?.isDataReady && selectedOrderDetails) {
      dispatch(
        initOrderEdition({
          order: selectedOrderDetails,
          orderEditMode: embeddedModeOrderEdition.isOrderPaid ? 'editPaidOrder' : 'editFullOrder',
        }),
      );
    }
  }, [embeddedModeOrderEdition, selectedOrderDetails]);

  useEffect(() => {
    if (editRequestStatus === 'failure' || editRequestStatus === 'success') {
      dispatch(clearEditRequestStatus());

      if (embeddedModeOrderEdition) {
        dispatch(clearEmbeddedModeOrderEdit());

        // Sometimes the changes are not yet there when user is redirected back to dispatcing screen.
        // Adding a 1 second delay should resolve most of such cases.
        setTimeout(() => {
          history.push('/dispatching');
        }, 1000);
      } else {
        history.push('/all-orders');
      }
    }
  }, [editRequestStatus, embeddedModeOrderEdition]);

  useEffect(() => {
    if (callbackEvent?.data?.payload?.totalPrice) {
      dispatch(
        getPaymentMethods({
          pickupType: callbackEvent.data.payload.isDelivery
            ? PickUpTypesValues.delivery
            : PickUpTypesValues.pickUp,
        }),
      );
      const totalPriceFromMessage = Number.parseFloat(callbackEvent.data.payload.totalPrice.replace(',', '.'));
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId: callbackEvent.data.payload.publicId,
          totalToPay: totalPriceFromMessage,
          successCallback: paymentSuccessfulHandler,
          closeCallback: paymentModalClosedHandler,
          deliveryType: callbackEvent.data.payload.isDelivery
            ? PickUpTypesValues.delivery
            : PickUpTypesValues.pickUp,
          isLocalOrder: true,
        }),
      );
    }
  }, [callbackEvent]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === Messages.Commands.StartPayment) {
        handleStartPaymentCommand(event);
      } else if (event.data.type === Messages.Commands.ChangeView) {
        handleChangeViewCommand(event);
      } else if (event.data.type === Messages.Commands.EditOrder) {
        handleEditOrderCommand(event);
      }
    });

    return () => {
      clearInterval(heartbeatTimer);
    };
  }, []);

  useEffect(() => {
    if (!machineActivityUpdate?.enabled) {
      return;
    }

    if (loggedCashier) {
      const timerId = setInterval(() => {
        dispatch(updateMachineLastActivity());
      }, machineActivityUpdate.updateIntervalInSecods * 1000);
      dispatch(updateMachineLastActivity());
      setHeartbeatTimer(timerId);
    } else if (heartbeatTimer) {
      clearInterval(heartbeatTimer);
    }
  }, [loggedCashier, machineActivityUpdate]);

  useEffect(() => {
    if (instanceType) {
      if (instanceType === 'Central') {
        setShowConnectivityInfo(false);
        dispatch(updateConnectivityStatus(ConnectivityStatusEnum.Connected));
      } else {
        setShowConnectivityInfo(true);
        dispatch(updateConnectivityStatus(ConnectivityStatusEnum.Disconnected));
      }

      dispatch(getSelectedStore());
      dispatch(getSwitchStores());
    }
  }, [onsiteMachine, onsiteMachineLoading]);

  return (
    <>
      <OperationInProgress />
      {featureFlags.OfflineModule_Fiscalization && <FiscalStatusHandler />}
      <ApplicationBar
        isMenuOpen={open}
        handleMenuOpenProp={handleDrawerOpen}
        showConnectivityInfo={showConnectivityInfo}
        isClockedIn={isClockedIn}
        isOnBreak={isOnBreak}
        onShiftBreakChangeClick={() => setIsShiftBreakDialogOpened(true)}
      />
      <AppMenu open={open} menuItemClicked={handleDrawerClose} />
      <Backdrop open={open} onClick={handleDrawerClose} sx={{ zIndex: 1101 }} />
      {idleTimeoutSeconds && <InactivityGuard idleTimeSeconds={idleTimeoutSeconds} />}
      <Box component="main" className={classes.content}>
        <div className={classes.menuHeader} id="application_main_bar_container" />
        {routes.map((route) => {
          return (
            <CashierRoute
              routeDetails={route}
              key={route.path}
              onRedirectToBase={() => {
                history.push(DefaultRoute.path);
              }}
            />
          );
        })}
      </Box>
      {paymentModalToShow === 'oldModal' && <SettlePayment />}
      {paymentModalToShow === 'newModal' && <OrderPayment />}
      {showGiftCardActivation && <GiftCardActivation />}
      {loggedCashier && (
        <CashierBreakDialog
          isOpened={isShiftBreakDialogOpened}
          isOnBreak={isOnBreak}
          onCancelClick={() => setIsShiftBreakDialogOpened(false)}
          onConfirmClick={onConfirmClick}
        />
      )}
    </>
  );
};

export default MainContainer;
