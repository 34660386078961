import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, sizing, spacing }) => {
  return {
    property: {
      width: sizing.propertyIcon,
      height: sizing.propertyIcon,
      marginLeft: spacing(0.5),
    },
  };
});

export default buildClasses;
