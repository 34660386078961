import React from 'react';
import { NavigateNext } from '@mui/icons-material';
import { MenuItems } from 'containers/Settings/SettingsConsts';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import buildClasses from './SettingMenuItem.css';

interface GroupProps {
  label: MenuItems;
  isActive: boolean;
  setActive: (value: MenuItems) => void;
}

const SettingGroup: React.FC<GroupProps> = ({ label, isActive, setActive }) => {
  const { classes } = buildClasses();
  const { spacing } = useTheme();
  const [t] = useTranslation('settings');

  return (
    <div
      className={`${classes.listItem} ${isActive && classes.active}`}
      onClick={(): void => setActive(label)}
      data-testid={`settings-side-menu__button--option-${label}`}
    >
      <Box sx={{ fontSize: spacing(2) }}>{t(label)}</Box>
      <NavigateNext />
    </div>
  );
};

export default SettingGroup;
