import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    productCardContent: {
      width: '100%',
      height: '100%',
    },

    root: { position: 'relative' },
    active: {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText,
    },
  };
});

export default buildClasses;
