import React, { useState, useRef } from 'react';
import { TextField, Popover, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';

interface CustomSelectProps {
  formRef: ReturnType<typeof useForm>['register'];
  label: string;
  options: { [key: string]: string };
  testId: string;
  inputName: string;
  isRequired: boolean;
  errorMessage: string;
  onChangeFn: () => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  formRef,
  label,
  options,
  testId,
  inputName,
  isRequired,
  errorMessage,
  onChangeFn,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(Object.entries(options)[0][0]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const textFieldElement = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  function handleOpen(event: React.MouseEvent<HTMLDivElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function gridButtonHandler(value: string): void {
    setSelectedValue(value);
    setAnchorEl(null);
  }

  function generateOptions(): JSX.Element[] {
    const entries = Object.entries(options);
    return entries.map((entry) => (
      <MenuItem
        value={entry[1]}
        data-testid={`finalize-address-form__select-option--${entry[0]}`}
        key={entry[0]}
        onClick={(): void => gridButtonHandler(entry[0])}
      >
        {entry[0]}
      </MenuItem>
    ));
  }

  return (
    <>
      <TextField
        ref={textFieldElement}
        inputRef={formRef({
          required: {
            value: isRequired,
            message: errorMessage,
          },
        })}
        name={inputName}
        value={selectedValue}
        label={label}
        variant="outlined"
        onClick={handleOpen}
        placeholder={label}
        inputProps={{ 'data-testid': testId }}
        fullWidth
        onChange={onChangeFn}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(): void => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 'inherit',
            maxWidth: 'inherit',
          },
        }}
        style={{
          width: textFieldElement?.current?.clientWidth,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options?.length && generateOptions()}
      </Popover>
    </>
  );
};

export default CustomSelect;
