import React from 'react';
import { Typography, CircularProgress, Box, useTheme } from '@mui/material';

export interface ProcessingComponentProps {
  text?: string;
  fullScreen?: boolean;
}

const ProcessingComponent: React.FC<ProcessingComponentProps> = ({ text, fullScreen }) => {
  const { spacing, palette } = useTheme();

  return (
    <Box
      sx={{
        padding: spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: fullScreen ? 'fixed' : 'inherit',
        top: fullScreen ? 0 : 'inherit',
        left: fullScreen ? 0 : 'inherit',
        zIndex: fullScreen ? 1101 : 1,
        background: fullScreen ? palette.primary.main : 'inherit',
        width: fullScreen ? '100vw' : 'inherit',
        height: fullScreen ? '100vh' : 'inherit',
      }}
      data-testid="processing-component__container--processing-component"
    >
      {text && (
        <Typography
          variant="h4"
          color="secondary"
          sx={{ margin: '25px' }}
          data-testid="processing-component__label--processing-component-text"
        >
          {text}
        </Typography>
      )}
      <CircularProgress color="secondary" />
    </Box>
  );
};
export default ProcessingComponent;
