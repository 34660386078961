import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CashierBasicInfo from 'components/Auth/CashierBasicInfo';
import { Box, useTheme } from '@mui/material';
import ConnectivityInfo from 'components/Connectivity/ConnectivityInfo';
import StorePanel from 'App/ApplicationBar/StorePanel';
import HybridModePanel from 'App/HybridMode/HybridModePanel';
import usePaymentSettings from 'hooks/usePaymentSettings';
import { setIsOfflineModePanelVisible } from 'stores/HybridMode';
import buildClasses from './ApplicationBar.css';
import EtaConfirmationNotifier from './EtaConfirmationNotifier';
import EtaInfo from './EtaInfo';

interface ApplicationBarProps {
  handleMenuOpenProp: () => void;
  isMenuOpen: boolean;
  showConnectivityInfo: boolean;
  isClockedIn: boolean;
  isOnBreak: boolean;
  onShiftBreakChangeClick: () => void;
}

export default function ApplicationBar({
  handleMenuOpenProp,
  isMenuOpen,
  showConnectivityInfo,
  isClockedIn,
  isOnBreak,
  onShiftBreakChangeClick,
}: ApplicationBarProps): JSX.Element {
  const { classes, cx } = buildClasses();
  const { instanceType } = useAppSelector((store) => store.config);
  const { etaConfirmationNotifierEnabled, etaAdjustmentConfiguration } = useAppSelector(({ config }) => config);
  const { loggedCashier } = useAppSelector((store) => store.cashier);
  const { isHybridModeActive, isOfflineModePanelVisible, isCloudPosAvailable, lastCloudPosConnectionAttempt } =
    useAppSelector((store) => store.hybridMode);
  const showSwitchButton = instanceType === 'InStore' && (isCloudPosAvailable ?? false);
  const showHybridModePanel = isHybridModeActive && isOfflineModePanelVisible;
  const { palette, transitions, sizing } = useTheme();

  const dispatch = useAppDispatch();
  const { GetPaymentSettings } = usePaymentSettings();
  const paymentSettings = GetPaymentSettings();

  useEffect(() => {
    if (instanceType === 'InStore' && isHybridModeActive) {
      dispatch(setIsOfflineModePanelVisible(true));
    }
  }, [isHybridModeActive, instanceType]);

  return (
    <AppBar
      position="fixed"
      id="application_main_bar"
      sx={{
        background: palette.appBar ? palette.appBar.main : palette.primary.main,
        height: sizing.appBarHeight,
        transition: transitions.create(['margin', 'width'], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.leavingScreen,
        }),
      }}
      className={cx(classes.appBar, {
        [classes.appBarShift]: isMenuOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarAction}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleMenuOpenProp}
            data-testid="app-header__button--open-navigation-drawer"
          >
            <MenuIcon />
          </IconButton>
          <StorePanel />
        </div>
        {showConnectivityInfo && !showHybridModePanel && <ConnectivityInfo />}
        {showHybridModePanel && (
          <HybridModePanel
            culture={paymentSettings.culture}
            lastCloudPosConnectionAttempt={lastCloudPosConnectionAttempt}
            isOnlinePosAvailable={isCloudPosAvailable ?? false}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {etaConfirmationNotifierEnabled && <EtaConfirmationNotifier />}
          {instanceType === 'Central' && etaAdjustmentConfiguration?.showEta && <EtaInfo />}
          {loggedCashier && (
            <CashierBasicInfo
              name={`${loggedCashier.firstName} ${loggedCashier.lastName}`}
              role={loggedCashier.roleName}
              showSwitchToOnlineButton={showSwitchButton}
              isClockedIn={isClockedIn}
              isOnBreak={isOnBreak}
              onShiftBreakChangeClick={onShiftBreakChangeClick}
            />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
