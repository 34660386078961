import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ typography, spacing, palette }) => {
  return {
    cashierInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textTransform: 'none',
    },
    employeeBreakWarningMessage: {
      marginLeft: spacing(1),
      color: 'rgba(205, 113, 27, 1)',
      backgroundColor: 'rgba(255, 237, 216, 1)',
      fontSize: typography.body2.fontSize,
      padding: `0px ${spacing(1)}`,
      borderRadius: '4px',
      border: '0px',
      textTransform: 'none',
      '&> div': {
        padding: `${spacing(0.5)} 0px`,
      },
    },
    accountMenu: {
      '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: spacing(1),
        minWidth: 180,
        color: 'black',
        boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
          padding: '4px 0',
        },
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: typography.h5.fontSize,
          marginRight: spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(palette.primary.main, palette.action.selectedOpacity),
        },
      },
    },
  };
});

export default buildClasses;
