import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useRedux';
import { finalizeOrderAndExit, manualExitOrderPayment } from 'stores/OrderPayment/orderPayment.actions';
import { Check } from '@mui/icons-material';
import PaymentSummary from 'components/Shared/OrderPayment/Shared/PaymentSummary';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';

const FinalizeFullyPaid: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { palette, typography } = useTheme();

  return (
    <PaymentFrame>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}
      >
        <Typography variant="h5" sx={{ color: palette.grey[900] }}>
          {t('Order is paid')}
        </Typography>
        <Check sx={{ fontSize: typography.h2.fontSize, color: palette.green[600] }} />
      </Box>
      <>
        <PaymentSummary />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: 2,
            mt: 5,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            size="large"
            sx={{ py: 2, px: 3 }}
            onClick={() => {
              dispatch(manualExitOrderPayment());
            }}
          >
            {t('Exit')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ py: 2, px: 3 }}
            onClick={() => {
              dispatch(finalizeOrderAndExit());
            }}
          >
            {t('Finalize order')}
          </Button>
        </Box>
      </>
    </PaymentFrame>
  );
};
export default FinalizeFullyPaid;
