import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, palette }) => {
  return {
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    floorSelectorContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      position: 'relative',
      backgroundColor: palette.white,
    },
    tabsContainer: {
      display: 'flex',
      width: '100%',
      border: `1px solid ${palette.customGray.dark}`,
    },
    tab: {
      background: palette.white,
      padding: spacing(0.5),
      textAlign: 'center',
      cursor: 'pointer',
      borderRight: `1px solid ${palette.customGray.dark}`,
      '&:last-child': {
        borderRight: 'none',
      },
      width: '100%',
    },
    tabActive: {
      background: palette.customGray.light,
    },
    canvasWrapper: {
      height: '100%',
      width: '100%',
      backgroundColor: palette.background.default,
    },
    editButton: {
      backgroundColor: palette.white,
      boxShadow:
        '0 3px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.07)',
      position: 'absolute',
      right: 16,
      bottom: 28,
      padding: spacing(2),
      '&:hover': {
        backgroundColor: palette.customGray.main,
      },
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    newDineInButtonContainer: {
      display: 'flex',
      paddingBottom: spacing(3),
      alignItems: 'center',
    },
    selectTableLabel: {
      color: palette.black.main,
      padding: spacing(2),
    },
    activeFloorText: {
      fontWeight: '700',
      color: palette.black.main,
    },
    goBackContainer: {
      color: palette.black.main,
      paddingBottom: spacing(1),
      marginLeft: spacing(-1),
    },
    arrowIcon: {
      marginRight: spacing(1),
      color: palette.text.primary,
    },
  };
});
export default buildClasses;
