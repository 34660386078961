import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, List, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setLoginState } from 'stores/Cashier';
import { LoginStates } from 'typings/Cashier';
import CashBalanceEntry from 'components/Auth/CashBalanceEntry';
import ClockedInEmployee from 'components/Auth/ClockedInEmployee';

const CashFlow: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { sizing } = useTheme();
  const { employeeCashBalanceInfo } = useAppSelector(({ cashier }) => cashier);

  return (
    <Box
      sx={{ height: sizing.pinPad.height, display: 'flex', justifyContent: 'flexStart', flexDirection: 'column' }}
    >
      <Typography color="black.main" variant="h3">
        {t('Cashflow')}
      </Typography>
      <ClockedInEmployee
        employeeInfo={{
          clockedInAt: employeeCashBalanceInfo?.timeOfCheck ?? 'Unknown',
          fullname: employeeCashBalanceInfo?.employeeName ?? 'Unknown name',
          role: employeeCashBalanceInfo?.roleDisplayName ?? 'Unknown role',
        }}
        breakTimeMinutes={employeeCashBalanceInfo?.breakTimeMinutes}
        shiftTime={employeeCashBalanceInfo?.shiftTime}
      />
      <List sx={{ my: 2, py: 0, gap: 1, display: 'flex', flexDirection: 'column' }}>
        <CashBalanceEntry entryName="Total in pocket" value={employeeCashBalanceInfo?.cashInPocket} />
        <CashBalanceEntry entryName="Total in cash" value={employeeCashBalanceInfo?.ordersInCashTotal} />
        <CashBalanceEntry entryName="Total in card" value={employeeCashBalanceInfo?.ordersInPinTotal} />
      </List>
      <Button
        sx={{ my: 2 }}
        variant="contained"
        fullWidth
        onClick={() => {
          dispatch(setLoginState(LoginStates.cashFlowPinPad));
        }}
      >
        {t('Pay & Clock Out')}
      </Button>
    </Box>
  );
};
export default CashFlow;
