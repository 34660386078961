import React from 'react';
import DineInReceiptProductItem from 'components/Intake/Receipt/DineIn/DineInReceiptProductItem';
import DineInReceiptDiscountItem from 'components/Intake/Receipt/DineIn/DineInReceiptDiscountItem';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCustomizationProductSettings } from 'stores/Products';
import { MealCoupon } from 'typings/Coupons';
import { setCouponToCustomize } from 'stores/Coupons';
import { cancelDineInItem, removeDineInCoupon } from 'stores/DineIn/dineIn-thunk.actions';
import { DineInReceiptProduct, ReceiptDiscount, BasketItem, DineInReceiptDiscount } from 'typings/Basket';
import { removeFromBasket } from 'stores/Basket/basket.thunk-actions';
import { Box } from '@mui/material';

interface ReceiptDineInItemsProps {
  receiptProducts: DineInReceiptProduct[];
  receiptDiscounts: ReceiptDiscount[];
  receiptMealDeals: DineInReceiptDiscount[];
  canRemoveItems: boolean;
  canCustomizeItems: boolean;
  tabId: string;
}

const ReceiptDineInItems: React.FC<ReceiptDineInItemsProps> = ({
  receiptProducts,
  receiptDiscounts,
  receiptMealDeals,
  canRemoveItems,
  canCustomizeItems,
  tabId,
}) => {
  const dispatch = useAppDispatch();
  const { basketCoupons } = useAppSelector((state) => state.basket);

  const removeCouponFromBasketHandler = (couponId: string): void => {
    dispatch(removeDineInCoupon({ tabId, couponId }));
  };

  function customizeProductHandler(basketItem: BasketItem): void {
    if (!canCustomizeItems) {
      return;
    }

    dispatch(getCustomizationProductSettings({ basketItem, searchType: 'itemId' }));
  }

  function customizeDiscountHandler(discountItem: ReceiptDiscount): void {
    if (!canCustomizeItems) {
      return;
    }

    const couponToCustomize = basketCoupons.find((bc) => bc.couponId === discountItem.couponId);

    if (couponToCustomize?.useMealConfigurator) {
      const mealCoupon = couponToCustomize as MealCoupon;
      dispatch(setCouponToCustomize(mealCoupon));
    }
  }

  function removeProductHandler(itemId: number): void {
    if (canCustomizeItems) {
      dispatch(removeFromBasket(itemId));
    } else {
      dispatch(cancelDineInItem({ tabId, dineInItemId: itemId }));
    }
  }

  return (
    <Box className="withripple" sx={{ display: 'block', overflowY: 'auto' }}>
      {receiptProducts.map((receiptProduct) => (
        <DineInReceiptProductItem
          key={receiptProduct.id}
          receiptProduct={receiptProduct}
          removeItem={(): void => removeProductHandler(receiptProduct.id)}
          customizeProduct={customizeProductHandler}
          canRemoveItem={receiptProduct.canBeRemoved}
        />
      ))}

      {receiptMealDeals?.map((discount: DineInReceiptDiscount) => (
        <DineInReceiptDiscountItem
          discount={discount}
          key={discount.couponId ?? discount.description}
          canRemoveDiscount={(canRemoveItems && discount.canDelete) ?? false}
          customizeDiscountItem={customizeProductHandler}
          customizeDiscount={customizeDiscountHandler}
          mealDealProducts={discount.dineInProducts ?? []}
          removeItem={removeCouponFromBasketHandler}
        />
      ))}
      {receiptDiscounts?.map((discount: ReceiptDiscount) => (
        <DineInReceiptDiscountItem
          discount={discount}
          key={discount.couponId ?? discount.description}
          canRemoveDiscount={(canRemoveItems && discount.canDelete) ?? false}
          customizeDiscount={customizeDiscountHandler}
          removeItem={removeCouponFromBasketHandler}
        />
      ))}
    </Box>
  );
};

export default ReceiptDineInItems;
