import React, { useEffect, useState } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import { sortProductsAlphabetically, sortProductsBySortOrder } from 'stores/Intake/IntakeStoreUtils';
import { useAppSelector } from 'hooks/useRedux';
import { Product, Option, Group } from 'typings/Products';
import { Box } from '@mui/system';
import { getOtherGroupCode, getPizzaCategoryCode } from 'stores/Config/config.selector';
import buildClasses from './ProductsCatalog.css';

type ProductCatalogProps = {
  products: Product[];
  groups: Group[];
  onProductClick: (product: Product) => void;
};

const ScrollableCatalog: React.FC<ProductCatalogProps> = ({ products, groups, onProductClick }) => {
  const { classes } = buildClasses();
  const { options, activeCategoryCode, activeOptionId } = useAppSelector((store) => store.products);
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);
  const otherGroupCode = useAppSelector(getOtherGroupCode);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const { intakeSettings } = useAppSelector((state) => state.config);

  const sortedProducts = intakeSettings?.sortProductsByName
    ? sortProductsAlphabetically(products)
    : sortProductsBySortOrder(products);

  useEffect(() => {
    const optionFound = options.find((opt) => opt.id === activeOptionId);
    setSelectedOption(optionFound);
  }, [activeOptionId]);

  return (
    <Box sx={{ flex: 1, overflowY: 'auto', mt: 3 }}>
      <div className={classes.scrollableWrapper}>
        {activeCategoryCode === pizzaCategoryCode || groups.length <= 1
          ? products.map((product) => (
              <ProductCard
                product={product}
                selectedOption={selectedOption}
                onProductSelected={onProductClick}
                hideOptionName
                key={`PRODUCT_${product.id}`}
                displayImage={selectedStore?.displayProductImagesDuringIntake}
              />
            ))
          : groups.map((group) => (
              <div className={classes.productsGroupContainer} key={`GROUP_${group.code}`}>
                <h3 className={classes.groupLabel}>{group.name}</h3>
                <div className={classes.productsGroup}>
                  {sortedProducts.length &&
                    sortedProducts.map(
                      (product) =>
                        (product.groupCode === group.code ||
                          (!product.groupCode && group.code === otherGroupCode)) && (
                          <ProductCard
                            key={`PRODUCT_${product.id}`}
                            product={product}
                            onProductSelected={onProductClick}
                            displayImage={selectedStore?.displayProductImagesDuringIntake}
                          />
                        ),
                    )}
                </div>
              </div>
            ))}
      </div>
    </Box>
  );
};

export default ScrollableCatalog;
