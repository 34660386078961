import React from 'react';
import { Check } from '@mui/icons-material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Typography, Box, useTheme } from '@mui/material';

interface IterationSeparatorProps {
  sentTimestamp: Date;
}

const IterationSeparator: React.FC<IterationSeparatorProps> = ({ sentTimestamp }) => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('intake');

  function getSeparatorText(): string {
    return `${t('Sent at')} ${format(new Date(sentTimestamp), 'HH:mm')}`;
  }

  const separatorText = getSeparatorText();

  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        padding: `${spacing(0.5)} ${spacing(2)}`,
        backgroundColor: palette.customGray.dark,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      data-testid="receipt_dine-in-iteration__separator"
    >
      <Typography variant="subtitle2" color="white">
        {separatorText}
      </Typography>
      <Check sx={{ fontSize: '25px', color: palette.white }} />
    </Box>
  );
};

export default IterationSeparator;
