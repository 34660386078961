import React from 'react';
import TextFieldDialog from 'components/Shared/TextFieldDialog/TextFieldDialog';
import { useAppDispatch } from 'hooks/useRedux';
import { addCustomerToBlacklist } from 'stores/AllOrders';

interface AddToBlacklistDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: () => void;
}

const AddToBlacklistDialog: React.FC<AddToBlacklistDialogProps> = ({ showDialog, orderId, abort, onConfirm }) => {
  const formId = `add-to-blacklist-form-order-${orderId}`;
  const dispatch = useAppDispatch();
  function onFormSubmit(reason: string) {
    dispatch(addCustomerToBlacklist({ reason, orderId, successCallback: () => onConfirm() }));
  }

  return (
    <TextFieldDialog
      showDialog={showDialog}
      onClose={abort}
      onSubmit={onFormSubmit}
      i18NameSpace="orders"
      errorMessageKey="Reason required"
      headerKey="Add customer to blacklist reason"
      formId={formId}
      maxLength={500}
      placeholder="Reason"
    />
  );
};

export default AddToBlacklistDialog;
