import React, { useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OrderDetailsSection from 'components/Orders/OrderDetails/OrderDetailsSection';
import { getDateFormatter, getTimeFormatter } from 'stores/Payments/payment.selector';
import { OSMOrderDayHistory } from 'stores/AllOrders';

const OrderDetailsLogs: React.FC = () => {
  const { selectedOrderDetails } = useAppSelector((state) => state.allOrders);
  const [t] = useTranslation('orders');
  const [logsExpanded, setLogsExpanded] = useState(false);
  const formatDate = useAppSelector(getDateFormatter());
  const formatTime = useAppSelector(getTimeFormatter());
  const { palette, spacing } = useTheme();

  if (!selectedOrderDetails) return null;

  const historyOrdersToShow = logsExpanded
    ? selectedOrderDetails.historyView
    : [
        {
          day: selectedOrderDetails.historyView[0].day,
          history: [selectedOrderDetails.historyView[0].history.at(-1)],
        } as OSMOrderDayHistory,
      ];

  return (
    <>
      {selectedOrderDetails.history.length > 0 && (
        <OrderDetailsSection name="Logs">
          {historyOrdersToShow.map((dayEntries, index) => {
            return (
              <Box key={dayEntries.day} data-testid={`all-orders__order_details_more_details--day-${index}`}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ px: 1, py: 0, borderRadius: 2, background: palette.grey[100], width: 'fit-content' }}>
                    <Typography variant="subtitle2" color="grey.800">
                      {formatDate(new Date(dayEntries.day))}
                    </Typography>
                  </Box>
                  {index === 0 && (
                    <Button
                      variant="text"
                      color="black"
                      data-testid="all-orders__order_details_more_details__button-expand"
                      onClick={() => {
                        setLogsExpanded(!logsExpanded);
                      }}
                      endIcon={logsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                      {t(logsExpanded ? 'Show less' : 'Show all')}
                    </Button>
                  )}
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  {dayEntries.history.map((historyEntry, indexx) => {
                    if (!historyEntry) return null;
                    return (
                      <Box
                        sx={{ my: 1 }}
                        key={historyEntry.id}
                        data-testid={`all-orders__order_details_more_details--dayEntry-${indexx}`}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              height: spacing(1),
                              width: spacing(1),
                              borderRadius: 2,
                              backgroundColor: palette.grey[300],
                              mr: 1,
                            }}
                          />
                          <Typography
                            variant="caption"
                            data-testid="all-orders__order_details_more_details--dayEntry-datetime"
                          >
                            {formatTime(new Date(Date.parse(historyEntry.timestamp)))}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ ml: 2 }}
                          variant="body2"
                          color="black.main"
                          data-testid="all-orders__order_details_more_details--dayEntry-message"
                        >
                          {t(historyEntry.message ? historyEntry.message : historyEntry.event)}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </OrderDetailsSection>
      )}
    </>
  );
};

export default OrderDetailsLogs;
