import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ typography, spacing, palette, radius }) => {
  return {
    storeSelector: {
      color: palette.primary.contrastText,
      backgroundColor: 'transparent',
      '& > svg': {
        color: palette.primary.contrastText,
      },
      root: {
        borderRadius: radius.sm,
      },
      maxWidth: '90%',
    },
    storePanelLoader: {
      color: palette.secondary.main,
    },
    storeChangeWarningMessage: {
      marginLeft: spacing(1),
      color: 'rgba(205, 113, 27, 1)',
      backgroundColor: 'rgba(255, 237, 216, 1)',
      fontSize: typography.body2.fontSize,
      padding: `0px ${spacing(1)}`,
      borderRadius: '4px',
      border: '0px',
      '&> div': {
        padding: `${spacing(0.5)} 0px`,
      },
    },
    myStore: {
      marginLeft: spacing(0.5),
    },
    selectItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    storeNameWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    storeName: {
      color: palette.secondary.contrastText,
    },
    changeStoreText: {
      color: palette.black.main,
      margin: spacing(3),
    },
    storeSelectorInner: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  };
});

export default buildClasses;
