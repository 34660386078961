import React, { useEffect, useState } from 'react';
import { AppBar, CircularProgress, Dialog, DialogTitle, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { Box } from '@mui/system';
import { getEFTStatus } from 'stores/Eft/eft.selector';
import { automaticCashierLogOut, logOutCashier } from 'stores/Cashier/cashier.thunk-actions';
import { getEFTPaymentStatus } from 'stores/EftPayment/eftPayment.selector';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';

interface InactivityGuardProps {
  idleTimeSeconds: number;
}

export default function InactivityGuard({ idleTimeSeconds }: InactivityGuardProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { sizingBig } = useTheme();
  const [t] = useTranslation('common');
  const [remainingSeconds, setRemainingSeconds] = useState<number>(idleTimeSeconds);
  const eftStatus = useAppSelector(getEFTStatus);
  const eftStatusV2 = useAppSelector(getEFTPaymentStatus);
  const { loggedCashier } = useAppSelector((state) => state.cashier);

  const appInsights = getAppInsights();

  const handleOnIdle = (): void => {
    dispatch(automaticCashierLogOut());

    appInsights.trackEvent({
      name: `[AutoSignout][InactivityGuard] User ${loggedCashier?.id} signed out due to inactivity`,
    });
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: 1000 * idleTimeSeconds,
    onIdle: handleOnIdle,
    events: ['mousemove', 'keydown', 'touchstart'],
    element: document,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (eftStatus === 'PENDING' || eftStatusV2 !== 'IDLE') {
        reset();
        return;
      }
      setRemainingSeconds(Math.round(getRemainingTime() / 1000));
    }, 1000);

    return (): void => clearInterval(interval);
  });

  function handleClose(): void {
    setRemainingSeconds(idleTimeSeconds);
  }

  const remainingSecondsThreshold = 30;
  const progressVal = Math.round(remainingSeconds * (100 / remainingSecondsThreshold));

  return (
    <>
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        open={remainingSeconds <= remainingSecondsThreshold}
        data-testid="idle-time-popup"
      >
        <AppBar position="relative">
          <DialogTitle id="simple-dialog-title">{t('Idle timer')}</DialogTitle>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${sizingBig(2)} ${sizingBig(4)}`,
          }}
        >
          <Typography variant="h4">{t('You will be logged out')}</Typography>
          <Box
            sx={{
              position: 'relative',
              height: sizingBig(10),
              width: sizingBig(10),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 4,
            }}
          >
            <Typography variant="h3">
              {remainingSeconds <= remainingSecondsThreshold ? remainingSeconds : ''}
            </Typography>
            <CircularProgress
              variant="determinate"
              defaultValue={0}
              value={progressVal}
              sx={{ position: 'absolute' }}
              color="primary"
              size={250}
            />
          </Box>
          <Typography variant="h4">{t('Touch screen to stop timer')}</Typography>
        </Box>
      </Dialog>
    </>
  );
}
