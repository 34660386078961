import React, { useEffect, useMemo } from 'react';
import { Box, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getActivePayment, getIsActivePaymentCashType } from 'stores/OrderPayment/orderPayment.selector';
import { AnimatePresence, motion } from 'framer-motion';
import BackspaceIcon from '@mui/icons-material/Backspace';
import {
  getPaymentFormatter,
  getStringToNumberFormatter,
  getStringToPaymentFormatter,
} from 'stores/Payments/payment.selector';
import { Controller, useFormContext } from 'react-hook-form';
import Validators from 'utils/validation/ValidationConstants';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { PaymentConfiguration } from 'stores/Config/configTypes';
import CloseIcon from '@mui/icons-material/Close';
import NumericKeypad, { KeyboardKey } from 'components/Shared/NumericKeypad/NumericKeypad';
import { Keypad, PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { calculateChange } from 'stores/OrderPayment/orderPayment.actions';

const TipEdition: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { spacing, palette, typography } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const formatStringToPayment = useAppSelector(getStringToPaymentFormatter());
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter());
  const { changeAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const { payment } = useAppSelector(({ config }) => config);

  const activePayment = useAppSelector(getActivePayment);
  const isActiveCashPayment = useAppSelector(getIsActivePaymentCashType());
  const initialFullChange = useMemo(() => (changeAmount ?? 0) + (activePayment?.tipValue ?? 0), []);
  const { control, reset, setValue, getValues, formState, watch, errors, trigger } =
    useFormContext<OrderPaymentForm>();

  const tipInputValue = watch('tipInput');
  const tipInputNumberValue = formatStringToNumber(tipInputValue);
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);

  const minimumEditedTipAmount = isActiveCashPayment ? payment?.minimumChangeAmount ?? 0 : 0;

  const tipsKeypad = Keypad.concat({
    displayName: `<-`,
    component: <BackspaceIcon />,
    numSx: { background: palette.grey[800], px: 2, ...typography.button, color: palette.white },
    action: () => {
      setValue('tipInput', formatToDisplay(0, true, false));
    },
  } as KeyboardKey);

  useEffect(() => {
    activePayment?.tipValue && setValue('tipInput', formatToDisplay(activePayment.tipValue, true, false));
  }, []);

  useEffect(() => {
    trigger().then((isValid) => {
      isValid &&
        dispatch(calculateChange({ paymentAmount: cashInputNumberValue, tipAmount: tipInputNumberValue }));
    });
  }, [tipInputValue]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          mb: 2,
          gap: 2,
        }}
      >
        <Controller
          rules={{
            maxLength: {
              value: 10,
              message: t('Value is too long'),
            },
            validate: {
              isAboveMinimumTipEditionValue: (val: string): boolean | string => {
                const value = formatStringToNumber(val);
                if (value === 0) return true;
                return (
                  Validators.IsAboveGivenValue.Validator(formatStringToNumber(val), minimumEditedTipAmount) ||
                  t('Value is too small')
                );
              },

              isMoney: (val: string): boolean | string =>
                Validators.Money.Validator(payment as PaymentConfiguration).test(val) || Validators.Money.Message,
            },
          }}
          defaultValue=""
          control={control}
          name="tipInput"
          render={(field) => (
            <TextField
              type="text"
              variant="outlined"
              label={t('Tip')}
              error={errors.tipInput && true}
              helperText={errors.tipInput && errors.tipInput.message}
              InputProps={{
                sx: { px: 2, py: 2, fontSize: 20, fontWeight: 500, color: palette.grey[600] },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ display: 'flex', alignItems: 'center', mt: '0px !important' }}
                  >
                    <Typography variant="h6" sx={{ color: palette.grey[500] }}>
                      {payment?.currencySymbol ?? ''}
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        reset({ tipInput: formatToDisplay(0, true, false), cashInput: getValues().cashInput });
                      }}
                    />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: '10',
                  autoFocus: true,
                  style: {
                    padding: '0',
                    color: tipInputNumberValue !== 0 ? 'black' : 'inherit',
                    WebkitTextFillColor: tipInputNumberValue !== 0 ? 'black' : 'inherit',
                  },
                  'data-testid': `order-payment-tip-amount-input`,
                },
              }}
              {...field}
            />
          )}
        />
        <>
          <ImportExportIcon
            sx={{ transform: 'rotate(90deg)' }}
            onClick={() => {
              const change = changeAmount ?? 0;

              if (change === 0) {
                setValue('tipInput', formatToDisplay(0, true, false));
              } else {
                setValue('tipInput', formatToDisplay(initialFullChange, true, false));
              }
            }}
          />
          <Box
            sx={{
              px: 2,
              py: 1.5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              background: palette.grey[50],
              borderRadius: spacing(1),
              width: '33%',
              overflow: 'hidden',
            }}
          >
            <Typography variant="caption">{t('Change')}</Typography>
            <Typography variant={changeAmount && formatToDisplay(changeAmount).length > 8 ? 'body1' : 'h6'}>
              {!formState.errors.tipInput && changeAmount !== undefined && changeAmount >= 0
                ? formatToDisplay(changeAmount)
                : '--'}
            </Typography>
          </Box>
        </>
      </Box>
      <AnimatePresence initial={false}>
        <motion.div
          key="tipValueNumpad"
          variants={PaymentModalAnimations.variants}
          initial="slideDown"
          animate="onPlace"
          transition={PaymentModalAnimations.transition}
        >
          <NumericKeypad
            keys={tipsKeypad}
            onChange={(clickedValue) => {
              if ((clickedValue === '0' || clickedValue === '00') && !formState.isDirty) return;
              const currentValue = getValues().tipInput;
              const newValue = formState.isDirty ? currentValue + clickedValue : clickedValue;
              if (newValue.length > 7) return;
              const numberValue = formatStringToPayment(newValue);
              setValue('tipInput', numberValue, { shouldValidate: true, shouldDirty: true });
            }}
          />
        </motion.div>
      </AnimatePresence>
    </>
  );
};
export default TipEdition;
