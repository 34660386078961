import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { FloorEntity, TableElement } from 'typings/Tables';
import { Layer as LayerRef } from 'konva/lib/Layer';
import { Stage as StageRef } from 'konva/lib/Stage';
import { KonvaEventObject } from 'konva/lib/Node';
import { getBackgroundUrl } from 'utils/floorsPlanner';
import ReadOnlyTableObject from './ReadOnlyTableObject';
import buildClasses from './ReadOnlyCanvas.css';

interface CustomizableCanvasProps {
  floor: FloorEntity;
  onSelect(table?: TableElement): void;
  wrapperHeight?: number;
  wrapperWidth?: number;
  openOrders: string[];
  selectedTableName?: string;
}

function ReadOnlyCanvas({
  floor,
  onSelect,
  wrapperHeight,
  wrapperWidth,
  openOrders,
  selectedTableName,
}: CustomizableCanvasProps) {
  const layerRef = useRef<LayerRef>(null);
  const stageRef = useRef<StageRef>(null);

  const [ratioX, setRatioX] = useState(1);
  const [ratioY, setRatioY] = useState(1);

  const { classes } = buildClasses();

  useEffect(() => {
    if (floor.defaultCanvasSize) {
      if (wrapperWidth) {
        setRatioX(wrapperWidth / floor.defaultCanvasSize.width);
      }
      if (wrapperHeight) {
        setRatioY(wrapperHeight / floor.defaultCanvasSize.height);
      }
    }
  }, [wrapperWidth, wrapperHeight, floor.defaultCanvasSize?.width, floor.defaultCanvasSize?.height]);

  if (!wrapperHeight || !wrapperWidth) {
    return <></>;
  }

  const checkDeselect = (e: KonvaEventObject<MouseEvent>) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      onSelect();
    }
  };

  return (
    <Stage
      width={wrapperWidth}
      height={wrapperHeight}
      className={classes.stage}
      style={{
        backgroundImage: `url(${getBackgroundUrl(floor)})`,
        backgroundSize: '100% 100%',
      }}
      ref={stageRef}
      onMouseDown={checkDeselect}
      onTap={checkDeselect}
    >
      <Layer ref={layerRef}>
        {floor.tables.map((table) => (
          <ReadOnlyTableObject
            id={table.id}
            placement={table.placement}
            size={table.size}
            name={table.name}
            onSelectFn={() => onSelect(table)}
            key={table.id}
            type={table.type}
            ratioX={ratioX}
            ratioY={ratioY}
            isActive={openOrders.includes(table.name)}
            isSelected={selectedTableName === table.name}
          />
        ))}
      </Layer>
    </Stage>
  );
}

export default ReadOnlyCanvas;
