import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Step, StepLabel, Stepper } from '@mui/material';
import LanguageSwitch from 'components/Settings/LanguageSwitchButton/LanguageSwitch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StoreIcon from '@mui/icons-material/Store';
import DoneIcon from '@mui/icons-material/Done';
import PrintIcon from '@mui/icons-material/Print';
import { useTranslation } from 'react-i18next';
import ProcessingComponent from 'components/Shared/Loaders/ProcessingComponent';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector from '@mui/material/StepConnector';
import LogIn from 'components/Auth/LogIn';
import ConfigureScreen from 'containers/FirstTimeLaunch/Pages/ConfigureScreen';
import SelectStoreScreen from 'containers/FirstTimeLaunch/Pages/SelectStoreScreen';
import FirstLaunchSummary from 'containers/FirstTimeLaunch/Pages/FirstLaunchSummary';
import { logOut, verifyUser } from 'utils/auth';
import { useAppDispatch } from 'hooks/useRedux';
import { StoreConfiguration } from 'typings/Store';
import { saveInitialSetup } from 'stores/Setup/setup.thunk-actions';
import buildClasses from './FirstTimeLaunch.css';

function StepIcons({ icon, active, completed }: StepIconProps): JSX.Element {
  const { classes, cx } = buildClasses();

  const icons: { [index: string]: React.ReactElement } = {
    1: <AccountCircleIcon />,
    2: <StoreIcon />,
    3: <PrintIcon />,
    4: <DoneIcon />,
  };

  return (
    <div
      className={cx(classes.step, {
        [classes.activeStep]: active,
        [classes.completedStep]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}

export interface BasicConfiguration {
  storeId: string;
  printingToolPath: string;
  htmlToPdfConverterPath: string;
  printReceiptsAfterOrderFinalization: boolean;
  targetPrinterName: string;
  isRollPrinter: boolean;
  printCopies: number;
  printEmployeeNameOnReceipt: boolean;
}

export interface LocalPrintingConfiguration {
  localPrintingEnabled: boolean;
  printingToolPath: string;
  htmlToPdfConverterPath: string;
  targetPrinterName: string;
  printCopies: number;
  printReceiptsAfterOrderFinalization: boolean;
}

export interface GeneralPrintingConfiguration {
  printEmployeeNameOnReceipt: boolean;
  isRollPrinter: boolean;
}

export interface PrintingConfiguration {
  general: GeneralPrintingConfiguration;
  local: LocalPrintingConfiguration;
}

interface ProcessingComponentSwitch {
  inProgress: boolean;
  text: string;
}

const FirstTimeLaunchFrame: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('settings');
  const [activeStep, setActiveStep] = React.useState(0);
  const [processing, setProcessingComponent] = React.useState<ProcessingComponentSwitch>({
    inProgress: false,
    text: '',
  });
  const [store, setStore] = React.useState<StoreConfiguration>({ id: 0, name: '' });
  const [localPrintingConfiguration, setLocalPrintingConfiguration] = React.useState<LocalPrintingConfiguration>({
    localPrintingEnabled: false,
    htmlToPdfConverterPath: 'C:\\Program Files\\wkhtmltopdf\\bin\\wkhtmltopdf.exe',
    printReceiptsAfterOrderFinalization: false,
    printingToolPath: 'C:\\Program Files (x86)\\Foxit Software\\Foxit Reader\\FoxitReader.exe',
    targetPrinterName: '',
    printCopies: 1,
  });
  const [generalPrintingConfiguration, setGeneralPrintingConfiguration] =
    React.useState<GeneralPrintingConfiguration>({
      printEmployeeNameOnReceipt: false,
      isRollPrinter: false,
    });
  const dispatch = useAppDispatch();

  function getSteps(): string[] {
    return [t('Log in'), t('Select store'), t('Printing Settings'), t('Summary')];
  }

  const steps = getSteps();
  const history = useHistory();

  const enableProcessing = (text: string): void => {
    setProcessingComponent({
      inProgress: true,
      text,
    });
  };

  const disableProcessing = (): void => {
    setProcessingComponent({
      inProgress: false,
      text: '',
    });
  };

  const handleNext = (): void => {
    const stepsCount = getSteps().length - 1;
    if (activeStep <= stepsCount) {
      setActiveStep((prev) => prev + 1);
    }
    if (activeStep === stepsCount) {
      enableProcessing(t('Saving configuration...'));
      dispatch(
        saveInitialSetup({
          printingConfiguration: {
            general: generalPrintingConfiguration,
            local: localPrintingConfiguration,
          },
          storeId: store.id ? store.id.toString() : null,
        }),
      );
      setTimeout(() => {
        disableProcessing();
        history.push('/app-config');
        logOut(); // config saved, log out now.
        window.location.reload();
      }, 1500);
    }
  };

  const handleReset = (): void => {
    logOut();
    setActiveStep(0);
  };
  const handleBack = (): void => {
    if (activeStep >= 2) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleStoreIdSelected = (id: number, name?: string): void => {
    enableProcessing(t('Selecting store...'));
    setStore({ id, name: name || '' });
    setTimeout(() => {
      disableProcessing();
    }, 500);
    handleNext();
  };

  const handlePrinterConfiguration = (
    foxitLocation: string,
    wkhtmlLocation: string,
    usePrinter: boolean,
    selectedPrinter: string,
    useRoll: boolean,
    printingCopies: number,
    printEmployeeNameOnReceipt: boolean,
  ): void => {
    setLocalPrintingConfiguration({
      localPrintingEnabled: !!selectedPrinter,
      htmlToPdfConverterPath: wkhtmlLocation,
      printReceiptsAfterOrderFinalization: usePrinter,
      printingToolPath: foxitLocation,
      targetPrinterName: selectedPrinter,
      printCopies: printingCopies,
    });
    setGeneralPrintingConfiguration({
      printEmployeeNameOnReceipt,
      isRollPrinter: useRoll,
    });
    handleNext();
  };

  function SwitchCurrentStepContent(): JSX.Element {
    switch (activeStep) {
      case 0:
        return <LogIn />;
      case 1:
        return (
          <SelectStoreScreen
            preselectedStoreId={store.id ? Number(store.id) : undefined}
            onSuccess={handleStoreIdSelected}
            onFailure={handleReset}
          />
        );
      case 2:
        return (
          <ConfigureScreen
            onSuccess={handlePrinterConfiguration}
            onFailure={handleBack}
            printerConfig={{
              general: {
                printEmployeeNameOnReceipt: generalPrintingConfiguration.printEmployeeNameOnReceipt,
                isRollPrinter: generalPrintingConfiguration.isRollPrinter,
              },
              local: {
                localPrintingEnabled: !!localPrintingConfiguration.targetPrinterName,
                printingToolPath: localPrintingConfiguration.printingToolPath,
                printReceiptsAfterOrderFinalization:
                  localPrintingConfiguration.printReceiptsAfterOrderFinalization,
                targetPrinterName: localPrintingConfiguration.targetPrinterName,
                htmlToPdfConverterPath: localPrintingConfiguration.htmlToPdfConverterPath,
                printCopies: localPrintingConfiguration.printCopies,
              },
            }}
          />
        );
      case 3:
        return (
          <FirstLaunchSummary
            onSuccess={handleNext}
            onFailure={handleBack}
            store={store}
            printingConfig={{ general: generalPrintingConfiguration, local: localPrintingConfiguration }}
          />
        );
      default:
        return <ProcessingComponent text={processing.text} />;
    }
  }

  useEffect(() => {
    enableProcessing(t('Trying log in'));

    if (verifyUser() && activeStep === 0) {
      setActiveStep(1);
    }
    disableProcessing();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.langSwitch}>
        <LanguageSwitch />
      </div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        data-testid="first-config__stepper"
        connector={
          <StepConnector
            className={classes.stepperLine}
            classes={{ active: classes.activeLine, line: classes.line, completed: classes.completedLine }}
          />
        }
        className={classes.stepperContainer}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={StepIcons}
              classes={{ active: classes.stepLabel, completed: classes.stepLabel }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {processing.inProgress ? (
        <ProcessingComponent text={processing.text} />
      ) : (
        <div className={classes.content}>{SwitchCurrentStepContent()}</div>
      )}
    </div>
  );
};
export default FirstTimeLaunchFrame;
