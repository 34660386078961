import React from 'react';

import { ProductSetStep } from 'typings/Products';
import { Typography, Box, useTheme } from '@mui/material';
import { Check, PriorityHighRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import buildClasses from './ProductSetSteps.css';

interface OptionTab extends ProductSetStep {
  isCompleted?: boolean;
}

interface ProductSetStepsProps {
  tabs: OptionTab[];
  selectTab: (step: number) => void;
  selectedTab: number;
}

const ProductSetSteps = ({ tabs, selectTab, selectedTab }: ProductSetStepsProps) => {
  const { classes, cx } = buildClasses();
  const { spacing, palette, radius, sizing } = useTheme();
  const [t] = useTranslation('intake');

  const currentTab = tabs.find((tab) => tab.order === selectedTab);
  return (
    <>
      {tabs.length > 1 && (
        <Box
          sx={{ display: 'flex', marginBottom: spacing(2) }}
          data-testid="product-set-configuration__button-group"
        >
          {tabs.map((tab) => (
            <div
              className={cx(classes.tab, { [classes.tabActive]: tab.order === selectedTab })}
              onClick={() => selectTab(tab.order)}
              data-testid={`product-set-configuration__button--${tab.order}`}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: spacing(0.5) }}>
                <Typography
                  variant="subtitle2"
                  className={cx(classes.badge, { [classes.activeBadge]: tab.order === selectedTab })}
                >
                  {tab.order + 1}
                </Typography>
                {tab.requiredAmountOfToppings > 0 && !tab.isCompleted && (
                  <div className={cx(classes.badge, classes.requiredWarning)}>
                    <PriorityHighRounded sx={{ color: palette.white, height: sizing.propertyIcon }} />
                  </div>
                )}
                {tab.isCompleted && (
                  <div className={cx(classes.badge, classes.badgeComplete)}>
                    <Check sx={{ height: sizing.propertyIcon }} />
                  </div>
                )}
              </Box>
              <Typography variant="body2">{tab.title}</Typography>
            </div>
          ))}
        </Box>
      )}
      {currentTab && (
        <Box
          sx={{
            marginBottom: spacing(2),
            backgroundColor: palette.customGray.main,
            borderRadius: radius.sm,
            padding: `${spacing(1)} ${spacing(2)}`,
            color: palette.black.main,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="subtitle1">{currentTab.title}</Typography>
          {currentTab.requiredAmountOfToppings > 0 ? (
            <Box
              sx={{
                backgroundColor: palette.red[600],
                padding: `${spacing(0.5)} ${spacing(1)}`,
                borderRadius: radius.sm,
                color: palette.white,
              }}
            >
              <Typography variant="body2">{t('Mandatory')}</Typography>
            </Box>
          ) : (
            <Typography variant="body2">{t('optional')}</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductSetSteps;
