import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    customerName: {
      color: palette.primary.dark,
      fontWeight: 'bold',
    },
  };
});

/*
.card {
  display: block;
  background: #FFFFFF;
  width: 300px;
  height: 300px;
  border-radius: 2px;
  margin: 10px;
  display: inline-block;
  align: center;
  transition: all .25s linear;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.4);
}

.card:hover{
  box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
}
*/

export default buildClasses;
