import React, { useEffect, useRef } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { getNoOfOrdersToConfirmEta } from 'stores/Notifications/notifications.thunk-actions';

export default function EtaConfirmationNotifier(): JSX.Element {
  const { noOfOrdersToConfirmEta } = useAppSelector(({ notifications }) => notifications);
  const dispatch = useDispatch();
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');
  const pollingInterval = useRef<any>(null);
  const history = useHistory();
  const pollingIntervalInSec = 60;
  const transparentColor = 'rgba(0, 0, 0, 0)';
  const animationVariants = {
    active: {
      backgroundColor: palette.yellow[600],
    },
    inactive: {
      backgroundColor: transparentColor,
      transition: {
        repeat: 0,
      },
    },
  };

  useEffect(() => {
    dispatch(getNoOfOrdersToConfirmEta());
    startOrdersToConfirmPooling(false);
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, []);

  useEffect(() => {
    startOrdersToConfirmPooling(true);
  }, [noOfOrdersToConfirmEta]);

  function startOrdersToConfirmPooling(withReset: boolean) {
    if (withReset) {
      clearInterval(pollingInterval.current);
    }

    pollingInterval.current = setInterval(() => {
      dispatch(getNoOfOrdersToConfirmEta());
    }, pollingIntervalInSec * 1000);
  }

  function goToMakescreen() {
    history.push('/makescreen?init=eta-confirm');
  }

  return (
    <Box sx={{ borderRight: `1px solid ${palette.white}`, mr: 2, pr: 2 }}>
      <motion.div
        variants={animationVariants}
        initial={{ borderRadius: spacing(0.5), backgroundColor: 'rgba(0, 0, 0, 0)' }}
        animate={noOfOrdersToConfirmEta > 0 ? 'active' : 'inactive'}
        transition={{ repeatType: 'reverse', repeat: Infinity, duration: 0.8 }}
      >
        <Box
          data-testid="eta-confirmation-conatainer"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: spacing(0.5),
            color: palette.white,
          }}
        >
          <Box sx={{ pl: 0.5, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                px: 1,
                background: 'red',
                borderRadius: spacing(0.5),
                textAlign: 'center',
              }}
            >
              {noOfOrdersToConfirmEta ?? 0}
            </Typography>
          </Box>
          <Button onClick={goToMakescreen} sx={{ px: 1, py: 1.5, color: palette.white }}>
            {t('NEW ORDERS')}
          </Button>
        </Box>
      </motion.div>
    </Box>
  );
}
