import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, mixins }) => {
  return {
    root: {
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${palette.secondary.dark}`,
      '&:first-of-type': {
        borderTop: `1px solid ${palette.secondary.dark}`,
      },
    },
    itemPriceDetails: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      color: palette.black.main,
    },
    discountTotalPrice: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      color: palette.black.main,
    },
    errorDescription: {
      display: 'flex',
      flexDirection: 'column',
      color: palette.black.main,
    },
    itemClickZone: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: `${spacing(0.5)} ${spacing(1)}`,
      width: '100%',
      height: '100%',
    },
    discountDetails: {
      padding: `${spacing(0.5)} ${spacing(1)}`,
      margin: spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      color: palette.black.main,
      wordBreak: 'break-word',
    },
    discountName: {
      fontWeight: 'bold',
    },
    discountDescription: {
      fontSize: '0.75rem',
    },
    discountIcon: {
      color: palette.virtualReceipt.items.discount.active.icon,
    },
    inactiveIcon: {
      color: palette.virtualReceipt.items.discount.inactive.icon,
    },
    deleteIcon: {
      color: palette.error.main,
    },
    listItemDetails: {
      padding: spacing(0.5),
      backgroundColor: palette.grey[50],
      borderBottom: `1px solid ${palette.secondary.dark}`,
    },
    listItemDetailsArrow: {
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: `14px solid ${palette.secondary.dark}`,
      position: 'relative',
      left: `calc(50% - ${spacing(1)})`,
      top: spacing(-0.5),
    },
    ...mixins.rotate,
  };
});
export default buildClasses;
