import React, { useState } from 'react';
import { IconButton, CardActionArea, Typography, Collapse, Box, useTheme } from '@mui/material';
import { BasketItem, DineInReceiptProduct, DiscountType, ReceiptDiscount } from 'typings/Basket';
import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { useTranslation } from 'react-i18next';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import ReceiptSetDiscountDetails from 'components/Intake/Receipt/Item/ReceiptSetDiscount/ReceiptSetDiscountDetails';
import buildClasses from './DineInReceiptDiscountItem.css';

interface DineInReceiptDiscountProps {
  discount: ReceiptDiscount;
  mealDealProducts?: DineInReceiptProduct[];
  removeItem?: (couponId: string) => void;
  customizeDiscount?: (discount: ReceiptDiscount) => void;
  customizeDiscountItem?: (item: BasketItem) => void;
  canRemoveDiscount: boolean;
}

const DineInReceiptDiscountItem: React.FC<DineInReceiptDiscountProps> = ({
  discount,
  removeItem,
  customizeDiscount,
  customizeDiscountItem,
  canRemoveDiscount,
  mealDealProducts,
}) => {
  const { classes, cx } = buildClasses();
  const { spacing, palette, sizing } = useTheme();
  const [t] = useTranslation('intake');
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const formatToDisplay = useAppSelector(getPaymentFormatter());

  function getDescription(): JSX.Element {
    if (discount.couponValidity === CouponValidity.Active) {
      return (
        <Typography
          variant="caption"
          className={classes.discountDescription}
          data-testid="receipt-item__label--description"
        >
          {t('Discount applied successfully')}
        </Typography>
      );
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', color: palette.black.main }}>
        <span>{t(discount.couponValidity)}</span>
      </Box>
    );
  }

  return (
    <>
      <div
        className={cx(classes.root, {
          [classes.active]: discount.couponValidity === CouponValidity.Active, // temp, will do it better
          [classes.inactive]: discount.couponValidity !== CouponValidity.Active, // temp, will do it better
        })}
        data-testid="receipt-discount__container"
      >
        <CardActionArea
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: `${spacing(0.5)} ${spacing(1)}`,
            width: '100%',
            height: '100%',
          }}
          onClick={(): void => {
            customizeDiscount && customizeDiscount(discount);
          }}
        >
          <LocalActivityIcon
            className={cx(classes.discountIcon, {
              [classes.inactiveIcon]: discount.couponValidity !== CouponValidity.Active, // temp, will do it better
            })}
          />
          <Box
            sx={{
              padding: `0px ${spacing(1)}`,
              margin: spacing(0.5),
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              wordBreak: 'break-word',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: palette.black.main, width: sizing.receiptItemTextWidth }}
              data-testid="receipt-item__label--name"
            >
              {discount.description}
            </Typography>
            {getDescription()}
          </Box>
          {discount.value && discount.discountType === DiscountType.FixedPrice && (
            <div>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', color: palette.black.main }}
                color="black"
              >
                {formatToDisplay(discount.value)}
              </Typography>
            </div>
          )}
        </CardActionArea>
        {mealDealProducts && mealDealProducts.length > 0 && (
          <IconButton
            onClick={(): void => {
              setShowDetails(!showDetails);
            }}
            data-testid="receipt-item-discount__button--customize-discount"
          >
            <ExpandMore className={cx(classes.rotate, { [classes.rotateActive]: showDetails })} />
          </IconButton>
        )}
        {canRemoveDiscount && (
          <IconButton
            onClick={(): void => {
              removeItem && removeItem(discount.couponId ?? '');
            }}
            className={classes.deleteIcon}
            data-testid="receipt-item-discount__button--delete-discount"
          >
            <DeleteOutline />
          </IconButton>
        )}
      </div>
      {discount.discountProducts && discount.discountProducts.length > 0 && (
        <Collapse in={!!showDetails}>
          <Box
            sx={{
              padding: spacing(0.5),
              borderBottom: `1px solid ${palette.secondary.dark}`,
              backgroundColor: palette.grey[50],
            }}
            data-testid="receipt-item__discount--discount-details"
          >
            <Box
              sx={{
                width: 0,
                height: 0,
                borderLeft: '20px solid transparent',
                borderRight: '20px solid transparent',
                borderTop: `14px solid ${palette.secondary.dark}`,
                position: 'relative',
                left: `calc(50% - ${spacing(1)})`,
                top: spacing(-0.5),
              }}
            />
            <ReceiptSetDiscountDetails
              canRemoveItem={false}
              customizeProductHandler={(product) => {
                customizeDiscountItem && customizeDiscountItem(product);
              }}
              discount={discount}
              discountProducts={discount.discountProducts}
            />
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default DineInReceiptDiscountItem;
