import React, { useState } from 'react';
import { Alert, Button, Menu, MenuItem, Typography } from '@mui/material';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import { logOutCashier } from 'stores/Cashier/cashier.thunk-actions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useRedux';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { Box, useTheme } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import buildClasses from './CashierBasicInfo.css';

export interface CashierBasicInfoProps {
  name: string;
  role?: string;
  showSwitchToOnlineButton: boolean;
  isClockedIn: boolean;
  isOnBreak?: boolean;
  onShiftBreakChangeClick: () => void;
}

const CashierBasicInfo: React.FC<CashierBasicInfoProps> = ({
  name,
  role,
  showSwitchToOnlineButton,
  isClockedIn,
  isOnBreak,
  onShiftBreakChangeClick,
}) => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { classes } = buildClasses();
  const [accountOptionsAnchorEl, setAccountOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(accountOptionsAnchorEl);

  function handleSwitchToOnlineClick() {
    const hybridModeCommunicator = getHybridModeCommunicator();

    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.SwitchToCloudPos);
  }

  const onAccountOptionsClick = (event: React.MouseEvent<HTMLElement>) =>
    setAccountOptionsAnchorEl(event.currentTarget);

  function onLockScreenClick() {
    dispatch(logOutCashier());
    setAccountOptionsAnchorEl(null);
  }

  function onShiftBreakOperationClick() {
    onShiftBreakChangeClick();
    setAccountOptionsAnchorEl(null);
  }

  return (
    <Box
      data-testid="account__container-logout"
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
    >
      {showSwitchToOnlineButton && (
        <Box sx={{ borderLeft: `1px solid ${palette.white}`, marginLeft: spacing(2) }}>
          <Button
            startIcon={<CloudDoneOutlinedIcon />}
            sx={{ color: palette.white }}
            onClick={handleSwitchToOnlineClick}
          >
            {t('Switch to online mode')}
          </Button>
        </Box>
      )}

      <Box>
        <Button
          id="account-options-button"
          sx={{ color: palette.white }}
          variant="text"
          disableElevation
          onClick={onAccountOptionsClick}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          <div className={classes.cashierInfoContainer}>
            <div>
              <Typography variant="h6" component="div" textAlign="right">
                {name}
              </Typography>
              {role && (
                <Typography variant="body2" component="div" textAlign="right">
                  {t(`roles:${role}`)}
                </Typography>
              )}
            </div>
            {isClockedIn && isOnBreak && (
              <Alert
                className={classes.employeeBreakWarningMessage}
                severity="warning"
                icon={false}
                data-testid="shift-paused-warning"
              >
                {t('Shift paused')}
              </Alert>
            )}
          </div>
        </Button>
        <Menu
          id="account-options-menu"
          className={classes.accountMenu}
          anchorEl={accountOptionsAnchorEl}
          open={open}
          onClose={() => setAccountOptionsAnchorEl(null)}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={onLockScreenClick} disableRipple>
            <LockOutlinedIcon />
            {t('Lock')}
          </MenuItem>
          {isClockedIn && !isOnBreak && (
            <MenuItem onClick={onShiftBreakOperationClick} disableRipple>
              <PauseIcon />
              {t('Pause shift')}
            </MenuItem>
          )}
          {isClockedIn && isOnBreak && (
            <MenuItem onClick={onShiftBreakOperationClick} disableRipple>
              <PlayArrowIcon />
              {t('Unpause shift')}
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  );
};
export default CashierBasicInfo;
