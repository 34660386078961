import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, mixins }) => {
  return {
    itemWrapper: {
      '&:first-of-type': {
        borderTop: `1px solid ${palette.secondary.dark}`,
      },
    },
    listItem: {
      height: sizing.receiptItemHeight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: palette.white,
    },
    itemPriceDetails: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: `0px ${spacing(1)}`,
    },
    itemName: {
      color: palette.black.main,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: sizing.receiptItemTextWidth,
    },
    itemClickZone: {
      padding: `${spacing(0.5)} ${spacing(1)}`,
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    itemDetails: {
      padding: `0px ${spacing(1)}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    itemDescription: {
      color: palette.black.main,
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: sizing.receiptItemTextWidth,
    },
    priceDetails: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    itemTotalPrice: {
      whiteSpace: 'nowrap',
      color: palette.black.main,
    },
    deleteIcon: {
      color: `${palette.error.main}`,
    },
    listItemDetails: {
      backgroundColor: palette.grey[50],
      padding: spacing(0.5),
    },
    listItemDetailsArrow: {
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: `14px solid ${palette.white}`,
      position: 'relative',
      left: spacing(5),
      transform: 'rotate(180deg)',
    },
    iconRotation: {
      transitionDuration: '0.5s',
      transitionProperty: 'transform',
      transform: 'rotate(180deg)',
      '&.active': {},
    },
    ...mixins.rotate,
  };
});
export default buildClasses;
