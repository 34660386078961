import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing, mixins }) => {
  return {
    wrapper: {
      backgroundColor: palette.background.default,
      overflowY: 'auto',
      height: sizing.full,
    },
    specialOffer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `${palette.primary.main}`,
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      padding: spacing(2),
      width: sizing.full,
      justifyContent: 'space-evenly',
    },
    button: {
      width: sizing.full,
      backgroundColor: palette.white,
      height: sizing.preFinalizeButtonHeight,
      color: palette.customGray.dark,
      margin: spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      margin: spacing(1),
    },
  };
});
export default buildClasses;
