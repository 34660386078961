import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';

import {
  getAllCardsAmount,
  getCardsToActivateAmount,
} from 'stores/GiftCardActivation/giftCardActivation.selector';

export type GiftCardActivationStep = 'scanOrManual' | 'enterCardNumber' | 'success' | 'failure';

const MultipleCardsIndicator: React.FC = () => {
  const [t] = useTranslation('common');
  const allGiftCards = useAppSelector(getAllCardsAmount);
  const { giftCards } = useAppSelector(({ giftCards }) => giftCards);
  const currentIndex = giftCards.findIndex((el) => el.isActiveStep);

  if (allGiftCards < 2) return null;

  return (
    <Typography variant="body1" color="black.main">
      ({currentIndex + 1}/{giftCards.length}) {t('cards')}
    </Typography>
  );
};
export default MultipleCardsIndicator;
