import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCustomizeProduct } from 'stores/Products';
import { Product, Option, Group } from 'typings/Products';
import { addToBasket } from 'stores/Basket/basket.thunk-actions';
import PagedCatalog from 'containers/Intake/Products/ProductCatalog/PagedCatalog';
import ScrollableCatalog from 'containers/Intake/Products/ProductCatalog/ScrollableCatalog';
import { Box, Chip, useTheme } from '@mui/material';
import { getOtherGroupCode, getPizzaCategoryCode } from 'stores/Config/config.selector';

type ProductCatalogProps = {
  products: Product[];
  groups: Group[];
};

const ProductCatalog: React.FC<ProductCatalogProps> = ({ products, groups }) => {
  const { options, activeCategoryCode, activeOptionId } = useAppSelector((store) => store.products);
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);
  const otherGroupCode = useAppSelector(getOtherGroupCode);
  const { usePagination } = useAppSelector((state) => state.config);
  const { palette, typography } = useTheme();

  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const [selectedGroupCodes, setSelectedGroupCodes] = useState<string[]>([]);
  const [availableGroupCodes, setAvailableGroupCodes] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  function sortGroupByName(sourceGroupCode: string, targetGroupCode: string): number {
    const sourceGroupName = getGroupDescription(sourceGroupCode) ?? '';
    const targetGroupName = getGroupDescription(targetGroupCode) ?? '';

    if (targetGroupCode === otherGroupCode || sourceGroupName.toLowerCase() < targetGroupName.toLowerCase()) {
      return -1;
    }

    if (sourceGroupCode === otherGroupCode || sourceGroupName.toLowerCase() < targetGroupName.toLowerCase()) {
      return 1;
    }

    return 0;
  }

  function getGroupDescription(groupCode: string | null): string | null {
    const currentGroup = groups.find((group) => {
      if (group.code === groupCode) return group.name;
      return null;
    });
    return currentGroup ? currentGroup.name : 'Other';
  }

  function addProductToBasket(product: Product): void {
    const optionId = activeOptionId ?? product.options.find((opt) => opt.isPriceAvailable)?.productOptionId;

    if (optionId) {
      dispatch(
        addToBasket({
          itemId: product.id,
          itemName: product.name,
          optionId,
          quantity: 1,
        }),
      );
    }
  }

  function customizeProduct(product: Product): void {
    dispatch(
      setCustomizeProduct({
        baseProduct: product,
        quantity: 1,
        originalOptionId:
          activeOptionId ??
          product.options.find((x) => x.default)?.productOptionId ??
          product.options[0].productOptionId,
      }),
    );
  }

  const productSelectedHandler = (product: Product): void => {
    if (product.isXTasty || product.isCYO) {
      customizeProduct(product);
    } else if (!selectedOption && product.options.length > 1) {
      customizeProduct(product);
    } else if (product.hasToppingsSelectionSteps) {
      customizeProduct(product);
    } else {
      addProductToBasket(product);
    }
  };

  useEffect(() => {
    const productGroupsCodes = Array.from(
      new Set(
        products
          .map((product) => (product.groupCode ? product.groupCode : otherGroupCode))
          .filter((val) => val != null),
      ),
    ).sort((sourceGroupCode, targetGroupCode) => sortGroupByName(sourceGroupCode, targetGroupCode));

    setAvailableGroupCodes(productGroupsCodes);
    setSelectedGroupCodes(productGroupsCodes);
  }, [products]);

  useEffect(() => {
    const optionFound = options.find((opt) => opt.id === activeOptionId);
    setSelectedOption(optionFound);
  }, [activeOptionId]);

  const productsFromSelectedGroups = products
    .map((product) => ({ ...product, groupCode: product.groupCode ?? 'Oth' }))
    .filter((el) => selectedGroupCodes.some((groupCode) => groupCode === el.groupCode));

  const showGroupsChips =
    usePagination?.productsPage && activeCategoryCode !== pizzaCategoryCode && availableGroupCodes.length > 1;

  return (
    <>
      {showGroupsChips && (
        <Box sx={{ display: 'flex', overflowX: 'auto' }}>
          {availableGroupCodes.map((groupCode) => {
            const isSelected = !!selectedGroupCodes.find((el) => el === groupCode);
            return (
              <Chip
                label={getGroupDescription(groupCode)}
                variant="outlined"
                key={groupCode}
                sx={{
                  color: palette.black.main,
                  fill: palette.black.main,
                  mx: 0.5,
                  my: 1,
                  backgroundColor: isSelected ? palette.grey[200] : 'transparent',
                  border: `1px solid ${isSelected ? palette.grey[500] : palette.grey[200]}`,
                  ...typography.body1,
                }}
                onClick={() => {
                  if (isSelected) {
                    setSelectedGroupCodes(selectedGroupCodes.filter((el) => el !== groupCode));
                  } else {
                    setSelectedGroupCodes([...selectedGroupCodes, groupCode]);
                  }
                }}
              />
            );
          })}
        </Box>
      )}
      {usePagination?.productsPage ? (
        <PagedCatalog products={productsFromSelectedGroups} onProductClick={productSelectedHandler} />
      ) : (
        <ScrollableCatalog
          groups={availableGroupCodes.map((agc) => ({ code: agc, name: getGroupDescription(agc) ?? '' }))}
          products={products}
          onProductClick={productSelectedHandler}
        />
      )}
    </>
  );
};

export default ProductCatalog;
