import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, IconButton, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { getIsAnyOrderActionPending } from 'stores/OrderPayment/orderPayment.selector';
import EnterCardDetails from 'components/Shared/GiftCardActivation/EnterCardDetails';
import { getCurrentGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.selector';
import { clearGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.slice';
import ActivationResult from 'components/Shared/GiftCardActivation/ActivationResult';
import ActivationInProgress from 'components/Shared/GiftCardActivation/ActivationInProgress';

export interface GiftCardActivationForm {
  cardNumberInput: string;
}

const GiftCardActivation: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { spacing, palette } = useTheme();
  const { details } = useAppSelector(({ giftCards }) => giftCards);

  const isAnyActionPending = useAppSelector(getIsAnyOrderActionPending);
  const hideCloseIcon = isAnyActionPending;
  const currentGiftCard = useAppSelector(getCurrentGiftCardActivation);

  const giftCardActivationForm = useForm<GiftCardActivationForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      cardNumberInput: '',
    },
  });

  const closeClicked = (): void => {
    dispatch(clearGiftCardActivation());
  };

  function getOrderHeader(): string {
    let header = t('Gift cards activation');
    if (details) {
      header += ` ${t('for ticket nr')} ${details.ticketNumber}`;
      if (details.tableName) {
        header += ` (${t('Table')}: '${details.tableName}')`;
      }
      if (details.orderReference) {
        header += ` (${t('Order reference')}: '${details.orderReference}')`;
      }
    }

    return header;
  }

  return (
    <Dialog
      onClose={closeClicked}
      open
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <FormProvider {...giftCardActivationForm}>
        <form
          onSubmit={(submitEvent) => {
            submitEvent?.preventDefault();
          }}
          autoComplete="off"
          name="giftCardActivationForm"
          id="giftCardActivationForm"
        >
          <Box sx={{ display: 'flex', py: 2, px: 3, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ color: palette.black.main }}>
              {getOrderHeader()}
            </Typography>
            {!hideCloseIcon && (
              <IconButton
                onClick={closeClicked}
                disableRipple
                sx={{
                  color: 'black',
                  fontSize: spacing(2.5),
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            {currentGiftCard?.activationStep === 'enterData' && <EnterCardDetails />}
            {currentGiftCard?.activationStep === 'inProgress' && <ActivationInProgress />}
            {(currentGiftCard?.activationStep === 'success' || currentGiftCard?.activationStep === 'failure') && (
              <ActivationResult />
            )}
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default GiftCardActivation;
