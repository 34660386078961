import { Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomerDisplayHeaderIcon } from 'stores/Cms/cms.selector';
import pizzaSliceIcon from 'assets/icons/pizza-slice.svg';

const CustomerDisplayHeader: React.FC = () => {
  const { palette, spacing, sizing } = useTheme();
  const [t] = useTranslation('customerDisplay');
  const cmsImage = useAppSelector(getCustomerDisplayHeaderIcon);

  return (
    <Box
      sx={{
        backgroundColor: palette.primary.main,
        paddingTop: '11px',
        paddingBottom: '11px',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: palette.primary.contrastText,
      }}
    >
      <Box
        component="img"
        src={cmsImage ? cmsImage.url : pizzaSliceIcon}
        alt="header-icon"
        sx={{ width: sizing.customerDisplayHeaderIconSize, height: sizing.customerDisplayHeaderIconSize }}
      />
      <Box
        sx={{
          marginLeft: spacing(1),
        }}
      >
        <Typography>{t('PIZZA')}</Typography>
        <Typography>{t('EVERYDAY')}</Typography>
      </Box>
    </Box>
  );
};

export default CustomerDisplayHeader;
