import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer, TablePagination, Typography, useTheme } from '@mui/material';
import { AvailableIntakeContents, DineInOrderStatus, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActivePaymentMethod } from 'stores/Intake';
import { PaymentSuccessfulResult } from 'typings/Payments';
import {
  cancelDineInOrder,
  fetchFilteredDineInOrders,
  getDineInOrder,
  postFinalizeDineInOrder,
} from 'stores/DineIn/dineIn-thunk.actions';
import { setDineInFilters, clearPendingItems } from 'stores/DineIn/dineIn.slice';
import { DineInOrder } from 'stores/DineIn/dineInTypes';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import uuidGenerator from 'utils/GuidGenerator';
import CancelDineInOrderDialog from './CancelDineInOrderDialog';
import DineInOrdersTableHeaders from './DineInOrdersTableHeaders';
import DineInOrdersTableRows from './DineInOrdersTableRows';

export type SortingOrder = 'asc' | 'desc';

interface DineInOrdersTableProps {
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  onOrderClick?: () => void;
}

const DineInOrdersTable: React.FC<DineInOrdersTableProps> = ({ pageIndex, setPageIndex, onOrderClick }) => {
  const { spacing } = useTheme();
  const [intakeTranslations] = useTranslation('intake');

  const { manualPriceOverride, activeIntakeTab } = useAppSelector(({ intake }) => intake);
  const { basketData } = useAppSelector((state) => state.basket);
  const { selectedStore } = useAppSelector((appState) => appState.stores);
  const { dineInOrders, selectedDineInOrder, dineInFilters, pendingItems } = useAppSelector(
    (appState) => appState.dineIn,
  );
  const { availablePaymentMethods } = useAppSelector(({ payments }) => payments);
  const { lastDineInRefreshMessage } = useAppSelector(({ notifications }) => notifications);
  const { activePaymentMethod } = useAppSelector((store) => store.intake);
  const [cancelationTabId, setCancelationTabId] = useState('');
  const [showCancelDineInOrder, setShowCancelDineInOrder] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    refreshDineInOrders();
  }, [dineInFilters, activeIntakeTab]);

  useEffect(() => {
    if (availablePaymentMethods && availablePaymentMethods.length > 0) {
      if (!availablePaymentMethods.find((el) => el.code === activePaymentMethod?.toString())) {
        dispatch(setActivePaymentMethod(availablePaymentMethods[0].code));
      }
    }
  }, [availablePaymentMethods]);

  useEffect(() => {
    if (dineInOrders && selectedDineInOrder) {
      const selectedDineInOrderInPage = dineInOrders.pageItems.find((o) => o.id === selectedDineInOrder.id);

      if (selectedDineInOrderInPage && selectedDineInOrderInPage.total !== selectedDineInOrder.total) {
        refreshDineInOrders();
      }
    } else if (!selectedDineInOrder) {
      refreshDineInOrders();
    }
  }, [selectedDineInOrder]);

  useEffect(() => {
    refreshDineInOrders();
  }, [lastDineInRefreshMessage]);

  function refreshDineInOrders(): void {
    if (selectedStore && activeIntakeTab === AvailableIntakeContents.DineInOpenOrders) {
      dispatch(fetchFilteredDineInOrders());
    }
  }

  function finalizeOrder(result: PaymentSuccessfulResult): void {
    if (!result.openTabId) {
      return;
    }

    dispatch(
      postFinalizeDineInOrder({
        tabId: result.openTabId,
        newOrderId: result.orderId,
        config: {
          paymentMethodCode: result.paymentMethodCode,
          eftPaymentOperation: result.eftPaymentOperation,
          manualPriceOverride,
        },
        shouldClear: result.orderId === selectedDineInOrder?.id,
        refreshConfig: true,
      }),
    );

    closeSettlePayment();
  }

  const handleSortChange = (property: string) => {
    const isAsc = dineInFilters.orderBy === property && dineInFilters.sortingOrder === 'asc';
    dispatch(setDineInFilters({ ...dineInFilters, sortingOrder: isAsc ? 'desc' : 'asc', orderBy: property }));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPageIndex(newPage);
    dispatch(setDineInFilters({ ...dineInFilters, rowIndex: newPage * dineInFilters.rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDineInFilters({ ...dineInFilters, rowsPerPage: parseInt(event.target.value, 10), rowIndex: 0 }));
    setPageIndex(0);
  };

  function handleFinalizeOrder(tabId: string, price: number): void {
    dispatch(
      openOrderPaymentFromSettlePayment({
        orderId: uuidGenerator(),
        openTabId: tabId,
        deliveryType: PickUpTypesValues.dineIn,
        closeCallback: closeSettlePayment,
        successCallback: finalizeOrder,
        totalToPay: price,
        isLocalOrder: true,
        cancelOnClose: true, // should it be like that?
      }),
    );
  }

  function closeSettlePayment(): void {
    dispatch(clearSettlePayment());
  }

  function handleCancelOrder(tabId: string): void {
    setCancelationTabId(tabId);
    setShowCancelDineInOrder(true);
  }

  function onCancelDineInOrderConfirm(reason: string): void {
    setShowCancelDineInOrder(false);
    dispatch(cancelDineInOrder({ tabId: cancelationTabId, reason }));
  }

  function onCancelDineInOrderAbort(): void {
    setShowCancelDineInOrder(false);
  }

  const [changeTableModalForOrderId, setChangeTableModalForOrderId] = useState<string | undefined>(undefined);

  async function handleOpenOrderClicked(dineInOrder: DineInOrder) {
    if (pendingItems?.length) {
      setChangeTableModalForOrderId(dineInOrder.id);
      return;
    }

    if (dineInOrder.status !== DineInOrderStatus.Opened || selectedDineInOrder?.id === dineInOrder.id) {
      if (onOrderClick) onOrderClick();
      return;
    }
    await dispatch(getDineInOrder(dineInOrder?.id));
  }

  function changeTable() {
    dispatch(getDineInOrder(changeTableModalForOrderId as string));
    cancelModal();
    dispatch(clearPendingItems());
  }

  function cancelModal() {
    setChangeTableModalForOrderId(undefined);
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="enhanced table">
          <DineInOrdersTableHeaders
            orderBy={dineInFilters.orderBy}
            sortingOrder={dineInFilters.sortingOrder}
            onSortingChange={handleSortChange}
            orderStatus={dineInFilters.orderStatus}
          />
          <TableBody>
            {dineInOrders && (
              <DineInOrdersTableRows
                dineInOrders={dineInOrders}
                selectedDineInOrder={selectedDineInOrder}
                total={basketData?.summary.total}
                orderStatus={dineInFilters.orderStatus}
                onOpenOrder={handleOpenOrderClicked}
                onFinalize={handleFinalizeOrder}
                onCancel={handleCancelOrder}
              />
            )}
            <ModalContainer
              onClose={cancelModal}
              title={intakeTranslations('Change table')}
              open={!!changeTableModalForOrderId}
              primaryButton={{
                label: intakeTranslations('Change table'),
                testId: 'change-table-modal-change-table',
                action: changeTable,
              }}
              secondaryButton={{
                label: intakeTranslations('Cancel'),
                testId: 'change-table-modal-cancel',
                action: cancelModal,
              }}
              testId="change-table-modal"
            >
              <Typography variant="body1" textAlign="center" sx={{ padding: spacing(3) }}>
                {intakeTranslations('Change table content')}
              </Typography>
            </ModalContainer>
          </TableBody>
        </Table>
      </TableContainer>
      {dineInOrders && dineInOrders.totalItemsCount > 10 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 15, 20]}
          colSpan={10}
          count={dineInOrders?.totalItemsCount ?? 0}
          rowsPerPage={dineInFilters.rowsPerPage}
          labelRowsPerPage={intakeTranslations('RowsPerPageLabel')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intakeTranslations('OfLabel')} ${count}`}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <CancelDineInOrderDialog
        showDialog={showCancelDineInOrder}
        orderId={cancelationTabId}
        onConfirm={onCancelDineInOrderConfirm}
        onAbort={onCancelDineInOrderAbort}
      />
    </>
  );
};

export default DineInOrdersTable;
