import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Box, useTheme } from '@mui/material';

export interface TableChangeConfirmationProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  currentTableName: string | undefined;
  targetTableName: string | undefined;
}

const TableChangeConfirmationModal: React.FC<TableChangeConfirmationProps> = ({
  onClose,
  open,
  onSuccess,
  currentTableName,
  targetTableName,
}) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');

  function onSubmit(): void {
    onSuccess();
    onClose();
  }

  function onReset(): void {
    onClose();
  }

  return (
    <ModalContainer
      onClose={onClose}
      open={open}
      title={t('Change table')}
      primaryButton={{
        type: 'submit',
        label: t('Change table'),
        testId: 'table-change-confirmation-modal__button--change-table',
        action: onSubmit,
      }}
      secondaryButton={{
        label: t('Cancel'),
        testId: 'table-change-confirmation-modal__button--cancel',
        action: onReset,
      }}
      testId="table-change-confirmation-modal"
    >
      <Box sx={{ overflowY: 'auto', padding: `${spacing(2)} ${spacing(3)}` }}>
        {`${t('Are you sure you want to change table')} ${currentTableName} ${t(
          'to table',
        )} ${targetTableName}? ${t('Not sent products will be removed from the basket.')}`}
      </Box>
    </ModalContainer>
  );
};

export default TableChangeConfirmationModal;
