import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '70%',
      margin: `${spacing(1.5)} auto`,
      padding: `${spacing(1.5)} ${spacing(2.5)}`,
      textAlign: 'start',
    },
    header: {
      paddingBottom: spacing(2),
    },
    subHeader: {
      '&:first-of-type': {
        padding: `0px 0px ${spacing(4)} ${spacing(4)}`,
      },
      padding: spacing(4),
    },
    stepButtons: {
      width: '70%',
      margin: `${spacing(3)} auto 0px auto`,
      display: 'flex',
      justifyContent: 'space-around',
    },
  };
});
export default buildClasses;
