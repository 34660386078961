import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: sizing.full,
      overflow: 'hidden',
      backgroundColor: palette.background.default,
    },
    navigation: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${spacing(2)} ${spacing(4)}`,
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16)',
    },
    content: {
      height: sizing.full,
      padding: spacing(4),
      overflowY: 'auto',
    },
    summaryBar: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: palette.white,
      padding: `${spacing(1.5)} ${spacing(2)}`,
      justifyContent: 'flex-end',
    },

    textField: {
      marginBottom: spacing(0.5),
    },
    textBlack: {
      color: palette.black.main,
    },
    priceField: {
      color: palette.black.main,
      padding: `${spacing(0.5)} ${spacing(2)}`,
    },
  };
});
export default buildClasses;
