import { useEffect, useState } from 'react';
import { floorsSelectors } from 'stores/Floors/floors.slice';
import { FloorEntity, TableElement } from 'typings/Tables';
import { saveFloors as dispatchSaveFloors } from 'stores/Floors/floors.thunk-actions';

import uuidGenerator from 'utils/GuidGenerator';
import { getLastSelectedFloorId, storeLastSelectedFloorId } from 'utils/floorsPlanner';
import { useAppDispatch, useAppSelector } from './useRedux';

export default function useFloorEditor() {
  const floorEntities = useAppSelector((state) => floorsSelectors.selectAll(state.floors));

  const MAX_FLOORS_COUNT = 4;
  const MIN_FLOORS_COUNT = 1;
  const newFloor = getNewFloor(undefined);

  const [floors, setFloors] = useState<FloorEntity[]>([newFloor]);
  const [selectedFloor, setSelectedFloor] = useState<FloorEntity>(newFloor);
  const [selectedObject, setSelectedObject] = useState<TableElement>();
  const [tableNumberError, setTableNumberError] = useState<'REQUIRED' | 'DUPLICATE'>();
  const [deletedFloorsIds, setDeleteFloorsIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (floorEntities && floorEntities.length > 0) {
      setFloors(floorEntities);

      const lastSelectedStoreId = getLastSelectedFloorId();
      const floorIdToSelect = lastSelectedStoreId ?? selectedFloor.id;
      const updatedSelectedFloor = floorEntities.find((f) => f.id === floorIdToSelect) ?? floorEntities[0];
      setSelectedFloor(updatedSelectedFloor);
    }
  }, [floorEntities]);

  function getNewFloor(canvasSize: FloorEntity['defaultCanvasSize']): FloorEntity {
    return {
      id: uuidGenerator(),
      name: 'New floor',
      tables: [],
      defaultCanvasSize: canvasSize,
    };
  }

  function addNewFloor(canvasSize: FloorEntity['defaultCanvasSize']) {
    const newFloor = getNewFloor(canvasSize);
    const updatedFloors = floors.map((floor) => (floor.id === selectedFloor.id ? selectedFloor : floor));
    setFloors([...updatedFloors, newFloor]);
    setSelectedFloor(newFloor);
    storeLastSelectedFloorId(newFloor.id);
  }

  function addNewTable(type: TableElement['type']) {
    setTableNumberError(undefined);
    const newTable = {
      id: uuidGenerator(),
      name: '',
      placement: {
        x: type === 'ROUND' ? 50 : 75,
        y: type === 'ROUND' ? 50 : 75,
        rotate: 0,
      },
      size: { width: 50, height: 50 },
      type,
    };
    setSelectedObject(newTable);
    setSelectedFloor((prev) => {
      const { tables } = prev as FloorEntity;
      return { ...(prev as FloorEntity), tables: [...tables, newTable] };
    });
  }

  function selectFloor(id: FloorEntity['id']) {
    setTableNumberError(undefined);
    setSelectedObject(undefined);

    const updatedFloors = floors.map((floor) => (floor.id === selectedFloor.id ? selectedFloor : floor));
    setFloors(updatedFloors);
    const newSelectedFloor = floors.find((floor) => floor.id === id);
    setSelectedFloor(newSelectedFloor as FloorEntity);
    storeLastSelectedFloorId(id);
  }

  function setFloorName(name: string) {
    setSelectedFloor((prev) => {
      return { ...(prev as FloorEntity), name };
    });
  }

  function duplicateFloor() {
    const newFloor = { ...selectedFloor };
    newFloor.id = uuidGenerator();
    newFloor.name = 'New floor';
    const updatedFloors = floors.map((floor) => (floor.id === selectedFloor.id ? selectedFloor : floor));

    setFloors([...updatedFloors, newFloor]);
    setSelectedFloor(newFloor);
    storeLastSelectedFloorId(newFloor.id);
  }

  function deleteFloor() {
    const updatedFloors = floors.filter((floor) => floor.id !== selectedFloor.id);

    setDeleteFloorsIds([...deletedFloorsIds, selectedFloor.id]);
    setTableNumberError(undefined);
    setFloors(updatedFloors);

    if (updatedFloors.length > 0) {
      setSelectedFloor(updatedFloors[0]);
    }
  }

  function updateBackground(background: string) {
    setSelectedFloor((prev) => {
      return { ...(prev as FloorEntity), background };
    });
  }

  function updateObject(size: TableElement['size'], placement: TableElement['placement']) {
    if (!selectedObject) return;

    const updatedObject = { ...selectedObject };
    updatedObject.size = size;
    updatedObject.placement = placement;

    updateTablesWithUpdatedObject(updatedObject);
  }

  function updateObjects(canvasWidth: number, canvasHeight: number) {
    let updatedFloors = floors;

    floors.forEach((floor) => {
      const updatedFloor = { ...floor };
      if (floor?.defaultCanvasSize) {
        const ratioX = canvasWidth / floor.defaultCanvasSize.width;
        const ratioY = canvasHeight / floor.defaultCanvasSize.height;
        floor.tables.forEach((table) => {
          const updatedObject = { ...table };
          updatedObject.size = { width: table.size.width * ratioX, height: table.size.height * ratioY };
          updatedObject.placement = {
            x: table.placement.x * ratioX,
            y: table.placement.y * ratioY,
            rotate: table.placement.rotate,
          };
          updatedFloor.tables = updatedFloor.tables.map((floorTable) =>
            floorTable.id === table.id ? updatedObject : floorTable,
          );
        });
        updatedFloor.defaultCanvasSize = { width: canvasWidth, height: canvasHeight };
        if (updatedFloor.id === selectedFloor.id) setSelectedFloor(updatedFloor);
      }
      updatedFloors = updatedFloors.map((item) => (floor.id === item.id ? updatedFloor : item));
    });

    setFloors(updatedFloors);
  }

  function saveFloors(onSuccessCallback: () => void) {
    setTableNumberError(undefined);

    const updatedFloors = floors.map((floor) => (floor.id === selectedFloor.id ? selectedFloor : floor));

    const mappedTables = updatedFloors.flatMap((floor) =>
      floor.tables.map((table) => {
        return { name: table.name, tableId: table.id, floorId: floor.id };
      }),
    );

    const tableNames = mappedTables.map((table) => table.name);
    let incorrectTableIndex: number | undefined;

    const hasTableWithoutName = tableNames.some((item, idx) => {
      if (!item || !item.trim()) {
        incorrectTableIndex = idx;
        return true;
      }
      return false;
    });

    if (incorrectTableIndex === undefined) {
      tableNames.forEach((item, idx) => {
        if (tableNames.indexOf(item) !== idx) {
          incorrectTableIndex = idx;
        }
      });
    }

    if (incorrectTableIndex !== undefined) {
      setTableNumberError(hasTableWithoutName ? 'REQUIRED' : 'DUPLICATE');

      const floorToEdit = updatedFloors.find(
        (floor) => floor.id === mappedTables[incorrectTableIndex as number].floorId,
      );

      if (floorToEdit) {
        setSelectedFloor(floorToEdit);
        const objectToUpdate = floorToEdit.tables.find(
          (table) => table.id === mappedTables[incorrectTableIndex as number].tableId,
        ) as TableElement;
        setSelectedObject(objectToUpdate);
      }
      return;
    }

    dispatch(dispatchSaveFloors({ currentState: updatedFloors, deletedFloorsIds }));
    setDeleteFloorsIds([]);
    onSuccessCallback();
  }

  function setDefaultCanvasSize(defaultCanvasSize: FloorEntity['defaultCanvasSize']) {
    setSelectedFloor((prev) => {
      return { ...prev, defaultCanvasSize };
    });
  }

  function rotateObject(direction: 'left' | 'right') {
    if (!selectedObject) return;

    let { rotate } = selectedObject.placement;
    if (direction === 'left') {
      rotate -= 90;
      if (rotate <= -360) {
        rotate += 360;
      }
    }
    if (direction === 'right') {
      rotate += 90;
      if (rotate >= 360) {
        rotate -= 360;
      }
    }
    const updatedObject = { ...selectedObject, placement: { ...selectedObject.placement, rotate } };

    updateTablesWithUpdatedObject(updatedObject);
  }

  function copyObject() {
    if (!selectedObject) return;

    const updatedFloor = { ...selectedFloor };
    const copiedObject = { ...selectedObject };
    copiedObject.id = uuidGenerator();
    copiedObject.placement = { x: 0, y: 0, rotate: selectedObject.placement.rotate };
    updatedFloor.tables.push(copiedObject);

    setSelectedObject(copiedObject);
    setSelectedFloor(updatedFloor);
  }

  function deleteObject() {
    if (!selectedObject) return;

    const updatedFloor = { ...selectedFloor };
    updatedFloor.tables = selectedFloor.tables.filter((table) => table.id !== selectedObject.id);

    setTableNumberError(undefined);
    setSelectedObject(undefined);
    setSelectedFloor(updatedFloor);
  }

  function setObjectNumber(text: string) {
    if (!selectedObject) return;

    const updatedObject = { ...selectedObject };
    updatedObject.name = text;

    setTableNumberError(undefined);
    updateTablesWithUpdatedObject(updatedObject);
  }

  function setObjectRotation(rotation: number) {
    if (!selectedObject) return;

    const updatedObject = { ...selectedObject };

    updatedObject.placement.rotate = rotation;
    updateTablesWithUpdatedObject(updatedObject);
  }

  function updateTablesWithUpdatedObject(updatedObject: TableElement) {
    if (!selectedObject) return;
    const updatedFloor = { ...selectedFloor };

    updatedFloor.tables = selectedFloor.tables.map((table) =>
      table.id === selectedObject.id ? updatedObject : table,
    );

    setSelectedObject(updatedObject);
    setSelectedFloor(updatedFloor);
  }

  function clearDeletedFloorsIds() {
    setDeleteFloorsIds([]);
  }

  return {
    addNewFloor,
    addNewTable,
    selectFloor,
    setFloorName,
    duplicateFloor,
    deleteFloor,
    saveFloors,
    setDefaultCanvasSize,
    rotateObject,
    copyObject,
    deleteObject,
    setObjectNumber,
    setObjectRotation,
    updateObject,
    updateObjects,
    selectedObject,
    setSelectedObject,
    tableNumberError,
    setTableNumberError,
    selectedFloor,
    floors,
    updateBackground,
    MAX_FLOORS_COUNT,
    MIN_FLOORS_COUNT,
    clearDeletedFloorsIds,
  };
}
