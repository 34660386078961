/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import CouponCard from 'components/Intake/Coupon/CouponCard/CouponCard';
import { Box, Card, useTheme } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import uuidGenerator from 'utils/GuidGenerator';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper';
import SwiperPagination, { ProductPagination } from 'components/Shared/Pagination/Pagination';

import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCustomizeProduct } from 'stores/Products';
import { BestSellerItem, Product } from 'typings/Products';
import { getCustomizationCouponMealSettings } from 'stores/Coupons';
import { Coupon } from 'typings/Coupons';
import { useWindowSize } from 'hooks/useWindow';
import { addCouponToTheBasket, addToBasket, editCouponInBasket } from 'stores/Basket/basket.thunk-actions';
import { getPizzaCategoryCode } from 'stores/Config/config.selector';
import buildClasses from './BestSellersCatalog.css';

const BestSellersCatalog: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const bestSellerPageRef = useRef<HTMLDivElement>(null);
  const { bestSellerCoupons } = useAppSelector((store) => store.coupons);
  const { bestSellerProducts, options } = useAppSelector((state) => state.products);
  const [pagination, setProductPagination] = useState<ProductPagination>({
    activePage: 0,
    pageItems: [[]],
  });
  const { usePagination } = useAppSelector((state) => state.config);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);
  const { sizing, breakpoints } = useTheme();
  const { width, height } = useWindowSize();
  const items = useMemo(() => {
    return generateBestSellerItems();
  }, [bestSellerCoupons, bestSellerProducts]);
  const dispatch = useAppDispatch();

  function generateBestSellerItems(): BestSellerItem[] {
    const numberOfBoardSlots = sizing.productCard.lineCount.md * sizing.bestSellerBoard.numberOfLines;
    const bestSellerItems: BestSellerItem[] = [];
    for (let i = 0; i <= numberOfBoardSlots; i += 1) {
      const bestSellerProduct = bestSellerProducts.find((el) => el.positionNumber === i);
      const bestSellerCoupon = bestSellerCoupons?.find((el) => el.positionNumber === i);
      if (bestSellerProduct) {
        bestSellerItems.push({
          product: bestSellerProduct.product,
          positionNumber: i,
          productOption: bestSellerProduct.product.options.find(
            (opt) => opt.productOptionId === bestSellerProduct.optionId,
          ),
        });
      } else if (bestSellerCoupon) {
        bestSellerItems.push({
          coupon: bestSellerCoupon.coupon,
          positionNumber: i,
        });
      } else {
        bestSellerItems.push({ positionNumber: i });
      }
    }
    return bestSellerItems;
  }

  function addProductToBasket(product: Product, optionId: number): void {
    dispatch(
      addToBasket({
        itemId: product.id,
        itemName: product.name,
        optionId,
        quantity: 1,
      }),
    );
  }

  function customizeProduct(product: Product, optionId: number): void {
    dispatch(
      setCustomizeProduct({
        baseProduct: product,
        quantity: 1,
        originalOptionId: optionId,
      }),
    );
  }

  const productSelectedHandler = (product: Product, bestSellerItem: BestSellerItem): void => {
    const optionId = (bestSellerItem.productOption?.productOptionId ??
      bestSellerItem.product?.options[0].productOptionId) as number;

    if (
      product.isXTasty ||
      product.isCYO ||
      (product.categoryCode !== pizzaCategoryCode && product.isCustomizable)
    ) {
      customizeProduct(product, optionId);
    } else {
      addProductToBasket(product, optionId);
    }
  };

  function addCouponToBasket(coupon2add: Coupon): void {
    if (coupon2add.couponId) {
      dispatch(editCouponInBasket(coupon2add));
    } else {
      dispatch(addCouponToTheBasket({ ...coupon2add, couponId: uuidGenerator() }));
    }
  }

  function handleCouponCardClicked(coupon2add: Coupon): void {
    if (coupon2add.useMealConfigurator) {
      dispatch(getCustomizationCouponMealSettings(coupon2add));
    } else {
      addCouponToBasket(coupon2add);
    }
  }

  const getProductRowAmount = (windowWidth: number | undefined): number => {
    if (windowWidth && windowWidth < breakpoints.values.md) return sizing.productCard.lineCount.xs;
    if (windowWidth && windowWidth > breakpoints.values.xl) return sizing.productCard.lineCount.xl;
    return sizing.productCard.lineCount.md;
  };

  const calculatePageCount = (): { itemsPerPage: number; pagesCount: number } => {
    const gridHeight = bestSellerPageRef.current?.clientHeight ?? 0;
    const productCartHeight = sizing.productCard.height;
    const itemsPerColumn = usePagination?.productsPage
      ? Math.floor((gridHeight - sizing.spacingBase * 5) / (productCartHeight + sizing.spacingBase * 1))
      : sizing.bestSellerBoard.numberOfLines;
    const itemsPerRow = getProductRowAmount(width);
    if (itemsPerColumn === 0) {
      return { pagesCount: Math.ceil(items.length / itemsPerRow), itemsPerPage: itemsPerRow };
    }

    const itemsPerPage = itemsPerColumn * itemsPerRow;
    const pagesCount = Math.ceil(items.length / itemsPerPage);
    return { pagesCount, itemsPerPage };
  };

  const recalculatePagination = () => {
    if (!usePagination) {
      setProductPagination({
        activePage: 0,
        pageItems: [[...items]],
      });
      return;
    }
    const { pagesCount, itemsPerPage } = calculatePageCount();
    const itemsPages = [];
    for (let i = 0; i < pagesCount; i += 1) {
      itemsPages.push(items.slice(i * itemsPerPage, (i + 1) * itemsPerPage));
    }
    setProductPagination({
      activePage: 0,
      pageItems: itemsPages,
    });
  };

  useEffect(() => {
    recalculatePagination();
  }, [width, height]);

  useEffect(() => {
    recalculatePagination();
  }, [items]);

  if (!items || items.length === 0)
    return (
      <>
        <div className={classes.noItemsContainer}>
          <div>
            <SentimentDissatisfiedIcon className={classes.noItemsIcon} />
            <div>
              <span>{t('No products found')}</span>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <Box
      sx={{ mt: 3, flex: 1, overflow: usePagination?.productsPage ? 'hidden' : 'auto' }}
      ref={bestSellerPageRef}
    >
      <Swiper
        modules={[Pagination, Mousewheel]}
        className={classes.swiper}
        mousewheel
        wrapperClass={classes.swiperWrapper}
        onSlideChange={(swiper) => {
          setProductPagination({
            ...pagination,
            activePage: swiper.activeIndex,
          });
        }}
      >
        {pagination.pageItems.map((page, pageIndex) => {
          return (
            <SwiperSlide className={classes.swiperSlide} key={`SWIPER_PAGE_${pageIndex}`}>
              {(page as BestSellerItem[]).map((item, itemIndex) => {
                const selectedOption = options.find((opt) => opt.id === item.productOption?.productOptionId);
                if (item.coupon)
                  return (
                    <CouponCard
                      key={`COUPON_${item.coupon?.couponCode ?? itemIndex}`}
                      coupon={item.coupon as Coupon}
                      onCouponSelected={handleCouponCardClicked}
                      classOverwrite={classes.cardRoot}
                    />
                  );
                if (item.product)
                  return (
                    <ProductCard
                      product={item.product}
                      selectedOption={selectedOption}
                      onProductSelected={(product: Product) => {
                        productSelectedHandler(product, item);
                      }}
                      key={`PRODUCT_${item.product.id}`}
                      displayImage={selectedStore?.displayProductImagesDuringIntake}
                    />
                  );
                if (!item.coupon && !item.product)
                  return <Card className={classes.cardRoot} key={`EMPTY_SLOT_${pageIndex}_${itemIndex}`} />;

                return null;
              })}
            </SwiperSlide>
          );
        })}
        <SwiperPagination activePage={pagination.activePage} pageItems={pagination.pageItems} />
      </Swiper>
    </Box>
  );
};

export default BestSellersCatalog;
