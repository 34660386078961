import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    filterOptionsList: {
      display: 'block',
      width: '250px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
      backgroundColor: palette.primary.main,
    },
    collapseButton: {
      color: palette.primary.contrastText,
      padding: '.625rem .9375rem 0 .9375rem',
      textTransform: 'none',
      margin: '10px 5px 0 0',
      borderRadius: 0,
    },
    activeButton: {
      backgroundColor: palette.primary.main,
    },
  };
});

export default buildClasses;
