import React, { useState } from 'react';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { postNewTab } from 'stores/DineIn/dineIn-thunk.actions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import NewTabModal from './NewTab/NewTab';

export interface NewDineInOrderProps {
  onNewTableCreated?: (tableName: string) => void;
}

const NewDineInOrder: React.FC<NewDineInOrderProps> = ({ onNewTableCreated }) => {
  const { spacing, sizing, palette } = useTheme();
  const dispatch = useAppDispatch();
  const [isNewCheckModalOpened, setIsNewCheckModalOpened] = useState(false);
  const [t] = useTranslation('intake');

  const { selectedStore } = useAppSelector((appState) => appState.stores);
  const { createDineInOrderDetails, isTableChangeInProgress } = useAppSelector((appState) => appState.dineIn);

  const addNewTab = (value: string) => {
    if (selectedStore && !isTableChangeInProgress) {
      dispatch(postNewTab({ tableIdentifier: value }));
    }

    if (onNewTableCreated) onNewTableCreated(value);
  };

  function onTabModalClose() {
    setIsNewCheckModalOpened(false);
  }

  return (
    <>
      <Button
        onClick={(): void => setIsNewCheckModalOpened(true)}
        sx={{
          padding: `${spacing(1)} ${spacing(2)}`,
          height: sizing.buttonBox.sm,
          backgroundColor: palette.white,
        }}
        variant="outlined"
        color="black"
        data-testid="open-orders__button--new-order"
      >
        {t('Create custom table')}
      </Button>
      <NewTabModal
        open={isNewCheckModalOpened}
        onClose={onTabModalClose}
        onSuccess={addNewTab}
        orderCreationDetails={createDineInOrderDetails}
      />
    </>
  );
};

export default NewDineInOrder;
