import React, { useEffect } from 'react';
import { Button, Typography, Box, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveProductCategory } from 'stores/Products';
import { Category } from 'typings/Products';
import { getBestSeller } from 'stores/Cms/cms.selector';
import bestSeller from 'assets/images/bestSeller.png';
import buildClasses from './ProductCategories.css';

const ProductCategories: React.FC = () => {
  const { classes, cx } = buildClasses();
  const { spacing } = useTheme();
  const dispatch = useAppDispatch();
  const { categories, activeCategoryCode } = useAppSelector((store) => store.products);
  const cmsImage = useAppSelector(getBestSeller);

  useEffect(() => {
    if (categories.length > 0) {
      if (!activeCategoryCode || !categories.find((cat) => cat.code === activeCategoryCode)) {
        dispatch(setActiveProductCategory(categories[0].code));
      }
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        margin: `0 0 ${spacing(0.5)} 0`,
        width: '100%',
        overflowX: 'auto',
        padding: spacing(0.5),
      }}
      data-testid="product-selection__container--categories"
    >
      {categories &&
        categories.map((category: Category) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: `0 ${spacing(2)} 0 ${spacing(2)}`,
            }}
            key={`category_${category.code}`}
          >
            <Button
              color="primary"
              className={cx(classes.categoryButton, {
                [classes.active]: activeCategoryCode === category.code,
              })}
              onClick={(): void => {
                dispatch(setActiveProductCategory(category.code));
              }}
              data-testid={`product-categories__button--${category.name}`}
            >
              {category.code === 'BSL' ? (
                <img
                  className={classes.categoryImg}
                  src={cmsImage ? cmsImage.url : bestSeller}
                  alt={category.code}
                />
              ) : category.imageUrl ? (
                <img className={classes.categoryImg} src={category.imageUrl} alt={category.code} />
              ) : (
                category.code
              )}
            </Button>
            <Typography
              variant="body2"
              className={cx(classes.categoryButtonLabel, {
                [classes.activeLabel]: activeCategoryCode === category.code,
              })}
            >
              {category.name}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default ProductCategories;
