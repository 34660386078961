import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    categoryButton: {
      border: 'none',
      color: palette.white,
      width: ' 50px',
      height: '50px',
      borderRadius: '32px ',
      backgroundColor: palette.grey[500],
      minWidth: '50px',
      '&:hover': {
        backgroundColor: palette.grey[600],
      },
    },
    categoryImg: {
      maxHeight: '90%',
      filter: 'invert(40%) brightness(1.5)',
    },
    categoryButtonLabel: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      textTransform: 'lowercase',
      marginTop: '5px',
      color: palette.grey[800],
      textAlign: 'center',
    },
    active: {
      backgroundColor: palette.primary.main,
      color: palette.white,
      '& img': {
        filter: 'none',
      },
    },
    activeLabel: {
      fontWeight: 500,
      color: palette.black.main,
    },
  };
});

export default buildClasses;
