import React from 'react';
import { Box, useTheme } from '@mui/material';

const FormPart: React.FC = ({ children }) => {
  const { spacing, palette } = useTheme();
  return (
    <Box
      sx={{
        background: `${palette.white}`,
        margin: spacing(4),
        padding: spacing(3),
        border: `1px solid ${palette.grey[200]}`,
      }}
    >
      {children}
    </Box>
  );
};
export default FormPart;
