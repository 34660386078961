import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TimekeepingButtons from 'components/Auth/TimekeepingButtons';
import { ClockinUser, CloudDownload } from 'assets/icons';
import {
  clockInCashier,
  clockOutCashier,
  getEmployeeCashBalance,
  logInCashier,
  resetCashierState,
  setLoginState,
} from 'stores/Cashier';
import { LoginStates } from 'typings/Cashier';
import PinPad from 'components/Auth/PinPad';
import { PIN_PAD_RETURN_TIMEOUT_MS } from 'App/global.consts';
import { getIsDesktop } from '../../stores/Config/config.selector';

const CashierAuth: React.FC = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('authentication');
  const [pin, setPin] = useState<string>('');
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [pinErrorMessage, setPinErrorMessage] = useState<string | undefined>(undefined);
  const { featureFlags } = useAppSelector(({ config }) => config);
  const { operationInProgress, loginState } = useAppSelector(({ cashier }) => cashier);
  const isTimekeepingCashBalance = featureFlags.OfflineModule_Timekeeping_CashBalance;
  const isDesktop = useAppSelector(getIsDesktop);

  function clearPin(): void {
    setPin('');
    setPinErrorMessage(undefined);
  }
  function updatePin(pinValue: string): void {
    setPinErrorMessage(undefined);
    setPin(pinValue);
  }
  function validatePin(): boolean {
    const isPinMissing = !pin;
    setPinErrorMessage(isPinMissing ? t('PIN is required') : undefined);
    return !isPinMissing;
  }

  function unlock(): void {
    if (!validatePin()) {
      return;
    }
    dispatch(logInCashier(pin));
  }

  function clockIn(): void {
    if (!validatePin()) {
      return;
    }
    dispatch(clockInCashier(pin));
  }

  function clockOut(): void {
    if (!validatePin()) {
      return;
    }
    if (isTimekeepingCashBalance) {
      dispatch(getEmployeeCashBalance(pin));
    } else {
      dispatch(clockOutCashier({ secret: pin }));
    }
  }

  useEffect(() => {
    if (loginState === LoginStates.error) {
      setPinErrorMessage(t('The PIN code is incorrect!'));
      setTimeout(() => {
        dispatch(resetCashierState());
      }, PIN_PAD_RETURN_TIMEOUT_MS);
    }
  }, [loginState]);

  const anchor = document.getElementById('auth-more-button');
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          id="auth-more-button"
          aria-controls={menuOpened ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpened ? 'true' : undefined}
          onClick={() => {
            setMenuOpened(true);
          }}
          endIcon={menuOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          variant="text"
          color="black"
        >
          {t('More')}
        </Button>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchor}
          open={menuOpened}
          color="black"
          onClose={() => {
            setMenuOpened(false);
          }}
          MenuListProps={{
            'aria-labelledby': 'auth-more-button',
          }}
        >
          <MenuItem
            color="palette.primary.main"
            sx={{ color: 'black' }}
            onClick={() => {
              setMenuOpened(false);
              dispatch(setLoginState(LoginStates.dataSync));
            }}
          >
            <CloudDownload />
            <Typography color="black.main" sx={{ ml: 1 }}>
              {t('DATA DOWNLOAD')}
            </Typography>
          </MenuItem>
          {!isDesktop && (
            <MenuItem
              color="black"
              onClick={() => {
                setMenuOpened(false);
                dispatch(setLoginState(LoginStates.clockedInEmployees));
              }}
            >
              <ClockinUser />
              <Typography color="black.main" sx={{ ml: 1 }}>
                {t('Show clocked in employees')}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
      <Typography color="black.main" variant="h3">
        {t('Enter your pin code')}
      </Typography>
      <PinPad
        pinCode={pin}
        clearPin={clearPin}
        submit={unlock}
        showExtraConfirm={false}
        onChange={updatePin}
        errorMessage={pinErrorMessage}
      />
      <TimekeepingButtons
        operationInProgress={operationInProgress}
        onUnlockClick={unlock}
        onClockInClick={clockIn}
        onClockOutClick={clockOut}
      />
    </Box>
  );
};
export default CashierAuth;
