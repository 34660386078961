import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalContainer from './ModalContainer';

interface GeneralPopupProps {
  showDialog: boolean;
  headerKey: string;
  text: string;
  onClose: () => void;
  onSubmit: () => void;
}

const GeneralPopup: React.FC<GeneralPopupProps> = ({ text, showDialog, headerKey, onClose, onSubmit }) => {
  const [t] = useTranslation('common');

  return (
    <ModalContainer
      onClose={onClose}
      title={t(headerKey)}
      open={showDialog}
      testId="text-field-modal"
      primaryButton={{
        label: t('OK'),
        testId: 'information-modal__button--ok',
        type: 'submit',
        action: onSubmit,
      }}
    >
      <Box
        sx={{ width: '100%', minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="subtitle2">{text}</Typography>
      </Box>
    </ModalContainer>
  );
};

export default GeneralPopup;
