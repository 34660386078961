import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { useTranslation } from 'react-i18next';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

const ConnectivityInfo: React.FC = () => {
  const [t] = useTranslation('common');
  const { spacing } = useTheme();
  const { connectivityStatus } = useAppSelector(({ config }) => config);
  const [initializedState, setInitializedState] = useState<boolean>(false);
  const [currentConnectionState, setCurrentConnectionState] = useState<ConnectivityStatusEnum>(
    ConnectivityStatusEnum.Disconnected,
  );

  useEffect(() => {
    if (!initializedState) {
      setInitializedState(true);
    }
  }, []);

  return (
    <>
      {connectivityStatus !== ConnectivityStatusEnum.Connected && (
        <Box
          data-testid="disconnected__container-info"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <CloudOffIcon sx={{ fontSize: spacing(3) }} />
          <div>
            <Typography component="div" variant="h6" sx={{ marginLeft: spacing(0.5) }}>
              <Box>{t('Disconnected')}</Box>
            </Typography>
          </div>
        </Box>
      )}
    </>
  );
};
export default ConnectivityInfo;
