import HomeIcon from '@mui/icons-material/Home';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IntakeWindow from 'containers/Intake/IntakeWindow';
import DataSync from 'containers/DataSync/DataSync';
import LocalOrders from 'containers/LocalOrders/LocalOrders';
import SettingsComponent from 'containers/Settings/Settings';
import Makescreen from 'containers/Makescreen/Makescreen';
import Dispatching from 'containers/Dispatching/Dispatching';
import CoreOrders from 'containers/CoreOrders/CoreOrders';
import Dashboard from 'containers/Dashboard/Dashboard';
import Store from 'containers/Store/Store';
import ExternalApi from 'containers/ExternalApi/ExternalApi';
import Notifications from 'containers/Notifications/Notifications';

import {
  LocalPizza,
  Settings,
  SvgIconComponent,
  OndemandVideo,
  AddToQueue,
  History,
  Store as StoreIcon,
  Dashboard as DashboardIcon,
  Transform,
  NotificationsNone,
  StoreOutlined,
} from '@mui/icons-material';
import AllOrders from 'containers/AllOrders/AllOrders';

const Routes: AppRoute[] = [
  {
    path: '/intake',
    navbarName: 'Intake',
    icon: HomeIcon,
    component: IntakeWindow,
    routeId: 0,
    isExperimental: false,
  },
  {
    path: '/local-orders',
    navbarName: 'Local orders',
    icon: LocalPizza,
    component: LocalOrders,
    routeId: 11,
    isExperimental: false,
  },
  {
    path: '/all-orders',
    navbarName: 'Orders [New]',
    icon: StoreOutlined,
    component: AllOrders,
    routeId: 1,
    isExperimental: false,
  },
  {
    path: '/makescreen',
    navbarName: 'Makescreen',
    icon: AddToQueue,
    component: Makescreen,
    routeId: 2,
    isExperimental: true,
    moduleIdentifier: 'online',
    requireClockIn: true,
  },
  {
    path: '/dispatching',
    navbarName: 'Dispatching',
    icon: OndemandVideo,
    component: Dispatching,
    routeId: 3,
    isExperimental: true,
    moduleIdentifier: 'online',
    requireClockIn: true,
  },
  {
    path: '/orders-history',
    navbarName: 'Orders',
    icon: History,
    component: CoreOrders,
    routeId: 4,
    isExperimental: true,
    moduleIdentifier: 'online',
  },
  {
    path: '/store',
    navbarName: 'Store',
    icon: StoreIcon,
    component: Store,
    routeId: 5,
    isExperimental: true,
    moduleIdentifier: 'online',
    reValidationNeeded: true,
  },
  {
    path: '/dashboard',
    navbarName: 'Dashboard',
    icon: DashboardIcon,
    component: Dashboard,
    routeId: 6,
    isExperimental: true,
    moduleIdentifier: 'online',
  },
  {
    path: '/externalApi',
    navbarName: 'External API',
    icon: Transform,
    component: ExternalApi,
    routeId: 7,
    isExperimental: true,
    moduleIdentifier: 'online',
  },
  {
    path: '/notifications',
    navbarName: 'Notifications',
    icon: NotificationsNone,
    component: Notifications,
    routeId: 8,
    isExperimental: true,
    moduleIdentifier: 'online',
  },
  {
    path: '/dataDownload',
    navbarName: 'Data Download',
    icon: CloudDownloadIcon,
    component: DataSync,
    routeId: 9,
    isExperimental: false,
  },
  {
    path: '/settings',
    navbarName: 'Settings',
    icon: Settings,
    component: SettingsComponent,
    routeId: 10,
    isExperimental: false,
    reValidationNeeded: true,
    forbiddenOnSwitchedStore: true,
  },
];

export const DefaultRoute = Routes[0];

export default Routes;

export interface AppRoute {
  path: string;
  navbarName: string;
  icon: SvgIconComponent;
  component: React.FC;
  routeId: number;
  isExperimental: boolean;
  moduleIdentifier?: string;
  reValidationNeeded?: boolean;
  forbiddenOnSwitchedStore?: boolean;
  requireClockIn?: boolean;
}
