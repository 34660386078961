import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { useTranslation } from 'react-i18next';

const OperationInProgress: React.FC = () => {
  const [t] = useTranslation('common');
  const { orderPlacementStatus } = useAppSelector(({ intake }) => intake);
  const openOrderPaymentInProgress = useAppSelector(({ orderPayment }) => orderPayment.openOrderPaymentInProgress);

  const blockingOperationInProgress = openOrderPaymentInProgress || orderPlacementStatus === 'pending';

  function getOperationText(): string {
    if (orderPlacementStatus === 'pending') return 'Placing order';
    if (openOrderPaymentInProgress) return 'Starting order payment';
    return 'Operation in progress';
  }

  return (
    <>
      {blockingOperationInProgress && (
        <Box
          onClick={(event) => {
            event.preventDefault();
          }}
          sx={{
            position: 'fixed',
            background: 'rgba(255,255,255,0.4)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <JumpingDotsLoader />
          <Typography variant="h5" sx={{ ml: 1 }}>
            {t(getOperationText())}...
          </Typography>
        </Box>
      )}
    </>
  );
};

export default OperationInProgress;
