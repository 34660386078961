import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, radius }) => {
  return {
    root: {
      height: sizing.couponCard.height,
      borderRadius: radius.sm,
      backgroundColor: palette.white,
    },
    disabledCard: {
      pointerEvents: 'none',
      background: palette.action.disabled,
      opacity: 0.7,
      boxShadow: 'none',
    },
    couponDescription: {
      color: palette.black.main,
    },
  };
});

export default buildClasses;
