import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, sizing }) => {
  return {
    paymentMethodToogleButtonBottomRemark: {
      position: 'absolute',
      marginTop: '60px',
    },
    paymentMethodToogleButtonTopRemark: {
      position: 'absolute',
      marginBottom: '75px',
    },

    activeToggleButton: {
      borderRadius: 0,
      width: sizing.full,
      color: palette.black.main,
    },
  };
});

export default buildClasses;
