import { Box } from '@mui/system';
import background from 'assets/images/intake-in-progress-pizza.png';
import React from 'react';
import { ReceiptProduct, ReceiptDiscount, BasketSummary } from 'typings/Basket';
import { Typography, useTheme } from '@mui/material';
import { getCustomerDisplayBasketImage } from 'stores/Cms/cms.selector';
import CarouselImage from 'components/Shared/Banner/Banner';
import { useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import CustomerDisplayBasketTotal from './CustomerDisplayBasketTotal';
import CustomerDisplayItemsCollection from './Items/CustomerDisplayItemsCollection';

interface CustomerDisplayBasketProps {
  products: ReceiptProduct[];
  discounts: ReceiptDiscount[];
  summary?: BasketSummary;
}

const CustomerDisplayBasket: React.FC<CustomerDisplayBasketProps> = ({ products, discounts, summary }) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('customerDisplay');
  const cmsBasketImage = useAppSelector(getCustomerDisplayBasketImage);

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(100vh - 70px)`,
      }}
    >
      <Box
        sx={{
          width: '50vw',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Typography
          sx={{
            backgroundColor: palette.customGray.light,
            height: spacing(6),
            display: 'flex',
            alignItems: 'center',
            padding: `${spacing(1.5)} ${spacing(3)}`,
            color: palette.black.main,
          }}
          variant="h5"
        >
          {t('Your order')}
        </Typography>
        <CustomerDisplayItemsCollection receiptProducts={products} receiptDiscounts={discounts} />
        <CustomerDisplayBasketTotal summary={summary} />
      </Box>
      <Box
        sx={{
          height: 'auto',
          width: '50vw',
        }}
      >
        {cmsBasketImage && cmsBasketImage.images?.length > 0 ? (
          <CarouselImage
            images={cmsBasketImage.images}
            carouselTimeSeconds={cmsBasketImage?.carouselTimeSeconds}
          />
        ) : (
          <Box
            sx={{ width: '100%', height: '100%', backgroundImage: `url(${background})`, backgroundSize: 'cover' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomerDisplayBasket;
