import { makeStyles } from 'tss-react/mui';

export const isBigScreenMediaQuery = '@media (min-width: 1439px)';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    receiptContainer: {
      zIndex: 1100,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: palette.white,
      boxShadow: '0px 2px 9px -2px rgba(0, 0, 0, 0.76)',
      maxHeight: `calc(var(--vh, 1vh) * 100 - ${sizing.appBarHeight}px)`,
      minWidth: sizing.receiptWidth,
      maxWidth: sizing.receiptWidth,
      height: '100%',
    },
    topButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    hideReceiptButton: {
      marginRight: spacing(2),
      color: palette.black.main,
    },
    [isBigScreenMediaQuery]: {
      hideReceiptButton: {
        display: 'none',
      },
    },
  };
});
export default buildClasses;
