import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, sizing, palette }) => {
  return {
    wrapper: {
      display: 'flex',
      width: `calc(100% - ${sizing.receiptWidth}px)`,
      height: 'calc(100% - 64px)',
      position: 'absolute',
      zIndex: 1101,
      backgroundColor: palette.white,
      transition: 'width 225ms',
    },
    wrapperFullWidth: {
      width: '100%',
    },
    leftMenu: {
      width: `calc(100% - ${sizing.receiptWidth}px)`,
      height: '100%',
      padding: spacing(4),
      paddingTop: spacing(2),
      backgroundColor: palette.customGray.light,
      display: 'flex',
      flexDirection: 'column',
    },
    goBackContainer: {
      color: 'black',
      paddingBottom: spacing(1),
      marginLeft: spacing(-1),
    },
    arrowIcon: {
      marginRight: spacing(1),
      color: palette.text.primary,
    },
    floorEditorContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    canvasContainer: {
      backgroundColor: palette.white,
      padding: spacing(2),
      width: '100%',
      height: '100%',
      display: 'flex',
      boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
      marginTop: 0,
      minHeight: '50px',
      position: 'relative',
      zIndex: 999,
    },
    canvasWrapper: {
      height: '100%',
      width: '100%',
      backgroundColor: palette.customGray.light,
    },
    rightMenu: {
      width: sizing.receiptWidth,
      minWidth: sizing.receiptWidth,
      display: 'flex',
      flexDirection: 'column',
    },
    saveButtonContainer: {
      padding: spacing(3),
      marginTop: 'auto',
      borderTop: '1px solid #ccc',
    },
  };
});
export default buildClasses;
