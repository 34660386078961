import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing }) => {
  return {
    dialogForm: {
      overflowY: 'auto',
      padding: '5vh 10vw',
    },
    alreadyAssignedInfo: {
      paddingTop: spacing(2),
      textAlign: 'center',
    },
  };
});
export default buildClasses;
