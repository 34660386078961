import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AnimatePresence, motion } from 'framer-motion';
import { PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';
import PaymentSummary from 'components/Shared/OrderPayment/Shared/PaymentSummary';
import { setActivePaymentMethod, setSelectedPaymentMethodGroupName } from 'stores/OrderPayment/orderPayment.slice';

const PaymentMethodsGrouped: React.FC = () => {
  const [t] = useTranslation('common');
  const { spacing, palette } = useTheme();
  const dispatch = useAppDispatch();
  const { availablePaymentMethods } = useAppSelector(({ orderPayment }) => orderPayment);
  const { selectedPaymentMethodGroupName } = useAppSelector(({ orderPayment }) => orderPayment);

  const methodsToShow = availablePaymentMethods.filter((pm) => pm.groupName === selectedPaymentMethodGroupName);

  return (
    <PaymentFrame>
      <>
        <Box sx={{ mb: 2, color: palette.black.main }}>
          <Typography variant="body1">{t('Select one of the payment in the selected group')}</Typography>
        </Box>
        {methodsToShow.map((pm) => {
          return (
            <Box
              key={`order-payment__paymentMethod--${pm.id}`}
              onClick={() => {
                dispatch(setActivePaymentMethod(pm));
                dispatch(setSelectedPaymentMethodGroupName(undefined));
              }}
              sx={{
                px: 2,
                py: 3,
                mb: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: palette.black.main,
                border: `1px solid ${palette.grey[200]}`,
                borderRadius: spacing(1),
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: palette.grey[200],
                },
              }}
            >
              <Typography variant="h6">{t(pm.description)}</Typography>
            </Box>
          );
        })}
      </>
      <>
        <PaymentSummary />
        <AnimatePresence initial={false}>
          <motion.div
            style={{ position: 'absolute', bottom: '0', width: '100%' }}
            key="selectPaymentInGroup"
            variants={PaymentModalAnimations.variants}
            initial="slideUp"
            animate="onPlace"
            exit="slideDown"
            transition={PaymentModalAnimations.transition}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: 2,
              }}
            >
              <Button
                onClick={() => {
                  dispatch(setSelectedPaymentMethodGroupName(undefined));
                }}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                sx={{ py: 2, px: 3 }}
              >
                {t('Cancel')}
              </Button>
            </Box>
          </motion.div>
        </AnimatePresence>
      </>
    </PaymentFrame>
  );
};

export default PaymentMethodsGrouped;
