import { makeStyles } from 'tss-react/mui';
import { keyframes } from '@mui/system';

const buildClasses = makeStyles()(({ palette, radius, sizing, spacing }) => {
  return {
    pizzaButton: {
      backgroundColor: palette.customGray.main,
      border: `3px solid`,
      borderColor: palette.grey[300],
      '&:hover': {
        backgroundColor: palette.grey[200],
      },
      width: sizing.pizzaButton / 2,
      height: sizing.pizzaButton,
    },
    halfPizzaLeft: {
      borderTopLeftRadius: radius.pizzaButton,
      borderBottomLeftRadius: radius.pizzaButton,
    },
    halfPizzaRight: {
      borderTopRightRadius: radius.pizzaButton,
      borderBottomRightRadius: radius.pizzaButton,
    },
    quarterPizzaLeftTop: {
      height: `${sizing.pizzaButton / 2}px`,
      borderTopLeftRadius: radius.pizzaButton,
    },
    quarterPizzaLeftBottom: {
      height: `${sizing.pizzaButton / 2}px`,
      borderBottomLeftRadius: radius.pizzaButton,
    },
    quarterPizzaRightBottom: {
      height: `${sizing.pizzaButton / 2}px`,
      borderBottomRightRadius: radius.pizzaButton,
    },
    quarterPizzaRightTop: {
      height: `${sizing.pizzaButton / 2}px`,
      borderTopRightRadius: radius.pizzaButton,
    },
    active: {
      borderColor: palette.green[500],
      color: palette.black.main,
      fontWeight: 500,
      animationName: keyframes`'0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        transform: 'scale(1)',
      }`,
      animationDuration: '0.25s',
      animationTimingFunction: 'ease-in-out',
    },
    complete: {
      borderColor: palette.green[500],
      backgroundColor: palette.green[200],
      '&:hover': {
        backgroundColor: palette.green[300],
      },
    },
    sliceNameLabel: {
      width: sizing.pizzaButtonLabel,
      textAlign: 'center',
      margin: 'auto',
      marginRight: spacing(2),
    },
    sliceContainer: {
      display: 'flex',
    },
    reverse: {
      flexDirection: 'row-reverse',
      '& $sliceNameLabel': {
        marginRight: spacing(0),
        marginLeft: spacing(2),
      },
    },
  };
});

export default buildClasses;
