/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { Box, useTheme } from '@mui/system';
import buildClasses from './LoginFailed.css';

const LoginFailed: React.FC = () => {
  const { palette } = useTheme();
  const [t] = useTranslation('common');
  const history = useHistory();

  const closeWindow = (): void => {
    history.replace('/'); // this will redirect to default page
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        zIndex: 1101,
        backgroundColor: palette.primary.main,
        padding: '8%',
      }}
    >
      <Typography variant="h5" color="secondary">
        {t('Sorry, error occurred during login process...')}
      </Typography>
      <RoundedButton
        margin="25px"
        height="4rem"
        width="60%"
        onClick={closeWindow}
        secondary
        testId="loginFailed_ok_button"
      >
        {t('Try again')}
      </RoundedButton>
    </Box>
  );
};
export default LoginFailed;
