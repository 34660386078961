/* eslint-disable no-console */
import { S4D } from 'themes/s4d.theme.css';
import { NYP } from 'themes/nyp.theme.css';
import { APACHE } from 'themes/apache.theme.css';
import { PIZZA_HUT } from 'themes/ph.theme.css';
import { VERHAGE } from 'themes/ver.theme.css';
import { BAKKER } from 'themes/bakkerBart.theme';
import { SPARE_RIB_EXPRESS } from 'themes/sre.theme.css';
import { DEMO } from 'themes/demo.theme.css';
import { PAPAJOHN } from 'themes/papaJohn.theme.css';
import { BELCHICKEN } from 'themes/belchicken.theme.css';
import { KOTI_THEME } from 'themes/koti.theme.css';

export enum Environment {
  QA = 'S4D',
  NYP = 'NYP',
  APA = 'APA',
  PH = 'PH',
  VER = 'VER',
  BB = 'BB',
  SRE = 'SRE',
  DM = 'DM',
  PJ = 'PJ',
  BC = 'BC',
  KOT = 'KOT',
}

export default function getThemeByKey(themeKey: string) {
  try {
    if (themeKey.toUpperCase().includes(Environment.NYP)) {
      console.debug('Setting application theme to NYP');
      return NYP;
    }
    if (themeKey.toUpperCase().includes(Environment.APA)) {
      console.debug('Setting application theme to APA');
      return APACHE;
    }
    if (themeKey.toUpperCase().includes(Environment.PH)) {
      console.debug('Setting application theme to PH');
      return PIZZA_HUT;
    }
    if (themeKey.toUpperCase().includes(Environment.VER)) {
      console.debug('Setting application theme to VERHAGE');
      return VERHAGE;
    }
    if (themeKey.toUpperCase().includes(Environment.BB)) {
      console.debug('Setting application theme to BAKKERBART');
      return BAKKER;
    }
    if (themeKey.toUpperCase().includes(Environment.SRE)) {
      console.debug('Setting application theme to SPARERIBSEXPRESS');
      return SPARE_RIB_EXPRESS;
    }
    if (themeKey.toUpperCase().includes(Environment.DM)) {
      console.debug('Setting application theme to DEMO');
      return DEMO;
    }
    if (themeKey.toUpperCase().includes(Environment.PJ)) {
      console.debug('Setting application theme to PAPAJOHN');
      return PAPAJOHN;
    }
    if (themeKey.toUpperCase().includes(Environment.BC)) {
      console.debug('Setting application theme to BELCHICKEN');
      return BELCHICKEN;
    }
    if (themeKey.toUpperCase().includes(Environment.KOT)) {
      console.debug('Setting application theme to KOTI');
      return KOTI_THEME;
    }
    console.debug('Setting application theme to Generic');
    return S4D;
  } catch {
    return S4D;
  }
}
