/* eslint-disable no-console */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from 'hooks/useRedux';
import RevalidatePinModal from 'containers/Authorize/RevalidatePinPad';
import SwitchedStoreGuard from 'components/Auth/SwitchedStoreGuard';
import { AppRoute } from 'App/Routes/routes-list';

export interface ProtectedRouteProps {
  routeDetails: AppRoute;
  onRedirectToBase: () => void;
}

const CashierRoute: React.FC<ProtectedRouteProps> = ({ routeDetails, onRedirectToBase }) => {
  const { loggedCashier, revalidationResult } = useAppSelector(({ cashier }) => cashier);

  const notValidatedYet =
    !revalidationResult || !revalidationResult.success || revalidationResult.revalidateFor !== routeDetails.path;

  const revalidationRequired = routeDetails.reValidationNeeded && notValidatedYet;

  return (
    <Route path={routeDetails.path}>
      {loggedCashier ? (
        <>
          {revalidationRequired ? (
            <RevalidatePinModal revalidateFor={routeDetails.path} onClose={onRedirectToBase} />
          ) : (
            <SwitchedStoreGuard route={routeDetails}>
              <routeDetails.component />
            </SwitchedStoreGuard>
          )}
        </>
      ) : (
        <Redirect to="/cashier-login" />
      )}
    </Route>
  );
};
export default CashierRoute;
