const Messages = {
  Commands: {
    StartPayment: 'start-payment-command',
    ChangeView: 'change-view-command',
    EditOrder: 'edit-order-command',
  },
  Events: {
    PaymentSucceeded: 'payment-success',
    PaymentModalClosed: 'payment-modal-closed',
  },
};

export default Messages;
