import React from 'react';
import { Typography } from '@mui/material';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

interface ErrorCouponProps {
  title: string;
  description: string;
  onClose: () => void;
}

const ErrorCoupon: React.FC<ErrorCouponProps> = ({ title, description, onClose }) => {
  return (
    <ModalContainer
      onClose={onClose}
      open
      title={title}
      primaryButton={{
        label: 'Close',
        action: onClose,
        testId: 'coupon-error-close-button',
      }}
      testId="coupon-error-modal"
    >
      <Typography variant="body1" textAlign="center" sx={{ padding: '24px' }}>
        {description}
      </Typography>
    </ModalContainer>
  );
};

export default ErrorCoupon;
