import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GiftCardFailed } from 'assets/icons';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const GiftCardErrorResult: React.FC = () => {
  const [t] = useTranslation('errorCodes');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <HighlightOffIcon color="error" />
        <Typography color="black.main" variant="h5">
          {t('Gift Card payment failed')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GiftCardFailed />
      </Box>
    </Box>
  );
};
export default GiftCardErrorResult;
