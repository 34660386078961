import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, spacing }) => {
  return {
    drawer: {
      height: `calc(100vh - ${sizing.appBarHeight}px)`,
      marginTop: `${sizing.appBarHeight}px`,
      overflowY: 'clip',
    },
    ordersContainer: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(4),
      paddingTop: spacing(3),
      paddingBottom: spacing(0),
      height: `calc(100vh - ${sizing.appBarHeight}px - ${spacing(4)} - ${spacing(3)})`,
    },
  };
});
export default buildClasses;
