import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PrintingSettingsGroup from 'components/Settings/SettingGroup/PrintingSettingsGroup';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { PrintingConfiguration } from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getPrintingConfiguration,
  savePrintingConfiguration,
  validateSettings,
} from 'stores/Setup/setup.thunk-actions';
import { Box } from '@mui/material';

function PrintingSettings(): JSX.Element {
  const [t] = useTranslation('settings');
  const [usingLocalPrinter, setLocalPrinterUsage] = useState<boolean>();
  const [printEmployeeName, setPrintEmployeeName] = useState<boolean>(false);
  const [isRollPrinter, setIsRollPrinter] = useState<boolean>(false);
  const { instanceType } = useAppSelector((state) => state.config);
  const { printerConfig, validatedPrintingConfig } = useAppSelector((store) => store.setup);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPrintingConfiguration());
  }, []);

  useEffect(() => {
    if (printerConfig) {
      setPrintEmployeeName(printerConfig.general.printEmployeeNameOnReceipt);
      setIsRollPrinter(printerConfig.general.isRollPrinter);

      if (
        printerConfig.local &&
        printerConfig.local.targetPrinterName !== null &&
        printerConfig.local.targetPrinterName !== ''
      ) {
        setLocalPrinterUsage(true);
      } else {
        setLocalPrinterUsage(false);
      }
    }
  }, [printerConfig]);

  useEffect(() => {
    if (validatedPrintingConfig?.isValid && validatedPrintingConfig.config) {
      dispatch(savePrintingConfiguration(validatedPrintingConfig.config));
    }
  }, [validatedPrintingConfig]);

  function submitWithoutPrintingConfiguration(): void {
    if (!usingLocalPrinter) {
      dispatch(
        savePrintingConfiguration({
          general: {
            printEmployeeNameOnReceipt: printEmployeeName,
            isRollPrinter,
          },
          local: {
            localPrintingEnabled: false,
            printReceiptsAfterOrderFinalization: false,
            htmlToPdfConverterPath: '',
            printingToolPath: '',
            targetPrinterName: '',
            printCopies: 1,
          },
        }),
      );
    }
  }

  function handleFormSubmit(settings: PrintingConfiguration): void {
    // TODO: Add separate validation if there will be more printing settings in cloud-pos
    const submittedSettings = {
      general: {
        printEmployeeNameOnReceipt: printEmployeeName,
        isRollPrinter,
      },
      local: {
        ...settings.local,
      },
    } as PrintingConfiguration;
    if (instanceType === 'Central') {
      dispatch(savePrintingConfiguration(submittedSettings));
      return;
    }
    dispatch(validateSettings(submittedSettings));
  }

  return (
    <SettingGroup label={t('Printing Settings')}>
      {usingLocalPrinter !== undefined && (
        <>
          <SettingItem
            label={t('Print employee name on receipt?')}
            type="custom"
            testId="printing-settings__input--print-employee-name"
          >
            <FilledSwitch
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                setPrintEmployeeName(checked)
              }
              checked={printEmployeeName}
            />
          </SettingItem>
          <SettingItem
            label={t('Use roll printing?')}
            type="custom"
            testId="printing-settings__input--use-roll-printing"
          >
            <FilledSwitch
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                setIsRollPrinter(checked)
              }
              checked={isRollPrinter}
              name="isRoll"
            />
          </SettingItem>
          {instanceType === 'InStore' && (
            <SettingItem
              label={t('Does this system uses local printing?')}
              type="custom"
              testId="printing-settings__input--using-printer"
            >
              <FilledSwitch
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                  setLocalPrinterUsage(checked)
                }
                checked={usingLocalPrinter}
              />
            </SettingItem>
          )}
          {printerConfig && usingLocalPrinter === true && (
            <PrintingSettingsGroup predefinedSettings={printerConfig} onFormSubmit={handleFormSubmit} />
          )}
          <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'flex-end' }}>
            <RoundedButton
              form="printingSettingsForm"
              type="submit"
              onClick={submitWithoutPrintingConfiguration}
              margin="10px 15px 0"
              testId="printing-settings__button--save-changes"
            >
              {t('Save')}
            </RoundedButton>
          </Box>
        </>
      )}
    </SettingGroup>
  );
}

export default PrintingSettings;
