import React from 'react';
import TextFieldDialog from 'components/Shared/TextFieldDialog/TextFieldDialog';
import { useTranslation } from 'react-i18next';

interface CancelDineInOrderDialogProps {
  showDialog: boolean;
  orderId: string;
  onConfirm: (reason: string) => void;
  onAbort: () => void;
}

const CancelDineInOrderDialog: React.FC<CancelDineInOrderDialogProps> = ({
  showDialog,
  orderId,
  onConfirm,
  onAbort,
}) => {
  const formId = `cancellation-form-order-${orderId}`;
  const [t] = useTranslation('common');

  return (
    <TextFieldDialog
      showDialog={showDialog}
      onClose={onAbort}
      onSubmit={onConfirm}
      i18NameSpace="orders"
      errorMessageKey="Reason required"
      headerKey="Cancellation reason"
      formId={formId}
      maxLength={500}
      placeholder="Reason"
      primaryButtonText={t('Save')}
    />
  );
};

export default CancelDineInOrderDialog;
