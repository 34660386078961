import React, { useState } from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import buildClasses from './ToggleButtonGroup.css';

interface ToggleButtonGroupProps {
  tabs: ToggleButtonTab[];
  val?: number;
  onClick: (value: number) => void;
  secondary?: boolean;
  testIdPrefix: string;
}

export interface ToggleButtonTab {
  value: number;
  hasSuccess?: boolean;
  icon?: string | JSX.Element;
  text?: string;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  tabs,
  onClick,
  secondary,
  val,
  children,
  testIdPrefix,
}) => {
  const { classes } = buildClasses(secondary)();
  const predefinedValue = val ?? tabs[0].value;
  const [activeTab, setActiveTab] = useState<number>();
  predefinedValue !== activeTab && setActiveTab(predefinedValue);
  function toggleButtonGroupChange(event: React.ChangeEvent<{}>, value: number): void {
    if (value !== undefined) {
      setActiveTab(value);
      onClick(value);
    }
  }

  const tabElements = (
    <>
      <Tabs
        value={activeTab}
        onChange={toggleButtonGroupChange}
        variant="scrollable"
        scrollButtons={!secondary}
        indicatorColor={secondary ? 'primary' : 'secondary'}
        textColor={secondary ? 'primary' : 'secondary'}
        aria-label="scrollable force tabs example"
        data-testid={`${testIdPrefix}__button-group`}
        classes={{ indicator: secondary ? classes.indicator : undefined }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.text}
            icon={tab.icon}
            iconPosition={secondary ? 'start' : 'top'}
            value={tab.value}
            data-testid={`${testIdPrefix}__button--${tab.value}`}
            classes={secondary ? { root: classes.secondaryTab, iconWrapper: classes.secondaryTabIcon } : undefined}
          />
        ))}
      </Tabs>
      {children}
    </>
  );

  if (secondary) {
    return <div className={classes.secondaryContainer}>{tabElements}</div>;
  }

  return (
    <AppBar position="relative" className={classes.subNavbar}>
      {tabElements}
    </AppBar>
  );
};

export default ToggleButtonGroup;
