import React, { useMemo } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { getCurrentGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.selector';
import { useTheme } from '@mui/system';

import { GiftCardFailed, PaymentImage } from 'assets/icons';
import {
  clearGiftCardActivation,
  resetCurrentActivation,
} from 'stores/GiftCardActivation/giftCardActivation.slice';
import MultipleCardsIndicator from 'components/Shared/GiftCardActivation/MultipleCardsIndicator';

export type GiftCardActivationStep = 'scanOrManual' | 'enterCardNumber' | 'success' | 'failure';

const ActivationResult: React.FC = () => {
  const [t] = useTranslation('common');
  const [tError] = useTranslation('errorCodes');
  const dispatch = useAppDispatch();
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const currentGiftCard = useAppSelector(getCurrentGiftCardActivation);

  const { isLocalOrder, orderId, errorMessage } = useAppSelector(({ giftCards }) => giftCards);

  const isSuccess = useMemo(() => {
    return currentGiftCard?.activationStep === 'success';
  }, [currentGiftCard]);

  if (!currentGiftCard) return null;

  function tryAgain(): void {
    dispatch(resetCurrentActivation());
  }

  function confirmCardActivation(): void {
    // orderId && dispatch(checkOrderGiftCards({ orderId, isLocalOrder }));\
    dispatch(clearGiftCardActivation());
  }

  return (
    <PaymentFrame>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {isSuccess ? <CheckCircleOutlineIcon color="primary" /> : <HighlightOffIcon color="error" />}
          <Typography color="black.main" variant="h5">
            {t(isSuccess ? 'Loading successful!' : 'Loading failed!')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isSuccess ? <PaymentImage /> : <GiftCardFailed />}
        </Box>
      </Box>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <MultipleCardsIndicator />
          <Typography variant="h5" color="black.main">
            {t(isSuccess ? 'Gift card is now active and loaded with:' : 'Not loaded:')}
          </Typography>
          <Typography
            variant="h2"
            color="black.main"
            sx={{ p: 2, background: palette.grey[50], borderRadius: spacing(1) }}
          >
            {formatToDisplay(currentGiftCard.amount)}
          </Typography>
        </Box>
        {errorMessage && (
          <>
            <Typography variant="body1" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%' }}>
              <Alert severity="error" icon={<HighlightOffIcon fontSize="inherit" />}>
                {tError(errorMessage)}
              </Alert>
            </Typography>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {isSuccess ? (
            <Button
              data-testid="gift-card-activation--confirm"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={confirmCardActivation}
            >
              {t('Confirm')}
            </Button>
          ) : (
            <Button
              data-testid="gift-card-activation--try-again"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={tryAgain}
            >
              {t('Try again')}
            </Button>
          )}
        </Box>
      </>
    </PaymentFrame>
  );
};
export default ActivationResult;
