/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getActivePayment, getFinalizationTimeDataSelector } from 'stores/OrderPayment/orderPayment.selector';
import { AnimatePresence } from 'framer-motion';
import { getEftPaymentStatus, startEftPayment } from 'stores/EftPayment/eftPayment.actions';
import { getEFTPaymentStatus } from 'stores/EftPayment/eftPayment.selector';
import EftError from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftError';
import EftSuccess from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftSuccess';
import EftPending from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftPending';
import { clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import EftOperationButtons from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftOperationButtons';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import EftIdle from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftIdle';
import PaymentSummary from 'components/Shared/OrderPayment/Shared/PaymentSummary';
import CashAmountEdition from 'components/Shared/OrderPayment/Shared/CashAmountEdition';
import TipEdition from 'components/Shared/OrderPayment/Shared/TipEdition';
import ChangeValue from 'components/Shared/OrderPayment/Shared/ChangeValue';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { clearActivePayment, setActivePaymentStep } from 'stores/OrderPayment/orderPayment.slice';
import ConfirmStepButtons from 'components/Shared/OrderPayment/Shared/ConfirmStepButtons';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import uuidGenerator from 'utils/GuidGenerator';
import ProvideAmountStepButtons from 'components/Shared/OrderPayment/Shared/ProvideAmountStepButtons';
import AdjustTipButtons from 'components/Shared/OrderPayment/Shared/AdjustTipButtons';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';

const CardPayment: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { eftPaymentSettings } = useAppSelector(({ config }) => config);
  const { orderId, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const { eftPaymentId } = useAppSelector(({ eftPayment }) => eftPayment);
  const pollingInterval = useRef<any>(null);
  const activePayment = useAppSelector(getActivePayment);
  const paymentStatus = useAppSelector(getEFTPaymentStatus);
  const getFinalizationTimeData = useAppSelector(getFinalizationTimeDataSelector());
  const appInsights = useAppInsightsContext();
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter());
  const { watch } = useFormContext<OrderPaymentForm>();
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);

  function startPaymentStatusPolling(identifier: string): void {
    const pollingIntervalInSeconds = eftPaymentSettings?.statusPollingIntervalInSeconds ?? 1;
    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(() => {
        dispatch(
          getEftPaymentStatus({
            paymentId: identifier,
          }),
        );
      }, pollingIntervalInSeconds * 1000);
      appInsights.trackEvent({
        name: 'Eft payment pulling started',
        properties: { identifier, orderId },
      });
    }
  }

  function stopPaymentStatusPolling(): void {
    clearInterval(pollingInterval?.current);
    if (pollingInterval) {
      pollingInterval.current = null;
      appInsights.trackEvent({
        name: 'Eft payment pulling stopped',
        properties: { orderId },
      });
    }
  }

  useEffect(() => {
    if (eftPaymentId === undefined) {
      stopPaymentStatusPolling();
    }
    if (eftPaymentId) {
      startPaymentStatusPolling(eftPaymentId);
    }
  }, [eftPaymentId]);

  useEffect(() => {
    return () => {
      stopPaymentStatusPolling();
      dispatch(clearEftPaymentData());
    };
  }, []);

  if (!activePayment) return null;

  const activeCardStep = activePayment.paymentStep;

  return (
    <PaymentFrame>
      <>
        {activeCardStep === 'provideAmount' && <CashAmountEdition />}
        {activeCardStep === 'confirm' && <ChangeValue />}
        {activeCardStep === 'provideTip' && <TipEdition />}

        {activeCardStep === 'finalizePayment' && (
          <>
            {paymentStatus === 'ERROR' && <EftError />}
            {paymentStatus === 'IDLE' && <EftIdle />}
            {getFinalizationTimeData().skipFinalizationScreen ? (
              (paymentStatus === 'PENDING' || paymentStatus === 'SUCCESS') && <EftPending />
            ) : (
              <>
                {paymentStatus === 'SUCCESS' && <EftSuccess />}
                {paymentStatus === 'PENDING' && <EftPending />}
              </>
            )}
          </>
        )}
      </>
      <>
        <PaymentSummary />

        <AnimatePresence initial={false}>
          {activeCardStep === 'provideAmount' && <ProvideAmountStepButtons />}
          {activeCardStep === 'provideTip' && <AdjustTipButtons />}
          {activeCardStep === 'confirm' && (
            <ConfirmStepButtons
              onGoBack={() => {
                dispatch(clearActivePayment());
              }}
              onNextStep={() => {
                const toPayAmount =
                  cashInputNumberValue < totalLeftAmount ? cashInputNumberValue : totalLeftAmount;

                dispatch(setActivePaymentStep('finalizePayment'));
                dispatch(
                  startEftPayment({
                    orderAmount: toPayAmount,
                    paymentId: uuidGenerator(),
                    orderId: orderId as string,
                    tipAmount: activePayment.tipValue,
                  }),
                );
              }}
            />
          )}
          {activeCardStep === 'finalizePayment' && <EftOperationButtons />}
        </AnimatePresence>
      </>
    </PaymentFrame>
  );
};
export default CardPayment;
