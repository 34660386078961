import React from 'react';
import LoginFailed from 'components/Auth/LoginFailed';
import PostLogin from 'components/Auth/PostLogin';
import AppConfigComponent from 'containers/Authorize/AppConfigComponent';
import LoginWrapper from 'containers/Authorize/LoginWrapper';
import FirstTimeLaunch from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import { useAppSelector } from 'hooks/useRedux';
import { Switch, Route, Redirect } from 'react-router';
import CustomerDisplay from 'containers/CustomerDisplay/CustomerDisplay';
import MainContainer from '../MainContainer/MainContainer';

const Routes = () => {
  const { showFirstTimeLaunch } = useAppSelector((state) => state.config);

  const DefaultRedirect = (): JSX.Element => {
    if (showFirstTimeLaunch === true) {
      return <Redirect to="./first-time-launch" />;
    }
    return <Redirect to="./app-config" />;
  };

  return (
    <Switch>
      <Route exact path="/" component={DefaultRedirect} />
      <Route path="/login-redirect" component={PostLogin} />
      <Route path="/login-failed" component={LoginFailed} />
      <Route path="/app-config" component={AppConfigComponent} />
      <Route path="/cashier-login" component={LoginWrapper} />
      <Route path="/first-time-launch" component={FirstTimeLaunch} />
      <Route path="/customer-display" component={CustomerDisplay} />
      <MainContainer />
    </Switch>
  );
};

export default Routes;
