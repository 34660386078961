/* eslint-disable no-console */
import React, { useEffect, useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableFooter,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import GeneralRow from 'components/Orders/Table/GeneralRow/GeneralRow';
import { format } from 'date-fns';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { finalizeOsmOrder, setActivePaymentMethod } from 'stores/Intake';
import { checkSpecifiedCustomerCanPayOnAccount, clearCustomerData } from 'stores/Customer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import {
  getOrderDetails,
  getOrderDetailsForFinalize,
  OSMOrder,
  resetSelectedOrderDetails,
  setCurrentPageIndex,
  setPageSize,
} from 'stores/AllOrders';
import AllOrdersTableHead from 'containers/AllOrders/Overview/Table/AllOrdersTableHead';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { CashierPermission } from 'typings/Cashier';
import { OrderStatus, submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { getPayOnAccountCode } from 'stores/Config/config.selector';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import buildClasses from './AllOrdersTable.css';
import { AllOrdersRowData } from '../../AllOrdersTypes';

const AllOrdersTable: React.FC = () => {
  const { classes } = buildClasses();
  const { ordersList, ordersFetchInProgress, tableOptions, selectedOrderDetails } = useAppSelector(
    (store) => store.allOrders,
  );
  const { featureFlags, payment } = useAppSelector((store) => store.config);
  const { selectedStore } = useAppSelector((store) => store.stores);
  const { availablePaymentMethods } = useAppSelector((store) => store.payments);
  const canUserAccess = useAppSelector(getPermissionChecker());
  const canCashierFinalize = useMemo(() => {
    return canUserAccess(CashierPermission.FinishOpenAndPausedOrder);
  }, []);
  const dispatch = useAppDispatch();
  const payOnAccountMethodCode = useAppSelector(getPayOnAccountCode);
  const { currentPageIndex, pageSize } = tableOptions;
  const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id) ?? false; // to remove when V1 is deleted

  const [t] = useTranslation('orders');

  function mapOrderToTableRow(order: OSMOrder): AllOrdersRowData {
    const placingDateTime = new Date(order.orderPlacedTimestamp);
    const collectionDateTime = new Date(order.collectionTimestamp);
    const waitTime = moment(order.waitTime ?? '00:00:00.000000', 'HH:mm:ss.SSSSSS').format('HH:mm');
    return {
      store: order.store ?? '-',
      ticket: order.ticketNumber,
      name: order.customerName ?? '-',
      address: order.address ?? '-',
      phone: order.phoneNumber ?? '-',
      price: order.price,
      date: format(placingDateTime, 'dd/MM/yyyy HH:mm'),
      collection: format(collectionDateTime, 'dd/MM/yyyy HH:mm'),
      waitingTime: waitTime !== '00:00' ? waitTime : t('Future'),
      deliveryType: t(order.pickupType),
      status: order.orderStatus,
      giftCardsIndicator: order.giftCardsIndicator,
    };
  }

  const selectRowHandler = function selectRowHandler(rowId?: string): void {
    if (rowId && rowId !== selectedOrderDetails?.id) {
      dispatch(getOrderDetails({ publicId: rowId, doFetch: true }));
      return;
    }
    dispatch(resetSelectedOrderDetails());
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
    dispatch(setCurrentPageIndex(0));
    submitAllOrdersForm();
  };

  function checkWhetherOrderCustomerCanPayOnAccount(customerId: number, orderTotal: number): void {
    if (featureFlags.OfflineModule_PayOnAccountEnabled) {
      const customerPayOnAccountCheckRequest = {
        customerId,
        orderValue: orderTotal,
        pickupType: PickUpTypesValues.pickUp,
      };

      dispatch(checkSpecifiedCustomerCanPayOnAccount(customerPayOnAccountCheckRequest));
    }
  }
  function finalizeClickHandler(id: string, price: number, customerId?: number, finishUnpaid = false): void {
    if (customerId) {
      checkWhetherOrderCustomerCanPayOnAccount(customerId, price);
    } else {
      dispatch(clearCustomerData());
    }
    dispatch(
      getOrderDetailsForFinalize({
        orderId: id,
        dataCallback: (details) => {
          if (details.canFinalize || finishUnpaid) {
            if (!details.isPaid || finishUnpaid) {
              const chargedCompany =
                details.companyId && details.companyName
                  ? {
                      id: details.companyId,
                      name: details.companyName,
                    }
                  : undefined;
              const customerCanPayOnAccount = details.paymentMethod?.code === payOnAccountMethodCode || undefined;

              dispatch(
                openOrderPaymentFromSettlePayment({
                  orderId: details.id,
                  totalToPay: details.priceSummary.total,
                  closeCallback: closeSettlePayment,
                  successCallback: finalizeOrderHandler,
                  deliveryType: details.pickupType,
                  forcedPaymentMethodCode: details.paymentMethod?.code,
                  customerData: {
                    customerId,
                    payOnAccountAccount: {
                      chargedCompany,
                      customerCanPayOnAccount,
                    },
                  },
                  orderPaymentDetails: {
                    ticketNumber: details.ticketNumber,
                    tableName: details.tableIdentifier,
                    orderReference: details.orderReference,
                  },
                  isLocalOrder: false,
                }),
              );
            }
          }
        },
      }),
    );
  }

  async function finalizeOrderHandler(result: PaymentSuccessfulResult) {
    const paymentMethodCode = availablePaymentMethods.find((pm) => pm.code === result.paymentMethodCode)
      ? result.paymentMethodCode
      : availablePaymentMethods[0]?.code;
    dispatch(
      finalizeOsmOrder({
        finalizationOrderId: result.orderId,
        paymentMethodCode,
        eftPaymentId: result.eftPaymentOperation?.eftPaymentId,
        eftFinishedManually: result.eftPaymentOperation?.finishedManually,
        companyId: result.chargedCompany?.id,
        successCallback: () => {
          dispatch(resetSelectedOrderDetails());
          submitAllOrdersForm();
        },
      }),
    );
    closeSettlePayment();
  }

  function closeSettlePayment(): void {
    dispatch(setActivePaymentMethod(undefined));
    dispatch(clearCustomerData());
    dispatch(clearSettlePayment());
  }

  const tableRows = ordersList.map((order) => {
    const row = mapOrderToTableRow(order);
    return (
      <React.Fragment key={order.orderId}>
        <GeneralRow
          row={row}
          orderId={order.orderId}
          selectRow={(rowId): void => selectRowHandler(rowId)}
          isSelected={selectedOrderDetails?.id === order.orderId}
          canBeFinalized={order.canFinalize && canCashierFinalize}
          canBeContinued={useV2Payment && row.status === OrderStatus.AwaitingExternalPayment}
          onFinalize={(): void => finalizeClickHandler(order.orderId, order.price, order.customerId)}
          onContinuePayment={(): void => finalizeClickHandler(order.orderId, order.price, order.customerId, true)}
        />
      </React.Fragment>
    );
  });

  const emptyRows = ordersFetchInProgress ? pageSize : pageSize - Math.min(pageSize, tableRows.length);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader sx={{ height: '100%' }}>
          <AllOrdersTableHead />
          <TableBody id="all-orders-table-body">
            {ordersFetchInProgress ? (
              <TableRow>
                <TableCell colSpan={100} sx={{ border: 0, height: 52 * emptyRows }}>
                  <div className={classes.tableBodyContent}>
                    <CircularProgress size={40} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {tableRows}
                {emptyRows > 0 && (
                  <TableRow
                    onClick={() => {
                      selectRowHandler(undefined);
                    }}
                  >
                    <TableCell style={{ height: 52 * emptyRows }} colSpan={100} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 60]}
                colSpan={10}
                count={tableOptions.totalOrders}
                rowsPerPage={pageSize}
                labelRowsPerPage={t('Rows per page')}
                page={currentPageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: false,
                }}
                onPageChange={(event, newPage): void => {
                  dispatch(setCurrentPageIndex(newPage));
                  submitAllOrdersForm();
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllOrdersTable;
