import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Ellipse, Rect, Text } from 'react-konva';
import Konva from 'konva';
import { TableElement } from 'typings/Tables';

interface TableObjectProps extends TableElement {
  onSelectFn(): void;
  ratioX: number;
  ratioY: number;
  isActive: boolean;
  isSelected: boolean;
}

const ReadOnlyTableObject = ({
  size,
  placement,
  name,
  type,
  onSelectFn,
  ratioX,
  ratioY,
  isActive,
  isSelected,
}: TableObjectProps) => {
  const shapeRef = React.useRef<Konva.Rect | Konva.Ellipse>(null);
  const textRef = useRef<Konva.Text>(null);

  const [textPosition, setTextPosition] = useState<{ x: number; y: number }>({ x: placement.x, y: placement.y });

  useEffect(() => {
    if (textRef.current) {
      setTextPosition({ x: textRef.current.getWidth() / 2, y: textRef.current.getHeight() / 2 });
    }
  }, [textRef]);

  const getFillColor = () => {
    if (isSelected) return '#A4D9FF';
    if (isActive) return '#CCCCCC';
    return '#EEFFF3';
  };

  const getStrokeColor = () => {
    if (isSelected) return '#0094FF';
    if (isActive) return '#858585';
    return '#69C483';
  };

  const textPositionX =
    type === 'RECT'
      ? placement.x * ratioX - textPosition.x
      : placement.x * ratioX - textPosition.x + (size.width * ratioX) / 2;
  const textPositionY =
    type === 'RECT'
      ? placement.y * ratioY - textPosition.y
      : placement.y * ratioY - textPosition.y + (size.height * ratioY) / 2;

  function renderElement() {
    if (type === 'RECT') {
      return (
        <Rect
          onClick={onSelectFn}
          onTap={onSelectFn}
          ref={shapeRef as RefObject<Konva.Rect>}
          x={placement.x * ratioX}
          y={placement.y * ratioY}
          rotation={placement.rotate}
          width={size.width}
          height={size.height}
          fill={getFillColor()}
          stroke={getStrokeColor()}
          strokeWidth={1}
          shadowBlur={5}
          name="object"
          scaleX={placement.rotate !== 0 ? 1 : ratioX}
          scaleY={placement.rotate !== 0 ? 1 : ratioY}
          offsetX={size.width / 2}
          offsetY={size.height / 2}
        />
      );
    }
    return (
      <Ellipse
        onClick={onSelectFn}
        onTap={onSelectFn}
        ref={shapeRef as RefObject<Konva.Ellipse>}
        x={placement.x * ratioX + (size.width / 2) * ratioX}
        y={placement.y * ratioY + (size.height / 2) * ratioY}
        rotation={placement.rotate}
        radiusX={size.width / 2}
        radiusY={size.height / 2}
        fill={getFillColor()}
        stroke={getStrokeColor()}
        strokeWidth={1}
        shadowBlur={5}
        name="object"
        scaleX={ratioX}
        scaleY={ratioY}
      />
    );
  }

  return (
    <>
      {renderElement()}
      <Text ref={textRef} onClick={onSelectFn} text={name} x={textPositionX} y={textPositionY} />
    </>
  );
};

export default ReadOnlyTableObject;
