import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizingBig }) => {
  return {
    root: {
      display: 'grid',
      gridTemplateRows: `${sizingBig(3)} auto ${sizingBig(2)}`,
      height: '100%',
      overflow: 'hidden',
    },
    wrapper: {
      padding: spacing(4),
      overflowY: 'auto',
    },
    headingContainer: {
      display: 'flex',
      paddingBottom: spacing(4),
    },
    pizzaOptions: {
      width: '100%',
    },
    xtastyOverview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navigation: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0px ${spacing(3)}`,
    },
    xtastyPart: {
      margin: spacing(3),
    },
    summaryBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0px ${spacing(3)}`,
      backgroundColor: palette.primary.main,
    },
    content: {
      padding: `0px ${spacing(2)}`,
      overflowY: 'auto',
    },
    disabled: {
      pointerEvents: 'none',
      backgroundColor: palette.secondary.dark,
    },
    toppingsWrapper: {
      padding: `${spacing(3)} 0`,
    },
    noToppingsWrapper: {
      padding: `${spacing(2)} 0`,
    },
  };
});
export default buildClasses;
