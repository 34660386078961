export const specialScannerSigns = ['ArrowDown', 'ArrowRight', 'Enter'];

export function checkIfLoyaltyCode(value: string): boolean {
  const loyaltyCardCodeStartChar = 'L';
  const loyaltyCardCodeLength = 9;
  const charactersPattern = /[^a-zA-Z0-9 @.-]/;

  if (value[0] !== loyaltyCardCodeStartChar) return false;

  const code = value.substring(0, loyaltyCardCodeLength);
  if (charactersPattern.test(code)) return false;
  return true;
}

export function checkIfEANCode(value: string): boolean {
  if (!isValidEAN13(value)) return false;
  return true;
}

function isValidEAN13(ean: string): boolean {
  const checkSum = ean.split('').reduce((p, v, i) => (i % 2 === 0 ? p + 1 * +v : p + 3 * +v), 0);
  return checkSum % 10 === 0;
}
