import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { PaymentMethod, PaymentMethodCode } from 'typings/Payments';
import { startEftPayment } from 'stores/EftPayment/eftPayment.actions';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { getActivePayment } from 'stores/OrderPayment/orderPayment.selector';
import {
  clearActivePayment,
  setActivePaymentMethod,
  setSelectedPaymentMethodGroupName,
  setActivePaymentStep,
  setActivePayment,
} from 'stores/OrderPayment/orderPayment.slice';
import uuidGenerator from 'utils/GuidGenerator';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import ConfirmStepButtons from 'components/Shared/OrderPayment/Shared/ConfirmStepButtons';
import PaymentTypeButtons from 'components/Shared/OrderPayment/Shared/PaymentTypeButtons';

const ProvideAmountStepButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedPaymentMethodGroupName } = useAppSelector(({ orderPayment }) => orderPayment);
  const activePayment = useAppSelector(getActivePayment);
  const { orderId, availablePaymentMethods, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const stringToNumber = useAppSelector(getStringToNumberFormatter());
  const { getValues, trigger } = useFormContext<OrderPaymentForm>();

  const selectedPaymentMethod = availablePaymentMethods.find(
    (el) => el.code === activePayment?.paymentMethod?.code,
  );

  const onPaymentMethodSelected = async (method: PaymentMethod | undefined) => {
    if (!method || !activePayment) return;
    const currentCashInput = stringToNumber(getValues().cashInput);

    if (!!selectedPaymentMethod && selectedPaymentMethod !== method) {
      if (method.groupName) {
        dispatch(setSelectedPaymentMethodGroupName(method.groupName));
        return;
      }
      dispatch(setActivePaymentMethod(method));
      return;
    }

    if (selectedPaymentMethod === undefined && method.groupName) {
      dispatch(setSelectedPaymentMethodGroupName(method.groupName));
      return;
    }

    await trigger('cashInput').then((isValid) => {
      if (isValid) {
        const selectedNonCash = method.code !== PaymentMethodCode.Cash;
        const fullTipValue = currentCashInput - totalLeftAmount > 0 ? currentCashInput - totalLeftAmount : 0;
        activePayment &&
          dispatch(
            setActivePayment({
              ...activePayment,
              paymentMethod: method,
              customerPaidWith: currentCashInput,
              paymentStep: 'confirm',
              tipValue: selectedNonCash ? fullTipValue : 0,
            }),
          );

        // here we will do some custom logic, but first we need to define it from backend :
        // cashPaymentCode / cardPaymentCode (like we have for payOnAccountCode)
        // additionally we can think of ~paymentMethodKind like eftKind, cashKind, voucherKind
        // to share some UI steps for those 'kinds'
        if (method.supportsEftPayment === true && method.code !== PaymentMethodCode.GiftcardQr) {
          if (currentCashInput === totalLeftAmount) {
            dispatch(setActivePaymentStep('finalizePayment'));
            // no tip + exact left to pay amount
            dispatch(
              startEftPayment({
                orderAmount: currentCashInput,
                tipAmount: activePayment?.tipValue,
                paymentId: uuidGenerator(),
                orderId: orderId as string,
              }),
            );
          }
        }

        if (method.supportsEftPayment === true && method.code === PaymentMethodCode.GiftcardQr) {
          if (currentCashInput === totalLeftAmount) {
            dispatch(setActivePaymentStep('provideQrCode'));
          }
        }
      }
    });
  };

  return (
    <>
      {selectedPaymentMethodGroupName === undefined && (
        <PaymentTypeButtons onPaymentMethodSelected={onPaymentMethodSelected} />
      )}
      {selectedPaymentMethodGroupName !== undefined &&
        selectedPaymentMethodGroupName === activePayment?.paymentMethod?.groupName && (
          <ConfirmStepButtons
            onNextStep={() => {
              onPaymentMethodSelected(activePayment?.paymentMethod);
            }}
            onGoBack={() => {
              dispatch(clearActivePayment());
            }}
          />
        )}
    </>
  );
};

export default ProvideAmountStepButtons;
