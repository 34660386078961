import { History, Store } from '@mui/icons-material';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { HeaderOptionButton } from 'components/Shared/PageHeader/PageHeader';

export enum HeaderOptions {
  open = 'openPickup',
  paused = 'pausedOrders',
  all = 'allOrders',
}

export const headerOptionButtons: HeaderOptionButton[] = [
  // COMMENTED OUT TEMPORARY, SHOULD BE IMPLEMENTED IN THE FUTURE
  {
    value: HeaderOptions.open,
    icon: Store,
  },
  //   {
  //     name: 'Paused Orders',
  //     value: HeaderOptions.paused,
  //     icon: PauseCircleOutline,
  //   },
  {
    value: HeaderOptions.all,
    icon: History,
  },
];

export enum OrdersStatusTypes {
  toPrepare = 'ToPrepare',
  inPreparation = 'InPreparation',
  prepared = 'Prepared',
  completed = 'Completed',
  canceled = 'Canceled',
}

export const deliveryFilterOptions: string[] = [
  PickUpTypesValues.takeAway,
  PickUpTypesValues.delivery,
  PickUpTypesValues.pickUp,
];
