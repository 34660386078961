/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ExternalStatusPanel from 'components/Plugins/ExternalStatusPanel';
import { Box } from '@mui/material';
import isElectronApplication from 'utils/applicationContainer/ApplicationContainerUtils';

const PluginComponent: React.FC = () => {
  const isDevelopmentMode = process.env.NODE_ENV === 'development';

  if (!isElectronApplication() || !isDevelopmentMode) {
    return <div />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <ExternalStatusPanel />
    </Box>
  );
};

export default PluginComponent;
