import React from 'react';
import { format } from 'date-fns';
import { TableRow, TableCell, Button } from '@mui/material';
import { DineInOrderStatus } from 'containers/Intake/IntakeConsts';
import { DineInOrdersPage, DineInOrder } from 'stores/DineIn/dineInTypes';
import { useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import buildClasses from './Table.css';

interface DineInOrdersTableRowsProps {
  dineInOrders: DineInOrdersPage;
  selectedDineInOrder?: DineInOrder;
  total?: number;
  orderStatus: DineInOrderStatus;
  onOpenOrder: (order: DineInOrder) => void;
  onFinalize: (id: string, total: number) => void;
  onCancel: (id: string) => void;
}

const DineInOrdersTableRows = ({
  dineInOrders,
  selectedDineInOrder,
  total,
  orderStatus,
  onOpenOrder,
  onFinalize,
  onCancel,
}: DineInOrdersTableRowsProps) => {
  const [t] = useTranslation('intake');
  const { classes } = buildClasses();

  const formatToDisplay = useAppSelector(getPaymentFormatter());

  return (
    <>
      {dineInOrders.pageItems.map((order) => {
        const modificationDate = format(new Date(order.modificationTimestamp), 'dd/MM/yyyy HH:mm');
        const isOrderSelected = selectedDineInOrder && selectedDineInOrder.id === order.id;
        const isFinalizeDisabled = isOrderSelected && order.total !== total;

        return (
          <TableRow
            hover
            key={`${order.id}`}
            onClick={() => onOpenOrder(order)}
            classes={{
              selected: classes.rowSelected,
            }}
            selected={isOrderSelected}
            data-testid={`dine-in-orders__table-row-${order.id}`}
          >
            <TableCell className={classes.tableCell} data-testid={`dine-in-orders__table-row-id-${order.id}`}>
              {order.tableIdentifier}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              data-testid={`dine-in-orders__table-row-modification-date-${order.id}`}
            >
              {modificationDate}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              data-testid={`dine-in-orders__table-row-order-status-${order.id}`}
            >
              {t(`${order.status}Order`)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.tableCell}
              data-testid={`dine-in-orders__table-row-price-${order.id}`}
            >
              {formatToDisplay(order.total)}
            </TableCell>
            {orderStatus === DineInOrderStatus.Opened && (
              <TableCell align="right" className={classes.tableActionCell}>
                <Button
                  className={classes.cancelButton}
                  color="black"
                  variant="outlined"
                  data-testid={`dine-in-orders__button-cancel-order-${order.id}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    onCancel(order.id);
                  }}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  color="primary"
                  className={classes.finalizeButton}
                  variant="contained"
                  data-testid={`dine-in-orders__button-finalize-order-${order.id}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    onFinalize(order.id, order.total);
                  }}
                  disabled={isFinalizeDisabled}
                >
                  {t('Finalize')}
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </>
  );
};

export default DineInOrdersTableRows;
