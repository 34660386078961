import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    selected: {
      backgroundColor: '#F5F5F5 !important',
    },
    statusCell: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    success: {
      color: palette.success.main,
    },
    pending: {
      color: palette.warning.main,
    },
    direct: {
      color: palette.info.main,
    },
  };
});

export default buildClasses;
