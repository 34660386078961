import React, { useEffect, useState } from 'react';
import IntakeCustomer from 'components/Intake/Receipt/Customer/IntakeCustomer';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import CustomerSearch from 'components/Intake/Receipt/Customer/CustomerSearch';
import DineInReceiptHeader from 'components/Intake/Receipt/DineIn/DineInReceiptHeader';
import { Box, ButtonBase, Typography } from '@mui/material';
import { LastPageOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { changeDeliveryType, setFinalizeTrigger, setIntakeStatus, setTableTag } from 'stores/Intake';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { canEditBasket } from 'stores/Intake/intake.selector';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { useFormContext } from 'react-hook-form';
import { submitPendingIteration } from 'stores/DineIn/dineIn-thunk.actions';
import { removeCustomerFromOrder } from 'stores/combined.actions';
import { useTranslation } from 'react-i18next';
import TableTagNumpadModal from 'components/Intake/Finalize/TakeAwayTypeSelector/TableTagNumpadModal';
import EmptyBasketPrompt from 'components/Intake/Receipt/EmptyBasketPrompt';
import buildClasses from './Receipt.css';
import ReceiptBarFooter from './Footer/ReceiptFooter';
import ReceiptBarControls from './ReceiptControls/ReceiptControls';
import ReceiptBarItems from './Items/ReceiptItems';
import { AvailableIntakeContents, IntakeStatuses, PickUpTypesValues } from '../IntakeConsts';
import ReceiptDineInContent from './DineIn/ReceiptDineInContent';
import ReceiptPersistentAlert from './ReceiptPersistentAlert/ReceiptPersistentAlert';

interface ReceiptProps {
  intakeStatus: IntakeStatuses;
  canChangePaymentMethod: boolean;
  onFinishOrderButtonClick: () => void;
  canEdit?: boolean;
  onSearchCustomer?: (query: string) => void;
  onIntakeWithoutProfile?: () => void;
  onHideReceipt?: () => void;
}
const Receipt: React.FC<ReceiptProps> = ({
  intakeStatus,
  onFinishOrderButtonClick,
  canEdit,
  canChangePaymentMethod,
  onSearchCustomer,
  onIntakeWithoutProfile,
  onHideReceipt,
}) => {
  const [t] = useTranslation('intake');
  const { classes } = buildClasses();
  const { activeDeliveryType, selectedOrderCustomer, activeIntakeTab, onSiteSettings } = useAppSelector(
    ({ intake }) => intake,
  );
  const { selectedDineInOrder } = useAppSelector((appState) => appState.dineIn);
  const { selectedStore } = useAppSelector((appState) => appState.stores);
  const basketEdition = useAppSelector(canEditBasket);
  const { connectivityStatus, intakeSettings, featureFlags } = useAppSelector(({ config }) => config);
  const { basketItems, basketData, basketCoupons, virtualReceipt } = useAppSelector((store) => store.basket);
  const dispatch = useAppDispatch();
  const [forceShowCustomerSearch, setForceShowCustomerSearch] = useState<boolean>(false);
  const [isTableTagModalVisible, setIsTableTagModalVisible] = useState(false);
  const isDineInOrder = !!selectedDineInOrder;
  const anyItemsInBasket = virtualReceipt.receiptProducts.length > 0 || virtualReceipt.receiptDiscounts.length > 0;
  // This will keep "restart order" button visible even when user removes manually all products.
  // Order has to be either restarted or finished to show the default view
  // For now lets hide it under fiscalization ff, if there won't be any issues we may detach it from FF as it seems it provides better UX than previous behavior
  const orderHasBeenStarted = basketData !== null && featureFlags.OfflineModule_Fiscalization === true;

  const isCustomerSearchEnabled = connectivityStatus === ConnectivityStatusEnum.Connected;
  const isCustomerSearchVisible =
    forceShowCustomerSearch ||
    (!anyItemsInBasket &&
      isCustomerSearchEnabled &&
      !selectedOrderCustomer?.profile?.id &&
      !isDineInOrder &&
      !orderHasBeenStarted);

  const { reset } = useFormContext<CheckoutDetailsForm>();
  const showEmptyBasketTitle = intakeSettings?.hideCustomerSearch === true;

  useEffect(() => {
    dispatch(changeDeliveryType());
  }, []);

  useEffect(() => {
    setForceShowCustomerSearch(false);
  }, [selectedOrderCustomer !== undefined]);

  function distinct(value: number, index: number, self: number[]): boolean {
    return self.indexOf(value) === index;
  }

  function determineSendDineInItemsIsVisible(): boolean {
    const dineInItems = selectedDineInOrder?.items ?? [];
    const dineInCoupons = selectedDineInOrder?.coupons ?? [];
    const distinctBasketItemsIds = basketItems.map((itm) => itm.splittedFromId ?? itm.id).filter(distinct);
    return (
      isDineInOrder &&
      (distinctBasketItemsIds.length > dineInItems.filter((itm) => !itm.isCancelled).length ||
        basketCoupons.length > dineInCoupons.length)
    );
  }

  function editOrderHandler(): void {
    canEdit && dispatch(setIntakeStatus(IntakeStatuses.productsSection));
  }

  function continueIntakeWithoutCustomerProfile(): void {
    if (onIntakeWithoutProfile) {
      setForceShowCustomerSearch(false);
      onIntakeWithoutProfile();
    }
  }

  function onCustomerSearchResultsChanged(query: string): void {
    if (onSearchCustomer) {
      onSearchCustomer(query);
    }
  }

  function onCustomerRemoveClicked(): void {
    reset({});
    dispatch(removeCustomerFromOrder());
    dispatch(recalculateBasket({}));
  }

  function dineInItemsSendHandler(): void {
    if (selectedStore) {
      dispatch(submitPendingIteration());
    }
  }

  const sendDineInItemsButtonVisible = determineSendDineInItemsIsVisible();

  const onCtaClick =
    intakeStatus === IntakeStatuses.finalizeOrder
      ? finalizeOrderHandler
      : canChangePaymentMethod &&
        (activeDeliveryType === PickUpTypesValues.takeAway || activeDeliveryType === PickUpTypesValues.dineIn) &&
        onSiteSettings?.isEatInPromptEnabledForToGo &&
        activeIntakeTab !== AvailableIntakeContents.Customer
      ? () => setIsTableTagModalVisible(true)
      : onFinishOrderButtonClick;

  function finalizeOrderHandler(): void {
    dispatch(setFinalizeTrigger(true));
  }
  function onTableTagSubmit(val: string): void {
    dispatch(setTableTag(val));
    setIsTableTagModalVisible(false);
    onFinishOrderButtonClick();
  }

  return (
    <>
      {isTableTagModalVisible && (
        <TableTagNumpadModal
          onClose={() => setIsTableTagModalVisible(false)}
          onSubmit={onTableTagSubmit}
          isMandatory={false}
        />
      )}
      <div className={classes.receiptContainer}>
        <ReceiptPersistentAlert />
        {isCustomerSearchVisible ? (
          <>
            {showEmptyBasketTitle ? (
              <EmptyBasketPrompt />
            ) : (
              <CustomerSearch
                onSearchClicked={onCustomerSearchResultsChanged}
                onIntakeWithoutProfileClick={continueIntakeWithoutCustomerProfile}
              />
            )}
          </>
        ) : (
          <>
            {' '}
            <div className={classes.topButtonsContainer}>
              {basketEdition && (
                <ReceiptBarControls
                  editOrder={editOrderHandler}
                  canEditOrder={intakeStatus === IntakeStatuses.productsSection}
                />
              )}
              {(activeIntakeTab === AvailableIntakeContents.Tables ||
                activeIntakeTab === AvailableIntakeContents.DineInOpenOrders) && (
                <ButtonBase className={classes.hideReceiptButton} onClick={onHideReceipt}>
                  <LastPageOutlined />
                </ButtonBase>
              )}
            </div>
            {isDineInOrder && <DineInReceiptHeader dineInOrder={selectedDineInOrder} />}
            {(isCustomerSearchEnabled || selectedOrderCustomer?.profile?.id) && !isDineInOrder && (
              <IntakeCustomer
                selectedOrderCustomer={selectedOrderCustomer}
                onSearchCustomerClick={() => {
                  setForceShowCustomerSearch(true);
                }}
                onCustomerRemoveClick={onCustomerRemoveClicked}
              />
            )}
            {isDineInOrder && selectedDineInOrder ? (
              <ReceiptDineInContent
                dineInOrder={selectedDineInOrder}
                virtualReceipt={virtualReceipt}
                itemPriceDetails={basketData?.itemsDetails ?? []}
                basketCoupons={basketCoupons}
              />
            ) : (
              <ReceiptBarItems
                receiptProducts={virtualReceipt.receiptProducts}
                receiptDiscounts={virtualReceipt.receiptDiscounts}
                canRemoveItems={intakeStatus === IntakeStatuses.productsSection}
              />
            )}
          </>
        )}
        <ReceiptBarFooter
          canEditBasket={basketEdition}
          canChangePaymentMethod={canChangePaymentMethod}
          canChangeDeliveryType={intakeStatus === IntakeStatuses.productsSection}
          summary={basketData?.summary}
          onFinishOrderButtonClick={onCtaClick}
          isFinishButtonVisible={
            !sendDineInItemsButtonVisible &&
            (intakeStatus === IntakeStatuses.productsSection || intakeStatus === IntakeStatuses.finalizeOrder)
          }
          deliveryType={activeDeliveryType}
          deliveryChargeDetails={basketData?.deliveryCharge}
          isDineInSendButtonVisible={sendDineInItemsButtonVisible}
          onDineInItemsSend={dineInItemsSendHandler}
        />
      </div>
    </>
  );
};

export default Receipt;
