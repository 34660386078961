import React from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailableIntakeContents } from 'containers/Intake/IntakeConsts';
import buildClasses from './IntakeContentSwitch.css';

interface IntakeContentSwitchProps {
  handleChange: (event: React.ChangeEvent<{}>, value: AvailableIntakeContents) => void;
  intakeContent: AvailableIntakeContents;
  availableContents: AvailableIntakeContents[];
  isVisible?: boolean;
}

const IntakeContentSwitch: React.FC<IntakeContentSwitchProps> = ({
  handleChange,
  intakeContent,
  availableContents,
  isVisible = true,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');

  if (!isVisible) return null;

  return (
    <AppBar position="static" className={classes.subNavbar}>
      <Tabs
        className={classes.subNavbarContent}
        classes={{
          indicator: classes.indicator,
        }}
        value={intakeContent}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        color="secondary"
        textColor="secondary"
        aria-label="scrollable force tabs example"
      >
        {availableContents.map((availableContent) => (
          <Tab
            data-testid={`intake__select_main_content_button_${availableContent}`}
            classes={{ root: classes.tabButton, selected: classes.tabButtonSelected }}
            key={availableContent}
            label={t(availableContent)}
            value={availableContent}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default IntakeContentSwitch;
