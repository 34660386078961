import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    stage: {
      backgroundSize: 'contain',
      backgroundColor: palette.black.main,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  };
});

export default buildClasses;
