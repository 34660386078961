import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import UseCustomerCreditModal from 'containers/Intake/Customer/CustomerCredit/UseCustomerCreditModal';
import { LocalStorageItems, getLocalStorageItem } from 'utils/localStorageUtils';
import { clearCustomerCreditDetails, setUseCustomerCredits } from 'stores/Customer/customer.slice';
import InvalidBasketModal from 'containers/Intake/Receipt/InvalidBasketModal/InvalidBasketModal';
import { restartOrder } from 'stores/combined.actions';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import GeneralPopup from 'components/Shared/Modal/GeneralPopup';
import { setManualPriceOverride } from 'stores/Intake';
import { clearCouponError } from 'stores/Coupons/coupons.slice';
import KeyboardScannerHandler from 'App/KeyboardScannerHandler/KeyboardScannerHandler';
import { useTranslation } from 'react-i18next';
import Intake from './Intake';
import ToGoIntake from './ToGoIntake';

const IntakeWindow: React.FC = () => {
  const [t] = useTranslation('intake');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const usingToGoIntake = getLocalStorageItem<boolean>(LocalStorageItems.useToGo);
  const useScanner = getLocalStorageItem<boolean>(LocalStorageItems.useScanner) ?? true;
  const { selectedOrderCustomer, manuallyFilledAddress, selectedOrderDeliveryTime, orderPlacementStatus } =
    useAppSelector(({ intake }) => intake);

  const { switchingStoreStatus } = useAppSelector((state) => state.stores);
  const { validationResult } = useAppSelector((state) => state.basket);
  const { couponCustomizationError } = useAppSelector((state) => state.coupons);
  const { customerCreditDetails } = useAppSelector((state) => state.customer);
  const checkoutDetailsForm = useForm<CheckoutDetailsForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      ...selectedOrderCustomer,
      ...manuallyFilledAddress,
      ...selectedOrderDeliveryTime,
    },
  });
  const { reset: resetCheckoutDetailsForm } = checkoutDetailsForm;

  useEffect(() => {
    if (orderPlacementStatus === 'success') {
      resetCheckoutDetailsForm({});
    }
  }, [orderPlacementStatus]);

  useEffect(() => {
    if (switchingStoreStatus === 'success') {
      resetCheckoutDetailsForm({});
    }
  }, [switchingStoreStatus]);

  return (
    <>
      <FormProvider {...checkoutDetailsForm}>
        {useScanner && <KeyboardScannerHandler />}
        {usingToGoIntake && <ToGoIntake />}
        {!usingToGoIntake && <Intake />}
        <UseCustomerCreditModal
          abort={() => {
            dispatch(setUseCustomerCredits(undefined));
            dispatch(clearCustomerCreditDetails());
          }}
          onConfirm={() => {
            dispatch(setManualPriceOverride(undefined));
            dispatch(setUseCustomerCredits(customerCreditDetails?.total));
            dispatch(clearCustomerCreditDetails());
            dispatch(recalculateBasket({}));
          }}
          showDialog={!!customerCreditDetails}
          customerCreditDetails={customerCreditDetails}
        />
        <InvalidBasketModal
          open={!!validationResult}
          invalidCoupons={validationResult?.invalidBasketCoupons ?? []}
          invalidProducts={validationResult?.invalidBasketItems ?? []}
          allProductsInvalid={
            (validationResult?.newRequestedBasket && validationResult.newRequestedBasket.length === 0) ?? false
          }
          onCancel={() => {
            resetCheckoutDetailsForm({});
            dispatch(restartOrder());
            history.push('/all-orders');
          }}
          onConfirm={() => {
            dispatch(
              recalculateBasket({
                requestedBasket: validationResult?.newRequestedBasket ?? [],
                coupons: validationResult?.newRequestedCoupons ?? [],
              }),
            );
          }}
        />
        <GeneralPopup
          showDialog={!!couponCustomizationError}
          headerKey={t('Coupon cannot be added to basket')}
          text={t(couponCustomizationError ?? '')}
          onSubmit={() => dispatch(clearCouponError())}
          onClose={() => dispatch(clearCouponError())}
        />
      </FormProvider>
    </>
  );
};

export default IntakeWindow;
