import React, { useEffect, useState } from 'react';
import { DefaultRoute } from 'App/Routes/routes-list';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getSynchronizationUpgradeStatus, loadAllConfig, UpgradeStatus } from 'stores/Config';
import StartupWrapper from 'App/Routes/DefaultCompontent';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';

const AppConfigComponent: React.FC = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [configError, setConfigError] = useState<string | undefined>();
  const { instanceType, appConfigStatus, configLoaded } = useAppSelector((state) => state.config);

  useEffect(() => {
    const statusTimer = setInterval(() => {
      dispatch(getSynchronizationUpgradeStatus());
    }, 1000);
    return (): void => {
      clearInterval(statusTimer);
    };
  }, []);

  useEffect(() => {
    if (appConfigStatus === UpgradeStatus.upToDate) {
      dispatch(loadAllConfig());
    } else if (appConfigStatus === UpgradeStatus.failure) {
      setConfigError(`${t('Upgrade failure')}\n${t('Contact administrator')}`);
    }
  }, [appConfigStatus]);

  useEffect(() => {
    if (appConfigStatus === UpgradeStatus.upToDate && configLoaded) {
      history.push(instanceType === 'Central' ? 'cashier-login' : DefaultRoute.path);
    }
  }, [appConfigStatus, configLoaded]);

  return (
    <StartupWrapper>
      <Box sx={{ my: 4, display: 'flex' }}>
        {configError ? <ErrorIcon /> : <JumpingDotsLoader />}
        <Typography sx={{ ml: 1 }} variant="h5">
          {configError ?? t('Getting configuration...')}
        </Typography>
      </Box>
    </StartupWrapper>
  );
};
export default AppConfigComponent;
