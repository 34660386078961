import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';

interface CashBalanceEntryProps {
  type?: 'normal' | 'negative' | 'positive';
  value: number | undefined;
  entryName: string;
}

const CashBalanceEntry: React.FC<CashBalanceEntryProps> = ({ entryName, value, type = 'normal' }) => {
  const [t] = useTranslation('common');
  const { palette } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  function getValueColor() {
    switch (type) {
      case 'negative': {
        return palette.red[700];
      }
      case 'positive': {
        return palette.green[700];
      }
      default:
        return 'black.main';
    }
  }

  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        background: palette.grey[50],
      }}
    >
      <Typography variant="body2">{t(entryName)}</Typography>
      <Typography variant="h6" color={getValueColor}>
        {type === 'positive' && '+'}
        {type === 'negative' && '-'}
        {formatToDisplay(value ?? 0)}
      </Typography>
    </ListItem>
  );
};
export default CashBalanceEntry;
