import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddressBookEntry, ProfileAddress } from 'stores/Intake/intakeTypes';
import buildClasses from './CustomerAddressBook.css';
import AddressBookItem from './AddressBookItem';
import FormPart from '../FormPart/FormPart';

interface CustomerAddressBookProps {
  addressBookEntries: AddressBookEntry[];
  onAddressSelected: (address: ProfileAddress) => void;
  onInputMethodChanged: () => void;
}

const CustomerAddressBook: React.FC<CustomerAddressBookProps> = ({
  addressBookEntries,
  onAddressSelected,
  onInputMethodChanged,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');

  function onAddressSelectedHandler(address: AddressBookEntry) {
    onAddressSelected(address);
  }

  return (
    <FormPart>
      <Typography variant="h6" gutterBottom className={classes.heading}>
        {t('Delivery Address')}
      </Typography>
      <div className={classes.addressesContent}>
        <div>
          {addressBookEntries.map((addr) => {
            return (
              <AddressBookItem
                key={addr.fullAddress?.trim()}
                address={addr}
                onAddressSelected={onAddressSelectedHandler}
              />
            );
          })}
        </div>
      </div>
      <Button
        className={classes.manualInputButton}
        data-testid="intake-finalize_button--add-new-address"
        onClick={onInputMethodChanged}
      >
        {t('Add new address')}
      </Button>
    </FormPart>
  );
};

export default CustomerAddressBook;
