import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    tableContainer: {
      boxShadow: 'none',
      position: 'relative',
    },
    legend: {
      background: palette.grey[100],
      padding: `${spacing(1)} ${spacing(3)}`,
      display: 'flex',
      flexDirection: 'row',
    },
    legendItem: {
      color: 'black',
      display: 'flex',
      paddingRight: spacing(4),
    },
    success: {
      marginRight: spacing(0.5),
      color: palette.success.main,
    },
    pending: {
      marginRight: spacing(0.5),
      color: palette.warning.main,
    },
    direct: {
      marginRight: spacing(0.5),
      color: palette.info.main,
    },
    tableBodyContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(255,255,255,0.6)',
      position: 'absolute',
    },
  };
});
export default buildClasses;
