import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { CashierPermission } from 'typings/Cashier';

export const getLoggedCashier = ({ cashier }: RootState) => cashier.loggedCashier;

export const getPermissionChecker = () =>
  createSelector(getLoggedCashier, (loggedCashier) => (accessType: CashierPermission) => {
    if (!loggedCashier || !accessType) return false;
    if (loggedCashier.permissions.some((el) => el === accessType)) return true;

    return false;
  });
