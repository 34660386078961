import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import { useForm, FieldError, Controller } from 'react-hook-form';
import { PrintingConfiguration } from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import { filePath } from 'utils/validation/ValidationConstants';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import { TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { getPrintersList } from 'stores/Setup/setup.thunk-actions';
import buildClasses from './PrintingSettingsGroup.css';

interface PrintingConfigurationErrors {
  foxit: FieldError;
  wkhtml: FieldError;
  printer: FieldError;
  usePrinter: FieldError;
  isRoll: FieldError;
  printCopies: FieldError;
  directUploadPrintCopies: FieldError;
}

interface PrintingConfigurationFields {
  foxit: string;
  wkhtml: string;
  printer: string;
  usePrinter: boolean;
  isRoll: boolean;
  printingCopies: number;
  directUploadPrintingCopies: number;
  printEmployeeNameOnReceipt: boolean;
}

export interface PrintingSettingsProps {
  onFormSubmit: (printingConfig: PrintingConfiguration) => void;
  predefinedSettings: PrintingConfiguration;
}

const PrintingSettingsGroup: React.FC<PrintingSettingsProps> = ({ onFormSubmit, predefinedSettings }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('settings');
  const [printAfterFinalize, setPrintAfterFinalize] = useState<boolean>(false);
  const [printEmployeeName, setPrintEmployeeName] = useState<boolean>(false);
  const { register, errors, setValue, control, handleSubmit } = useForm<PrintingConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const typedErrors = errors as PrintingConfigurationErrors;
  const { instanceType } = useAppSelector((store) => store.config);
  const { printersList } = useAppSelector((store) => store.setup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (predefinedSettings.local) {
      setValue('foxit', predefinedSettings.local.printingToolPath);
      setValue('wkhtml', predefinedSettings.local.htmlToPdfConverterPath);
      setPrintAfterFinalize(predefinedSettings.local.printReceiptsAfterOrderFinalization);
      setValue('printingCopies', predefinedSettings.local.printCopies);
      setPrintEmployeeName(predefinedSettings.general.printEmployeeNameOnReceipt);
    }
  }, []);

  function RenderInstanceSettings() {
    if (instanceType === 'Central') {
      return <></>;
    }

    return (
      <>
        <SettingItem
          label={t('Print receipt after order finalization?')}
          type="custom"
          testId="printing-settings__input--print-receipt-after-order"
        >
          <FilledSwitch
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
              setPrintAfterFinalize(checked)
            }
            checked={printAfterFinalize}
            inputRef={register()}
            name="usePrinter"
          />
        </SettingItem>
        <SettingItem
          label={t('Number of receipt copies')}
          type="custom"
          testId="printing-settings__input--print-copies"
        >
          <TextField
            inputRef={register({
              required: true,
              min: 1,
              max: 50,
            })}
            name="printingCopies"
            fullWidth
            variant="outlined"
            type="number"
            defaultValue="1"
            error={typedErrors.printCopies && true}
            helperText={typedErrors.printCopies && typedErrors.printCopies.message}
            inputProps={{ 'data-testid': 'printing-settings__input--print-copies-amount', min: '1' }}
          />
          <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
        </SettingItem>
        <SettingItem label={t('Select printer')} type="custom" testId="printing-settings__input--printers-list">
          <Controller
            as={
              <Select fullWidth variant="outlined">
                {printersList?.map((printer) => {
                  return (
                    <MenuItem key={printer} value={printer}>
                      {printer}
                    </MenuItem>
                  );
                })}
              </Select>
            }
            name="printer"
            rules={{ required: t('Printer must be selected!') as string }}
            control={control}
            defaultValue=""
          />
          <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
        </SettingItem>
        <SettingItem
          label={t('Foxit reader location')}
          type="custom"
          testId="printing-settings__input--foxit-location"
        >
          <TextField
            inputRef={register({
              required: true,
              pattern: { value: filePath(), message: t('Not a filepath') },
            })}
            name="foxit"
            fullWidth
            variant="outlined"
            placeholder="C:\Program Files (x86)\Foxit Software\Foxit Reader\FoxitReader.exe"
            error={typedErrors.foxit && true}
            helperText={typedErrors.foxit && typedErrors.foxit.message}
            inputProps={{ 'data-testid': 'printing-settings__--printer-path' }}
          />
          <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
        </SettingItem>
        <SettingItem
          label={t('HTML to PDF converter location')}
          type="custom"
          testId="printing-settings__input--wkhtml-location"
        >
          <TextField
            inputRef={register({
              required: true,
              pattern: { value: filePath(), message: t('Not a filepath') },
            })}
            name="wkhtml"
            fullWidth
            variant="outlined"
            placeholder="C:\Program Files\wkhtmltopdf\bin\wkhtmltopdf.exe"
            error={typedErrors.wkhtml && true}
            helperText={typedErrors.wkhtml && typedErrors.wkhtml.message}
            inputProps={{ 'data-testid': 'printing-settings__input--html-to-pdf-path' }}
          />
          <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
        </SettingItem>
      </>
    );
  }

  useEffect(() => {
    dispatch(getPrintersList());
  }, [predefinedSettings.local?.targetPrinterName]);

  useEffect(() => {
    if (printersList?.length > 0) {
      const selectedValue = predefinedSettings.local?.targetPrinterName || printersList[0];
      setValue('printer', selectedValue, { shouldValidate: true });
    }
  }, [printersList]);

  const onSubmit = ({
    foxit,
    wkhtml,
    usePrinter,
    printer,
    isRoll,
    printingCopies,
  }: PrintingConfigurationFields): void => {
    onFormSubmit({
      general: {
        printEmployeeNameOnReceipt: printEmployeeName,
        isRollPrinter: isRoll,
      },
      local: {
        localPrintingEnabled: !!printer,
        htmlToPdfConverterPath: wkhtml,
        printReceiptsAfterOrderFinalization: usePrinter,
        targetPrinterName: printer,
        printingToolPath: foxit,
        printCopies: printingCopies,
      },
    });
  };

  return (
    <form
      className={classes.form}
      id="printingSettingsForm"
      name="printingSettingsForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      {RenderInstanceSettings()}
    </form>
  );
};

export default PrintingSettingsGroup;
