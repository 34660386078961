import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import { Coupon } from 'typings/Coupons';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

export interface InapplicableCouponProps {
  open: boolean;
  incompatibleCoupons: Coupon[];
  onClose: () => void;
  onSuccess: (inputValue?: string) => void;
}

const InapplicableCouponModal: React.FC<InapplicableCouponProps> = ({
  onClose,
  open,
  onSuccess,
  incompatibleCoupons,
}) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');

  return (
    <ModalContainer
      onClose={onClose}
      open={open}
      title={t('Coupon cannot be added to basket')}
      primaryButton={{
        label: t('common:Proceed'),
        testId: 'inapplicable-coupon-logout-proceed',
        action: onSuccess,
      }}
      secondaryButton={{
        label: t('Cancel'),
        testId: 'inapplicable-coupon-cancel',
        action: onClose,
      }}
      testId="inapplicable-coupon-modal"
    >
      <Typography variant="body1" textAlign="center" sx={{ padding: spacing(3) }}>
        {t('Coupon cannot be added to basket as it is not compatible with :')}
        <br />
        <b>
          {incompatibleCoupons.map((ic) => (
            <>
              {ic.description}
              <br />
            </>
          ))}
        </b>
        {t('Adding this coupon will remove incompatible coupons from basket')}
      </Typography>
    </ModalContainer>
  );
};

export default InapplicableCouponModal;
