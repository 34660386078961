import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    form: {
      width: '100%',
    },
  };
});

export default buildClasses;
