import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, sizing, palette }) => {
  return {
    wrapper: {
      height: `calc(100vh - ${sizing.appBarHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: palette.background.default,
    },
    content: {
      overflowY: 'auto',
      height: '100%',
    },
    button: {
      height: sizing.buttonHeight.md,
    },
    editButtons: {
      padding: `${spacing(3)} ${spacing(4)}`,
    },
  };
});
export default buildClasses;
