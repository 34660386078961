import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EftSuccess as EftSuccessIcon } from 'assets/icons';

const EftSuccess: React.FC = () => {
  const [t] = useTranslation('common');
  const { spacing, palette, typography } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '374px',
        minHeight: '456px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Typography color="black.main" variant="h5" sx={{ m: 1 }} data-testid="settle-payment__label--eft-success">
        {t('Eft success')}
      </Typography>
      <EftSuccessIcon />
    </Box>
  );
};
export default EftSuccess;
