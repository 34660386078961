import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette }) => {
  return {
    strikeThrough: {
      position: 'relative',
      display: 'inline-block',
      color: palette.warning.main,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '40%',
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: palette.warning.main,
        transform: 'rotate(45deg)',
        transformOrigin: 'center',
      },
    },
  };
});

export default buildClasses;
