import React, { FC, useEffect } from 'react';
import { CloudDoneOutlined } from '@mui/icons-material';
import { Alert, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import SyncHistoryTable from 'components/DataSync/SyncHistory/SyncHistoryTable';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  DataSyncHistoryStatus,
  DataSyncStatus,
  getSyncHistory,
  getSyncStatus,
  runSynchronization,
} from 'stores/Config';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { format } from 'date-fns';
import { Box } from '@mui/system';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';

const DataSync: FC = () => {
  const [t] = useTranslation('dataSync');
  const { selectedStore } = useAppSelector((state) => state.stores);
  const { featureFlags, syncStatus, syncHistory } = useAppSelector((state) => state.config);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSyncHistory());
    const statusTimer = setInterval(() => {
      dispatch(getSyncStatus());
    }, 1000);
    return (): void => {
      clearInterval(statusTimer);
    };
  }, []);

  useEffect(() => {
    dispatch(getSyncHistory());
  }, [syncStatus?.status]);

  const inProgress = !syncStatus || syncStatus.status === DataSyncStatus.inProgress;

  function getSyncStatusMessage(): { isError: boolean; message: string } {
    if (syncHistory?.length > 0 && syncHistory[0].status === DataSyncHistoryStatus.failed) {
      return { isError: true, message: 'Last data download failed' };
    }

    if (!syncStatus) return { isError: true, message: 'Data not downloaded yet' };

    switch (syncStatus?.status) {
      case DataSyncStatus.dataNotDownloaded: {
        return { isError: true, message: 'Data not downloaded yet' };
      }
      case DataSyncStatus.updateRequired: {
        return { isError: true, message: 'Update is required!' };
      }
      case DataSyncStatus.inProgress: {
        return { isError: true, message: 'Data not downloaded yet' };
      }
      default: {
        return { isError: false, message: 'Up to date' };
      }
    }
  }

  const syncStatusMessage = getSyncStatusMessage();

  return (
    <Box>
      <Typography variant="h3" color="black.main" sx={{ mb: 2 }}>
        {t('Data download')}
      </Typography>
      {inProgress ? (
        <Box sx={{ my: 4, display: 'flex' }}>
          <JumpingDotsLoader />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {t('Downloading data...')}
          </Typography>
        </Box>
      ) : (
        <Typography variant="h5" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%', mb: 2 }}>
          <Alert
            severity={syncStatusMessage.isError ? 'error' : 'success'}
            icon={syncStatusMessage.isError ? <CancelOutlinedIcon /> : <CloudDoneOutlined fontSize="inherit" />}
          >
            {t(syncStatusMessage.message)}
          </Alert>
        </Typography>
      )}
      <Typography color="black.main" variant="body1" data-testid="status__label--storeid">
        {t('StoreID')} {selectedStore?.id}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {t('StoreName')} {selectedStore?.name}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {t('Last success')}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {syncStatus && syncStatus.lastSuccessfulSyncTimestamp
          ? format(new Date(syncStatus.lastSuccessfulSyncTimestamp), 'dd/MM/yyyy HH:mm:ss')
          : '-'}
      </Typography>
      {syncStatus && (
        <>
          <Typography color="black.main" variant="subtitle1" sx={{ my: 2 }}>
            {t('History')}
          </Typography>
          <SyncHistoryTable historyData={syncHistory} />
        </>
      )}
      <Button
        onClick={() => dispatch(runSynchronization())}
        variant="contained"
        fullWidth
        startIcon={<AutorenewIcon />}
        sx={{ mt: 2 }}
        disabled={inProgress}
      >
        {t('Refresh now')}
      </Button>
    </Box>
  );
};

export default DataSync;
