import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing, sizingBig }) => {
  return {
    root: {
      display: 'grid',
      gridTemplateRows: `${sizingBig(3)} auto ${sizingBig(2)}`,
      flex: 1,
      overflow: 'hidden',
    },
    customizationRoot: {
      flex: 1,
      height: `calc(100% - ${sizingBig(2)})`,
    },
    navigation: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0px ${spacing(3)}`,
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16)',
    },
    summaryBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0px ${spacing(3)}`,
      backgroundColor: palette.primary.main,
    },
    disabled: {
      pointerEvents: 'none',
      backgroundColor: palette.secondary.dark,
    },
    textField: {
      marginBottom: spacing(1),
    },
    content: {
      overflowX: 'auto',
      padding: spacing(4),
    },
  };
});
export default buildClasses;
