import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, radius }) => {
  return {
    tab: {
      padding: spacing(1),
      border: `1px solid ${palette.grey[100]}`,
      backgroundColor: palette.customGray.light,
      borderRadius: radius.sm,
      width: sizing.full,
      color: palette.black.main,
      cursor: 'pointer',
      '&:not(:last-child)': {
        marginRight: spacing(1),
      },
    },

    tabActive: {
      border: `1px solid ${palette.grey[200]}`,
      backgroundColor: palette.customGray.main,
    },
    badge: {
      width: sizing.badge.md,
      height: sizing.badge.md,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: radius.sm,
    },
    activeBadge: {
      color: palette.white,
      backgroundColor: palette.black.main,
    },
    requiredWarning: {
      color: palette.white,
      backgroundColor: palette.red[600],
    },

    badgeComplete: {
      color: palette.white,
      backgroundColor: palette.green[600],
    },
  };
});

export default buildClasses;
