import { Stage as StageRef } from 'konva/lib/Stage';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { FloorEntity, GuideLine } from 'typings/Tables';

const GUIDELINE_OFFSET = 5;

export function getLineGuideStops(skipShape: Shape<ShapeConfig> | StageRef, stageRef: StageRef) {
  if (!stageRef) return { vertical: [], horizontal: [] };

  // we can snap to stage borders and the center of the stage
  const vertical: number[][] = [];
  const horizontal: number[][] = [];

  // and we snap over edges and center of each object on the canvas
  stageRef.find('.object').forEach((guideItem) => {
    if (guideItem !== skipShape) {
      const box = guideItem.getClientRect();
      // and we can snap to all edges of shapes
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
    }
  });
  return {
    vertical: vertical.flat(),
    horizontal: horizontal.flat(),
  };
}

export function getObjectSnappingEdges(node: Shape<ShapeConfig> | StageRef) {
  const box = node.getClientRect();
  const absPos = node.absolutePosition();

  return {
    vertical: [
      {
        guide: Math.round(box.x),
        offset: Math.round(absPos.x - box.x),
        snap: 'start',
      },
      {
        guide: Math.round(box.x + box.width / 2),
        offset: Math.round(absPos.x - box.x - box.width / 2),
        snap: 'center',
      },
      {
        guide: Math.round(box.x + box.width),
        offset: Math.round(absPos.x - box.x - box.width),
        snap: 'end',
      },
    ],
    horizontal: [
      {
        guide: Math.round(box.y),
        offset: Math.round(absPos.y - box.y),
        snap: 'start',
      },
      {
        guide: Math.round(box.y + box.height / 2),
        offset: Math.round(absPos.y - box.y - box.height / 2),
        snap: 'center',
      },
      {
        guide: Math.round(box.y + box.height),
        offset: Math.round(absPos.y - box.y - box.height),
        snap: 'end',
      },
    ],
  };
}

// find all snapping possibilities
export function getGuides(
  lineGuideStops: {
    vertical: number[];
    horizontal: number[];
  },
  itemBounds: {
    vertical: {
      guide: number;
      offset: number;
      snap: string;
    }[];
    horizontal: {
      guide: number;
      offset: number;
      snap: string;
    }[];
  },
) {
  type Result = { lineGuide: number; diff: number; snap: string; offset: number };
  const resultV: Result[] = [];
  const resultH: Result[] = [];

  lineGuideStops.vertical.forEach((lineGuide) => {
    itemBounds.vertical.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      // if the distance between guild line and object snap point is close we can consider this for snapping
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide,
          diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide) => {
    itemBounds.horizontal.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide,
          diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  const guides: GuideLine[] = [];

  // find closest snap
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: 'V',
      snap: minV.snap,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: 'H',
      snap: minH.snap,
    });
  }
  return guides;
}

export function getBackgroundUrl(floor: FloorEntity): string | undefined {
  if (!floor.background) {
    return undefined;
  }

  if (floor.background.startsWith('blob:')) {
    return floor.background;
  }

  const cacheBreakerPart = floor.backgroundModificationTimestamp ? getBackgroundModificationTimestamp(floor) : '';

  return `${floor.background}${cacheBreakerPart}`;
}

function getBackgroundModificationTimestamp(floor: FloorEntity): string {
  if (floor.background?.includes('?')) return `&t=${floor.backgroundModificationTimestamp}`;

  return `?t=${floor.backgroundModificationTimestamp}`;
}

export function storeLastSelectedFloorId(id: string) {
  localStorage.setItem('floor_lastSelectedId', id);
}

export function getLastSelectedFloorId(): string | undefined {
  return localStorage.getItem('floor_lastSelectedId') ?? undefined;
}
