import { CustomizationProduct, CustomizationRestrictions, Product } from 'typings/Products';

export interface CouponState {
  couponsMasterData: Coupon[];
  couponToCustomize?: MealCoupon;
  bestSellerCoupons: BestSellerCoupon[];
  mealSettings?: MealSettings[];
  couponsForCurrentBasket: Coupon[] | MealCoupon[];
  pendingGetCouponsRequest: boolean;
  couponCustomizationError?: string;
}

export interface Coupon {
  description: string;
  couponCode: string;
  couponId: string;
  singleUseCouponCode?: string;
  isApplicable?: boolean;
  customizable?: boolean;
  useMealConfigurator: boolean;
  linkedBaskedItemIds: number[];
  price?: number;
  imageUrl: string;
  notApplicableWithCouponCodes: string[];
  notApplicableReason: NotApplicableCouponReason;
}

export enum NotApplicableCouponReason {
  None = 'None',
  CouponCombination = 'CouponCombination',
  DeliveryType = 'DeliveryType',
  OncePerOrderLimitation = 'OncePerOrderLimitation',
}

export interface MealCoupon extends Coupon {
  title: string;
  price: number;
  meals: Meal[];
  complete: boolean;
}

export interface BestSellerCoupon {
  coupon: Coupon;
  positionNumber: number;
}

export interface Meal {
  categoryName: string;
  defaultOptionId?: number;
  availableProducts: Product[];
  selectedProduct?: CustomizationProduct;
  customizationRestrictions?: CustomizationRestrictions;
}

export interface MealSettings {
  optionId?: number;
  productId?: number;
  quantity: number;
  categoryCode: string;
  groupCode: string;
  canChangeOption: boolean;
  allowedProductsIds: number[];
  allowedOptionsIds?: number[];
}
