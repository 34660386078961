import React from 'react';
import { Typography, Grid, TextField, debounce } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import {
  emailPattern,
  noBlankAtBeginningNoDigitsPattern,
  phoneNumberPattern,
} from 'utils/validation/ValidationConstants';
import FormPart from '../FormPart/FormPart';

interface CustomerDataFormProps {
  isPhoneRequired: boolean;
  onPhoneNumberChanged?: (phoneNumberValue: string | null) => void;
}

const CustomerDataForm: React.FC<CustomerDataFormProps> = ({ isPhoneRequired, onPhoneNumberChanged }) => {
  const { register, errors } = useFormContext<CheckoutDetailsForm>();
  const [t] = useTranslation('intake');
  const incorrectValueMessage = t('Incorrect value');
  const optimisedPhoneNumberChangedHandler = debounce(phoneNumberChanged, 500);
  const { palette, spacing } = useTheme();

  function phoneNumberChanged(value: string | null) {
    if (onPhoneNumberChanged) {
      onPhoneNumberChanged(value);
    }
  }

  function MaxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('max.')} ${max})`;
  }

  return (
    <FormPart>
      <Typography variant="h6" gutterBottom sx={{ color: palette.black.main, marginBottom: spacing(3) }}>
        {t('Customer data')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register({
              pattern: { value: noBlankAtBeginningNoDigitsPattern, message: incorrectValueMessage },
              maxLength: { value: 200, message: MaxLengthMessage(200) },
            })}
            name="name"
            fullWidth
            label={t('First Name')}
            variant="outlined"
            error={errors.name && true}
            helperText={errors.name && errors.name.message}
            inputProps={{ 'data-testid': 'finalize-customer-form__input--name' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register({
              pattern: { value: noBlankAtBeginningNoDigitsPattern, message: incorrectValueMessage },
              maxLength: { value: 200, message: MaxLengthMessage(200) },
            })}
            name="surname"
            fullWidth
            label={t('Last Name')}
            variant="outlined"
            error={errors.surname && true}
            helperText={errors.surname && errors.surname.message}
            inputProps={{ 'data-testid': 'finalize-customer-form__input--last-name' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register({
              required: { value: isPhoneRequired, message: t('Field required') },
              pattern: { value: phoneNumberPattern, message: incorrectValueMessage },
              maxLength: { value: 20, message: MaxLengthMessage(20) },
            })}
            name="telephoneNumber"
            fullWidth
            label={t('Phone Number')}
            variant="outlined"
            error={errors.telephoneNumber && true}
            helperText={errors.telephoneNumber && errors.telephoneNumber.message}
            inputProps={{ 'data-testid': 'finalize-customer-form__input--phone-number' }}
            onChange={(e) => {
              optimisedPhoneNumberChangedHandler(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register({
              pattern: { value: emailPattern, message: incorrectValueMessage },
              maxLength: { value: 250, message: MaxLengthMessage(250) },
            })}
            name="emailAddress"
            fullWidth
            label={t('Email Address')}
            variant="outlined"
            error={errors.emailAddress && true}
            helperText={errors.emailAddress && errors.emailAddress.message}
            inputProps={{ 'data-testid': 'finalize-customer-form__input--email-address' }}
          />
        </Grid>
      </Grid>
    </FormPart>
  );
};

export default CustomerDataForm;
