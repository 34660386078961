import React from 'react';
import { Input, FormControl, IconButton, useTheme } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

export interface SearchInputProps {
  fullWidth?: boolean;
  placeholder?: string;
  changeFunc: (value: string) => void;
  value: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ changeFunc, placeholder, fullWidth, value }) => {
  const { palette, spacing, typography } = useTheme();

  return (
    <FormControl fullWidth={fullWidth}>
      <Input
        disableUnderline
        onChange={(e) => changeFunc(e.target.value)}
        value={value}
        sx={{
          backgroundColor: palette.white,
          padding: spacing(1.5),
          paddingLeft: spacing(2),
          marginBottom: spacing(2),
          color: palette.text.primary,
          borderRadius: spacing(0.5),
          border: `1px solid ${palette.grey[100]}`,
          borderColor: palette.customGray.main,
          ...typography.body1,
          ':-ms-input-placeholder': {
            color: 'red',
          },
          ':placeholder': {
            color: 'red',
          },
        }}
        placeholder={placeholder || 'Search...'}
        id="input-with-icon-adornment"
        startAdornment={
          <Search
            sx={{
              color: palette.grey[600],
              fontSize: '1.5rem',
              marginRight: spacing(1),
            }}
          />
        }
        endAdornment={
          value ? (
            <IconButton sx={{ padding: spacing(0.5) }} onClick={() => changeFunc('')}>
              <Clear sx={{ color: palette.grey[600], fontSize: '1.5rem' }} />
            </IconButton>
          ) : (
            <></>
          )
        }
        inputProps={{ 'data-testid': 'search__input--search-field', style: { padding: 0 } }}
      />
    </FormControl>
  );
};

export default SearchInput;
