import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  changeDeliveryType,
  clearPhoneNumberSearchQuery,
  payForOrderWithEft,
  putAcceptTakeAwayPayment,
  setActiveIntakeTab,
  setCustomerSearchQuery,
  setManualDeliveryAddress,
  setTableTag,
  submitOrder,
} from 'stores/Intake';
import OrderRequiredInfoForm from 'components/Intake/Finalize/OrderRequiredInfoForm/OrderRequiredInfoForm';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import IntakeContentSwitch from 'components/Intake/Finalize/IntakeContentSwitch/IntakeContentSwitch';
import VisibilityContainer from 'components/Shared/Containers/VisibilityContainer';
import CouponCatalog from 'containers/Intake/Coupons/Coupons';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import uuidGenerator from 'utils/GuidGenerator';
import TakeAwayTypeSelector from 'components/Intake/Finalize/TakeAwayTypeSelector/TakeAwayTypeSelector';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

import { fetchAllCouponsData } from 'stores/Coupons';
import { postFinalizeDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import {
  EftPaymentOperation,
  PaymentMethodCode,
  PaymentSuccessfulResult,
  StartPaymentResult,
} from 'typings/Payments';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { restoreTransferredIntakeState } from 'stores/HybridMode/hybridMode.thunk-actions';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { removeCustomerFromOrder, restartOrder, fetchAllProductData } from 'stores/combined.actions';
import { clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import { FiscalContext } from 'App/AppEventsTracker/AppEventsTracker';

import ProductsContainer from './Products/Products';
import ReceiptBar from './Receipt/Receipt';
import buildClasses from './ToGoIntake.css';
import {
  AvailableIntakeContents,
  getAvailableToGoIntakeContents,
  IntakeStatuses,
  PickUpTypesValues,
} from './IntakeConsts';
import CustomerSearchResults from './Customer/CustomerSearchResults';
import { isBigScreenMediaQuery } from './Receipt/Receipt.css';
import DineInOrdersOverview from './DineInOrders/Overview';
import FloorsSection from './Floors/FloorsSection';
import MealConfigurator from './Products/MealConfigurator/MealConfigurator';

const ToGoIntake: React.FC = () => {
  const { classes, cx } = buildClasses();
  const { sizing } = useTheme();

  const [receiptVisible, setReceiptVisible] = useState<boolean>(true);
  const [orderId, setOrderId] = useState<string>(uuidGenerator());
  const isBigScreen = useMediaQuery(isBigScreenMediaQuery);
  const { currentFiscalSummary } = useContext(FiscalContext);

  const {
    activeDeliveryType,
    selectedOrderCustomer,
    activeIntakeTab,
    manualPriceOverride,
    phoneNumberSearchQuery,
    activePaymentMethod,
    orderPlacementStatus,
    customerSearchQuery,
  } = useAppSelector(({ intake }) => intake);
  const { selectedDineInOrder } = useAppSelector((store) => store.dineIn);
  const { productToCustomize } = useAppSelector((store) => store.products);
  const { couponToCustomize } = useAppSelector((store) => store.coupons);
  const { basketData } = useAppSelector((store) => store.basket);
  const { customerCanPayOnAccount, chargedCompany } = useAppSelector((store) => store.customer);
  const { selectedStore } = useAppSelector((appState) => appState.stores);
  const { featureFlags } = useAppSelector((store) => store.config);
  const { transferredIntake } = useAppSelector((store) => store.hybridMode);
  const { successfulTrigger, cancelTrigger, abandonTrigger } = useAppSelector((store) => store.orderPayment);

  const dispatch = useAppDispatch();
  const showCustomerSearch = customerSearchQuery !== undefined;

  const orderRequiredInfoFormRef = useRef<HTMLFormElement>(null);
  const { getValues, reset: resetCheckoutForm, setValue, trigger } = useFormContext<CheckoutDetailsForm>();

  const isTakeAwayOrder = () => activeDeliveryType === PickUpTypesValues.takeAway;

  const [t] = useTranslation('intake');

  function getFormData(): CheckoutDetailsForm {
    let finalizationData = { ...getValues() };

    if (selectedOrderCustomer?.profile?.id) {
      finalizationData = { ...finalizationData, profile: { ...selectedOrderCustomer.profile } };
    }

    return finalizationData;
  }
  function clearEftDataFromForm(): void {
    setValue('EftPaymentOperation', undefined);
  }

  const openSettlePaymentForm = (): void => {
    // for payment V2 take-away/dine-in/eat-in we always place unpaid order.
    if (basketData?.summary) {
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId,
          closeCallback: closeSettlePaymentForm,
          successCallback: finalizePayment,
          startPaymentCallback: startPayment,
          totalToPay: basketData.summary.total,
          useTwoStepOrderPlacing: selectedDineInOrder === undefined,
          openTabId: selectedDineInOrder?.id,
          orderFinalizationData: getFormData(),
          preselectedPaymentMethod: activePaymentMethod,
          deliveryType: activeDeliveryType,
          isLocalOrder: true,
          cancelOnClose: true,
          customerData: {
            payOnAccountAccount: {
              customerCanPayOnAccount,
              chargedCompany,
            },
          },
        }),
      );
    }
  };
  const closeSettlePaymentForm = (): void => {
    clearEftDataFromForm(); // to remove
    dispatch(clearSettlePayment()); // to remove with payment v2
    dispatch(clearEftPaymentData());
    setOrderId(uuidGenerator());
  };

  function finalizePayment(result: PaymentSuccessfulResult): void {
    const usePaymentAcceptance =
      result.orderAlreadyPlaced || (isTakeAwayOrder() && !!result.eftPaymentOperation?.eftPaymentId);

    if (usePaymentAcceptance) {
      acceptPayment(result.orderId, result.paymentMethodCode, result.eftPaymentOperation);
    } else {
      paymentSuccessful(result.orderId, result.paymentMethodCode, result.eftPaymentOperation);
    }
  }

  function startPayment(result: StartPaymentResult): void {
    const usePaymentAcceptance = isTakeAwayOrder() && !!result.eftPaymentId;
    if (usePaymentAcceptance) {
      if (activeDeliveryType === PickUpTypesValues.dineIn && selectedDineInOrder !== undefined) {
        return;
      }

      if (result.orderAlreadyPlaced) {
        dispatch(payForOrderWithEft());
      } else {
        setValue('EftPaymentOperation', {
          eftPaymentId: result.eftPaymentId,
          finishedManually: false,
        });
        submitForm();
      }
    }
  }

  function acceptPayment(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    dispatch(
      putAcceptTakeAwayPayment({
        orderId,
        paymentMethodCode: paymentMethod,
        eftPaymentOperation,
      }),
    );
    closeSettlePaymentForm();
    handleFinishedOrder();
  }

  function handleFinishedOrder(): void {
    clearEftDataFromForm(); // to remove, should not exit any data in form that is not handled via checkoutForm context
    dispatch(setActiveIntakeTab(AvailableIntakeContents.Products)); // to remove, should be in combined "restart order action"
    dispatch(removeCustomerFromOrder()); // to remove with payment V2
    dispatch(setManualDeliveryAddress()); // to remove with payment V2
    setOrderId(uuidGenerator());
    resetCheckoutForm({});
  }

  function onHideReceipt(): void {
    if (!isBigScreen) setReceiptVisible(false);
  }

  function onShowReceipt(): void {
    setReceiptVisible(true);
  }

  function submitForm(): void {
    if (!orderRequiredInfoFormRef.current) {
      return;
    }
    orderRequiredInfoFormRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
  }

  async function finishButtonClickedHandler(): Promise<void> {
    const isCheckoutFormValid = await trigger();
    if (!isCheckoutFormValid && activeDeliveryType !== PickUpTypesValues.dineIn) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Customer));
      return;
    }
    if (activeDeliveryType === PickUpTypesValues.takeAway || activeDeliveryType === PickUpTypesValues.dineIn) {
      openSettlePaymentForm();
    } else {
      submitForm();
    }
  }

  function paymentSuccessful(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    const formValues = getValues();
    if (selectedDineInOrder) {
      dispatch(
        postFinalizeDineInOrder({
          tabId: selectedDineInOrder.id,
          newOrderId: orderId,
          config: { paymentMethodCode: paymentMethod, eftPaymentOperation, manualPriceOverride },
        }),
      );
    } else {
      onFormSubmit(formValues, true);
    }
    closeSettlePaymentForm();
  }

  function onFormSubmit(data: CheckoutDetailsForm, paymentSettled = false): void {
    let finalizationData = { ...data };

    if (selectedOrderCustomer?.profile?.id) {
      finalizationData = { ...finalizationData, profile: { ...selectedOrderCustomer.profile } };
    }

    switch (activeDeliveryType) {
      case PickUpTypesValues.takeAway:
        if (
          activeDeliveryType === PickUpTypesValues.takeAway &&
          finalizationData.EftPaymentOperation?.eftPaymentId !== undefined &&
          finalizationData.EftPaymentOperation?.finishedManually === false
        ) {
          dispatch(
            submitOrder({
              orderId,
              data: finalizationData,
              placeUnpaid: true,
              fiscalSummary: currentFiscalSummary,
            }),
          );
        } else if (paymentSettled) {
          dispatch(
            submitOrder({
              orderId,
              data: finalizationData,
              fiscalSummary: currentFiscalSummary,
            }),
          );
        } else {
          openSettlePaymentForm();
        }
        break;
      default:
        dispatch(
          submitOrder({
            orderId,
            data: finalizationData,
            isAsap: finalizationData.time === t('asap'),
            fiscalSummary: currentFiscalSummary,
          }),
        );
    }
  }

  function switchIntakeContent(event: React.ChangeEvent<{}>, value: AvailableIntakeContents): void {
    switch (value) {
      case AvailableIntakeContents.Tables:
      case AvailableIntakeContents.DineInOpenOrders:
        if (!selectedDineInOrder) {
          dispatch(changeDeliveryType(PickUpTypesValues.takeAway));
          onHideReceipt();
        } else onShowReceipt();
        break;
      default:
        onShowReceipt();
    }

    dispatch(setActiveIntakeTab(value));
  }

  function onSearchCustomer(query: string): void {
    dispatch(setCustomerSearchQuery(query));
  }

  function onCancelCustomerSelecction(): void {
    dispatch(setCustomerSearchQuery(undefined));
  }

  function onOrderWithoutCustomerProfile(): void {
    dispatch(setCustomerSearchQuery(undefined));
  }

  function onCustomerSelected() {
    dispatch(setCustomerSearchQuery(undefined));
  }

  function saveTableTag(value?: string): void {
    dispatch(setTableTag(value));
  }

  useEffect(() => {
    if (productToCustomize) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }, [productToCustomize]);

  useEffect(() => {
    if (couponToCustomize) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Coupons));
    }
  }, [couponToCustomize]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const phoneNumber = searchParams.get('phone-number');
    if (phoneNumber) dispatch(setCustomerSearchQuery(phoneNumber));
  }, []);

  useEffect(() => {
    if (phoneNumberSearchQuery) {
      dispatch(setCustomerSearchQuery(phoneNumberSearchQuery));
      dispatch(clearPhoneNumberSearchQuery());
    }
  }, [phoneNumberSearchQuery]);

  useEffect(() => {
    if (transferredIntake && selectedStore) {
      dispatch(restoreTransferredIntakeState(transferredIntake));
    }
  }, [transferredIntake, selectedStore]);

  useEffect(() => {
    if (selectedStore) {
      dispatch(fetchAllProductData(selectedStore));
      dispatch(fetchAllCouponsData());
    }
    dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
  }, [selectedStore]);

  useEffect(() => {
    if (selectedDineInOrder) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
      onShowReceipt();
    } else if (
      activeIntakeTab === AvailableIntakeContents.DineInOpenOrders ||
      activeIntakeTab === AvailableIntakeContents.Tables
    ) {
      onHideReceipt();
    }
  }, [selectedDineInOrder]);

  useEffect(() => {
    if (orderPlacementStatus === 'success') {
      handleFinishedOrder();
    }
  }, [orderPlacementStatus]);

  useEffect(() => {
    if (successfulTrigger === orderId || abandonTrigger === orderId) {
      handleFinishedOrder();
      dispatch(restartOrder());
    }
    if (cancelTrigger === orderId) {
      closeSettlePaymentForm();
    }
  }, [successfulTrigger, cancelTrigger, abandonTrigger]);

  return (
    <div id="intake-window" className={classes.intakeWindow}>
      <div className={cx(classes.intakeContent, { [classes.intakeContentFullWidth]: !receiptVisible })}>
        <>
          {showCustomerSearch && (
            <CustomerSearchResults
              query={customerSearchQuery}
              onCancelCustomerSelection={onCancelCustomerSelecction}
              onIntakeWithoutProfileClick={onOrderWithoutCustomerProfile}
              onCustomerSelected={onCustomerSelected}
            />
          )}
          {couponToCustomize && !showCustomerSearch && <MealConfigurator />}
          {!couponToCustomize && !showCustomerSearch && (
            <>
              <IntakeContentSwitch
                handleChange={switchIntakeContent}
                intakeContent={activeIntakeTab}
                availableContents={getAvailableToGoIntakeContents(
                  featureFlags.OfflineModule_Restaurant,
                  featureFlags.OfflineModule_UseFloorPlanConfigurator,
                )}
                isVisible={productToCustomize === undefined}
              />
              {activeIntakeTab === AvailableIntakeContents.Products && (
                <Box className={classes.intakeProducts} sx={{ display: 'grid' }}>
                  <ProductsContainer onHideReceipt={onHideReceipt} onShowReceipt={onShowReceipt} />
                </Box>
              )}
              {activeIntakeTab === AvailableIntakeContents.Coupons && (
                <Box className={classes.intakeProducts} sx={{ display: 'grid' }}>
                  <CouponCatalog />
                </Box>
              )}
              {activeIntakeTab === AvailableIntakeContents.DineInOpenOrders && (
                <Box sx={{ display: 'grid' }}>
                  <DineInOrdersOverview onOrderClick={onShowReceipt} />
                </Box>
              )}
              {activeIntakeTab === AvailableIntakeContents.Tables && (
                <Box className={classes.intakeProducts} sx={{ display: 'block' }}>
                  <FloorsSection receiptVisible={receiptVisible} setReceiptVisibility={setReceiptVisible} />
                </Box>
              )}

              <VisibilityContainer isVisible={activeIntakeTab === AvailableIntakeContents.Customer}>
                <Box sx={{ overflowY: 'auto' }}>
                  <OrderRequiredInfoForm reference={orderRequiredInfoFormRef} onFormSubmit={onFormSubmit} />
                  {selectedStore?.enableEatInOrders === true &&
                    activeDeliveryType === PickUpTypesValues.takeAway && (
                      <TakeAwayTypeSelector onTableTagChange={saveTableTag} />
                    )}
                </Box>
              </VisibilityContainer>
            </>
          )}
        </>
      </div>
      <Drawer
        anchor="right"
        open={receiptVisible}
        variant="persistent"
        sx={{
          zIndex: 1100,
          '& .MuiDrawer-paper': {
            marginTop: `${sizing.appBarHeight}px`,
            overflowY: 'clip',
          },
        }}
      >
        <ReceiptBar
          canChangePaymentMethod
          intakeStatus={IntakeStatuses.productsSection}
          onFinishOrderButtonClick={finishButtonClickedHandler}
          onSearchCustomer={onSearchCustomer}
          onIntakeWithoutProfile={onOrderWithoutCustomerProfile}
        />
      </Drawer>
    </div>
  );
};

export default ToGoIntake;
