import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import BasketItemDetails from 'components/Shared/Basket/BasketItemDetails';
import { ReceiptProduct } from 'typings/Basket';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

interface BasketItemProps {
  receiptProduct: ReceiptProduct;
  discountColor?: string;
  alwaysExpanded?: boolean;
  index: number;
}

const BasketItem: React.FC<BasketItemProps> = ({ receiptProduct, discountColor, alwaysExpanded, index }) => {
  const { itemName, itemPrice, optionName, quantity } = receiptProduct;
  const [showDetails, setShowDetails] = useState<boolean>(alwaysExpanded ?? false);
  const itemIsXTasty =
    (receiptProduct.sliceCustomizations && receiptProduct.sliceCustomizations.length > 1) ?? false;
  const itemHasToppingCustomization: boolean = receiptProduct.sliceCustomizations
    ? receiptProduct.sliceCustomizations.length > 0 &&
      receiptProduct.sliceCustomizations.some(
        (el) =>
          (el.addedToppings && el.addedToppings.length > 0) ||
          (el.removedToppings && el.removedToppings.length > 0),
      )
    : false;
  const canShowDetails: boolean =
    itemHasToppingCustomization || itemIsXTasty || receiptProduct.remark !== undefined;

  const expandDetailsButtonVisible = canShowDetails && !alwaysExpanded;
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${palette.grey[200]}`,
      }}
      data-testid={`basket-item__wrapper--${receiptProduct.itemId}-${receiptProduct.optionId}-at-${index}`}
    >
      <Box
        sx={{
          px: 2,
          py: 1,
          backgroundColor: palette.grey[50],
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {discountColor && (
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: spacing(0.5),
              ml: spacing(-2),
              backgroundColor: discountColor,
            }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: palette.grey[200],
            borderRadius: spacing(1.5),
            width: spacing(3),
            height: spacing(3),
            mr: 2,
          }}
          data-testid="basket-item__field--quantity"
        >
          <Typography variant="subtitle2">{quantity}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2" color="black.main" data-testid="basket-item__field--item-name">
            {itemName}
          </Typography>
          <Typography variant="caption" color="grey.600" data-testid="basket-item__field--item-option-name">
            {optionName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {!discountColor && (
            <Typography variant="body1" color="black.main" data-testid="basket-item__field--gross-value">
              {formatToDisplay(itemPrice.total.grossValue)}
            </Typography>
          )}
          {expandDetailsButtonVisible && (
            <>
              {showDetails ? (
                <ExpandLess
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setShowDetails(false);
                  }}
                  data-testid="basket-item__button--show-less"
                />
              ) : (
                <ExpandMore
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setShowDetails(true);
                  }}
                  data-testid="basket-item__button--show-more"
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {showDetails && (
        <Box
          sx={{
            backgroundColor: palette.grey[100],
          }}
        >
          <BasketItemDetails
            customizations={receiptProduct.sliceCustomizations}
            stepSelections={receiptProduct.selectedSetSteps}
          />
          {receiptProduct.remark && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', px: 2, py: 0.75 }}
              data-testid="basket-item__field--remark"
            >
              <CommentOutlinedIcon sx={{ fill: palette.grey[600], mr: 2 }} />
              <Typography variant="body2" color="black.main">
                {receiptProduct.remark}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BasketItem;
