import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, transitions, spacing }) => {
  return {
    appBar: {
      boxShadow: 'none',
      height: sizing.appBarHeight,
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${sizing.sideMenuWidth}px)`,
      marginLeft: sizing.sideMenuWidth,
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    toolbarAction: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      flex: '1 1 auto',
    },
  };
});

export default buildClasses;
