import React, { useState } from 'react';
import { TableHead, TableRow, TableCell, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailableColumns, NotSortableColumns, submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { OrderColumn, resetSorting, setSorting } from 'stores/AllOrders';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const AllOrdersTableHead: React.FC = () => {
  const [t] = useTranslation('orders');
  const [hoveredColumn, setHoveredColumn] = useState<string | undefined>();
  const { palette, spacing } = useTheme();
  const { tableOptions } = useAppSelector((store) => store.allOrders);
  const dispatch = useAppDispatch();
  const { sortBy, sortingDirection } = tableOptions;

  const setSortValue = (column: OrderColumn) => {
    if (NotSortableColumns.some((el) => el === column.key)) return;

    if (sortBy === column.key) {
      if (sortingDirection === 'asc') {
        dispatch(
          setSorting({
            sortBy: column.key,
            sortingDirection: 'desc',
          }),
        );
        submitAllOrdersForm();
        return;
      }
      if (sortingDirection === 'desc') {
        dispatch(resetSorting());
        submitAllOrdersForm();
        return;
      }
    }

    dispatch(
      setSorting({
        sortBy: column.key,
        sortingDirection: 'asc',
      }),
    );
    submitAllOrdersForm();
  };

  return (
    <TableHead sx={{ backgroundColor: 'white' }}>
      <TableRow>
        {AvailableColumns.sort((a, b) => a.columnSortNumber - b.columnSortNumber).map((ac) => {
          return (
            <TableCell
              align={ac.align}
              key={ac.name}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setSortValue(ac);
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onMouseEnter={() => {
                  if (NotSortableColumns.some((el) => el === ac.key)) return;
                  setHoveredColumn(ac.key);
                }}
                onMouseLeave={() => {
                  if (NotSortableColumns.some((el) => el === ac.key)) return;
                  setHoveredColumn(undefined);
                }}
              >
                {t(ac.name)}
                <Box sx={{ height: spacing(3), width: spacing(3), ml: 0.5, color: palette.grey[500] }}>
                  {sortBy === ac.key ? (
                    <>
                      {sortingDirection === 'asc' && (
                        <ArrowDownwardIcon sx={{ height: spacing(3), width: spacing(3) }} />
                      )}
                      {sortingDirection === 'desc' && (
                        <ArrowUpwardIcon sx={{ height: spacing(3), width: spacing(3) }} />
                      )}
                    </>
                  ) : (
                    <>
                      {hoveredColumn === ac.key && (
                        <ArrowDownwardIcon sx={{ height: spacing(3), width: spacing(3) }} />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </TableCell>
          );
        })}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AllOrdersTableHead;
