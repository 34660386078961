import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { Alert, CircularProgress, useTheme, Box, Typography } from '@mui/material';
import { Done } from '@mui/icons-material';
import { DeviceInternalStatusEntry, DeviceInternalStatusType, EftPaymentTerminal } from 'typings/Payments';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getEftPaymentStatus, getEftPaymentTerminal } from 'stores/Eft';
import { clearCtmpStatus, clearTerminalLastStatus, getTerminalStatus, sendCtmpCommand } from 'stores/Payments';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { spacing } from '@mui/system';

function PaymentSettings(): JSX.Element {
  const { spacing } = useTheme();
  const [t] = useTranslation('settings');

  const { eftPaymentTerminal } = useAppSelector((state) => state.eft);
  const { ctmpCommandStatus, terminalLastStatus } = useAppSelector((state) => state.payments);
  const dispatch = useAppDispatch();

  const [eftDeviceAvailabilityRetrieved, setEftDeviceAvailabilityRetrieved] = useState<boolean>(false);
  const [successIconVisible, setSuccessIconVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getEftPaymentTerminal({ checkCtmp: true })).then(() => {
      setEftDeviceAvailabilityRetrieved(true);
    });
    return () => {
      dispatch(clearCtmpStatus());
      dispatch(clearTerminalLastStatus());
    };
  }, []);

  useEffect(() => {
    if (ctmpCommandStatus === 'SUCCESS') {
      showSuccessIcon();
    }
  }, [ctmpCommandStatus]);

  function showSuccessIcon(): void {
    setSuccessIconVisible(true);
    window.setTimeout(() => {
      setSuccessIconVisible(false);
    }, 2000);
  }

  function handleSendCtmpCommandClick(): void {
    dispatch(sendCtmpCommand());
  }

  function handleCheckEFTTerminalStatusClick(): void {
    dispatch(getTerminalStatus());
  }

  function getStatusTypeIcon(statusType: DeviceInternalStatusType) {
    switch (statusType) {
      case DeviceInternalStatusType.Correct:
        return <CheckIcon sx={{ marginRight: spacing(1), stroke: 'green' }} />;
      case DeviceInternalStatusType.Incorrect:
        return <CloseIcon sx={{ marginRight: spacing(1), stroke: 'red' }} />;
      case DeviceInternalStatusType.Warning:
        return <WarningAmberIcon sx={{ marginRight: spacing(1), stroke: 'orange' }} />;
      case DeviceInternalStatusType.Info:
        return <InfoOutlinedIcon sx={{ marginRight: spacing(1), stroke: 'blue' }} />;
      default:
        return <></>;
    }
  }

  const eftTerminalNotAvailable = !eftPaymentTerminal || eftPaymentTerminal === 'not-configured';
  const buttonMargin = `0 ${spacing(2)} 0`;

  return (
    <SettingGroup label={t('Eft payment settings')}>
      <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'flex-start' }}>
        <RoundedButton
          margin={buttonMargin}
          testId="payments-settings__button--start-ctmp-on-terminal"
          onClick={handleSendCtmpCommandClick}
          disabled={eftTerminalNotAvailable || eftPaymentTerminal.canStartCTMP === false}
        >
          {t('Start ctmp on terminal')}
        </RoundedButton>
        {eftTerminalNotAvailable && eftDeviceAvailabilityRetrieved && (
          <Alert sx={{ paddingTop: '0', paddingBottom: '0', border: '0' }} severity="warning">
            {t('Eft device not configured')}
          </Alert>
        )}
        {eftPaymentTerminal && (
          <>
            <Box
              component="span"
              sx={{ alignItems: 'center', display: 'inline-flex', marginRight: spacing(1), fontSize: 'large' }}
            >
              {(eftPaymentTerminal as EftPaymentTerminal).name}
            </Box>
            {ctmpCommandStatus === 'PENDING' && <CircularProgress color="primary" />}
            {successIconVisible && <Done color="primary" fontSize="large" />}
            {ctmpCommandStatus === 'FAILED' && <Alert severity="error">{t('CTMP command failed')}</Alert>}
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'row', marginTop: spacing(2) }}>
        <RoundedButton
          margin={buttonMargin}
          testId="payments-settings__button--check-eft-terminal-status"
          onClick={handleCheckEFTTerminalStatusClick}
          disabled={eftTerminalNotAvailable}
        >
          {t('Check EFT terminal status')}
        </RoundedButton>
        {terminalLastStatus && terminalLastStatus.additionalInfo && (
          <Alert sx={{ paddingTop: '0', paddingBottom: '0', border: '0' }} severity="warning">
            {t(terminalLastStatus.additionalInfo)}
          </Alert>
        )}
      </Box>
      <Box sx={{ marginTop: spacing(2), marginLeft: spacing(2) }}>
        {terminalLastStatus && (
          <Box sx={{ display: 'flex', flexFlow: 'column', width: '50%' }}>
            {Object.entries(terminalLastStatus.statusReport).map(
              (entry: [key: string, value: DeviceInternalStatusEntry]) => {
                return (
                  <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="body2">
                      <Box sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
                        {getStatusTypeIcon(entry[1].statusType)}
                        {entry[0]}
                      </Box>
                    </Typography>
                    <Typography variant="body2">{entry[1].value}</Typography>
                  </Box>
                );
              },
            )}
          </Box>
        )}
      </Box>
    </SettingGroup>
  );
}

export default PaymentSettings;
