import React from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { getPaymentFormatter, getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { HighlightOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { setActivePaymentStep } from 'stores/OrderPayment/orderPayment.slice';
import { getActivePayment, getIsActivePaymentCashType } from 'stores/OrderPayment/orderPayment.selector';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { getShowTippingModule } from 'stores/Config/config.selector';

const PaymentSummary: React.FC = () => {
  const [t] = useTranslation('common');
  const [tIntake] = useTranslation('intake');
  const [tError] = useTranslation('errorCodes');
  const { spacing, palette } = useTheme();
  const dispatch = useAppDispatch();
  const formatToDisplay = useAppSelector(getPaymentFormatter());
  const { payment } = useAppSelector(({ config }) => config);
  const { totalLeftAmount, totalPaidAmount, changeAmount, orderTotalAmount, selectedPaymentMethodGroupName } =
    useAppSelector(({ orderPayment }) => orderPayment);
  const { eftStatusResponse } = useAppSelector((state) => state.eftPayment);
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter());
  const tippingModuleAvailable = useAppSelector(getShowTippingModule);
  const activePayment = useAppSelector(getActivePayment);
  const isActiveCashPayment = useAppSelector(getIsActivePaymentCashType());
  const { watch } = useFormContext<OrderPaymentForm>();
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);

  // to do : move to selector and handle possible other errors
  const errorMessage = eftStatusResponse?.startPaymentErrorCode;
  const missingReceivedForTipMessage =
    activePayment?.paymentStep === 'provideTip' && !!changeAmount && changeAmount < 0;
  const authorizedPartially = eftStatusResponse?.authorizedAmount;
  const changeExists =
    cashInputNumberValue - totalLeftAmount > (isActiveCashPayment ? payment?.minimumChangeAmount ?? 0 : 0);
  const showTipping = tippingModuleAvailable && changeExists && activePayment?.paymentStep !== 'provideAmount';
  const isInContextOfSinglePayment = activePayment && activePayment?.paymentStep !== 'provideAmount';

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {selectedPaymentMethodGroupName &&
          activePayment?.paymentMethod?.groupName === selectedPaymentMethodGroupName && (
            <Box sx={{ mb: 2, color: palette.black.main }}>
              <Typography variant="body1" alignContent="space-between">
                {tIntake('You are paying now with')}
                <Typography variant="subtitle1" display="initial">
                  {activePayment?.paymentMethod?.description}
                </Typography>
              </Typography>
            </Box>
          )}
        <Box
          sx={{
            px: 2,
            py: 1.5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            background: palette.grey[100],
            alignItems: 'center',
            borderRadius: spacing(1),
          }}
        >
          <Typography variant="body1">{t('Amount due')}</Typography>
          <Typography variant="h4" sx={{ color: palette.grey[900] }} data-testid="finalize-payment_amount-due">
            {formatToDisplay(totalLeftAmount)}
          </Typography>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">{t('Total')}</Typography>
          <Typography variant="h6" sx={{ color: palette.grey[900] }} data-testid="finalize-payment_total">
            {formatToDisplay(orderTotalAmount)}
          </Typography>
        </Box>
        <Box sx={{ height: '1px', background: palette.grey[200] }} />
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">{t(isInContextOfSinglePayment ? 'Received' : 'Total received')}</Typography>
          <Typography variant="h6" sx={{ color: palette.grey[900] }} data-testid="finalize-payment_received">
            {formatToDisplay(isInContextOfSinglePayment ? activePayment?.customerPaidWith ?? 0 : totalPaidAmount)}
          </Typography>
        </Box>

        {showTipping && (
          <Box
            sx={{
              px: 2,
              py: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: palette.grey[50],
              borderRadius: spacing(1),
            }}
          >
            <Typography variant="body1">{t('Tip')}</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant="h6" sx={{ color: palette.grey[900] }} data-testid="finalize-payment_tip-amount">
                {formatToDisplay(activePayment?.tipValue ?? 0)}
              </Typography>
              {activePayment?.paymentStep === 'confirm' &&
                cashInputNumberValue > totalLeftAmount &&
                isActiveCashPayment && (
                  <EditIcon
                    onClick={() => {
                      dispatch(setActivePaymentStep('provideTip'));
                    }}
                  />
                )}
            </Box>
          </Box>
        )}
      </Box>
      {errorMessage && (
        <>
          <Typography variant="body1" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%' }}>
            <Alert severity="error" icon={<HighlightOff fontSize="inherit" />}>
              {tError(errorMessage)}
            </Alert>
          </Typography>
        </>
      )}
      {missingReceivedForTipMessage && (
        <>
          <Typography variant="body1" gutterBottom data-testid="pinpad-info" sx={{ width: '100%' }}>
            <Alert severity="info" icon={<HighlightOff fontSize="inherit" />}>
              {`${t('Missing')} ${formatToDisplay(-changeAmount)} ${t('will be added to received value')}`}
            </Alert>
          </Typography>
        </>
      )}
      {authorizedPartially && (
        <>
          <Typography variant="body1" gutterBottom data-testid="pinpad-info" sx={{ width: '100%' }}>
            <Alert severity="warning" icon={<HighlightOff fontSize="inherit" />}>
              {`${tIntake('Amount covered partially')}`}
            </Alert>
          </Typography>
        </>
      )}
    </Box>
  );
};
export default PaymentSummary;
