import { PaymentConfiguration } from '../stores/Config/configTypes';
import { useAppSelector } from './useRedux';

// TODO remove this hook, and get data from redux directly in components
interface UsePaymentSettings {
  GetPaymentSettings: () => PaymentConfiguration;
}

export default function usePaymentSettings(): UsePaymentSettings {
  const { payment } = useAppSelector(({ config }) => config);
  function GetPaymentSettings() {
    if (payment) {
      return payment;
    }

    throw new Error('Payment settings are not available');
  }

  return {
    GetPaymentSettings,
  };
}
