/* eslint-disable react/no-array-index-key */
import React, { ReactComponentElement, ReactElement } from 'react';
import { Box, ButtonBase, SxProps, Theme, Typography, useTheme } from '@mui/material';

export interface KeyboardKey {
  displayName: string;
  value?: string;
  numberValue?: number;
  action?: () => void;
  numSx?: SxProps<Theme> | undefined;
  component?: ReactElement;
}

interface NumericKeypadProps {
  onChange: (value: string) => void;
  keys: KeyboardKey[];
  disabled?: boolean;
}

const NumericKeypad: React.FC<NumericKeypadProps> = ({ onChange, keys, disabled }) => {
  const { spacing, palette, typography } = useTheme();

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          flex: 1,
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        {keys.map((key) => {
          return (
            <ButtonBase
              key={`numeric_keypad_${key.displayName}`}
              sx={{
                userSelect: 'none',
                color: palette.black.main,
                background: palette.grey[100],
                borderRadius: spacing(1),
                height: '80px',
                width: `calc(33% - 10px)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
                ...typography.h6,
                ...key.numSx,
              }}
              data-testid={`numeric_keypad__button--${key.displayName}`}
              onClick={(): void => {
                if (key.value) onChange(key.value);
                if (key.action) key.action();
              }}
            >
              {key.component ? key.component : key.displayName}
            </ButtonBase>
          );
        })}
      </Box>
    </>
  );
};
export default NumericKeypad;
