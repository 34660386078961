import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, palette, spacing }) => {
  return {
    wrapper: {
      height: sizing.pinPad.height,
      overflow: 'hidden',
    },
    employeeListContainer: {
      maxHeight: '78%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    emptyListAlert: {
      padding: spacing(2),
      background: palette.grey[50],
      borderRadius: spacing(1),
    },
  };
});
export default buildClasses;
