import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { RoundedButton } from 'components/Shared/Buttons';
import { useTranslation } from 'react-i18next';

interface NoFloorAddedProps {
  onAdd(): void;
}

const NoFloorAdded = ({ onAdd }: NoFloorAddedProps) => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('intake');

  return (
    <Box sx={{ padding: spacing(4), display: 'flex', backgroundColor: palette.background.default, width: '100%' }}>
      <Box sx={{ margin: 'auto', maxWidth: '210px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ marginBottom: spacing(3) }}>
          {t('You haven’t created floor plan yet')}
        </Typography>
        <RoundedButton
          testId="tables__button--add-first-floor"
          onClick={() => {
            onAdd();
          }}
        >
          {t('Create a floor plan')}
        </RoundedButton>
      </Box>
    </Box>
  );
};

export default NoFloorAdded;
