import React from 'react';
import { Typography } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { formatTimeToDisplay } from 'utils/localisation/dateTimeUtils';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';

interface HybridModePanelProps {
  culture: string;
  lastCloudPosConnectionAttempt?: Date;
  isOnlinePosAvailable: boolean;
}

const HybridModePanel: React.FC<HybridModePanelProps> = ({
  culture,
  lastCloudPosConnectionAttempt,
  isOnlinePosAvailable,
}) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('common');

  const lastConnectionAttempt = lastCloudPosConnectionAttempt
    ? formatTimeToDisplay(lastCloudPosConnectionAttempt, culture)
    : formatTimeToDisplay(new Date(), culture);

  const statusMessage = isOnlinePosAvailable ? t('Offline Mode') : t('Server disconnected');

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: palette.primary.dark,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          borderRadius: `0px 0px ${spacing(1)} ${spacing(1)}`,
          padding: `${spacing(0.5)} ${spacing(2)} ${spacing(0.5)}`,
        }}
      >
        <CloudOffIcon fontSize="medium" color="info" sx={{ mr: 1, color: palette.white }} />
        <Box sx={{ display: 'inline-grid' }}>
          <Box sx={{ display: 'inline-flex' }}>
            <Typography variant="subtitle1">{statusMessage}</Typography>
          </Box>
          {!isOnlinePosAvailable && (
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant="caption" color="white">
                {t('Last connection attempt')} {lastConnectionAttempt}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default HybridModePanel;
